import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { CrudService } from 'src/app/api/crud.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { Column } from 'src/app/model/crud-response';
import { EntityConfig } from 'src/app/model/entity-config';

@Component({
  selector: 'app-insert-page',
  templateUrl: './insert-page.component.html',
  styleUrls: ['./insert-page.component.css']
})
export class InsertPageComponent implements OnInit {
  entityConfig: EntityConfig;
  private idValue: boolean;
//  private navigateBack: string;

  constructor(private router: Router,
    private crudService: CrudService,
    private dateTimeUtility: DateTimeUtility) { }

  ngOnInit(): void {
    const entityConfig: EntityConfig = JSON.parse(localStorage.getItem('entityConfig'));
    // localStorage.removeItem('entityConfig');
    this.entityConfig = entityConfig;
    this.idValue = this.entityConfig.columns.filter(col => col.id == 'id')[0].value;
    console.log('entityConfig', this.entityConfig);
  }

  public filterColumn(columns: Column[]): Column[] {
    return columns
      .filter(column => /*column.id != 'id' &&*/ column.showOnForm)
      .filter(column => {
        const format = column.format == null ? 'text' : column.format;
        return format != 'password';
      }
      );
  }
 
  public resolveType(column: Column): string {
    if (column.format == null) return 'text';
    const format = column.format.toLowerCase();
    if (format == 'password') return column.format;
    if (format.startsWith('num')) return 'number';
    return 'text';
  }

  public isSelectField(column: Column): boolean {
    return column.externalChild != null;
  }

  public isBooleanField(column: Column): boolean {
    if (column.format == null) return false;
    const format = column.format.toLowerCase();
    return format.startsWith('bool') || format.startsWith('logic');
  }

  public isDateField(column: Column): boolean {
    if (column.dataType == null) return false;
    const dataType = column.dataType.toLowerCase();
    return dataType.endsWith('date');
  }

  public goBack(): void {
    localStorage.setItem('tabSelected', this.entityConfig.dynamicInfo.filter(kv => kv.key == 'tabSelected')[0].value);
    const navigateBack = this.entityConfig.dynamicInfo.filter(kv => kv.key == 'navigateBack')[0].value
    this.router.navigate([navigateBack]);
  }

  public doSave(): void {
    this.clearFieldDate();

    if (this.idValue == null) {
      this
        .crudService
        .insertRecord(this.entityConfig.alias,
          this
            .entityConfig
            .columns
            .filter(column => column.showOnForm)
        ).subscribe(
          response => {
            console.log(response);
            this.goBack();
          },
          problems => console.log(problems)
        );
    } else {
      console.log('Llamando al servicio para update');
      console.log(this.entityConfig.alias);
      console.log(this
        .entityConfig
        .columns
        .filter(column => column.showOnForm || column.id == 'id')
      );
      this
        .crudService
        .updateRecord(this.entityConfig.alias,
          this
            .entityConfig
            .columns
            .filter(column => column.showOnForm || column.id == 'id')
        ).subscribe(
          response => {
            console.log(response);
            this.goBack();
          },
          problems => console.log(problems)
        );
    }
  }

  private clearFieldDate(): void {
    this
      .entityConfig
      .columns
      // .filter(column => column.showOnForm || column.id == 'id')
      .forEach(column => {
        if (column.dataType.toLowerCase().endsWith('date')) {
          const mm = this.dateTimeUtility.stringToMoment(column.value);
          const dd = this.dateTimeUtility.momentToDate(mm);
          column.value = dd;
          // } else if (column.value == null) {
          //   column.value = '';
        }
      });
  }






}
