import { Adulto } from "./adulto";
import { Alumno } from "./alumno";

export class StudentAdult {
    id: string;
    studentId: string;
    adultoId: string;
    student: Alumno = new Alumno();
    adulto: Adulto = new Adulto();
    relacion: string;

}