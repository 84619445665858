import {
  Component,
  EventEmitter, Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';

@Component({
  selector: 'com-course-selector',
  templateUrl: './course-selector.component.html',
  styleUrls: ['./course-selector.component.css']
})
export class CourseSelectorComponent implements OnInit, OnChanges {
  @Input() public courseId: string;
  @Input() public teacherId = null;
  @Input() public subjectId = null;
  @Input() public showTeacherName: boolean = false;
  @Input() public showSpecialityName: boolean = true;
  @Input() public year: number = 0;

  @Output() courseChangeEvent = new EventEmitter<string>();

  @Output() courseSelectEvent = new EventEmitter<Course>();

  courses: Course[] = [];

  constructor(public courseService: CourseService) { }

  ngOnInit(): void {
    if (this.teacherId != null && this.subjectId != null) {
      this.courseService.listByTeacherAndSubject(this.teacherId, this.subjectId)
        .subscribe(
          response => this.courses = response,
          problems => console.error(problems)
        );
    } else {
      // console.log('YEAR: ', this.year);

      if (this.year > 0) {
        this.courseService.listByYear(this.year)
          .subscribe(
            (response: Course[]) => {
              // console.log(response);
              this.courses = response;
            });
      } else {
        this.courseService.listAll()
          .subscribe(
            (response: Course[]) => {
              // console.log(response);
              this.courses = response;
            });
      }
    }
  }

  ngOnChanges(sc: SimpleChanges) {
    //    console.log('CourseSelectorComponent.ngOnChanges', sc);
    if (sc.courseId === undefined) return;
    if (sc.courseId.firstChange || sc.courseId.currentValue == '') return;
    //    this.module = null;
    /*         this.refreshModules();
            this.module = null;
            this.moduleId = null;
     */
  }

  onChangeCourse() {
    this.courseChangeEvent.emit(this.courseId);
    if (this.courseId == '') {
      this.courseSelectEvent.emit(null);
    } else {
      this.courseSelectEvent.emit(this.courses.filter(course => course.id == this.courseId)[0]);
    }
  }

}
