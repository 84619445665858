  <h1>Administración de practicas</h1>
  <br>
  <mat-grid-list cols="2" rowHeight="300">
    <mat-grid-tile id="tile-test" md-rowspan="1" md-colspan="1"  >
      <div class="container responsive-grid">
      <mat-card class="example-card" >
        <mat-card-header>
          <mat-card-title>Empresas</mat-card-title>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h1 style="font-size:4vw">{{totalCompany}}</h1>
        </mat-card-content>
        <mat-button-toggle-group>
          <mat-button-toggle value="bold" (click)="listCompaniesOnClick()">LISTAR ACTIVAS</mat-button-toggle>
          <mat-button-toggle value="bold" (click)="listAllCompaniesOnClick()">LISTAR TODAS</mat-button-toggle>
          <mat-button-toggle value="bold" (click)="addCompanyOnClick()">AGREGAR</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-card>
      </div>
    </mat-grid-tile>
    <mat-grid-tile md-rowspan="1" md-colspan="1">
      <div class="container responsive-grid">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-title>Practicas {{practicaActual}}</mat-card-title>
          <mat-card-subtitle>   </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card-content>
            <h1 style="font-size:4vw">{{totalPractices}}</h1>
          </mat-card-content>
        </mat-card-content>
        <mat-button-toggle-group>
          <mat-button-toggle value="bold" (click)="openDialog()">LISTAR</mat-button-toggle>
          <mat-button-toggle value="bold" (click)="listAllCompaniesOnClick('ADD_PRACTICE')">CREAR</mat-button-toggle>
        </mat-button-toggle-group>
      </mat-card>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="2" rowHeight="400">
    <mat-grid-tile md-rowspan="1" md-colspan="1">
      <div class="container responsive-grid">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-subtitle>Histórico de Prácticas por Empresas</mat-card-subtitle>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card-content>
            <div class="chart-container">
              <canvas  id="MyChart2" >{{ chart }}</canvas>
            </div>
          </mat-card-content>
        </mat-card-content>
      </mat-card>
      </div>
    </mat-grid-tile>
    <mat-grid-tile md-rowspan="1" md-colspan="1">
      <div class="container responsive-grid">
      <mat-card class="example-card">
        <mat-card-header>
          <mat-card-subtitle>Cantidad de Prácticas por año</mat-card-subtitle>
          <mat-card-subtitle></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <mat-card-content>
            <div class="chart-container">
              <canvas  id="MyChart" >{{ chart }}</canvas>
            </div>
          </mat-card-content>
        </mat-card-content>
      </mat-card>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
