import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from '../model/user';
import { AuthorService } from './author.service';
import { Course } from "../model/course";
import { Teacher } from "../model/teacher";

@Injectable({
    providedIn: 'root'
})

export class TeacherService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/teacher';

    private listByCourseAndSubjectUrl: string = '/1.0/find-by-course-and-subject';
    private listUrl: string = '/1.0';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listByCourseAndSubject(courseId: string, subjectId: string): Observable<User[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listByCourseAndSubjectUrl}/${courseId}/${subjectId}`;
        return this.http.get<User[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAll(): Observable<Teacher[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listAll}`;
        return this.http.get<Teacher[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}