import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { PermissionService } from 'src/app/api/permission.service';
import { Course } from 'src/app/model/course';
import { StudentAverages, SubjectAverage, SubjectNames, SummaryReport } from 'src/app/model/summary-report/course-summary-report';
import { DownloadUtility } from 'src/app/utility/download.utility';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.css', '../../../style/table.css']
})
export class SummaryReportComponent implements OnInit, OnChanges {
  @Input()
  public course: Course;

  @Input()
  public canChangeCourse: boolean;

  @Input()
  public periodSelected: number;

  public report: SummaryReport;
  public borderSize: number = 0;
  public showTables: boolean = true;
  public showAttendance: boolean = true;
  public courses: Course[];
  private periodsCounter: number = 0;
  public periods: number[] = [];
  public limitScore: number = 0;
  public canDownloadSummaryReport: boolean = false;

  constructor(public courseService: CourseService,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private downloadUtility: DownloadUtility) { }

  ngOnInit(): void {
    console.log('INIT');
    if (this.canChangeCourse) {
      this.configService.getCurrentYear()
        .subscribe(
          response => {
            this.courseService.listByYear(response)
              .subscribe(
                (response: Course[]) => {
                  this.courses = response;
                },
                problems => console.error(problems)
              )
          },
          problems => console.error(problems)
        );
    }

    this.configService.findByKey('nota_limite')
      .subscribe(
        response => {
          this.limitScore = +response.valor;
        },
        problems => console.error(problems)
      );
    this.loadPeriodsConfig();
  }

  private async loadPeriodsConfig() {
    this.periodsCounter = +(await this.configService.findByKeyAsync('periodos')).valor;

    for (let i = 0; i < this.periodsCounter; i++) {
      this.periods.push(i + 1);
    }
    this.periods.push(0);

    this.periodSelected = +(await this.configService.findByKeyAsync('periodo_actual')).valor;

    this.canDownloadSummaryReport = await this.permissionService.hasPermissionAsync('HOME.DOWNLOAD-SUMMARY-REPORT');
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.course.currentValue == null) return;
    this.refreshData(simpleChanges.course.currentValue.id, this.periodSelected);
  }

  private refreshData(courseId: string, period: number): void {
    this.report = null;
    this.courseService.summaryReport(courseId, period)
      .subscribe(
        (response: SummaryReport) => {
          console.log('Response: ', response);
          this.report = response;
        },
        problems => console.error('PROBLEMS: ', problems)
      );
  }

  public onChangeCourse(ev) {
    this.refreshData(ev.value.id, this.periodSelected);
  }

  public notAverage(sa: SubjectAverage[]): SubjectAverage[] {
    return sa.filter(s => !s.subjectId.startsWith('StudentAverage'));
  }

  public onlyAverage(subjectAverages: SubjectAverage[], periodNumber: number): number {
    const period: string = '' + periodNumber; // this.getPeriodName();
    const sa: SubjectAverage[] = subjectAverages.filter(s => s.subjectId == 'StudentAverage' + period);
    return sa.length > 0 ? sa[0].average : 0;
  }

  private getPeriodName(): string {
    return ('' + this.periodSelected);
  }

  public numberOrEmpty(n: number): string {
    return n == 0 ? '' : n.toFixed(1);
  }

  public removeStash(shortName: string): string {
    return shortName.replace('_', ' ');
  }

  public redColorIfApply(n: number, limit: number, rowN?: number): string {
    const style: string = 'text-align: center; width: 50px;';
    const value: number = n == null ? 0 : +n.toFixed(1);
    return style + (value < limit ? 'color:red' : '');
  }

  public prevPeriods(): number[] {
    const periods: number = this.periodSelected == 1 ? 0 : this.periodSelected - 1;
    let periodsArray: number[] = [];
    for (let i = 0; i < periods; i++) {
      periodsArray.push(i + 1);
    }
    return periodsArray;
  }

  public prevPeriodsPlusCurrent(): number[] {
    let periodsArray: number[] = [];
    periodsArray.push(this.periodSelected);
    periodsArray.push(...this.prevPeriods());
    return periodsArray;
  }

  public onChangePeriod(ev) {
    this.refreshData(this.course.id, this.periodSelected);
  }

  public getGeneralAverage(n: number): number {
    return this.report.averages.filter(sa => sa.subjectId == `StudentAverage${n}`)[0].average;
  }

  public downloadAsExcel(): void {
    this.courseService.downloadSummaryReport(this.course.id, this.periodSelected)
      .subscribe(
        (response: ArrayBuffer) => this.downloadUtility.download(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'resumen-curso.xlsx'),
        problems => console.error(problems)
      );
  }

  public translateToLeter(s: SubjectAverage): string {
    const n: number = s.average;
    if (n == 0) return '';

    const showAsLeter: boolean = this.report.subjects.filter(subject => subject.id == s.subjectId)[0].showAsLeter;

    if (!showAsLeter) return '' + n;

    let calification: string = '';
    if (n > 0 && n < 4) {
      calification = 'I';
    } else if (n >= 4 && n < 5) {
      calification = 'S';
    } else if (n >= 5 && n < 6) {
      calification = 'B';
    } else if (n >= 6) {
      calification = 'MB';
    }

    return calification;
  }

  public sumAverages(student: StudentAverages): string {
    let result: number = 0;

    const aStudent: StudentAverages[] = this.report.students.filter(st => st.studentId == student.studentId);
    aStudent[0].scores
      .filter(score => !score.subjectId.startsWith('StudentAverage'))
      .filter(score => this.report.subjects.filter(subject => subject.id == score.subjectId)[0].doesAverage)
      .forEach(score => result += score.average);
    return result.toFixed(2).toString();
  }

  public failedSubjects(student: StudentAverages): number {
    let failed: number = this.report.students
      .filter(studentAvg => studentAvg.studentId == student.studentId)[0].scores
      .filter(score => !score.subjectId.startsWith('StudentAverage'))
      .filter(score => score.average > 0)
      .filter(score => +score.average.toFixed(1) < this.limitScore)
      .length;
    return failed;
  }

  public getUnenoughCounter(): number {
    let counter: number = 0;
    this.report.scoreSummary.unenough.forEach(sa => counter += sa.average);
    return counter;
  }

  public getUnenoughPercentCounter(): number {
    let counter: number = 0;
    this.report.scoreSummary.unenoughPercentage.forEach(sa => counter += sa.average);
    return counter;
  }

  public getScoresCounter(): number {
    let counter: number = 0;
    this.report.scoreSummary.scoresCount.forEach(sa => counter += sa.average);
    return counter;
  }

  public getSubjectName(subjectId: string, average?: number): string {
    return average == 0 ? '' : this.report.subjects.filter(subject => subject.id == subjectId)[0].longName;
  }

  public getSubjectNameAndExam(studentId: string, subjectId: string, average?: number) {
    const subjectName = this.report.subjects.filter(subject => subject.id == subjectId)[0].longName;
    const student = this.report.students.filter(student => student.studentId == studentId);

    if (student.length > 0 && student[0].exams) {
      const exams = student[0].exams.filter(exam => exam.subjectId == subjectId);
      if (exams != null && exams.length > 0) {
        return `${subjectName} (Exámen: ${exams[0].score})`;
      }
    }



    return `${subjectName}`;
  }

  public filterDoesSubject(subjects: SubjectNames[], doesAverage: boolean = true): SubjectNames[] {
    return subjects.filter(sa => sa.doesAverage == doesAverage);
  }
  public filterPeriods(): number[] {
    if (this.periodSelected == 0) {
      return [0];
    }
    return this.periods.filter(p => p > 0);
  }

  public filterDoesAverage(subjectAverages: SubjectAverage[], doesAverage: boolean = true): SubjectAverage[] {
    const subjects = this.report.subjects;
    const response = subjectAverages.filter(sa => {
      const subjectName: SubjectNames = subjects.filter(s => s.id == sa.subjectId)[0];
      if (subjectName == undefined) return false;
      return subjectName.doesAverage == doesAverage;
    });
    return response;
  }

}
