import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LectionaryRequest } from "../model/lectionary-request";
import { LectionaryBody } from "../model/lectionary-body";
import { AuthorService } from './author.service';
import { LectionarySummary } from "../model/lectionary-summary";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class LectionaryService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/lectionary/1.0';
    private findUrl: string = '/find';
    private saveUrl: string = '/save';
    private listUrl: string = '/list';
    private signUrl: string = '/sign';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public find(lectionaryRequest: LectionaryRequest): Observable<LectionaryBody> {
        const url = this.baseUrl + this.serviceUrl + `${this.findUrl}`;
        return this.http.post<LectionaryBody>(url, lectionaryRequest, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public save(lectionary: LectionaryBody): Observable<LectionaryBody> {
        const url = this.baseUrl + this.serviceUrl + `${this.saveUrl}`;
        return this.http.post<LectionaryBody>(url, lectionary, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public list(courseId: string, hourId: string, lessonDate: string): Observable<LectionarySummary[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listUrl}`;
        const lectionaryRequest: LectionaryRequest = new LectionaryRequest();
        lectionaryRequest.courseId = courseId;
        lectionaryRequest.hourId = hourId;
        lectionaryRequest.lessonDate = lessonDate;

        return this.http.post<LectionarySummary[]>(url, lectionaryRequest, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public sign(lectionary: LectionaryBody): Observable<LectionaryBody> {
        const url = this.baseUrl + this.serviceUrl + `${this.signUrl}`;
        return this.http.post<LectionaryBody>(url, lectionary, { headers: this.authorService.headerWithAuthorization(null) });
    }

}