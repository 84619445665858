import { Component, Input, OnInit } from '@angular/core';
import { CourseService } from 'src/app/api/course.service';
import { GroupsService } from 'src/app/api/groups.service';
import { Alumno } from 'src/app/model/alumno';
import { Course } from 'src/app/model/course';
import { GroupsDetail } from 'src/app/model/groups-detail';
import { AlertUtility } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-course-groups',
  templateUrl: './course-groups.component.html',
  styleUrls: ['./course-groups.component.css',
    '../../../../style/table.css'
  ]
})
export class CourseGroupsComponent implements OnInit {
  @Input() course: Course;

  public displayedColumns: string[] = ['numero', 'rut', 'nombre', 'grupo'];
  public dataSource: { numero: number, rut: string, nombre: string, grupo: string }[] = [];
  // form: FormGroup;
  public groupCounter: number = 0;

  public groupsDetail: GroupsDetail;
  public groupsAlreadyExists: boolean = false;

  constructor(private groupsService: GroupsService, public courseService: CourseService,
    private alert: AlertUtility) {
  }

  ngOnInit(): void {
    this.groupsService.findGroupsDetail(this.course.id, true)
      .subscribe(
        (response: GroupsDetail) => this.processGroupsDetail(response),
        (problems) => console.error(problems)
      );

  }

  private processGroupsDetail(groupsDetail: GroupsDetail): void {
    let i = 0;
    this.dataSource = [];


    this.groupsAlreadyExists = groupsDetail.groups.length > 0;
    this.groupCounter = groupsDetail.groups.length;

    groupsDetail.students.forEach(
      (student: Alumno) => {
        this.dataSource.push({
          numero: student.numeroLista,
          rut: `${student.rut}-${student.dv}`,
          nombre: `${student.paterno} ${student.materno}, ${student.nombres}`,
          grupo: this.getGroupLeter(groupsDetail, student.id)
        });

        i++;
      }
    );

  }

  private getGroupLeter(groupsDetail: GroupsDetail, studentId: string): string {
    // console.log(groupsDetail);
    let leter: string = '';

    groupsDetail.groups.forEach(group => {
      const counter: number = group.students.filter(student => student.id == studentId).length;
      if (counter > 0) leter = group.group;
    }
    );

    return leter;
  }

  public async createGroupClick(): Promise<void> {
    const doConfirm = await this.alert.confirmMessage(`Una vez que confirme esta accion, la cantidad de grupos
                                                   no se podrá cambiar durante el año ¿Esta seguro de crear 
                                                   ${this.groupCounter} para este curso?`, 'CONFIRME');

    if (doConfirm) {
      this.groupsService.createGroups(this.course.id, this.groupCounter)
        .subscribe(
          (response: GroupsDetail) => this.processGroupsDetail(response),
          (problems) => console.error(problems)
        );
    }


  }
}
