import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from 'src/app/api/crud.service';
import { Column } from 'src/app/model/crud-response';

@Component({
  selector: 'app-child-elements-dialog',
  templateUrl: './child-elements-dialog.component.html',
  styleUrls: ['./child-elements-dialog.component.css']
})
export class ChildElementsDialogComponent implements OnInit {
  public elements: Element[] = [];


  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ChildElementDialogParameter,
    private crudService: CrudService) { }

  ngOnInit(): void {
    // console.clear();
    // console.log(this.dialogData);

    this
      .crudService
      .list(this.dialogData.column.childEntityId)
      .subscribe(
        response => {
          //          console.log(response);
          console.warn('Aqui hay campos que deberian ser dinamicos, el id y name');
          this.elements = response.data.map(data => {
            //          console.log(data);
            const e: Element = new Element();
            e.label = data.name;
            e.id = data.id;
            e.checked = false;
            return e;
          });

          this
            .elements
            .forEach(e =>
              e.checked = this
                .dialogData
                .entitiesSelected
                .filter(s => s.id == e.id)
                .length > 0);
        },
        problems => console.log(problems)
      );

  }



  public onAccept(): void {
    this.dialogData.entitiesSelected = [];
    this.dialogData.entitiesSelected = this.elements.filter(element => element.checked);

    console.log(this.dialogData.entitiesSelected);

  }

}

class Element {
  label: string;
  id: string;
  checked: boolean;
}

export class ChildElementDialogParameter {
  column: Column;
  entitiesSelected: any[];
  parentName: string;
}
