<mat-form-field appearance="fill" fxFlexFill>
    <mat-label>Asignatura</mat-label>
    <mat-select [(ngModel)]="this.subjectId" (selectionChange)="onChangeSelection()">
        <mat-option value="">- Ninguna especifica -</mat-option>
        <mat-option *ngFor="let subject of subjects" [value]="subject.id">
        {{subject.name}}
        </mat-option>
    </mat-select>
</mat-form-field>

