import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { Subject } from '../model/subject';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class SubjectService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/subject';

    // private listByTeacherUrl: string = '/list-by-teacher';
    // private listByLevelIdUrl: string = '/list-by-level';
    // private listByNotCourseUrl: string = '/list-by-not-course';
    // private listByNotCourseAndInludeUrl: string = '/list-by-not-course-and-include';
    // private listAllUrl: string = '/list-all';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listByCourse(courseId: string): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-by-course/${courseId}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByCourseV2(courseId: string, username: string): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/V2/list-by-course/${courseId}/${username}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByTeacher(teacherId: string): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-by-teacher/${teacherId}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByLevelId(levelId: string, year: number): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-by-level/${levelId}/${year}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByLevelIdV2(levelId: string, year: number, username : string): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/V2/list-by-level/${levelId}/${year}/${username}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByNotCourse(courseId: string): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-by-not-course/${courseId}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByNotCourseAndInclude(courseId: string, subjectId: string): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-by-not-course-and-include/${courseId}/${subjectId}`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAll(): Observable<Subject[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-all`;
        return this.http.get<Subject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}