import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PermissionService } from 'src/app/api/permission.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class ChangePasswordDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ChangePasswordParamDialog,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.findConfiguration();
  }

  async findConfiguration() {
    const canChangePasswordToOthers = await this.permissionService.hasPermissionAsync('cl.ges.permission.security.change-password-other-users');

    console.log('canChangePasswordToOthers', canChangePasswordToOthers);
  }

}

export class ChangePasswordParamDialog {
  username: string;
  oldPassword: string;
  newPassword1: string;
  newPassword2: string;
}