import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resource } from 'src/app/model/resource';

@Component({
  selector: 'app-activities-dialog',
  templateUrl: './activities-dialog.component.html',
  styleUrls: ['./activities-dialog.component.css']
})
export class ActivitiesDialogComponent implements OnInit {
  resourceAvailableNames: string[] = [];
  resourceSelectedNames: string[] = [];

  constructor(public dialogRef: MatDialogRef<ActivitiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ActivitiesParamDialog) { }

  ngOnInit(): void {
    if (this.dialogData.resourcesIds == null) {
      this.dialogData.resourcesIds = [];
    } else {
      this.resourceSelectedNames = this
        .dialogData
        .resourcesIds
        .map(resourceId => this
          .dialogData
          .resources
          .find(resource => resource.id == resourceId)
          .name);
    }

    this.resourceAvailableNames = this.dialogData.resources.map(resource => resource.name);
  }

  elementsUpdated(elements: string[]) {
    console.log(elements);
    this.resourceSelectedNames = elements;
    this.refreshResourceIds();
  }

  refreshResourceIds() {
    while (this.dialogData.resourcesIds?.length > 0)
      this.dialogData.resourcesIds.pop();

    this.resourceSelectedNames.forEach(resourceName => {
      const resource = this.dialogData.resources.find(resource => resource.name === resourceName);
      this.dialogData.resourcesIds.push(resource.id);
    });
  }
}

export class ActivitiesParamDialog {
  resourcesIds: string[];
  resources: Resource[];
  activityStart: string;
  activityDevelop: string;
  activityFinish: string;
  readonly: boolean;
}