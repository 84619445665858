import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Accident } from "../model/accident";
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class AccidentService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/accident/1.0';
    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public saveNewAccident(accident: Accident): Observable<Accident> {
        const url = this.baseUrl + this.serviceUrl + `/new-accident`;
        return this.http.post<Accident>(url, accident, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async getAsync(accidentId: string): Promise<Accident> {
        const url = this.baseUrl + this.serviceUrl + `/${accidentId}`;
        console.log('URL: ', url);
        
        return this.http.get<Accident>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public downloadAccidentForm(accidentId: string) {
        const url = `${this.baseUrl}${this.serviceUrl}/download-accident-form/${accidentId}`;
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }

    public listAccidents(studentId: string): Observable<Accident[]> {
        const url = this.baseUrl + this.serviceUrl + `/list-accidents/${studentId}`;
        return this.http.get<Accident[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public updateAccident(accident: Accident): Observable<Accident> {
        const url = this.baseUrl + this.serviceUrl + `/update-accident`;
        return this.http.post<Accident>(url, accident, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public uploadAttentionFile(accidentId: string, file: File, username: string): Observable<HttpEvent<Accident>> {
        const formData = new FormData();

        const url: string = this.baseUrl + this.serviceUrl + '/upload-attention-file';
        formData.append('file', file);
        formData.append('accidentId', accidentId);
        formData.append('username', username);

        console.log(url);

        const req = new HttpRequest('POST', url, formData, {
            reportProgress: false
        });

        return this.http.request(req);
    }

    public downloadAttentionFile(accidentId: string) {
        const url = `${this.baseUrl}${this.serviceUrl}/download-attention-file/${accidentId}`;
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }

}