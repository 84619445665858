import { Button } from "./button";
import { Column } from "./crud-response";

export class EntityConfig {
	alias: string;
	title: string;
	buttons: Button[];
	columns: Column[];
	dynamicInfo: KeyValue[];
	name: string;
}

export class KeyValue {
	key: string;
	value: string;

	constructor(key: string, value: string) {
		this.key = key;
		this.value = value;
	}
}
