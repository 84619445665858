<h3>Reuniones de apoderados</h3>

<div *ngIf="this.viewAsManager">
    <table border="0">
        <tr>
            <td width="25%">&nbsp;</td>
            <td width="500px">
                <com-course-selector [showSpecialityName]="false" [showTeacherName]="true"
                    (courseChangeEvent)="this.courseChange($event)"></com-course-selector>
            </td>
        </tr>
    </table>

</div>


<div>
    <table mat-table [dataSource]="datasource" fxFlexFill *ngIf="datasource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="align-left">Sel.</th>
            <td mat-cell *matCellDef="let element" class="align-left">
                <mat-radio-button type="radio"></mat-radio-button>
            </td>
        </ng-container>

        <ng-container matColumnDef="meeting_date">
            <th mat-header-cell *matHeaderCellDef class="align-left">Fecha Reunión</th>
            <td mat-cell *matCellDef="let element" class="align-left">{{
                this.dtUtility.dateToHumanString(this.dtUtility.stringToDate(element.meetingDate)) }} </td>
        </ng-container>

        <ng-container matColumnDef="summary">
            <th mat-header-cell *matHeaderCellDef class="align-left">Asunto</th>
            <td mat-cell *matCellDef="let element" class="align-left">{{this.resolveTitle(element.title)}}
            </td>
        </ng-container>
        <!-- TODO: Delete if customer agree
            
            <ng-container matColumnDef="course">
            <th mat-header-cell *matHeaderCellDef class="align-left">Curso</th>
            <td mat-cell *matCellDef="let element" class="align-left" valign="top">{{element.detail}} </td>
        </ng-container>
        <ng-container matColumnDef="teacher">
            <th mat-header-cell *matHeaderCellDef class="align-left">Profesor</th>
            <td mat-cell *matCellDef="let element" class="align-left" valign="top">{{element.detail}} </td>
        </ng-container> -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="align-left">Acciones</th>
            <td mat-cell *matCellDef="let element" class="align-left">

                <button mat-icon-button color="primary" matTooltip="Participantes"
                    (click)="this.openAttendanceDialog(element)">
                    <mat-icon>groups</mat-icon>
                </button>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <button mat-icon-button color="primary" matTooltip="Acuerdos"  (click)="this.openAgreementDialog(element)" >
                    <mat-icon>gavel</mat-icon>
                </button>



            </td>
        </ng-container>

    </table>
    <br>
    <div>
        <button mat-raised-button color="primary" *ngIf="!this.viewAsManager" (click)="newMeetDialog()">
            <mat-icon>add</mat-icon> Programar reunión
        </button>


    </div>
</div>
<br>