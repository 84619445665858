import { Component, Input, OnInit } from '@angular/core';
import { LicenseService } from 'src/app/api/license.service';
import { License } from 'src/app/model/license';
import { HttpUtility } from 'src/app/utility/http-utility';

@Component({
  selector: 'licenses-list',
  templateUrl: './licenses-list.component.html',
  styleUrls: ['./licenses-list.component.css', '../../../style/table.css']
})
export class LicensesListComponent implements OnInit {
  @Input()
  public studentId: string;
  @Input()
  public year: number;

  public datasource: any;
  public displayedColumns: string[] = ['reason', 'start', 'finish', 'actions'];

  constructor(private licenseService: LicenseService,
    public utilsService: HttpUtility) { }

  ngOnInit(): void {
    if (this.year == undefined || this.studentId == undefined) {
      console.log('Alumno o Curso indefinido');
      return;
    }

    this.licenseService.listLicences(this.studentId, this.year)
      .subscribe(
        (response: License[]) => {
          this.datasource = response;
        },
        problems => console.error(problems)
      );
  }

  public resolveType(type: string): string {
    switch (type) {
      case 'ENFER': return 'Enfermedad';
      case 'ACCID': return 'Accidente';
      default:
        return '';
    }
  }

  public doDownload(id: string, filename: string) {
    this
      .licenseService
      .downloadLicense(id)
      .subscribe(
        (response: ArrayBuffer) =>
          this.utilsService.download(response, filename)
      );
  }

}
