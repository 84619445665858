import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class HttpUtility {
    public downloadPdf(binaryData: ArrayBuffer, fileName: string): void {
        // const file: Blob = new Blob([binaryData], { type: 'application/pdf' });
        // const url: string = window.URL.createObjectURL(file);
        // const anchorElement: HTMLAnchorElement = document.createElement('a');
        // anchorElement.download = fileName;
        // anchorElement.href = url;
        // anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        return this.genericDownload(binaryData, fileName, 'application/pdf');
    }

    public download(binaryData: ArrayBuffer, fileName: string): void {
        return this.genericDownload(binaryData, fileName, 'application/octet-stream');
    }

    public genericDownload(binaryData: ArrayBuffer, fileName: string, contentType: string): void {
        const file: Blob = new Blob([binaryData], { type: contentType });
        const url: string = window.URL.createObjectURL(file);
        const anchorElement: HTMLAnchorElement = document.createElement('a');
        anchorElement.download = fileName;
        anchorElement.href = url;
        anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    }



}