import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { ConfigService } from 'src/app/api/config.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { SignatureReportRequest, SignatureService, SignatureStatus, SignaturesReport } from 'src/app/api/signature.service';
import { User } from 'src/app/model/user';
import { UserSign } from 'src/app/model/user-sign';
import { AlertUtility, DialogMessageType } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-signatures',
  templateUrl: './signatures.component.html',
  styleUrls: ['./signatures.component.css', '../../../style/table.css']
})
export class SignaturesComponent implements OnInit {
  public currentYear: number = 0;
  public teacherId: string = '';
  private courseId: string;
  private subjectId: string;
  public startDate: Moment;
  public finishDate: Moment;
  public dataSource: MatTableDataSource<SignatureStatus>;
  public displayedColumns: string[] = ['date', 'course', 'subject', 'teacher', 'block', /*'signer',*/ 'state'];

  constructor(private configService: ConfigService,
    private alertUtility: AlertUtility,
    private signatureService: SignatureService,
    public dateTimeUtility: DateTimeUtility) { }

  ngOnInit(): void {
    this.configService.getCurrentYear()
      .subscribe(
        response => this.currentYear = response,
        problems => console.error(problems)
      );

  }

  public onTeacherChange(teacher: User): void {
    this.teacherId = teacher != null ? teacher.id : null;
  }

  public onCourseSelect(courseId): void {
    this.courseId = courseId;
  }

  public onSubjectSelect(subjectId: string): void {
    this.subjectId = subjectId;
  }

  public onSearchClick(e: MouseEvent): void {
    this.teacherId = this.teacherId == '' ? null : this.teacherId;
    this.courseId = this.courseId == '' ? null : this.courseId;
    this.subjectId = this.subjectId == '' ? null : this.subjectId;

    if (this.startDate == undefined || this.finishDate == undefined) {
      this.alertUtility.dialogMessage('Debe seleccionar un rango de fechas para buscar.', 'Atención',
        DialogMessageType.WARNING);
      return;
    }
    if (this.teacherId == null && this.courseId == null && this.subjectId == null) {
      this.alertUtility.dialogMessage('Debe seleccionar un curso, asigntaura o profesor para buscar.', 'Atención',
        DialogMessageType.WARNING);
      return;
    }

    const signatureRequest: SignatureReportRequest = new SignatureReportRequest();
    signatureRequest.start = this.dateTimeUtility.momentToString(this.startDate);
    signatureRequest.finish = this.dateTimeUtility.momentToString(this.finishDate);
    signatureRequest.teacherId = this.teacherId == '' ? null : this.teacherId;
    signatureRequest.courseId = this.courseId == '' ? null : this.courseId;
    signatureRequest.subjectId = this.subjectId == '' ? null : this.subjectId;

    console.log(signatureRequest);

    this.signatureService.getReport(signatureRequest)
      .subscribe(
        (response: any/*SignaturesReport*/) => {
          console.log(response);
          this.refreshTable(response);
        },
        problems => console.error(problems)
      );

  }

  private refreshTable(response: SignaturesReport): void {
    console.log('RESPONSE: ', response);
    // this.dataSource = null;
    if (response.signatures.length == 0) {
      this.alertUtility.fastMessage('Sin información para los criterios de búsqueda.');
    }
    this.dataSource = new MatTableDataSource(response.signatures);
  }

  public primaryColor(isPrimary: boolean): string {
    return isPrimary ? "primary" : "accent";
  }

  public async onBookButtonClick(signId: string): Promise<void> {
    console.log(signId);
    const response = await this.alertUtility.verifyUserAndPassword('Firme el libro de clases para este bloque horario.');
    console.log(response);

    if (!response.isValid) {
      this.alertUtility.fastMessage('Clave invalida');
      return;
    }

    const record: SignatureStatus = this.dataSource.data.filter(rec => rec.id == signId)[0];

    const userSign = new UserSign();
    userSign.comment = response.comment;
    userSign.courseId = record.course.id;
    userSign.subjectId = record.subject.id;
    userSign.teacherId = record.teacher.id;
    userSign.scheduleId = record.timeBlockId;
    userSign.type = 'CLASS_BOOK';
    userSign.signatureDate = record.signatureDate;

    this.signatureService.doSign(userSign)
      .subscribe(
        response => {
          this.alertUtility.fastMessage('Firma almacenada');
          record.bookSigned = true;
        },
        problems => console.error(problems)
      );



  }
}
