import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { ManagementService } from 'src/app/api/management.service';
import { ShortedLectionaryItem, ShortedLectionarySummary } from 'src/app/model/lectionary-summary';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-lectionary-summary',
  templateUrl: './lectionary-summary.component.html',
  styleUrls: ['./lectionary-summary.component.css']
})
export class LectionarySummaryComponent implements OnInit {
  public displayedColumns: string[] = ['date', 'course', 'subject', 'module', 'lectionary'];
  public dataSource: MatTableDataSource<ShortedLectionaryItem> = new MatTableDataSource<ShortedLectionaryItem>([]);;

  @ViewChild(MatPaginator) paginator: MatPaginator;
//   @ViewChild(MatSort, { static: true }) sort!: MatSort;

  public teacher: User = null;
  public startDate: Moment = null;
  public finishDate: Moment = null;
  public subjectId: string = null;
  public currentYear: number = 0;

  constructor(private managementService: ManagementService,
    private dateTimeUtility: DateTimeUtility) { }

  ngOnInit(): void {
    this.loadData();
    this.teacher = new User();
    this.teacher.username = '';
  }

  private loadData(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public onTeacherChange(teacher: User): void {
    this.teacher = teacher;
  }

  public onSearchButton(event: MouseEvent): void {
    console.log('this.teacher:', this.teacher, 'startDate: ', this.startDate, 'finishDate: ', this.finishDate, 'this.subjectId: ', this.subjectId);

    if (this.teacher == null || this.startDate == null || this.finishDate == null || this.subjectId == null) return;
    const startDate: string = this.dateTimeUtility.momentToString(this.startDate);
    const finishDate: string = this.dateTimeUtility.momentToString(this.finishDate);

    this.refreshData(startDate, finishDate);

  }

  private refreshData(startDate: string, finishDate: string): void {
    this.managementService.retrieveLectionaries(this.teacher.id, startDate, finishDate, this.subjectId)
      .subscribe(
        response => this.refreshTable(response),
        problems => console.error(problems)
      );

  }

  private refreshTable(response: ShortedLectionarySummary): void {
    console.log(response);
    this.dataSource = new MatTableDataSource(response.lectionaries);
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  public onSubjectChange(subjectId: string): void {
    this.subjectId = subjectId;
  }

}
