<h1 mat-dialog-title>Acuerdos de reunión
    {{this.dtUtility.dateToHumanString(this.dtUtility.stringToDate(this.dialogData.meetingDate))}}.
    {{this.courseService.formatLetra(this.dialogData.courseLeter)}}</h1>


<div mat-dialog-content>
    <table mat-table [dataSource]="datasource" fxFlexFill *ngIf="datasource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="align-left">Num</th>
            <td mat-cell *matCellDef="let element; let i = index" class="align-left" valign="top">
                {{i+1}}
            </td>
        </ng-container>

        <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef class="align-left">Acuerdo</th>
            <td mat-cell *matCellDef="let element" class="align-left">
                <textarea matInput [(ngModel)]="element.detail" placeholder="Ingrese acuerdo" [readonly]="this.dialogData.viewAsManager"></textarea>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="align-center">Acción</th>
            <td mat-cell *matCellDef="let element" class="align-center">
                <button mat-icon-button color="primary" (click)="removeAgreement(element.id)" [disabled]="this.dialogData.viewAsManager">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

    </table>
    <br>
    <p>
        <button mat-raised-button color="primary" (click)="addAgreement()" [disabled]="this.dialogData.viewAsManager">
            <mat-icon>add</mat-icon>Nuevo
        </button>
    </p>
</div>
<br>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="beforeSave()" [disabled]="this.dialogData.viewAsManager">
        <mat-icon>save</mat-icon> Aceptar acuerdos
    </button>
    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>