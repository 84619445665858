import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MineducSendParams } from "../model/mineduc-send-params";
import { MineducSummary } from "../model/mineduc-summary";
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class MineducService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/mineduc/1.0';
    private sendInfoUrl: string = '/send-info-to-mineduc';


    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public sendMineducInfo(start: string, finish: string, courseId: string, month: number): Observable<MineducSummary[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.sendInfoUrl}`;

        const mineducSendParams: MineducSendParams = new MineducSendParams();
        mineducSendParams.courseId = courseId;
        mineducSendParams.start = start;
        mineducSendParams.finish = finish;
        mineducSendParams.month = month;

        return this.http.post<MineducSummary[]>(url, mineducSendParams, { headers: this.authorService.headerWithAuthorization(null) });
    }

}
