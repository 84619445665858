<div style="text-align:xleft">
    <div fxLayout="column" fxFlexAlign="center center" *ngIf="course">
        <h1 *ngIf="course">Asistencia para el curso {{this.courseService.formatLetra(course.letra)}}</h1>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="start center"
            fxLayoutGap="10px">

            <div fxFlex="15" fxFlex.lt-md="100" fxLayout="column">
                <mat-form-field appearance="fill">
                    <mat-label>Bloque horario</mat-label>
                    <mat-select (selectionChange)="timeBlockOnChange()" [(ngModel)]="timeBlockId">
                        <mat-option *ngFor="let timeBlock of timeBlocks" [value]="timeBlock.id">
                            {{timeBlock.desc}} ({{timeBlock.start.substr(0,5)}} - {{timeBlock.finish.substr(0,5)}})
                            {{timeBlock.group.length > 0 ? 'Grp: ' + timeBlock.group : ''}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-calendar [(selected)]="currentDate" (selectedChange)="onChangeCalendar($event)" disabled="false"
                    [maxDate]="maxDate" [minDate]="minDate"></mat-calendar>
                <mat-divider></mat-divider>
                <div *ngIf="this.currentDate"><strong>Fecha: </strong>{{fdate()}}</div>
            </div>
            <div fxFlex="80" fxFlex.lt-md="100" fxFlexAlign="start start">
                <div *ngIf="!timeBlockId">Seleccione una fecha y bloque horario</div>

                <table mat-table [dataSource]="dataSource" *ngIf="timeBlockId" border="0" fxFlexFill>
                    <ng-container matColumnDef="listNumber">
                        <th mat-header-cell *matHeaderCellDef class="align-center" mat-sort-header> Numero Lista </th>
                        <td mat-cell *matCellDef="let row" class="align-center" nowrap
                            [ngStyle]="{'max-height.px':'10px'}">
                            {{row.studentListNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</th>
                        <td mat-cell *matCellDef="let row" class="align-left " nowrap 
                            [ngStyle]="{'max-height.px':'10px'}">
                            <mat-icon *ngIf="row.studentStatus == 'MAT'"
                                inline="false">{{row.takeReligion?'church':'face'}}</mat-icon>

                            <strong>{{getStudentStatus(row.studentStatus)}}</strong>

                            {{row.studentName }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="assistence">
                        <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Asistencia </th>
                        <td mat-cell *matCellDef="let row" class="align-left" nowrap
                            [ngStyle]="{'max-height.px':'10px'}">

                            <mat-button-toggle-group name="asistence" aria-label="Asistencia"
                                [disabled]="row.studentStatus != 'MAT'" (change)="asistenceOnChange($event)"
                                [(ngModel)]="row.assistance">
                                <mat-button-toggle value="P" checked="true">Presente</mat-button-toggle>
                                <mat-button-toggle value="O">Atrasado</mat-button-toggle>
                                <mat-button-toggle value="A">Ausente</mat-button-toggle>
                            </mat-button-toggle-group>

                        </td>
                    </ng-container>

                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Comentario </th>
                        <td mat-cell *matCellDef="let row" class="align-left" nowrap [ngStyle]="{'font-size.px':'15'}">
                            <mat-form-field appearance="fill" *ngIf="row.assistance != 'P'" fxFlexFill>
                                <mat-label>Observación</mat-label>
                                <input type="text" matInput [(ngModel)]="row.comment">
                            </mat-form-field>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
            </div>
        </div>

        <p fxLayout="row" fxLayoutAlign="space-evenly center">

            <button mat-raised-button color="primary" (click)="saveAndSignOnClick()"
                [disabled]="!timeBlockId || this.signed || this.readonly">
                <mat-icon>draw</mat-icon>Firmar,
                grabar y volver</button>

            <button mat-raised-button (click)="saveOnClick()"
                [disabled]="!timeBlockId || this.signed || this.readonly"><mat-icon>save</mat-icon> Grabar y
                volver</button>

            <button mat-raised-button (click)="cancelOnClick()"><mat-icon>arrow_back</mat-icon> Volver</button>

        </p>
    </div>
</div>