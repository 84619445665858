<p>
<h2 style="text-align: center;">Cambio de curso</h2>
<mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Seleccione nuevo curso</mat-label>
    <mat-select style="width: 100%;" [(ngModel)]="changeCourseResponse">
        <mat-option *ngFor="let c of courses" [value]="c.id">{{courseService.formatLetra(c.letra)}} :
            {{c.especialidadNombre}} ({{c.studentsCount}} alumnos)</mat-option>
    </mat-select>
</mat-form-field>

<br>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="changeCourseResponse">
        <mat-icon>save</mat-icon>Aceptar</button>
    &nbsp;
    <button mat-raised-button (click)="onNoClick()"><mat-icon>close</mat-icon>Cancelar</button>
</div>