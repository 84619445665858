<div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="dataSource">

    <h1 fxFlexFill>Asignaturas y Profesores del curso</h1>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows fxFlexFill>
        <ng-container matColumnDef="selector">
            <th mat-header-cell *matHeaderCellDef class="align-center"> Selección </th>
            <td mat-cell *matCellDef="let row" class="align-center">
                <mat-radio-button (click)="onSelectOne(row);$event.stopPropagation();"
                    (change)="$event ? selection.toggle(row) : null">
                </mat-radio-button> 
            </td>
        </ng-container>

        <ng-container matColumnDef="teacher">
            <th mat-header-cell *matHeaderCellDef class="align-left"> Profesor </th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.teacher.fatherSurname + ' ' +
                row.teacher.motherSurname + ' ' + row.teacher.names}} </td>
        </ng-container>
        <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef class="align-left"> Asignatura </th>
            <td mat-cell *matCellDef="let element" class="align-left"> {{element.subject.name}} ({{element.subject.abbreviated}})</td>
        </ng-container>

        <ng-container matColumnDef="mineduc-code">
            <th mat-header-cell *matHeaderCellDef class="align-center"> Código Mineduc </th>
            <td mat-cell *matCellDef="let element" class="align-center"> {{element.subject.mineduc}} </td>
        </ng-container>
        

        <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef class="align-center"> Orden </th>
            <td mat-cell *matCellDef="let element" class="align-center"> {{element.priority}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        <!--
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">No hay conincidencias para "{{input.value}}"</td>
        </tr>
-->
    </table>
    <!--
    <div fxFlexFill>
        <mat-paginator 
        [pageSizeOptions]="[3, 5, 10, 12]"
        
        ></mat-paginator>
    </div>
-->
    <p fxLayout="row" fxLayoutAlign="space-evenly center" fxFlexFill>
        <button mat-raised-button color="primary" (click)="onOpenDialogForAdd()">
            <mat-icon>add</mat-icon>Agregar
        </button>
        <button mat-raised-button (click)="onUpdate()"
            [disabled]="nothingSelected()">
            <mat-icon>edit</mat-icon>Modificar
        </button>
        <button mat-raised-button (click)="onRemove()"
            [disabled]="nothingSelected()">
            <mat-icon>remove</mat-icon>Quitar
        </button>
    </p>
</div>