import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Especialidad } from "../model/especialidad";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";
import { HttpUtility } from "../utility/http-utility";
import { FileHandler } from "../model/file-handler";

@Injectable({
    providedIn: 'root'
})

export class FileService {
    private baseUrl: string = environment.apiUrl;
    private fileServiceUrl: string = '/api/ges2/file';

    constructor(private http: HttpClient,
        private authorService: AuthorService,
        public utilsService: HttpUtility) {
    }

    public async getFileInfoAsync(fileId: string): Promise<FileHandler> {
        const url = this.baseUrl + this.fileServiceUrl + `/1.0/fileInfo/${fileId}`;
        console.log(url);

        return this.http.get<FileHandler>(url, { headers: this.authorService.headerWithAuthorization(null) })
            .toPromise();
    }

    public async download(fileId: string) {
        const url = this.baseUrl + this.fileServiceUrl + `/1.0/download/${fileId}`;

        const fileInfo = await this.getFileInfoAsync(fileId);
        const contentType: string = fileInfo.contentType;

        console.log(url);
        const buffer: ArrayBuffer = await this.getBuffer(url, contentType);

        this.utilsService.download(buffer, fileInfo.originalFilename);
    }

    private async getBuffer(url: string, contentType: string): Promise<ArrayBuffer> {
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', contentType)
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        }).toPromise();
    }


    public async downloadCustomFile(studentId: string, courseId: string, index: number): Promise<void> {
        const url: string = this.baseUrl + this.fileServiceUrl + `/1.0/downloadCustomDocument/${index}/${studentId}/${courseId}`;

        console.log(url);


        const buffer: ArrayBuffer = await this.getBuffer(url, 'application/pdf');

        this.utilsService.download(buffer, `document-${index}.pdf`);
    }


}