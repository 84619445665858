import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'src/app/api/config.service';
import { PermissionService } from 'src/app/api/permission.service';
import { PlanService } from 'src/app/api/plan.service';
import { EvaluationCriteria, EvaluationCriteriaItem } from 'src/app/model/evaluation-criteria';
import { GenericObjective } from 'src/app/model/generic-objetive';
import { Module } from 'src/app/model/module';
import { SpecificObjective } from 'src/app/model/specific-objetive';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-lectionary-planning',
  templateUrl: './lectionary-planning.component.html',
  styleUrls: ['./lectionary-planning.component.css',
    '../../../style/table.css']
})
export class LectionaryPlanningComponent implements OnInit {
  //  courseId: string = '';
  subjectId: string = '';
  moduleId: string = '';
  specificObjetiveId: string = '';
  specificObjetive: SpecificObjective = null;
  levelId: string = '';
  genericObjectives: GenericObjective[] = [];
  planningYear: number = 0;
  evaluationCriteria: EvaluationCriteria = new EvaluationCriteria();
  public canDuplicate: boolean = false;
  public readonly: boolean = false;
  public username: string | null = null;


  constructor(private planService: PlanService,
    private configService: ConfigService,
    private snackBar: MatSnackBar,
    private permissionsService: PermissionService) {
    this.evaluationCriteria = new EvaluationCriteria();
    this.evaluationCriteria.items = [];
  }

  ngOnInit(): void {
    this.planService.listGenericObjectives()
      .subscribe(
        response => {
          this.genericObjectives = response;
        },
        problems => console.error(problems)
      );

    this.configService.findByKey('planning_year')
      .subscribe(response =>
        this.planningYear = +response.valor,
        problmes => console.error(problmes)
      );

    this.loadConfig();
  }

  private async loadConfig() {
    this.readonly = (await this.permissionsService.hasPermissionAsync('PLANNING.READONLY'));
  }


  public onLevelChangeEvent(levelId: string): void {
    this.levelId = levelId;
    this.subjectId = '';
    this.specificObjetiveId = '';
    this.moduleId = '';
  }

  onSubjectChangeEvent(subjectId: string): void {
    console.log(subjectId);

    this.subjectId = subjectId;
    this.moduleId = '';
    this.specificObjetiveId = '';
    this.moduleId = '';
  }

  onModuleChangeEvent(moduleId: string): void {
    this.moduleId = moduleId;
    this.specificObjetiveId = '';
  }

  public onLoadModulesEvent(modules: Module[]) {
    console.log(`ModulesCount: ${modules.length} / ${this.subjectId}`);

    this.canDuplicate = modules.length == 0;
  }

  onSpecificObjectiveChangeEvent(specificObjetive: SpecificObjective) {
    this.specificObjetive = specificObjetive;
    this.specificObjetiveId = specificObjetive.id;
  }

  onEvaluationCriteriaLoadedEvent(evaluationCriteria: EvaluationCriteria) {
    console.log('onEvaluationCriteriaLoadedEvent', evaluationCriteria);
  }

  public onEvaluationCriteriaItemChangedEvent(item: EvaluationCriteriaItem): void {
    console.log('onEvaluationCriteriaItemChangedEvent', item);
    console.log('onEvaluationCriteriaItemChangedEvent', this.evaluationCriteria);
  }

  public savePlan(eve: MouseEvent): void {
    console.log('savePlan: ', this.evaluationCriteria);
    this.evaluationCriteria.specificObjectiveId = this.specificObjetiveId;

    this.planService.savePlan(this.evaluationCriteria)
      .subscribe(
        response => {
          this.snackBar.open(`Planificación grabada!`, 'OK', { duration: 5000 });
          console.log(response);
        }
        ,
        problems => console.error(problems)
      );

  }

  cancel(eve?: MouseEvent): void {
    this.levelId = '';
    this.subjectId = '';
    this.moduleId = '';
    this.specificObjetiveId = '';
  }

  elementsUpdated(e: string[]) {
    alert(e);
  }

  public duplicateFormPrevYearClick(event: MouseEvent): void {
    const subjectId: string = this.subjectId;
    const levelId: string = this.levelId;
    const planningYear: number = this.planningYear;

    this.subjectId = '';
    this.levelId = '';
    this.planningYear = 0;
    this.canDuplicate = false;

    this.planService.duplicateModulesFromPrevYear(subjectId, levelId, planningYear)
      .subscribe(
        response => {
          //          console.log(response);
          this.subjectId = subjectId;
          this.levelId = levelId;
          this.planningYear = planningYear;
        },
        problems => console.error(problems)
      );
  }

  public onTeacherChange(teacher: User) {
    console.log('teacher', teacher);
    if (teacher == null)
      this.cancel();
    else
      this.username = teacher.username;


  }

}
