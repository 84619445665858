import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { RutUtility } from 'src/app/utility/rut.utility';

@Component({
  selector: 'com-rut-input',
  templateUrl: './rut-input.component.html',
  styleUrls: ['./rut-input.component.css']
})
export class RutInputComponent implements OnInit {

  @Input() public data: string;

  @Output() validateRutEvent = new EventEmitter<ValidateRut>();

  constructor(
    private rutUtility: RutUtility,
    private alert: AlertUtility) { }

  ngOnInit(): void {
  }

  public onBlur(eve: FocusEvent): void {
    // eve.target;
    const validate: boolean = this.rutUtility.rutValido(this.data) || this.data.trim().length == 0;

    if (validate) {
      this.data = this.rutUtility.clearRut(this.data);
    } else {
      this.alert.fastMessage(`Rut '${this.data}' invalido`);
    }

    const validateRut: ValidateRut = new ValidateRut();
    validateRut.rut = this.data;
    validateRut.valid = validate;
    this.validateRutEvent.emit(validateRut);

  }

}

export class ValidateRut {
  valid: boolean;
  rut: string;
}