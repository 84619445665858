import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/api/course.service';
import { EspecialidadService } from 'src/app/api/especialidad.service';
import { UserService } from 'src/app/api/user.service';
import { Course } from 'src/app/model/course';
import { Especialidad } from 'src/app/model/especialidad';
import { User } from '../../../model/user';

@Component({
  selector: 'app-course',
  templateUrl: './course-properties.component.html',
  styleUrls: ['./course-properties.component.css']
})
export class CoursePropertiesComponent implements OnInit {
  course: Course;

  constructor(public courseService: CourseService) { }

  ngOnInit(): void {
    const courseId = localStorage.getItem('courseId');
    this
      .courseService
      .get(courseId)
      .subscribe(
        response => {
          this.course = response;
        },
        problems => console.error(problems)
      );
  }

}
