import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthorService } from './author.service';
import { ContactData } from '../model/contact-data';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ContactDataService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/dato-contacto';
    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public findByValue(contactDataType: string, value: string): Observable<ContactData[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/find-by-value/${contactDataType}/${value}`;
        return this.http.get<ContactData[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}
