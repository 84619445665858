<div fxLayoutAlign="space-evenly center" style="text-align: left;">
    <table border="0" width="90%">
        <tr>
            <td colspan="4">
                <h1>Planificación anual, {{this.planningYear}}</h1>
            </td>
        </tr>
        <tr *ngIf="this.readonly">
            <td colspan="2"><com-teacher-selector (teacherChange)="onTeacherChange($event)"></com-teacher-selector>
            </td>
            <td colspan="2">&nbsp;</td>
        </tr>

        <tr>
            <td colspan="2">
                <com-level-selector [year]="this.planningYear" (levelChangeEvent)="onLevelChangeEvent($event)"
                    [levelId]="this.levelId" [username]="this.username">
                </com-level-selector>
            </td>

            <td colspan="1">
                <com-subject-selector [levelId]="this.levelId" [year]="this.planningYear" [username]="this.username"
                    (subjectChangeEvent)="onSubjectChangeEvent($event)"></com-subject-selector>
            </td>
            <td width="10%" *ngIf="canDuplicate && !this.readonly">
                <button mat-raised-button color="primary" fxFlexFill
                    (click)="duplicateFormPrevYearClick($event)"><mat-icon>content_copy</mat-icon>&nbsp;Copiar desde año
                    anterior</button>
            </td>

        </tr>
        <tr>
            <td colspan="4">
                <com-module-selector [subjectId]="this.subjectId" [levelId]="this.levelId" [year]="this.planningYear"
                    (moduleChangeEvent)="onModuleChangeEvent($event)" (moduleLoadEvent)="onLoadModulesEvent($event)">
                </com-module-selector>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <com-specific-objective-selector [moduleId]="this.moduleId"
                    (SpecificObjectiveChangeEvent)="onSpecificObjectiveChangeEvent($event)">
                </com-specific-objective-selector>
            </td>
        </tr>
        <tr>
            <td colspan="4">
                <com-evaluation-criteria-grid [specificObjetiveId]="this.specificObjetiveId"
                    [specificObjetive]="this.specificObjetive" [levelId]="this.levelId" [year]="this.planningYear"
                    [subjectId]="this.subjectId" [genericObjectives]="this.genericObjectives"
                    [evaluationCriteria]="this.evaluationCriteria"
                    (evaluationCriteriaLoadedEvent)="onEvaluationCriteriaLoadedEvent($event)"
                    (evaluationCriteriaItemChangedEvent)="onEvaluationCriteriaItemChangedEvent($event)"
                    [planningYear]="this.planningYear" [readonly]="this.readonly">
                </com-evaluation-criteria-grid>
            </td>
        </tr>
        <tr>
            <td colspan="2" style="text-align: center;">
                <button mat-raised-button (click)="this.savePlan($event)" color="primary" [disabled]="this.readonly">Grabar plan</button>
            </td>
            <td colspan="2" style="text-align: center;" *ngIf="!this.readonly">
                <button mat-raised-button (click)="cancel($event)">Cancelar</button>
            </td>
        </tr>
        <!-- Esta parte es para probar los componentes de manera individual, hay que usar ID's correctos.
        -->
        <tr>
            <td colspan="4">
                <!--
                <p>
                    <com-evaluation-criteria-grid specificObjetiveId="16548a64-3a7b-11ed-9171-df41aa90d168"
                        levelId="dd8a9ce4-50a4-11ed-95c1-eb77947fc043" [year]="2022"
                        subjectId="0db26758-db29-4077-b7d3-d255c098703c" [genericObjectives]="this.genericObjectives"
                        [evaluationCriteria]="this.evaluationCriteria"
                        (evaluationCriteriaLoadedEvent)="onEvaluationCriteriaLoadedEvent($event)"
                        (evaluationCriteriaItemChangedEvent)="onEvaluationCriteriaItemChangedEvent($event)"
                        [planningYear]="this.planningYear">
                    </com-evaluation-criteria-grid>
                </p>
            
                <p style="display:none">
                    <com-chips-selector [elements]="['textos', 'lapiz', 'cuaderno', 'tijeras', 'pegamento']"
                        label="Elementos educativos" placeholder="Nuevo elemento..."
                        (elementsUpdated)="elementsUpdated($event)"></com-chips-selector>
                </p>
            -->
            </td>
        </tr>

    </table>



</div>