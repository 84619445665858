<h1 mat-dialog-title>Seleccione Curso
</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Curso</mat-label>
    <mat-select required  [(value)]="selected">
      <mat-option *ngFor="let myItem of this.data" [value]="myItem" >{{ myItem.letra }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="onSelectCourseClick()" [disabled]="selected==undefined" cdkFocusInitial>Mostrar</button>
</div>
