import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { PersonalityReportService } from 'src/app/api/personality-report.service';
import { StudentService } from 'src/app/api/student.service';
import { Alumno } from 'src/app/model/alumno';
import { Course } from 'src/app/model/course';
import { Answer, Question, Questions, Replies } from 'src/app/model/personality-report';
import { AlertUtility } from 'src/app/utility/alert.utility';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    console.log('en la validacion');

    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-personality-report',
  templateUrl: './personality-report.component.html',
  styleUrls: ['./personality-report.component.css',
    '../../../../style/table.css']
})
export class PersonalityReportComponent implements OnInit {
  public course: Course;
  public student: Alumno = null;
  private withStudentColumns: string[] = ['Nro', 'area', 'quiestion', 'studentAnswer', 'teacherAnswer'];
  private withoutStudentColumns: string[] = ['Nro', 'area', 'quiestion', 'teacherAnswer'];
  public displayedColumns: string[] = [];
  public dataSource: Question[] = [];
  public answers: string[] = [];
  public answersHint: string;
  private allowSelfReview: boolean = false;
  public answered: boolean = false;

  constructor(private personalityReportService: PersonalityReportService,
    private configService: ConfigService,
    private courseService: CourseService,
    private router: Router,
    private studentService: StudentService,
    private alert: AlertUtility) { }

  ngOnInit(): void {
    const studentId: string = localStorage.getItem('studentId');
    const courseId: string = localStorage.getItem('courseId');

    console.log(studentId);
    this.personalityReportService.listQuestions(studentId)
      .subscribe(
        response => {
          console.log(response);
          this.processListQuestions(response);
        },
        problems => console.error(problems)
      );

    this.courseService.get(courseId)
      .subscribe(
        response => this.course = response,
        problems => console.error(problems)
      );

    this.configService.listApplicationProperty('cl.buildersoft.personality-report.answers')
      .subscribe(
        response => {
          this.answers = response;
          this.answersHint = '';
          this.answers.forEach(answer => this.answersHint += answer + ', ');
          this.answersHint = this.answersHint.trim();
          this.answersHint = this.answersHint.substring(0, this.answersHint.length - 1);
          this.answers = this.answers.map(answer => answer.toLowerCase());
        },
        problems => console.error(problems)
      );

    this.studentService.findById2(studentId, false)
      .subscribe(
        response => this.student = response,
        problems => console.error(problems)
      );
  }

  private processListQuestions(response: Questions) {
    this.dataSource = response.questions;
    this.allowSelfReview = response.allowSelfReview;
    this.answered = response.status == 'ANSWERED';

    if (response.allowSelfReview) {
      this.displayedColumns = this.withStudentColumns;
    } else {
      this.displayedColumns = this.withoutStudentColumns;
    }
  }
  public saveAndFinish() {
    this.saveAndSend('answered');
  }

  public saveAndContinue() {
    this.saveAndSend('pending', false);
  }

  public saveAndSend(status: string, validate?: boolean) {
    validate = validate == undefined;
    const isValid = validate ? this.validateResponses() : true;

    if (!isValid) {
      this.alert.dialogMessage('Hay respuestas incorrectas o inconclusas!', 'Error');
      return;
    }

    this.saveAnswers(status);

  }
  private validateResponses(): boolean {
    let isValid: boolean = true;

    for (let i = 0; i < this.dataSource.length; i++) {
      const row = this.dataSource[i];

      if (this.allowSelfReview) {
        if (!this.validAnswer(row.teacherAnswer) || !this.validAnswer(row.studentAnswer)) {
          isValid = false;
          break;
        }
      } else {
        if (!this.validAnswer(row.teacherAnswer)) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }

  private saveAnswers(status: string) {
    const replies: Replies = new Replies();
    replies.status = status;
    replies.studentId = this.student.id;
    replies.answers = [];

    this.dataSource.forEach(question => {
      const answer: Answer = new Answer();
      answer.questionId = question.id;
      answer.studentAnswer = question.studentAnswer;
      answer.teacherAnswer = question.teacherAnswer;
      replies.answers.push(answer);
    });

    this.personalityReportService.reply(replies)
      .subscribe(
        response => {
          this.alert.fastMessage('Evaluación grabada con éxito');
          this.goBack();
        },
        problems => console.error(problems)
      );

  }


  private validAnswer(answer: string): boolean {
    // console.log(answer);
    if (answer == undefined)
      return false;

    return this.answers.includes(answer.toLowerCase());
  }

  public goBack(): void {
    localStorage.setItem('courseId', this.course.id);
    this.router.navigate(['student-list']);
  }

  public onKeypress(e) {
    const current = document.activeElement;
    const currInput: HTMLInputElement = current as HTMLInputElement;


    if (current.tagName != 'INPUT')
      return;

    if (!this.validAnswer(currInput.value))
      return;

    // console.log(current.tagName);
    // console.log(currInput.value);

    const inputs = document.getElementsByTagName("input");

    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i] == current) {
        var next = inputs[i + 1];
        if (next && next.focus) {
          next.focus();
          next.select();
        }
        break;
      }
    }


  }

}

