<div style="text-align: left;" *ngIf="annotations">
  
    <!-- canAdminAnnotation: {{this.canAdminAnnotation}}<br>
    canAuditAnnotation: {{this.canAuditAnnotation}}<br>
    showDeleted: {{this.showDeleted}}<br> -->
 

    <mat-toolbar>
        <h2>Directas del alumno</h2>
        <span class="spacer"></span>
        <div *ngIf="this.canAuditOrAdmin">
            <mat-slide-toggle [(ngModel)]="showDeleted" color="primary">Ver anotaciones borradas</mat-slide-toggle>
        </div>
    </mat-toolbar>

    <ul *ngIf="filter4student(annotations, true, isEvent).length > 0; else without4student">
        <li *ngFor="let annotation of filter4student(annotations, true, isEvent)">
            <div [ngClass]="{'through':annotation.deleted}">

                <div>
                    <b>{{annotation.createdAt}}.
                        {{annotation.annotationType.name}}:</b>&nbsp;{{annotation.text}}
                    <br>
                    <i>{{annotation.creator.names+' '+annotation.creator.fatherSurname + ' ' +
                        annotation.creator.motherSurname}}.
                    </i>
                    <button mat-icon-button color="primary" *ngIf="canAdminAnnotations(annotation)"
                        matTooltip="Modifica la anotación" (click)="onEditAnnotation(annotation)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" *ngIf="canAdminAnnotations(annotation)"
                        matTooltip="Borra anotación" (click)="onDeleteAnnotation(annotation)">
                        <mat-icon>delete_forever</mat-icon>
                    </button>
                    
                    <button mat-icon-button color="primary" *ngIf="annotation.historyCounter>0 && this.canAuditOrAdmin"
                        (click)="onViewHistortAnnotationsClick(annotation)"
                        [matTooltip]="tooltipAnnotationsCounter(annotation.historyCounter)">
                        <mat-icon>history</mat-icon>
                    </button>

                    <table *ngIf="showHistoryDetail(annotation)" fxFlexFill>
                        <tr>
                            <td class="border">Fecha</td>
                            <td class="border">Modificador</td>
                            <td class="border">Texto Anterior</td>
                        </tr>
                        <tr *ngFor="let history of annotation.history">
                            <td valign="top" nowrap>{{history.modifiedAt}}</td>
                            <td valign="top" nowrap>{{displayModifierName(history.modifier)}}</td>
                            <td valign="top">{{history.previousText}}</td>
                        </tr>
                    </table>

                </div>


            </div>
<div *ngIf="annotation.deleted">Borrada por: {{displayModifierName(annotation.deleter)}}, {{annotation.deletedAt}}
    <hr>
</div>
            
        </li>
    </ul>

    <ng-template #without4student>
        <ul>
            <p><i>Sin {{isEvent ? 'eventos registrados': 'anotaciones registradas'}}.</i></p>
        </ul>
    </ng-template>

    <mat-toolbar>
        <h2>Anotaciones del curso</h2>
    </mat-toolbar>

    <ul *ngIf="filter4student(annotations, false, isEvent).length > 0; else without4course">
        <li *ngFor="let annotation of filter4student(annotations, false, isEvent);">
            <div>
                <p>
                    <b>{{annotation.createdAt}}.
                        {{annotation.annotationType.name}}:</b>&nbsp;{{annotation.text}}
                    <br>
                    <i>{{annotation.creator.names+' '+annotation.creator.fatherSurname + ' ' +
                        annotation.creator.motherSurname}}.
                    </i>
                </p>
            </div>
        </li>
    </ul>
    <ng-template #without4course>
        <ul>
            <p><i>Curso sin {{isEvent ? 'eventos': 'anotaciones'}}.</i></p>
        </ul>
    </ng-template>
</div>