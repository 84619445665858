import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EvaluationType } from 'src/app/model/evaluation-type';

@Component({
  selector: 'app-evaluations-dialog',
  templateUrl: './evaluations-dialog.component.html',
  styleUrls: ['./evaluations-dialog.component.css']
})
export class EvaluationsDialogComponent implements OnInit {
  evaluationTypeAvailableNames: string[] = [];
  evaluationTypeSelectedNames: string[] = [];

  // evaluationIndicator:string ='';
  // criteriaToEvaluate:string ='';

  constructor(public dialogRef: MatDialogRef<EvaluationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: EvaluationsParamDialog) { }

  ngOnInit(): void {
    if (this.dialogData.evaluationTypeIds == null) {
      this.dialogData.evaluationTypeIds = [];
    } else {
      this.evaluationTypeSelectedNames = this
        .dialogData
        .evaluationTypeIds
        .map(evaluationTypeId => this
          .dialogData
          .evaluationTypes
          .find(evaluationType => evaluationType.id == evaluationTypeId)
          .name);

      //      console.log('this.evaluationTypeSelectedNames', this.evaluationTypeSelectedNames);

      //      this.refreshResourceIds();
    }

    this.evaluationTypeAvailableNames = this.dialogData.evaluationTypes.map(evaluationType => evaluationType.name);
  }

  public elementsUpdated(elements: string[]): void {
    console.log(elements);
    this.evaluationTypeSelectedNames = elements;
    this.refreshEvaluationTypeIds();
  }

  private refreshEvaluationTypeIds(): void {
    while (this.dialogData.evaluationTypeIds?.length > 0)
      this.dialogData.evaluationTypeIds.pop();

    this
      .evaluationTypeSelectedNames
      .forEach(evaluationTypeName => {
        const resource = this
          .dialogData
          .evaluationTypes
          .find(evaluationType => evaluationType.name === evaluationTypeName);
        this.dialogData.evaluationTypeIds.push(resource.id);
      });
  }
}

export class EvaluationsParamDialog {
  evaluationTypeIds: string[];
  evaluationTypes: EvaluationType[];
  evaluationIndicator: string;
  criteriaToEvaluate: string;
  readonly: boolean;

}