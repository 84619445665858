import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AssistanceService } from 'src/app/api/assistance.service';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';
import { DateTimeUtility } from '../../../api/date-time-utility.service';
import { Hour } from '../../../model/hour';
import { Assistance } from './assistance';
import { AssistanceBlock } from './assistance-block';
import { Moment } from 'moment';
import { AlertUtility, VerifyUserAndPasswordResponse } from 'src/app/utility/alert.utility';
import { SignatureService } from 'src/app/api/signature.service';
import { UserSign } from 'src/app/model/user-sign';
import { Signed } from 'src/app/model/signed';

@Component({
  selector: 'app-course-assistance',
  templateUrl: './course-assistance.component2.html',
  styleUrls: ['./course-assistance.component.css',
    '../../../style/table.css']
})
export class CourseAssistanceComponent implements OnInit {
  public course: Course;
  private courseId: string;
  assistance: Assistance[] = [];
  displayedColumns: string[] = ['listNumber', 'name', 'assistence', 'comment'];
  dataSource: MatTableDataSource<Assistance>;

  timeBlocks: Hour[];
  timeBlockId: string = '';

  public currentDate: Moment;
  public minDate: Moment;
  public maxDate: Moment;

  public assistanceBlock: AssistanceBlock = new AssistanceBlock();
  private signatureType: string = '';
  public signed: boolean = false;
  public readonly: boolean = true;

  constructor(
    public courseService: CourseService, private configService: ConfigService, private assistanceService: AssistanceService,
    public dateTimeUtil: DateTimeUtility, private router: Router, private alert: AlertUtility,
    private signatureService: SignatureService) { }

  ngOnInit(): void {
    this.courseId = localStorage.getItem('courseId');
    this.readonly = localStorage.getItem('readonly') == 'true';
    this.initData();
  }

  private async initData() {
    await this.getCourse();
    await this.getCurrentDate();
    await this.getFirstDate();
    await this.loadTimeBlocks();

    this.configService.getApplicationProperty2('cl.buildersoft.signature.type')
      .subscribe(
        response => {
          this.signatureType = response.valor;
          console.log(`Tipo de firma: ${this.signatureType}`);
        },
        problems => console.error(problems)
      );

  }

  public async getCourse() {
    this.course = await this.courseService.getAsync(this.courseId);
  }

  public fdate(): string {
    return this.dateTimeUtil.momentToHumanString(this.currentDate);
  }


  public async getFirstDate() {
    const response = await this.assistanceService.getFirstDateAsync();
    this.minDate = this.dateTimeUtil.stringToMoment(response);
  }

  public async getCurrentDate() {
    const response = await this.assistanceService.getCurrentDateAsync();
    this.currentDate = this.dateTimeUtil.stringToMoment(response);
    this.maxDate = this.dateTimeUtil.stringToMoment(response);
  }

  private async loadTimeBlocks(): Promise<void> {
    console.log('Before call getTimeBlocks service', this.courseId, this.dateTimeUtil.momentToString(this.currentDate));

    this.configService.getTimeBlocks(this.courseId, this.dateTimeUtil.momentToString(this.currentDate))
      .subscribe(
        response => {
          console.log('Lista de TimeBlock :' + JSON.stringify(response));
          this.timeBlocks = response;
          try {
            this.timeBlockId = this.timeBlocks.filter(tb => tb.selected)[0].id;
          } catch (e) {
            this.timeBlockId = '';
            console.warn('Ahora no es un bloque horario definido.');
          }

          this.timeBlockOnChange();
        },
        problems => console.log(problems)
      );
  }

  public timeBlockOnChange(): void {
    console.log('TB: ' + this.timeBlockId + ' / Fecha: ' + this.dateTimeUtil.momentToString(this.currentDate));
    const sign = new Signed();
    if (this.timeBlockId != '') {


      const teacherId: string = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].teacherId;
      const subjectId: string = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].subjectId;
      console.log('TEACHER: ', teacherId, `subjectId: ${subjectId}`);

      this.assistanceService.listAssistance(this.courseId, this.dateTimeUtil.momentToString(this.currentDate), this.timeBlockId, teacherId, subjectId)
        .subscribe(
          response => {
            this.assistance = response;
            this.dataSource = new MatTableDataSource(this.assistance);
            this.assistanceBlock.assistance = this.assistance;
            this.assistanceBlock.courseId = this.courseId;
            this.assistanceBlock.date = this.dateTimeUtil.momentToString(this.currentDate);
            this.assistanceBlock.timeBlockId = this.timeBlockId;
          },
          problems => console.log(problems)
        );


      sign.courseId = this.courseId;
      sign.signatureDate = this.dateTimeUtil.momentToString(this.currentDate);
      sign.subjectId = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].subjectId;
      sign.teacherId = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].teacherId;
      sign.timeBlockId = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].scheduleId; // this.timeBlockId;
      sign.type = 'ATTENDANCE';

      this.signatureService.isSigned(sign)
        .subscribe(
          response => {
            this.signed = response != null;
          },
          problems => console.error(problems)
        );

    }
  }

  public asistenceOnChange(e) {
  }

  public cancelOnClick(): void {
    this.router.navigate(['courses']);
  }

  public onChangeCalendar(e: Moment) {
    console.log('Cambiando fecha del calendario', e.format('YYYY-MM-DD'));

    this.currentDate = e;

    this.loadTimeBlocks();
    this.dataSource = new MatTableDataSource(null);
  }

  public async saveAndSignOnClick(): Promise<void> {
    console.log('assistance: ', this.assistance);
    console.log(this.currentDate);
    console.log(this.assistanceBlock);

    const response: VerifyUserAndPasswordResponse = await this.alert.verifyUserAndPassword(`Firme para grabar la asistencia de ${this.course.letra}`, true, true);

    if (!response.isValid) {
      this.alert.fastMessage('Clave incorrecta');
      return;
    }

    const userSign: UserSign = new UserSign();
    userSign.comment = response.comment;
    userSign.courseId = this.courseId;
    userSign.signatureDate = this.dateTimeUtil.momentToString(this.currentDate);
    userSign.subjectId = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].subjectId;
    userSign.teacherId = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].teacherId;
    userSign.type = 'ATTENDANCE';
    userSign.scheduleId = this.timeBlocks.filter(tb => tb.id == this.timeBlockId)[0].scheduleId;
    console.log(userSign);

    this.signatureService.doSign(userSign)
      .subscribe(
        response => {
          console.log(response);
          this.saveAssistance();
        },
        problems => {
          console.error(problems);
          this.alert.fastMessage(problems.error.message);
        }
      );

  }

  public saveOnClick(): void {
    this.saveAssistance();
  }

  private saveAssistance(): void {
    this.assistanceService.saveAssistanceBlock(this.assistanceBlock)
      .subscribe(
        response => {
          console.log(response);
          this.alert.dialogMessage('Asistencia grabada con éxito');
          this.router.navigate(['courses']);
        },
        problems => {
          console.log(problems);
          alert(JSON.stringify(problems));
        }
      );
  }

  public getStudentStatus(studentStatus: string) {
    if (studentStatus == 'MAT') return '';
    switch (studentStatus) {
      case 'RET': return '(Retirado)';
      case 'PRM': return '(Promovido)';
      case 'INS': return '(Inscrito)';
      case 'REP': return '(Reprobado)';
      case 'XMT': return '(Por matricular)';
      case 'NMT': return '(Matricula cuestionada)';
      case 'NOC': return '(No continua)';
      default:
        return studentStatus;
    }
  }

}
