import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ConfigService } from 'src/app/api/config.service';

@Component({
  selector: 'app-send-to-mineduc-dialog',
  templateUrl: './send-to-mineduc-dialog.component.html',
  styleUrls: ['./send-to-mineduc-dialog.component.css']
})
export class SendToMineducDialogComponent implements OnInit {
  public minDate: Moment = moment();
  public maxDate: Moment = moment();

  constructor(public dialogRef: MatDialogRef<SendToMineducDialogParams>,
    @Inject(MAT_DIALOG_DATA) public dialogData: SendToMineducDialogParams  ) { }

  ngOnInit(): void {
    console.log('DialogData: ', this.dialogData);

    this.minDate.date(1);
    this.minDate.month(this.dialogData.month - 1);
    this.minDate.year(this.dialogData.currentYear);

    let nextMonth: Moment = moment(this.minDate); 
    debugger;
    nextMonth.add(1, 'month');

    let day = nextMonth.add(-1, 'day');
    console.log(nextMonth.date());
    console.log(nextMonth.day());


    this.maxDate.date(day.date());
    this.maxDate.month(this.minDate.month());
    this.maxDate.year(this.minDate.year());

   this.maxDate = moment();
//    this.dialogData.startDate 


    console.log('MinDate: ', this.minDate.format('YYYY-MM-DD'));
    console.log('MaxDate: ', this.maxDate.format('YYYY-MM-DD'));
    // console.log('dialogData.startDate: ', this.dialogData.startDate.format('YYYY-MM-DD'));
    

  }

  public planningYearFilter = (d: Moment | null): boolean => {
    return d.year() == this.dialogData.currentYear;
  }

  public doClickButton(doSend: boolean): void {
    // console.log('this.dialogData.startDate -> ', this.dialogData.startDate.format('yyyy-MM-dd'));
    // console.log('this.dialogData.finishDate -> ', this.dialogData.finishDate.format('yyyy-MM-dd'));

    // this.dialogData.startDate = this.dialogData.startDate.add(-1, 'M');
    // this.dialogData.finishDate = this.dialogData.finishDate.add(-1, 'M');
    // console.log('this.dialogData.startDate -> ', this.dialogData.startDate);

    this.dialogData.doSend = doSend;
  }
}

export class SendToMineducDialogParams {
  startDate: Moment;
  finishDate: Moment;
  doSend: boolean = false;
  currentYear: number;
  month: number;
}