<!--
{{showAllCourses}}
{{readonly}}
-->
<div class="mat-elevation-z1" style="width: 98%;" fxLayout="row" fxLayoutGap="10px" fxFlexOffset="10px">
  <table mat-table [dataSource]="dataSource" width="100%">

    <ng-container matColumnDef="letter">
      <th mat-header-cell *matHeaderCellDef class="align-center" mat-sort-header> Letra </th>
      <td mat-cell *matCellDef="let row" class="align-center" nowrap> {{courseService.formatLetra(row.letra)}} </td>
    </ng-container>

    <ng-container matColumnDef="specialty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Especialidad </th>
      <td mat-cell *matCellDef="let row" nowrap class="align-left">
          {{row.especialidadNombre}}
          <mat-icon *ngIf="row.teacherId == this.auth.user.id" color="primary">star</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="teacher">
      <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Profesor </th>
      <td mat-cell *matCellDef="let row" class="align-left" nowrap> {{row.teacherName}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Acciones </th>
      <td mat-cell *matCellDef="let row" class="align-center" nowrap>

        <button mat-raised-button (click)="alumnosOnClick(row.id)" color="primary" matTooltip="Lista de Alumnos">
          <mat-icon>group</mat-icon>&nbsp;
        </button>
        <span>&nbsp;</span>
        <button mat-raised-button (click)="asistenciaOnClick(row.id)" matTooltip="Asistencia">
          <mat-icon>how_to_reg</mat-icon>&nbsp;
        </button>

        <span>&nbsp;</span>
        <button mat-raised-button (click)="doAddAnnotation(row.id)" matTooltip="Nueva anotacion al curso..." *ngIf="!this.readonly">
          <mat-icon>assignment_add</mat-icon>
        </button>

        <button mat-raised-button (click)="doLectionary(row.id)" matTooltip="Leccionario...">
          <mat-icon>menu_book</mat-icon>
        </button>

        <button mat-raised-button (click)="showHoraryClick(row.id)" matTooltip="Horario">
          <mat-icon>calendar_month</mat-icon>
        </button>

        <button mat-raised-button (click)="propertiesOnClick(row.id)" *ngIf="canEditProperties && !readonly"
          matTooltip="Propiedades del curso">
          <mat-icon>tune</mat-icon>
        </button>

        <button mat-raised-button (click)="downloadSummaryOnClick(row.id)" *ngIf="this.canDownloadSummary"
          matTooltip="Informe de notas del curso">
          <mat-icon>book</mat-icon>
        </button>

        <button mat-raised-button (click)="downloadPersonalityReportOnClick(row.id)"
          *ngIf="this.canDownloadPersonalityReport" matTooltip="Informes de personalidad">
          <mat-icon>person_book</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>