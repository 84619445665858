import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CourseService } from 'src/app/api/course.service';
import { TeacherAndSubject } from 'src/app/model/teacher-and-subject';
import { CourseTeacherSubjectDialogComponent } from './course-teacher-subject-dialog/course-teacher-subject-dialog.component';

@Component({
  selector: 'app-course-teachers',
  templateUrl: './course-teachers.component.html',
  styleUrls: ['./course-teachers.component.css', './../../../../style/table.css']
})
export class CourseTeachersComponent implements OnInit, AfterViewInit {
  @Input()
  courseId: string;

  dataSource: MatTableDataSource<TeacherAndSubject> = new MatTableDataSource([]);
  columnsToDisplay: string[] = ['selector', 'teacher', 'subject', 'mineduc-code','priority'];
  selection = new SelectionModel<TeacherAndSubject>(true, []);

  currentSelected: TeacherAndSubject = null;

  constructor(private courseService: CourseService,
    public dialog: MatDialog) { }

  ngAfterViewInit() {
  }

  onSelectOne(row: TeacherAndSubject) {
    console.log(row);
    this.currentSelected = row;
  }

  ngOnInit(): void {
    this
      .courseService
      .listTeachersAndSubjects(this.courseId)
      .subscribe(
        response => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response);
        },
        problems => console.error(problems)
      );
  }

  nothingSelected() {
    return this.currentSelected == null;
  }

  onOpenDialogForAdd(): void {
    const courseTeacherSubjectDialogParam: CourseTeacherSubjectDialogParam = new CourseTeacherSubjectDialogParam();
    courseTeacherSubjectDialogParam.title = 'Agregar asignatura y profesor';
    courseTeacherSubjectDialogParam.courseId = this.courseId;

    courseTeacherSubjectDialogParam.subjectId = null;
    courseTeacherSubjectDialogParam.teacherId = null;
    courseTeacherSubjectDialogParam.priority = this.maxPriority(this.dataSource.data) + 1;

    const dialogRef = this.dialog.open(CourseTeacherSubjectDialogComponent,
      { data: courseTeacherSubjectDialogParam, width:'50%' });

    dialogRef.afterClosed().subscribe(responseFromDialog => {
      if (typeof responseFromDialog !== 'undefined') {
        const teacherAndSubjectToSave: TeacherAndSubject = new TeacherAndSubject();
        teacherAndSubjectToSave.priority = responseFromDialog.priority;
        teacherAndSubjectToSave.teacherId = responseFromDialog.teacherId;
        teacherAndSubjectToSave.subjectId = responseFromDialog.subjectId;
        this
          .courseService
          .relateTeacherSubject(this.courseId, teacherAndSubjectToSave)
          .subscribe(
            response => {
              const tempData = this.dataSource.data;
              tempData.push(response);
              //              this.dataSource.data = tempData;
              this.dataSource.data = this.sortByPriority(tempData);
            },
            problems => console.error(problems)
          );
      }
    });
  }

  onUpdate() {
    const courseTeacherSubjectDialogParam: CourseTeacherSubjectDialogParam = new CourseTeacherSubjectDialogParam();
    courseTeacherSubjectDialogParam.title = 'Actualizar asignatura y profesor';
    courseTeacherSubjectDialogParam.courseId = this.courseId;
    courseTeacherSubjectDialogParam.subjectId = this.currentSelected.subject.id;
    courseTeacherSubjectDialogParam.teacherId = this.currentSelected.teacher.id;
    courseTeacherSubjectDialogParam.priority = this.currentSelected.priority;

    const dialogRef = this.dialog.open(CourseTeacherSubjectDialogComponent,
      { data: courseTeacherSubjectDialogParam, width:'80%' });

    dialogRef
      .afterClosed()
      .subscribe(responseFromDialog => {
        if (typeof responseFromDialog !== 'undefined') {
          const teacherAndSubjectToSave: TeacherAndSubject = new TeacherAndSubject();
          teacherAndSubjectToSave.id = this.currentSelected.id;
          teacherAndSubjectToSave.priority = responseFromDialog.priority;
          teacherAndSubjectToSave.teacherId = responseFromDialog.teacherId;
          teacherAndSubjectToSave.subjectId = responseFromDialog.subjectId;

          this
            .courseService
            .updateTeacherSubject(this.courseId, teacherAndSubjectToSave)
            .subscribe(
              response => {
                const tempData = this.removeFormList(this.dataSource.data, response.id);
                tempData.push(response);
                this.dataSource.data = this.sortByPriority(tempData);
              },
              problems => console.error(problems)
            );
        }
      });
  }

  onRemove(): void {
    if (confirm('Desea retirar esta relacion?')) {
      this
        .courseService
        .removeRelateTeacherSubject(this.currentSelected.id)
        .subscribe(
          response => {
            if (response) {
              this.dataSource.data = this.removeFormList(this.dataSource.data, this.currentSelected.id);
              this.currentSelected = null;
            } else {
              alert('No se quitó la al profesor, es posible que exista un horario asociasdo');
            }
          },
          problems => {
            console.error(problems);
            alert(problems.error.message);
          }
        )
    }
  }

  removeFormList(list: TeacherAndSubject[], id: string): TeacherAndSubject[] {
    const tempData = list.slice();
    return tempData.filter(tas => tas.id != id);
  }

  sortByPriority(list: TeacherAndSubject[]): TeacherAndSubject[] {
    const tempData = list.slice();
    tempData.sort((o1, o2) => {
      if (o1.priority > o2.priority) return 1;
      if (o1.priority < o2.priority) return -1;
      return 0;
    });
    return tempData;
  }

  maxPriority(list: TeacherAndSubject[]): number {
    let max: number = 0;
    let first: boolean = true;
    if (list.length == 0) {
      return max;
    }

    list.forEach(tas => {
      if (first) {
        max = tas.priority;
        first = false;
      } else {
        if (tas.priority >= max) max = tas.priority;
      }
    });
    return max;
  }
}

export class CourseTeacherSubjectDialogParam {
  title: string;
  courseId: string;
  teacherId: string;
  subjectId: string;
  priority: number;

}
