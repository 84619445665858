<div fxLayout="column" fxLayoutAlign="center center">
  <h1>Lista de cursos, año {{year}}</h1>


  <mat-tab-group fxFlexFill x_animationDuration="300ms" *ngIf="this.year > 0">
    <mat-tab label="Mis cursos">
      <app-course-list [readonly]="false" [year]="this.year" [showAllCourses]="false"></app-course-list>
    </mat-tab>
    <mat-tab label="Otros cursos" *ngIf="this.canShowAllCourses">
      <app-course-list [readonly]="true" [year]="this.year" [showAllCourses]="this.canShowAllCourses"></app-course-list>
    </mat-tab>
  </mat-tab-group>

</div>

