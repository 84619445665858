<h1 mat-dialog-title>Datos de contacto</h1>
<div mat-dialog-content>

    <table fxFlexFill border="0">
        <tr>
            <td>
                <!-- 
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Rut</mat-label>
                    <input matInput placeholder="12345678-5" [(ngModel)]="legalGuardianDialogParam.rut">
                </mat-form-field>
            -->
                <com-rut-input *ngIf="legalGuardianDialogParam.canAdminContacts"
                    [data]="legalGuardianDialogParam.rut" (validateRutEvent)="validateRut($event)"></com-rut-input>

                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Rut:</strong>{{legalGuardianDialogParam.rut}}
                </div>
            </td>
            <td width="300px">
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Nombre</mat-label>
                    <input matInput placeholder="Juan Perez" [(ngModel)]="legalGuardianDialogParam.name">
                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Nombre:</strong>{{legalGuardianDialogParam.name}}
                </div>

            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Parentezco</mat-label>
                    <mat-select [(ngModel)]="legalGuardianDialogParam.relationshipId">
                        <mat-option *ngFor="let relationship of this.relationships" [value]="relationship.id">
                            {{relationship.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Parentezco:</strong>{{legalGuardianDialogParam.relationshipName}}
                </div>

            </td>
            <td>
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Numero Celular</mat-label>
                    <input matInput placeholder="987654321" [(ngModel)]="legalGuardianDialogParam.cellPhoneNumber">
                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts"><strong>Numero
                        Celular:</strong>{{legalGuardianDialogParam.cellPhoneNumber}}</div>

            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="juan.perez@gmail.com" [(ngModel)]="legalGuardianDialogParam.email">
                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Email:</strong>{{legalGuardianDialogParam.email}}
                </div>

            </td>
            <td>
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Dirección</mat-label>
                    <input matInput placeholder="Dirección física" [(ngModel)]="legalGuardianDialogParam.address" (keyup)="this.findAddress($event)"
                    [formControl]="formControl"
                    [matAutocomplete]="auto"                    >
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>



                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Dirección:</strong>{{legalGuardianDialogParam.address}}
                </div>

            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Actividad</mat-label>
                    <input matInput placeholder="Actividad que desempeña"
                        [(ngModel)]="legalGuardianDialogParam.activity">
                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Actividad:</strong>{{legalGuardianDialogParam.activity}}
                </div>

            </td>
            <td>
                <mat-form-field fxFlexFill appearance="fill" *ngIf="legalGuardianDialogParam.canAdminContacts">
                    <mat-label>Estudios</mat-label>
                    <input matInput placeholder="Últimos estudios completados"
                        [(ngModel)]="legalGuardianDialogParam.studies">
                </mat-form-field>
                <div *ngIf="!legalGuardianDialogParam.canAdminContacts">
                    <strong>Estudios:</strong>{{legalGuardianDialogParam.studies}}
                </div>

            </td>
        </tr>

        <!--
        <tr>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
        </tr>
        <tr>
            <td></td>
            <td></td>
        </tr>
        -->
    </table>




</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.legalGuardianDialogParam" color="primary" cdkFocusInitial
        *ngIf="legalGuardianDialogParam.canAdminContacts">
        <mat-icon>save</mat-icon> Grabar
    </button>
    <button mat-raised-button [mat-dialog-close]="">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>