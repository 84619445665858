import { Component, OnInit, OnChanges, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { LevelService } from 'src/app/api/level.service';
import { Level } from 'src/app/model/level';

@Component({
  selector: 'com-level-selector',
  templateUrl: './level-selector.component.html',
  styleUrls: ['./level-selector.component.css']
})
export class LevelSelectorComponent implements OnInit, OnChanges {
  @Input() year: number;

  levelList: Level[] = [];

  @Input() levelId: string = '';

  @Input() username: string | null = null;

  @Output() levelChangeEvent = new EventEmitter<string>();

  constructor(private levelService: LevelService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh();
  }

  private refresh() {
    if (this.year == 0) return;
    this.levelList = [];
    if (this.username == null) {
      this.levelService.listByYear(this.year)
        .subscribe(
          response => this.levelList = response,
          problems => console.error(problems)
        );
    } else {
      this.levelService.listByYearV2(this.year, this.username)
        .subscribe(
          response => this.levelList = response,
          problems => console.error(problems)
        );

    }
  }

  onChangeLevel(): void {
    this.levelChangeEvent.emit(this.levelId);
  }
}
