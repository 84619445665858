<h1 mat-dialog-title>Cambio número de Rut</h1>
<div mat-dialog-content>
    <strong>Alumno:</strong>{{changeRutDialogParams.fullName}}
    <br>

    <p>Confirme o corrija el número de Rut del alumno. Los datos del Alumno no serán desvinculados.</p>

    <com-rut-input [data]="changeRutDialogParams.rut" (validateRutEvent)="validateRutEvent($event)"></com-rut-input>

</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="changeRutDialogParams" cdkFocusInitial color="primary"
        [disabled]="!this.rutValid">
        <mat-icon>save</mat-icon>Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">
        <mat-icon>close</mat-icon>Cancelar</button>
</div>