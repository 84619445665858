export class Company {
  in_emp: number
  empresa: string
  rut_emp: string
  repre_legal: string
  sueldo: number
  direccion: string
  comuna: string
  fono: string
  fax: string
  movil: string
  email: string
  convenio: string
  donacion: number
  fecha_inicio: string
  rubro: string
  giro: string
  web: string
  ntraba: number
  contacto: string
  nalum: number
  boletin: string
}
