import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubjectService } from 'src/app/api/subject.service';
import { UserService } from 'src/app/api/user.service';
// import { DialogData } from 'src/app/gui/alumno/alumno.component';
import { Subject } from 'src/app/model/subject';
import { User } from 'src/app/model/user';
// import { isNumericLiteral } from 'typescript';
import { GroupsService } from 'src/app/api/groups.service';
import { CourseTeacherSubjectDialogParam } from '../course-teachers.component';
import { GroupsDetail } from 'src/app/model/groups-detail';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';

@Component({
  selector: 'app-course-teacher-subject-dialog',
  templateUrl: './course-teacher-subject-dialog.component.html',
  styleUrls: ['./course-teacher-subject-dialog.component.css']
})
export class CourseTeacherSubjectDialogComponent implements OnInit {
  public teachers: User[];
  public subjects: Subject[];
  public groups: string[] = [];
  public startDate: string;
  public finishDate: string;
  private course: Course;
  public freezeGroup: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: CourseTeacherSubjectDialogParam,
    public dialogRef: MatDialogRef<CourseTeacherSubjectDialogComponent>,
    private userService: UserService,
    private subjectService: SubjectService,
    private groupsService: GroupsService,
    private courseService: CourseService) {
      console.log('Constructor');
      
    // console.log('DialogData On Constructor: ', dialogData);
    // this.hasGroup = this.dialogData.group != null && this.dialogData.group.length > 0;

    // this.dialogData.group == null  || this.dialogData.group.length == 0
    this.freezeGroup = false;

    console.log('dialogData.action', dialogData.action, dialogData.group);

    if (dialogData.action == 'update') {
      this.freezeGroup = true; // !this.hasGroup(dialogData.group);
    } else {
      this.groups = [''];
    }
  }

  public hasGroup(group: string): boolean {
    return group != null && group.trim().length > 0;
  }


  ngOnInit(): void {
    console.log('Init dialog', this.dialogData.group);


    this.groupsService.findGroupsDetail(this.dialogData.courseId, false)
      .subscribe(
        (response: GroupsDetail) => {
          // console.log(response);
          response.groups.forEach(g => this.groups.push(g.group));
        },
        problems => console.error(problems)
      );


    this.userService.listTeachers()
      .subscribe(response => this.teachers = response);

    // console.log(this.dialogData.courseId );

    // Hay que cargar el curso primero para poder llamar a la lista de asignaturas.
    /*
    this.courseService.get(this.dialogData.courseId)
      .subscribe(
        (response: Course) => {
          //hay que llamar al endpoint: /V2/list-by-level y pasarle username=null
          this.subjectService.listByLevelIdV2(response.level.id, response.agno, null)
            .subscribe(
              response => this.subjects = response,
              problems => console.error(problems)
            );
        }
      );
*/
    /*
        if (this.dialogData.subjectId != null) {
          this.subjectService.listByNotCourseAndInclude(this.dialogData.courseId, this.dialogData.subjectId)
            .subscribe(response => this.subjects = response);
        } else {
          this.subjectService.listByNotCourse(this.dialogData.courseId)
            .subscribe(response => this.subjects = response);
        }
    */

    this.subjectService.listAll()
      .subscribe(response => {
        // console.log(response);
        this.subjects = response;
      });


  }

}
