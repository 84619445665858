import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, Routes } from '@angular/router';
import { AuthorService } from 'src/app/api/author.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title: string = "Acceso seguro";
  user: User;  // { username: '', password: '' };
  // accessWrong: boolean = false;
  // accessMessage: string = '';

  constructor(
    private authorService: AuthorService,
    private router: Router,
    private snackBar: MatSnackBar) {
    this.user = new User();
  }

  ngOnInit() {
    if (this.authorService.isAuthenticated()) {
      this.router.navigate(['welcome']);
    }
  }

  onClickLogin(): void {
    //    console.log('listo para llamar al servicio', this.user.username, this.user.password);
    this.authorService.login(this.user.username, this.user.password)
      .subscribe(
        response => {
          console.log(response);
          //          this.parseToken(response);
          this.authorService.saveToken(response.access_token);
          this.authorService.saveUser(response.access_token);
          // let user = this.authorService.user;

          // console.log('Roles: ', this.authorService.user.roles);

          if (this.authorService.user.roles.length == 0) {
            this.snackBar.open('Usuario no tiene roles definidos', 'OK', { duration: 2000 });
            this.authorService.logout();
            return;
          }

          this.router.navigate(['welcome']);
        },
        problems => {
          console.error(problems);
          // this.accessWrong = true;
          // this.accessMessage = problems.error.error_description;
          this.snackBar.open(`Acceso denegado (${problems.message})`, 'OK', { duration: 2000 });
        }
      )
  }

  parseToken(response: any): void {
    let payload = JSON.parse(atob(response.access_token.split(".")[1]));

    console.log(payload);
  }

  onChangeCredentials(): void {
    // this.accessWrong = false;
  }
}
