<mat-form-field class="chip-list-style" appearance="fill">
    <mat-label id='chip-label'>{{label}}</mat-label>
    <mat-chip-list #chipList aria-label="Selección" [formControl]="formControl">
        <mat-chip *ngFor="let fruit of this.selectedElements" (removed)="remove(fruit)">
            {{fruit}}
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <input [placeholder]="this.placeholder" #elementInput    [matAutocomplete]="auto"
            [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let fruit of filteredElements | async" [value]="fruit">
            {{fruit}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
