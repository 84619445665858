<h1 mat-dialog-title>{{dialogData.title}}</h1>

<div mat-dialog-content fxLayout="column">

    <div *ngFor="let column of filterColumns(dialogData.columns); let i = index">
        <!--
        {{f(column.value)}}
-->
        <div *ngIf="isBooleanField(column); else notBooleanFields">
            <mat-slide-toggle [(ngModel)]="column.value">{{i+1}}.- {{column.header}}</mat-slide-toggle>
        </div>

        <ng-template #notBooleanFields>
            <mat-form-field appearance="fill" fxFlexFill *ngIf="isSelectField(column); else inputFields">
                <mat-label>{{i+1}}.- {{column.header}}</mat-label>
                <mat-select [(ngModel)]="column.value">
                    <mat-option *ngFor="let data of column.externalChild.data" [value]="data[column.externalChild.id]">
                        {{data[column.externalChild.name]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>

        <ng-template #inputFields>
            <mat-form-field appearance="fill" *ngIf="isTextarea(column); else inputDateFields" fxFlexFill>
                <mat-label>{{i+1}}.- {{column.header}}</mat-label>
                <textarea matInput [(ngModel)]="column.value" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>
        </ng-template>

        <ng-template #inputDateFields>
            <mat-form-field appearance="fill" *ngIf="this.isDateField(column); else inputTextFields" fxFlexFill>
                <mat-label>{{i+1}}.- {{column.header}}</mat-label>
                <input matInput [matDatepicker]="picker" [(ngModel)]="column.value">
                <mat-datepicker-toggle matSuffix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </ng-template>


        <ng-template #inputTextFields>
            <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>{{i+1}}.- {{column.header}}</mat-label>
                <input matInput [type]="resolveType(column)" [maxLength]="column.length" [placeholder]="column.header"
                    [(ngModel)]="column.value">
            </mat-form-field>
        </ng-template>






    </div>

</div>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="this.dialogData">
        <mat-icon>save</mat-icon>Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>close</mat-icon>Cancelar
    </button>
</mat-dialog-actions>