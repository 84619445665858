import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Payment, Payments } from "../model/payment";
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class PaymentsService {
    private baseUrl: string = environment.apiAdapterUrl;
    private paymentsUrl: string = '/api/adapter/payments';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listPayments(studentRut: string, year: string): Observable<Payments> {
        const url = this.baseUrl + this.paymentsUrl + `/v1?studentRut=${studentRut}&year=${year}`;
        console.log(url);
        return this.http.get<Payments>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }


}