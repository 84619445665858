import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnnotationService } from 'src/app/api/annotation.service';
import { AuthorService } from 'src/app/api/author.service';
import { Annotation } from 'src/app/model/annotation';
import { Status } from 'src/app/model/status';
import { User } from 'src/app/model/user';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { PromptDialogType } from 'src/app/utility/prompt-dialog/prompt-dialog.component';

@Component({
  selector: 'app-annotations',
  templateUrl: './annotations.component.html',
  styleUrls: ['./annotations.component.css', '../../../style/table.css']
})
export class AnnotationsComponent implements OnInit, OnChanges {

  @Input() public annotations: Annotation[];
  @Input() public isEvent: boolean;
  @Input() public canAdminAnnotation: boolean = false;
  @Input() public canAuditAnnotation: boolean = false;

  public currentUserId: string = null;
  public showDeleted: boolean = false;
  public canAuditOrAdmin: boolean = false;

  constructor(private authService: AuthorService,
    private alertUtility: AlertUtility,
    private annotationService: AnnotationService) { }

  ngOnInit(): void {
    this.currentUserId = this.authService.user.id;
  }

  public filter4student(annotations: Annotation[], its4student: boolean, isEvent: boolean): Annotation[] {
    return annotations.filter(annotation => annotation.its4Student == its4student)
      .filter(annotation => annotation.annotationType.event == isEvent)
      .filter(annotation => !annotation.deleted || this.showDeleted);
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('ngOnChanges', changes);
    this.canAuditOrAdmin = this.canAdminAnnotation || this.canAuditAnnotation;
  }

  public canAdminAnnotations(annotation: Annotation): boolean {
    if (annotation.deleted) return false;
    return this.canAdminAnnotation || (annotation.creator.id == this.currentUserId && annotation.inTimeForWrite);
  }

  public async onDeleteAnnotation(annotation: Annotation): Promise<void> {
    const userMessage = `Seguro de borrar anotación ${annotation.annotationType.name} del ${annotation.createdAt}`;
    const confirm = await this.alertUtility.confirmMessage(userMessage);

    if (confirm) {
      this.annotationService.delete(annotation.id)
        .subscribe(
          (response: Status) => {
            console.log(response);
            this.annotations.filter(_annotation => _annotation.id == annotation.id)[0].deleted = true;
            this.alertUtility.fastMessage("Anotation eliminada...");
          },
          problems => console.error(problems)
        );

    }
  }

  public async onEditAnnotation(annotation: Annotation): Promise<void> {
    console.log('editando annotation: ', annotation);

    const newText = await this.alertUtility.promptMessage(PromptDialogType.TEXT_AREA, annotation.text,
      'Modifique la anotacion',
      `Puede modificar la anotación del alumno. 
      El cambio quedará almacenado.`, 'Contenido actual de anotación');
    if (newText != null) {
      annotation.text = newText;
      this.annotationService.update(annotation)
        .subscribe(
          response => {
            this.alertUtility.fastMessage('Modificacion efectuada');
            annotation.history = null;
            annotation.historyCounter++;
            annotation.showHistoryDetail = false;
          },
          problems => console.error(problems)
        );
    }
    return null;
  }

  public tooltipAnnotationsCounter(historyCounter: number): string {
    return historyCounter == 1 ? 'Hay una anotación anterior' : 'Hay ' + historyCounter + ' anotaciones...'
  }

  public showHistoryDetail(annotation: Annotation): boolean {
    return annotation.historyCounter > 0 && annotation.showHistoryDetail;
  }

  public onViewHistortAnnotationsClick(annotation: Annotation): void {
    if (annotation.history == null) {
      this.annotationService.listHistoricAnnotations(annotation.id)
        .subscribe(
          response => {
            console.log(response);
            annotation.history = response;
          },
          problems => console.error(problems)
        );
    }


    annotation.showHistoryDetail = !annotation.showHistoryDetail;
  }

  public displayModifierName(user: User): string {
    return `${user.fatherSurname} ${user.motherSurname}, ${user.names}`;

  }

}
