import { Course } from './course';
export class Alumno {
    id: string;
    rut: number;
    dv: string;
    paterno: string;
    materno: string;
    nombres: string;
    familia: number;
    prevision: string;
    tieneInternet: boolean;
    cuantosPc: number;
    estado: string;
    masculino: boolean;
    direccion: string;

    comunaId: string;
    comunaNombre: string;

    agnoActual: number;
    course: string;
    courseDto: Course;
    edad: number;

    numeroMatricula: number;
    rutApoderado: string;
    nombreApoderado: string;
    fonoApoderado: string;
    mailApoderado: string;

    creadoEn: string;
    nacimiento: string;

    numeroLista: number;

    assistance: string;

    nationalityId: string;
    nationalityName: string;
    gender: string;
    takeReligion: boolean;
    requiresPsychologist: boolean;

    source: string;
}

export class CustomButton {
    label: string;
    index: number;
}
