<h1 *ngIf="hideAdd">Agregar Alumnos en Práctica a {{company.empresa}}
</h1>
<h1 *ngIf="hideUpdate">Actualizar Práctica de Alumno en {{company.empresa}}
</h1>
<br>
<mat-card>
  <mat-card-content>
    <form>
      <table class="example-full-width">
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Fecha</mat-label>
              <input matInput disabled value="{{formattedDate}}">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Orden</mat-label>
              <input matInput disabled value="">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Empresa</mat-label>
              <input matInput disabled value="{{company.empresa}} ">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Rut</mat-label>
              <input matInput disabled value="{{company.rut_emp}}">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Alumno</mat-label>
            <input type="text" placeholder="Ingrese Nombre" matInput  [matAutocomplete]="auto" (keyup)="applyFilter($event)" value="{{studentName}}" formControlName="name" name="name" >
                  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setRut($event.option.value)" required >
                    <mat-option *ngFor="let option of students" [value]="option.paterno+' '+option.materno+' '+option.nombres+' - '+option.rut">
                      {{option.paterno}} {{option.materno}} {{option.nombres}}
                    </mat-option>
                  </mat-autocomplete>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>RUT</mat-label>
              <input matInput value="{{studentRut}}" [disabled]="true" required>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td >
            <mat-form-field class="example-full-width">
              <mat-label>Fecha de inicio / Fecha de termino</mat-label>
              <mat-date-range-input [formGroup]="registerForm" [rangePicker]="picker">
                <input matStartDate formControlName="inicio" name="inicio" placeholder="Fecha de inicio">
                <input matEndDate formControlName="termino"  name="termino" placeholder="Fecha de termino">
              </mat-date-range-input>
              <mat-hint></mat-hint>
              <mat-error *ngIf="registerForm.controls.inicio.hasError('matStartDateInvalid')">Fecha de inicio inválida</mat-error>
              <mat-error *ngIf="registerForm.controls.termino.hasError('matEndDateInvalid')">Fecha de termino inválida</mat-error>
            </mat-form-field>
          </td>
          <td align="left">
            <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </td>
        </tr>
        <tr>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Horario de entrada</mat-label>
            <mat-select [(ngModel)]="entradaValue" formControlName="entrada" name="entrada">
              <mat-option *ngFor="let horario of horarios" [value]="horario.value">
                {{horario.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Horario de salida</mat-label>
            <mat-select [(ngModel)]="salidaValue" formControlName="salida" name="salida">
              <mat-option *ngFor="let horario of horarios" [value]="horario.value">
                {{horario.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field></td>
        </tr>
        <tr>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Dias</mat-label>
            <mat-select [(ngModel)]="diaLaboralValue" name="dias" formControlName="dias" name="dias">
              <mat-option *ngFor="let dia of dias" [value]="dia.value">
                {{dia.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Supervisor</mat-label>
            <input matInput value="{{supervisor}}" formControlName="supervisor" name="supervisor" (keyup)="setSupervisor($event)">
          </mat-form-field></td>
        </tr>
        <tr>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Instructor</mat-label>
            <input matInput value="{{instructor}}" formControlName="instructor" name="instructor" (keyup)="setInstructor($event)">
          </mat-form-field></td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Fono</mat-label>
              <input matInput value="{{phoneNumber}}" formControlName="fono" name="fono" (keyup)="setPhone($event)">
            </mat-form-field>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <br/>
        <button mat-raised-button color="primary" (click)="addPractice()" *ngIf="hideAdd" [disabled]="!registerForm.valid || studentRut===undefined">Crear práctica</button>
        <button mat-raised-button color="primary" (click)="updatePractice()" *ngIf="hideUpdate" >Actualizar práctica</button>
        <span>&nbsp;</span>
        <button mat-raised-button color="warn" (click)="cancelUpdatePractice()" *ngIf="hideUpdate">Cancelar</button>
        <span>&nbsp;</span>
        <button mat-raised-button color="basic" (click)="back()">Volver</button>

    </form>
  </mat-card-content>

</mat-card>
<mat-card>
  <mat-card-content>
    <div class="mat-elevation-z8">
      <br/>
      <h4>{{total}} Alumnos en Práctica empresa {{company.empresa}} en el año {{yearDb}}</h4>
      <br/>
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 ">

        <ng-container matColumnDef="Id">
          <th mat-header-cell *matHeaderCellDef>
              Id
          </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="Alumno">
          <th mat-header-cell *matHeaderCellDef>
            Alumno - Rut
          </th>
          <td mat-cell *matCellDef="let element"> {{getStudentName(element.run_alum)}} - {{element.run_alum}}</td>
        </ng-container>

        <ng-container matColumnDef="Instructor">
          <th mat-header-cell *matHeaderCellDef>
            Instructor
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.instructor}}<br />
            Fono : {{element.fono}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Supervisor">
          <th mat-header-cell *matHeaderCellDef>
            Supervisor
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.supervisor}}</td>
        </ng-container>

        <ng-container matColumnDef="Curso">
          <th mat-header-cell *matHeaderCellDef>
            Curso
          </th>
          <td mat-cell *matCellDef="let element"> {{getStudentCourse(element.run_alum)}}</td>
        </ng-container>

        <ng-container matColumnDef="Dias">
          <th mat-header-cell *matHeaderCellDef>
            Días y horarios
          </th>
          <td mat-cell *matCellDef="let element">
            {{element.dias}}<br />
            Desde las {{element.ini}} hasta las {{element.sal}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Inicio">
          <th mat-header-cell *matHeaderCellDef>
            Fecha práctica
          </th>
          <td mat-cell *matCellDef="let element">
                  Inicio : {{formatDate(element.inicio)}}<br/>
                  Termino : {{formatDate(element.termino)}}
          </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>
            Acción
          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button (click)="practicesEditOnClick(element)" color="primary" matTooltipShowDelay="250"
            matTooltipHideDelay="100" matTooltip="Editar">
            <mat-icon>edit</mat-icon>&nbsp;
          </button>
          <span>&nbsp;</span>
          <button mat-raised-button (click)="openDialog(element)" color="primary" matTooltipShowDelay="250"
          matTooltipHideDelay="100" matTooltip="Eliminar">
          <mat-icon>delete</mat-icon>&nbsp;
        </button>
        <span>&nbsp;</span>
          <button mat-raised-button (click)="contractOnClick(element)" color="primary" matTooltipShowDelay="250"
          matTooltipHideDelay="100" matTooltip="Imprimir set de contratos completo">
          <mat-icon>print</mat-icon>&nbsp;
        </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator class="mat-elevation-z1" [pageSizeOptions]="[10,25, 30, 40, 50]" [pageSize]="10" showFirstLastButtons>
      </mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
