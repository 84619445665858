import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { Alumno } from 'src/app/model/alumno';

@Component({
  selector: 'app-accident-form',
  templateUrl: './accident-form-dialog.component.html',
  styleUrls: ['./accident-form-dialog.component.css']
})
export class AccidentFormDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AccidentFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AccidentFormDialogDataParam) { }

  ngOnInit(): void {
  }

}

export class AccidentFormDialogDataParam {
  student: Alumno;
  accidentDate: Moment;
  description: string;
  healthPlace: string;
  escortsName: string;
  escortsRelationship: string;
}

