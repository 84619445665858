<h1 mat-dialog-title>{{dialogData.title}}</h1>

<div mat-dialog-content fxLayout="column">
    <mat-form-field appearance="fill">
        <mat-label>Profesor:</mat-label>
        <mat-select [(ngModel)]="dialogData.teacherId">
            <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                {{teacher.fatherSurname + ' ' + teacher.motherSurname + ' ' + teacher.names}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Asignatura:</mat-label>
        <mat-select [(ngModel)]="dialogData.subjectId">
            <mat-option *ngFor="let subject of subjects" [value]="subject.id">
                {{subject.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Prioridad:</mat-label>
        <input matInput [(ngModel)]="dialogData.priority"
        placeholder="Orden de aparicion" 
        type="number" min="1" max="99">
    </mat-form-field>

</div>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close]="dialogData">
        <mat-icon>done</mat-icon>Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>close</mat-icon>Cancelar
    </button>
</mat-dialog-actions>