<div fxLayoutAlign="space-evenly center" style="text-align: left;">
    <table [border]="this.tableBorder" width="90%">
        <tr>
            <td *ngIf="this.course" colspan="3">
                <h1 fxFlexFill>Leccionario: {{this.courseService.formatLetra(this.course.letra)}}, {{this.course.agno}},
                    {{this.course.especialidadNombre}} </h1>
            </td>
        </tr>
        <tr>
            <td width="20%">
                <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>Bloque horario</mat-label>
                    <mat-select (selectionChange)="timeBlockOnChange()" [(ngModel)]="this.timeBlockId">
                        <mat-option *ngFor="let timeBlock of timeBlocks" [value]="timeBlock.id">
                            {{timeBlock.desc}} ({{timeBlock.start.substr(0,5)}} - {{timeBlock.finish.substr(0,5)}})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>

            <td with="60%" colspan="2" *ngIf="this.loaded && this.lectionary">
                <com-chips-selector [elements]="this.getGenericObjectives()" label="Objetivos Genericos"
                    placeholder="Indique los objetivos genericos" (elementsUpdated)="this.ooggUpdated($event)"
                    [selectedElements]="this.ooggSelected" [readonly]="this.signed()">
                </com-chips-selector>
            </td>

            <td rowspan="2" width="10%" *ngIf="!this.loaded" align="center">
                <button mat-fab matTooltipShowDelay="250" matTooltipHideDelay="100" color="primary"
                    (click)="this.newLectionary($event)" [disabled]="this.timeBlockId==''"
                    matTooltip="Nuevo leccionario...">
                    <mat-icon>add</mat-icon>
                </button>
            </td>
            <td rowspan="2" *ngIf="!this.loaded" valign="top">
                <ul>

                    <li *ngFor="let summary of this.lectionarySummaries">Clase {{summary.lessonNumber}} -
                        {{summary.lessonDate}} - {{summary.module.name}}
                        <button mat-icon-button style="cursor:default">
                            <mat-icon *ngIf="summary.signed" color="primary" matTooltip="Firmado"
                                class="cursor">history_edu</mat-icon>
                            <mat-icon *ngIf="!summary.signed" matTooltip="Sin firmar..." class="cursor">
                                history_edu</mat-icon>
                        </button>
                        <button mat-icon-button (click)="loadLectionary(summary.id)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </li>

                </ul>
            </td>
        </tr>
        <tr>
            <td>
                <mat-calendar [(selected)]="currentDate" (selectedChange)="onChangeCalendar($event)" disabled="false"
                    [maxDate]="maxDate" [minDate]="minDate"></mat-calendar>
            </td>
            <td colspan="2" *ngIf="this.loaded" vAlign="top">

                <table [border]="this.tableBorder" fxFlexFill>
                    <tr>
                        <td>Asignatura:
                            {{this.lectionary.module.subject.name}}</td>
                        <td>Fecha de la clase: {{this.dateTimeUtil.momentToHumanString(this.currentDate)}}</td>
                    </tr>
                    <tr>
                        <td>Modulo/Unidad:
                            {{this.lectionary.module.name}}</td>
                        <td>Fecha de hoy: {{this.dateTimeUtil.momentToHumanString(this.today)}}</td>
                    </tr>

                    <tr>
                        <td colspan="2">
                            <h3>Objetivos de aprendizaje</h3>
                            <ul>
                                <li *ngFor="let ooee of this.lectionary.specificObjectives">
                                    <mat-checkbox color="primary" [disabled]="this.signed()"
                                        [(ngModel)]="ooee.selected">
                                        <p [matTooltip]="ooee.detail">{{this.cutText(ooee.detail, 150)}}</p>
                                    </mat-checkbox>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <mat-form-field appearance="fill" fxFlexFill>
                                <mat-label>Informe del leccionario</mat-label>
                                <textarea matInput placeholder="Ej.: Se entregaron los conenidos de..."
                                    [(ngModel)]="this.lectionary.detail" [readonly]="this.signed()"></textarea>
                            </mat-form-field>
                            <!--                            <textarea fxFlexFill rows="10"></textarea>-->
                        </td>
                    </tr>
                    <tr *ngIf="!this.signed()">
                        <td>&nbsp;</td>
                        <td width="50%">
                            <button mat-raised-button fxFlexFill (click)="this.findLastComment($event)">Buscar último
                                informe</button>
                        </td>
                    </tr>
                    <tr *ngIf="this.signed()">
                        <td>Firma: {{this.lectionary.signer.names + ' '+ this.lectionary.signer.fatherSurname}} </td>
                        <td>Fecha: {{this.lectionary.signDate}}</td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3">
                <table [border]="this.tableBorder" width="100%">
                    <tr>
                        <td align="center" width="40%">
                            <button mat-raised-button fxFlexFill (click)="this.doSign($event)" color="primary"
                                [disabled]="this.readonly ||  !this.loaded || this.signed()">Firmar y
                                cerrar</button>
                        </td>
                        <td align="center" width="30%">
                            <button mat-raised-button fxFlexFill (click)="this.doSaveDraft($event)"
                                [disabled]="this.readonly || !this.loaded || this.signed()">Grabar borrador</button>
                        </td>
                        <td align="center" width="30%">
                            <button mat-raised-button fxFlexFill (click)="this.doCancel($event)">Volver</button>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
</div>