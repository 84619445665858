import {LiveAnnouncer} from '@angular/cdk/a11y';
import { AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { CompanyService } from 'src/app/api/company.service';
import { Company } from 'src/app/model/company';
import {MatIconModule} from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';


@Component({
  selector: 'app-list-all-companies',
  templateUrl: './list-all-companies.component.html',
  styleUrls: ['./list-all-companies.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule,MatPaginatorModule,MatIconModule,CommonModule, MatTooltipModule,MatButtonModule]
})
export class ListAllCompaniesComponent implements AfterViewInit {


  displayedColumns: string[] = ['Empresa', 'Rut', 'N° Alumnos', 'Pago','Sueldo','Acción'];
  dataSource: MatTableDataSource<Company>;
  companiesData: Company[]  = [];
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  year: number = new Date().getFullYear();
  yearDb: string;

  constructor(private companyService: CompanyService,private _liveAnnouncer: LiveAnnouncer, private router: Router, private location: Location) {

  }

  async ngOnInit() {
    this.yearDb = localStorage.getItem('practica_actual');
    await this.companyService
    .getCompanies(this.yearDb)
    .subscribe(
      response => {
        this.companiesData = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
      },
      problems => {
        console.log(problems);

      }
    );
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addPracticeOnClick(company: any){
    sessionStorage.removeItem('action');
    sessionStorage.setItem('companySelected',JSON.stringify(company));
    this.router.navigate(['add-practice']);
  }

  editCompanyOnClick(company: any) {
    sessionStorage.removeItem('action');
    sessionStorage.setItem('companySelected',JSON.stringify(company));
    this.router.navigate(['edit-company']);
    }

  back(): void {
    this.location.back();
  }
}
