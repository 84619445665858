import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { v4 as uuidv4 } from 'uuid';
import { AuthorService } from "../../../../api/author.service";
import { Agreement } from "../model/agreement";
import { Meeting } from "../model/meeting";
import { Participant } from "../model/participant.model";

@Injectable({
    providedIn: 'root'
})

export class MeetingService {
    private baseUrl: string = environment.apiAttorneyUrl;
    private serviceUrl: string = '/api/ges2/attorney/meeting';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listByTeacherIdAndYear(teacherId: string, year: number): Observable<Meeting[]> {
        const url = this.baseUrl + this.serviceUrl + `/v1?teacherId=${teacherId}&year=${year}`;
        console.log(url);
        return this.http.get<Meeting[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByCourseId(courseId: string): Observable<Meeting[]> {
        const url = this.baseUrl + this.serviceUrl + `/v1/list-by-course?courseId=${courseId}`;
        console.log(url);
        return this.http.get<Meeting[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public create(meeting: Meeting): Observable<string> {
        const url = this.baseUrl + this.serviceUrl + `/v1`;
        console.log(url);

        meeting.id = uuidv4();

        const headers = this.authorService.headerWithAuthorization(null);
        return this.http.post<string>(url, meeting, { headers, responseType: 'text' as 'json' });
    }

    public async listParticipantsAsync(meetingId: string): Promise<Participant[]> {
        const url = this.baseUrl + this.serviceUrl + `/v1/${meetingId}/participants`;
        console.log(url);
        return this.http.get<Participant[]>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public updateAttendance(participantId: string, assist: boolean): Observable<string> {
        const url = this.baseUrl + this.serviceUrl + `/v1/participants/${participantId}`;
        const params = new HttpParams().set('assist', assist.toString());

        return this.http.put(url, {}, { headers: this.authorService.headerWithAuthorization(null), params, responseType: 'text' });
    }

    public async listAgreementsAsync(meetingId: string): Promise<Agreement[]> {
        const url = this.baseUrl + this.serviceUrl + `/v1/${meetingId}/agreements`;
        console.log(url);
        return this.http.get<Agreement[]>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public saveAgreements(meetingId: string, agreements: Agreement[]): Observable<string> {
        const url = this.baseUrl + this.serviceUrl + `/v1/${meetingId}/agreements`;
        // const params = new HttpParams().set('assist', assist.toString());

        return this.http.post<string>(url, agreements, { headers: this.authorService.headerWithAuthorization(null) });
    }

}
