<h1 mat-dialog-title>{{this.dialogData.title}}</h1>

<div mat-dialog-content>

  <p *ngIf="this.dialogData.message">
    {{this.dialogData.message}}
  </p>
  <p>
    <mat-form-field appearance="fill" *ngIf="this.dialogData.type == INPUT" fxFlexFill>
      <mat-label>{{this.dialogData.placeholder}}</mat-label>
      <input matInput [(ngModel)]="this.dialogData.text" >
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.type == TEXT_AREA" fxFlexFill>
      <mat-label>{{this.dialogData.placeholder}}</mat-label>
      <textarea matInput [(ngModel)]="this.dialogData.text" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
    </mat-form-field>

  </p>

</div>
<div mat-dialog-actions>
  <button mat-raised-button cdkFocusInitial (click)="doClickButton()" [mat-dialog-close]="dialogData"
    color="primary"><mat-icon>check_circle</mat-icon>&nbsp;Aceptar</button>
  <button mat-raised-button (click)="doClickButton()" [mat-dialog-close]="null">
    <mat-icon>cancel</mat-icon>&nbsp;Cancelar</button>

</div>