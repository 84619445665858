import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class RutUtility {
    constructor() {
    }

    rutValido(rutFull: string): boolean {
        let rayaPos: number = 0;
        let rut: number = 0;
        let dv: string = '';
        let dvV: string = '';

        rutFull = this.clearRut(rutFull);

        rayaPos = rutFull.indexOf('-');
        if (rayaPos >= 0) {
            rut = Number(rutFull.substring(0, rayaPos));
            dv = rutFull.substring(rayaPos + 1);
        } else {
            rayaPos = rutFull.length - 1;
            rut = Number(rutFull.substring(0, rayaPos));
            dv = rutFull.substring(rayaPos)
        }
        dv = dv.toLowerCase();

        dvV = this.dv(rut).toLowerCase();
        return dv == dvV;
    }

    dv(T: number): string {
        var M = 0, S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return "" + (S ? S - 1 : 'k');
    }

    clearRut(dirtyRut: string): string {
        const permitChars: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', 'k', 'K'];
        let out: string = '';
        let s: string;
        let lCh: string;

        for (s of dirtyRut) {
            for (lCh of permitChars) {
                if (s == lCh) {
                    out += s;
                }
            }
        }

        if (out.indexOf('-') == -1 && out.length >= 2) {
            out = out.substring(0, out.length - 1) + '-' + out.substring(out.length - 1);
        }

        return out;
    }

    extractNumber(rutFull: string): number {
        rutFull = this.clearRut(rutFull);
        let rut: number = 0;
        let arrowPos: number = rutFull.indexOf('-');
        if (arrowPos >= 0) {
            rut = +rutFull.substring(0, arrowPos);
        } else {
            arrowPos = rutFull.length - 1;
            rut = +rutFull.substring(0, arrowPos);
        }

        return rut;
    }
}