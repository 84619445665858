export class Practice {
  id: number
  ini: string
  sal: string
  run_alum: number
  cod_run: string
  cod_empre: string
  fono: string
  instructor: string
  supervisor: string
  agno: string
  dias: string
  inicio: string
  termino: string
  estado: string
}
