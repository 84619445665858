import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/api/permission.service';
import { PersonalityReportService } from 'src/app/api/personality-report.service';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { DownloadUtility } from 'src/app/utility/download.utility';
import { ConfigService } from '../../api/config.service';
import { CourseService } from '../../api/course.service';
import { Course } from '../../model/course';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css', '../../style/table.css']
})
export class CoursesComponent implements OnInit {
  public year: number = 0;
  public canShowAllCourses: boolean = false;

  constructor(private configService: ConfigService,
    private permissionService: PermissionService,
  ) { }

  ngOnInit(): void {
    this.configService.getCurrentYear()
      .subscribe(
        response => this.year = response,
        problems => console.error(problems)
      );

    this.permissionService.hasPermissions(['COURSES.SHOW_ALL'])
      .subscribe(
        response => this.canShowAllCourses = response,
        problems => console.error(problems)
      );



  }


}
