<h1>Formulario de matricula {{isStudent()?'Alumnos':'Postulante'}}</h1>
{{nextYear}} -
<div *ngIf="enrollForm" style="display: inline;">

    Estado: {{enrollForm.enrollNumber > 0 ? 'Matriculado (número: '+enrollForm.enrollNumber+')': haveCourse()? 'Aprobado
    para matricular en '+ this.getCourse() :'Habilitado para matricula' }}
</div>
<div fxLayout="column" fxLayoutAlign="center center" *ngIf="enrollForm" fxLayoutGap="20px">
    <mat-card class="mat-elevation-z0 center" style="width: 90%;">
        <mat-card-subtitle>Datos alumno</mat-card-subtitle>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlex.xs="90" fxFlexOffset="5">
                <mat-label>Nombres postulante</mat-label>
                <input matInput placeholder="Ingrese los Nombres" [(ngModel)]="enrollForm.nombres">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Apellido paterno</mat-label>
                <input matInput placeholder="Ingrese el apellido paterno" [(ngModel)]="enrollForm.paterno">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Apellido materno</mat-label>
                <input matInput placeholder="Ingrese el apellido materno" [(ngModel)]="enrollForm.materno">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="35" fxFlexOffset="5">
                <!--fxFlexOffset="10"-->
                <mat-label>Rut</mat-label>
                <input matInput placeholder="Ingrese Rut válido" [(ngModel)]="enrollForm.rut" readonly="true">
            </mat-form-field>
            <div fxFlexOffset="10" fxFlexOffset.xs="0">&nbsp;</div>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput placeholder="Fecha de nacimiento" [(ngModel)]="enrollForm.nacimiento"
                    [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <!--fxFlexOffset="10"-->
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="Ingrese dirección" [(ngModel)]="enrollForm.direccion">
            </mat-form-field>
            <div fxFlexOffset="0" fxFlexOffset.xs="0">&nbsp;</div>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Comuna</mat-label>
                <mat-select [(ngModel)]="enrollForm.comuna">
                    <mat-option *ngFor="let comuna of comunas" [value]="comuna.id">
                        {{comuna.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <!--fxFlexOffset="10"-->
                <mat-label>Especialidad</mat-label>
                <input type="hidden" [(ngModel)]="enrollForm.especialidad">
                <input matInput value="{{enrollForm.especialidadNombre}}" readonly="true">
                <!--
                <mat-select [(ngModel)]="enrollForm.especialidad" readonly="true">
                    <mat-option *ngFor="let especialidad of especialidades" [value]="especialidad.id">
                        {{especialidad.nombre}}
                    </mat-option>
                </mat-select>
-->
            </mat-form-field>
            <div fxFlexOffset="0" fxFlexOffset.xs="0">&nbsp;</div>
        </div>


        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <!--fxFlexOffset="10"-->
                <mat-label>Nacionalidad</mat-label>
                <mat-select [(ngModel)]="enrollForm.nationalityId">
                    <mat-option *ngFor="let country of countries" [value]="country.id">
                        {{country.demonym}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Genero</mat-label>
                <mat-select [(ngModel)]="enrollForm.gender">
                    <mat-option value="M">Masculino</mat-option>
                    <mat-option value="F">Femenino</mat-option>
                </mat-select>
            </mat-form-field>

            <div fxFlexOffset="0" fxFlexOffset.xs="0">&nbsp;</div>
        </div>
    </mat-card>

    <mat-card class="mat-elevation-z0 center" style="width: 90%;">
        <mat-card-subtitle>Datos Apoderado</mat-card-subtitle>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Nombres y apellidos</mat-label>
                <input matInput placeholder="Ingrese Nombres y apellidos" [(ngModel)]="enrollForm.nombreApoderado">
                <mat-hint>Nombres y apellidos del apoderado</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Rut apoderado</mat-label>
                <input matInput placeholder="Ingrese el rut del paterno" [(ngModel)]="enrollForm.rutApoderado"
                    (blur)="validaRut()">
                <mat-hint>Formato: 12345678-5</mat-hint>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="Ingrese telefono de apoderado" [(ngModel)]="enrollForm.fonoApoderado">
                <mat-hint>Teléfono de contacto, puede ser movil o fijo.</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Correo electrónico</mat-label>
                <input matInput placeholder="Ingrese correo de contacto" [(ngModel)]="enrollForm.mailApoderado">
            </mat-form-field>
        </div>

    </mat-card>

    <mat-card class="mat-elevation-z0 center" style="width: 90%;">
        <mat-card-subtitle>Datos Academicos</mat-card-subtitle>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start" *ngIf="!isStudent()">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Colegio de procedencia</mat-label>
                <input matInput placeholder="Indique colegio de procedencia"
                    [(ngModel)]="enrollForm.nombreColegioProcedencia">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Comuna Colegio</mat-label>
                <mat-select [(ngModel)]="enrollForm.comunaColegioProcedencia">
                    <mat-option *ngFor="let comuna of comunas" [value]="comuna.id">
                        {{comuna.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start">
            <div fxFlex="45" fxFlexOffset="5">
                <mat-slide-toggle [(ngModel)]="enrollForm.takeReligion">Estudiará Religión</mat-slide-toggle>
            </div>
            <div fxFlex="45" fxFlexOffset="5">
                <mat-slide-toggle [(ngModel)]="enrollForm.requiresPsychologist">¿Necesita atención de un
                    psicopedagogo?</mat-slide-toggle>
            </div>
        </div>
    </mat-card>

    <mat-card class="mat-elevation-z0 center" style="width: 90%;">
        <mat-card-subtitle>Datos estadísticos</mat-card-subtitle>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Grupo Familiar</mat-label>
                <input matInput type="number" placeholder="Indique cuantas personas componen su familia"
                    [(ngModel)]="enrollForm.familia">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Previsión</mat-label>
                <mat-select [(ngModel)]="enrollForm.prevision">
                    <mat-option value="FONASA">Fonasa</mat-option>
                    <mat-option value="ISAPRE">Isapre</mat-option>
                    <mat-option value="CAPRED">Capredena</mat-option>
                    <mat-option value="DIPREC">Dipreca</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Tiene Internet</mat-label>
                <mat-select [(ngModel)]="enrollForm.tieneInternet">
                    <mat-option value="true" selected>Si</mat-option>
                    <mat-option value="false">No</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Cuantos computadores</mat-label>
                <input matInput type="number" placeholder="Indique cuantos computadores hay en la casa"
                    [(ngModel)]="enrollForm.cuantosPc">
            </mat-form-field>
        </div>
    </mat-card>

    <mat-card class="mat-elevation-z0 center" style="width: 90%;">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <button mat-raised-button color="primary" (click)="onMatricularClick()"
                *ngIf="enrollForm.enrollNumber==0 || enrollForm.enrollNumber==null">Matricular</button>
            <button mat-raised-button color="primary" (click)="onEnrollDocumentClick()"
                *ngIf="enrollForm.enrollNumber > 0">Documento Matricula</button>
            <button mat-raised-button (click)="onCancelClick()">Cancelar</button>
        </div>
    </mat-card>


</div>