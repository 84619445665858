<h1 mat-dialog-title>{{this.dialogData.title}}</h1>

<div mat-dialog-content>

    <p>{{this.dialogData.content}}</p>

    <input type="file" class="input-file" onchange="console.log(event.target.files)" (change)="onFileSelected($event)"
        #fileUpload>
    <div class="file-upload">
        <button mat-raised-button color="primary" (click)="fileUpload.click()">
            <mat-icon *ngIf="this.dialogData.fileName">attach_file</mat-icon>
            <mat-icon *ngIf="!this.dialogData.fileName">upload_file</mat-icon>
            {{this.dialogData.fileName || "Presione aquí para seleccionar archivo."}}
        </button>
    </div>
<br>

</div>

<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary">
        <mat-icon>upload</mat-icon>
        Cargar
    </button>
    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Volver
    </button>
</div>