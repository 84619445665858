import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.css']
})
export class PromptDialogComponent implements OnInit {
  public INPUT: PromptDialogType = PromptDialogType.INPUT;
  public TEXT_AREA: PromptDialogType = PromptDialogType.TEXT_AREA;

  constructor(public dialogRef: MatDialogRef<PromptDialogParameters>,
    @Inject(MAT_DIALOG_DATA) public dialogData: PromptDialogParameters) { }

  ngOnInit(): void {
  }

  doClickButton() {

  }

}

export class PromptDialogParameters {
  title: string;
  message: string;
  text: string;
  type: PromptDialogType;
  placeholder: string;
}

export enum PromptDialogType {
  TEXT_AREA, INPUT
}
