import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { AuthorService } from 'src/app/api/author.service';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { LectionaryService } from 'src/app/api/lectionary.service';
import { PlanService } from 'src/app/api/plan.service';
import { Course } from 'src/app/model/course';
import { GenericObjective } from 'src/app/model/generic-objetive';
import { Hour } from 'src/app/model/hour';
import { LectionaryBody } from 'src/app/model/lectionary-body';
import { LectionaryRequest } from 'src/app/model/lectionary-request';
import { LectionarySummary } from 'src/app/model/lectionary-summary';
import { AlertUtility, DialogMessageType, VerifyUserAndPasswordResponse } from 'src/app/utility/alert.utility';
import { VerifyPasswordDialogComponent, VerifyPasswordParamDialog } from '../../calificaciones/verify-password-dialog/verify-password-dialog.component';

@Component({
  selector: 'app-lectionary',
  templateUrl: './lectionary.component.html',
  styleUrls: ['./lectionary.component.css']
})
export class LectionaryComponent implements OnInit {
  public course: Course;
  public loaded: boolean = false;
  public tableBorder: number = 0;
  public readonly: boolean = true;

  timeBlocks: Hour[];
  timeBlockId: string = '';

  today: Moment = moment();
  currentDate: Moment;
  minDate: Date;
  maxDate: Date;

  lectionary: LectionaryBody;
  lectionarySummaries: LectionarySummary[];
  genericObjectives: GenericObjective[];
  ooggSelected: string[] = [];

  lectionaryId: string;

  constructor(
    private lectionaryService: LectionaryService,
    private planService: PlanService,
    public courseService: CourseService,
    public dateTimeUtil: DateTimeUtility,
    private router: Router,
    private configService: ConfigService,
    private dialog: MatDialog,
    private authorService: AuthorService,
    private snackBar: MatSnackBar,
    private alertUtility: AlertUtility) { }

  ngOnInit(): void {
    const courseId = localStorage.getItem('courseId');
    this.readonly = localStorage.getItem('readonly') == 'true';

    this
      .courseService
      .get(courseId)
      .subscribe(
        response => this.course = response,
        problems => console.error(problems)
      );

    this
      .planService
      .listGenericObjectives()
      .subscribe(
        response => this.genericObjectives = response,
        problems => console.error(problems)
      );

  }

  public timeBlockOnChange(): void {
    if (this.timeBlockId != '') {
      this
        .lectionaryService
        .list(this.course.id, this.timeBlockId, this.dateTimeUtil.momentToString(this.currentDate))
        .subscribe(
          response => {
            console.log(response);
            this.lectionarySummaries = response;
          },
          problems => console.error(problems)
        );
    } else {
      this.resetScreen();
    }
  }

  private resetScreen() {
    this.lectionarySummaries = [];
    this.loaded = false;
    this.timeBlockId = '';
  }

  public onChangeCalendar(e: Moment) {
    this.loadTimeBlocks();

  }

  private loadTimeBlocks(): void {
    const currentDate = this.dateTimeUtil.momentToString(this.currentDate);
    this.resetScreen();

    this.configService.getTimeBlocks(this.course.id, currentDate)
      .subscribe(
        response => {
          this.timeBlocks = response;
          try {
            this.timeBlockId = this.timeBlocks.filter(tb => tb.selected)[0].id;
          } catch (e) {
            console.warn('Ahora no es un bloque horario definido.');
          }
          this.timeBlockOnChange();
        },
        problems => console.log(problems)
      );
  }

  public getGenericObjectives(): string[] {
    const letters: string[] = this.genericObjectives
      .filter(
        ggoo => this.lectionary.genericObjectives
          .map(gObj => gObj.id)
          .includes(ggoo.id)
      )
      .map(ggoo => 'OA-' + ggoo.letter)
      .filter(letter => !this.ooggSelected.includes(letter));

    return letters;
  }

  public newLectionary(e: MouseEvent) {
    console.log(this.lectionarySummaries.length);
    const currentDateString = this.dateTimeUtil.momentToString(this.currentDate);
    const timeBlockId = this.timeBlockId.split('.')[1];

    // console.log(currentDateString);
    // console.log(timeBlockId);

    for (let i = 0; i < this.lectionarySummaries.length; i++) {
      const lectionarySummary: LectionarySummary = this.lectionarySummaries[i];
      if (lectionarySummary.lessonDate == currentDateString && timeBlockId == lectionarySummary.hourId) {
        this.snackBar.open('Ya existe un leccionario para esta fecha y bloque horario.', 'OK', { duration: 5000 });
        return;
      }

    }

    // this.lectionarySummaries.forEach(lecc => console.log(lecc));

    this
      .lectionaryService
      .find(this.getLectionaryRequest(null))
      // .find(this.course.id, this.timeBlockId, this.dateTimeUtil.momentToString(this.currentDate))
      .subscribe(
        (response: LectionaryBody) => {
          if (response.module == null) {
            this.snackBar.open('No hay planificación para esta fecha.', 'OK', { duration: 5000 });
            this.loaded = false;
            return;
          }

          this.loaded = true;
          this.lectionary = response;
          console.log('NEW LECTIONARY: ', this.lectionary);

          this.ooggSelected = this.lectionary.genericObjectives
            .filter(ggoo => ggoo.selected)
            .map(ggoo => 'OA-' + ggoo.letter);
        },
        problems => console.error(problems)
      );

  }

  public ooggUpdated(elements: string[]): void {
    console.log(elements);
  }

  public doCancel(event: MouseEvent) {
    this.doInteligentCancel();
  }

  private doInteligentCancel() {
    if (this.loaded) {
      this.timeBlockOnChange();
      this.loaded = false;
    } else {
      this.router.navigate(['courses']);
    }
  }



  public doSaveDraft(event: MouseEvent) {
    this.doSave(ActionSaveLetionary.SaveDraft, null);
  }

  public doSave(action: ActionSaveLetionary, signComment: string) {
    const lectionary: LectionaryBody = new LectionaryBody();

    lectionary.id = this.lectionary.id;
    lectionary.courseId = this.course.id;
    lectionary.genericObjectives = this.ooggSelected.map(ggoo => {
      let genericObjective = this.genericObjectives.filter(go => 'OA-' + go.letter == ggoo)[0];
      genericObjective.selected = true;
      return genericObjective;
    });
    lectionary.hourId = this.timeBlockId;
    const lessonDate = this.dateTimeUtil.momentToString(this.currentDate);
    lectionary.lessonDate = lessonDate;
    lectionary.specificObjectives = this.lectionary.specificObjectives.filter(ssoo => ssoo.selected);
    lectionary.detail = this.lectionary.detail;
    lectionary.signComment = signComment;

    console.log('doSaveDraft', lectionary);

    if (action == ActionSaveLetionary.SaveDraft) {
      if (!this.lectionary.previousSigned) {
        this.snackBar.open('Aún no se han firmado todos los leccionarios anteriores...', 'OK', { duration: 5000 });
        return;
      }

      this.lectionaryService.save(lectionary)
        .subscribe(
          (response: LectionaryBody) => {
            console.log(response);
            this.snackBar.open('Leccionario grabado con éxito.', 'OK', { duration: 2000 });
          },
          problems => console.error(problems)
        );
    } else {
      this.lectionaryService.sign(lectionary)
        .subscribe(
          (response: LectionaryBody) => {
            this.snackBar.open('Leccionario grabado y firmado con éxito', 'OK', { duration: 2000 });
            this.doInteligentCancel();
          },
          problems => {
            console.error(problems);
            this.alertUtility.fastMessage(problems.error.message);
          }
        );

    }

  }

  private getLectionaryRequest(lectionaryId: string): LectionaryRequest {
    const lectionaryRequest: LectionaryRequest = new LectionaryRequest();
    lectionaryRequest.id = lectionaryId;
    lectionaryRequest.hourId = this.timeBlockId;
    lectionaryRequest.courseId = this.course.id;
    lectionaryRequest.lessonDate = this.dateTimeUtil.momentToString(this.currentDate);
    return lectionaryRequest;
  }

  public loadLectionary(lectionaryId: string) {
    this
      .lectionaryService.find(this.getLectionaryRequest(lectionaryId))
      .subscribe(
        (response: LectionaryBody) => {
          this.lectionary = response;
          this.loaded = true;

          this.ooggSelected = this.lectionary.genericObjectives
            .filter(ggoo => ggoo.selected)
            .map(ggoo => 'OA-' + ggoo.letter);

          console.log('LECTIONARY', response);
        },
        problems => console.error(problems)
      );
  }

  public async doSign(event: MouseEvent) {
    console.log(event);

    const response: VerifyUserAndPasswordResponse = await this.alertUtility.verifyUserAndPassword('Firme el respectivo leccionario.', true, true);


    if (response.isValid) {
      this.doSave(ActionSaveLetionary.SaveAndSign, response.comment);
    } else {
      this.alertUtility.dialogMessage('Clave no es valida', 'Atención', DialogMessageType.WARNING);
    }


    /**
    
        const verifyPasswordParamDialog: VerifyPasswordParamDialog = new VerifyPasswordParamDialog();
        verifyPasswordParamDialog.username = this.authorService.user.username;
        verifyPasswordParamDialog.password = '';
        const dialogRef = this.dialog.open(VerifyPasswordDialogComponent, { data: verifyPasswordParamDialog });
    
        dialogRef.afterClosed().subscribe((result: VerifyPasswordParamDialog) => {
          console.log(result);
    
          this.authorService.verifyPassword(result.password)
            .subscribe(
              response => {
                if (!response.isValid) {
                  this.snackBar.open(`Clave no es valida`, 'OK', { duration: 5000 });
                  return;
                }
    
                this.doSave(ActionSaveLetionary.SaveAndSign);
    
              },
              problems => console.log(problems)
            );
        });
    
     */

  }

  public signed(): boolean {
    return this.lectionary.signer != null;
  }

  public findLastComment(event: MouseEvent): void {
    this.lectionary.detail = this.lectionary.lastComment;
  }

  public cutText(text: string, len: number) {
    return text.length >= len ? `${text.substring(0, len)}...` : text;
  }

}

enum ActionSaveLetionary {
  SaveDraft, SaveAndSign
}