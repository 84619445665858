<h1 mat-dialog-title>Cambio de estado/curso de alumno</h1>

<p>
Rut: <strong>{{dialogData.studentRut}}</strong> <br>
Nombre: <strong>{{dialogData.studentName}}</strong>
</p>

<div mat-dialog-content fxLayout="column">
    <mat-form-field appearance="fill" *ngIf="this.options">
        <mat-label>Accion</mat-label>
        <mat-select [(ngModel)]="this.dialogData.action" (selectionChange)="changeAction($event)">
            <mat-option *ngFor="let option of this.options" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.action == 'NOC'">
        <mat-label>Confirme con el Rut del alumno ({{this.student.rut}})</mat-label>
        <input matInput placeholder="Ingrese sin guión ni dígito verificador" [(ngModel)]="this.changeStatusDialogForm.rut"
            (keyup)="this.validateForm();" >
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.action == 'CHC' || this.dialogData.action == 'REP'">
        <mat-label>Nuevo curso</mat-label>
        <mat-select [(ngModel)]="this.dialogData.newCourseId" (selectionChange)="this.validateForm()">
            <mat-option *ngFor="let course of this.courses" [value]="course.id">{{this.courseService.formatLetra(course.letra)}} ({{course.studentsCount}}) - {{course.especialidadNombre}} </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.action == 'NMT'">
        <mat-label>Motivo</mat-label>
        <mat-select [(ngModel)]="this.dialogData.reason" (selectionChange)="this.validateForm()">
            <mat-option *ngFor="let reason of this.reasons" [value]="reason.value">{{reason.name}}</mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field appearance="fill" *ngIf="this.dialogData.action == 'NMT'">
        <mat-label>Motivo para cancelar/detener matricula</mat-label>
        <input matInput placeholder="Indique motivo" [(ngModel)]="this.dialogData.comment"
            (keyup)="this.validateForm();" maxlength="180">
    </mat-form-field>

</div>

 
<mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="this.dialogData" [disabled]="!this.changeStatusDialogForm.validForm">
        <mat-icon>save</mat-icon>Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>close</mat-icon>Cancelar
    </button>
</mat-dialog-actions>