<div fxLayout="row" fxLayoutAlign="start start">
  &nbsp;&nbsp;&nbsp;<button mat-icon-button fxFlex="30px" (click)="goBack()">
        <mat-icon>close</mat-icon>
      </button>
      <div fxFlex="*" *ngIf="enrollNumber">
          <h2>Documento de matricula N° {{enrollNumber}}</h2>
      </div>
      <button mat-icon-button (click)="downloadDocument()">
        <mat-icon>file_download</mat-icon>
      </button>      
      <!--
      <button mat-icon-button fxFlex="40px">
        <mat-icon>print</mat-icon>
      </button>
-->
</div>

<!--
      src="https://cdn2.hubspot.net/hubfs/2506394/Mallas-2020/Facultad-Artes/COM_AUDIOVISUAL_TELEVISION.pdf?utm_campaign=Intake%20Marzo%202020&utm_medium=email&_hsmi=70044425&_hsenc=p2ANqtz-9xkbQB96SnpFDpoZ4iLNInBarWWPf9AhsGWW2W8il7jvZS_7uemOGB7_erfdowIYrWio7K50n7z8z_lmRja1NvC4tVVXMPR6IMELpP6gk7X1QaYq0&utm_content=70044425&utm_source=hs_automation"
-->
<div style="display: block; height: 100vh">
    <pdf-viewer *ngIf="pdfUrl"
    [src]="pdfUrl"
    [rotation]="0"
    [original-size]="true"
    [show-all]="true"
    [fit-to-page]="false"
    [zoom]="0.8"
    [zoom-scale]="'page-width'"
    [stick-to-page]="true"
    [render-text]="true"
    [external-link-target]="'blank'"
    [autoresize]="false"
    [show-borders]="true"
    ></pdf-viewer>
</div>
    