import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Alumno } from '../model/alumno';
import { Course } from '../model/course';
import { CreateCoursesFromLevel } from '../model/create-from-level';
import { TeacherAndSubject } from '../model/teacher-and-subject';
import { AuthorService } from './author.service';
import { environment } from 'src/environments/environment';
import { SummaryReport } from '../model/summary-report/course-summary-report';

@Injectable({
    providedIn: 'root'
})

export class CourseService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/curso/1.0';
    private listForPostulantUrl: string = '/listForPostulant';
    private listByYearUrl: string = '/list-for-year';
    private listAllByYearUrl: string = '/list-all-for-year';
    private listAllUrl: string = '/list-all';
    private getOne: string = '/';
    private updateUrl: string = '/update';
    private updateSpecialtyUrl: string = '/update-specialty';
    private studentsListUrl: string = '/students-list';
    private studentsListPdfUrl: string = '/students-list-pdf';
    private listTeachersAndSubjectsUrl: string = '/list-teachers-and-subjects';
    private relateTeacherSubjectUrl: string = '/relate-teacher-subject';
    private removeRelateTeacherSubjectUrl: string = '/remove-relate-teacher-subject';
    private updateTeacherSubjectUrl: string = '/update-teacher-subject';
    private createFromLevelUrl: string = '/create-from-level';
    private listByLevelAndExcludeUrl: string = '/list-by-level-and-exclude';
    private listByTeacherAndSubjectUrl: string = '/list-by-teacher-and-subject';
    private studentsListForAdmissionUrl: string = '/students-list-for-admission';
    private downloadSummaryUrl: string = '/download-summary';
    private getByCurrentUserUrl: string = '/get-by-current-user';
    private summaryReportUrl: string = '/summary-report'
    private downloadSummaryReportUrl: string = '/download-summary-report'
    private calculateOrderStudentsUrl: string = '/calculate-order-students';

    constructor(
        private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listForPostulant(): Observable<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listForPostulantUrl;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listStudents(courseId: string): Observable<Alumno[]> {
        const url = this.baseUrl + this.serviceUrl + this.studentsListUrl + `/${courseId}`;
        return this.http.get<Alumno[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByYear(year: number): Observable<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listByYearUrl + `/${year}`;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAll(): Observable<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listAllUrl;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async listByYearAsync(year: number): Promise<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listByYearUrl + `/${year}`;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public async listAllByYearAsync(year: number): Promise<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listAllByYearUrl + `/${year}`;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public listAllByYear(year: number): Observable<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listAllByYearUrl + `/${year}`;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByYearExcludingUser(year: number): Observable<Course[]> {
        const url = this.baseUrl + this.serviceUrl + `/list-by-year-excluding-user/${year}`;
        console.log(url);        
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public get(id: string): Observable<Course> {
        const url = this.baseUrl + this.serviceUrl + this.getOne + `${id}`;
        return this.http.get<Course>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async getAsync(id: string): Promise<Course> {
        const url = this.baseUrl + this.serviceUrl + this.getOne + `${id}`;
        return this.http.get<Course>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public update(course: Course): Observable<Course> {
        const url = this.baseUrl + this.serviceUrl + this.updateUrl;
        return this.http.post<Course>(url, course, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public updateSpecialty(courseId: string, specialtyId): Observable<Course> {
        const url = this.baseUrl + this.serviceUrl + this.updateSpecialtyUrl;
        const course: Course = new Course();
        course.id = courseId;
        course.especialidadId = specialtyId;
        return this.http.post<Course>(url, course, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public formatLetra(letra: string): string {
        return !letra ? '-' : letra.substring(0, 1) + "-" + letra.substring(1);
    }

    public getStudentsListById(courseId: string): string {
        const url = this.baseUrl + this.serviceUrl + this.studentsListPdfUrl + `/${courseId}`;
        return url;
    }

    public downloadCourseListAsPdf(courseId: string) {
        const url = this.getStudentsListById(courseId);
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }

    public listTeachersAndSubjects(id: string): Observable<TeacherAndSubject[]> {
        const url = this.baseUrl + this.serviceUrl + this.listTeachersAndSubjectsUrl + `/${id}`;
        return this.http.get<TeacherAndSubject[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public relateTeacherSubject(courseId: string, teacherSubject: TeacherAndSubject): Observable<TeacherAndSubject> {
        const url = this.baseUrl + this.serviceUrl + this.relateTeacherSubjectUrl;
        const parameter = {
            courseId,
            teacherId: teacherSubject.teacherId,
            subjectId: teacherSubject.subjectId,
            priority: teacherSubject.priority
        };
        return this.http.post<TeacherAndSubject>(url, parameter, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public removeRelateTeacherSubject(id: string): Observable<boolean> {
        const url = this.baseUrl + this.serviceUrl + this.removeRelateTeacherSubjectUrl + `/${id}`;
        return this.http.get<boolean>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public updateTeacherSubject(courseId: string, teacherSubject: TeacherAndSubject): Observable<TeacherAndSubject> {
        const url = this.baseUrl + this.serviceUrl + this.updateTeacherSubjectUrl;
        console.log(url);

        const parameter = {
            id: teacherSubject.id,
            courseId,
            teacherId: teacherSubject.teacherId,
            subjectId: teacherSubject.subjectId,
            priority: teacherSubject.priority
        };
        return this.http.post<TeacherAndSubject>(url, parameter, { headers: this.authorService.headerWithAuthorization(null) });
    }
    public createFromLevel(createCoursesFromLevel: CreateCoursesFromLevel): Observable<Course> {
        const url = this.baseUrl + this.serviceUrl + this.createFromLevelUrl;
        return this.http.post<Course>(url, createCoursesFromLevel, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async listByLevelAndExcludeAsync(levelId: string, courseId: string): Promise<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listByLevelAndExcludeUrl + `/${levelId}/${courseId}`;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public listByTeacherAndSubject(teacherId: string, subjectId: string): Observable<Course[]> {
        const url = this.baseUrl + this.serviceUrl + this.listByTeacherAndSubjectUrl + `/${teacherId}/${subjectId}`;
        return this.http.get<Course[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listStudentsForAdmission(courseId: string): Observable<Alumno[]> {
        const url = this.baseUrl + this.serviceUrl + this.studentsListForAdmissionUrl + `/${courseId}`;
        return this.http.get<Alumno[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public downloadSummaryAsPdf(courseId: string) {
        const url = this.baseUrl + this.serviceUrl + this.downloadSummaryUrl + `/${courseId}`;
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }

    public getByCurrentUser(): Observable<Course> {
        const url = this.baseUrl + this.serviceUrl + this.getByCurrentUserUrl;
        return this.http.get<Course>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public summaryReport(courseId: string, period: number): Observable<SummaryReport> {
        const url = this.baseUrl + this.serviceUrl + this.summaryReportUrl + `/${courseId}/${period}`;
        console.log('URL; ', url);
        return this.http.get<SummaryReport>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public downloadSummaryReport(courseId: string, period: number) {
        const url = this.baseUrl + this.serviceUrl + this.downloadSummaryReportUrl + `/${courseId}/${period}`;
        console.log(url);

        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                .append(
                    this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        }
        );
    }

    public calculateOrderStudents(courseId: string): Observable<Course> {
        const url = this.baseUrl + this.serviceUrl + this.calculateOrderStudentsUrl + `/${courseId}`;
        console.log('URL: ', url);
        return this.http.get<Course>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}