import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PostulanteService } from 'src/app/api/postulante.service';
import { Postulante } from 'src/app/model/postulant';

@Component({
  selector: 'app-enroller-document-viewer',
  templateUrl: './enroller-document-viewer.component.html',
  styleUrls: ['./enroller-document-viewer.component.css']
})
export class EnrollerDocumentViewerComponent implements OnInit {
  /*
  postulanteId: string;
  alumnoId: string;
  postulante: Postulante;
*/
  pdfUrl: string;
  enrollingTo: string;
  enrollNumber: number;

  constructor(private router: Router,
    private postulanteService: PostulanteService) { }

  public isStudent(): boolean {
    return this.enrollingTo == 'A';
  }

  ngOnInit(): void {
    /*
    this.postulanteId = localStorage.getItem('postulantId');
    this.alumnoId = localStorage.getItem('alumnoId');
    */
    this.enrollNumber = +localStorage.getItem('enrollNumber');
    this.enrollingTo = localStorage.getItem('enrollingTo');
    console.log(this.enrollingTo);

    //    console.log('alumno : ' + this.alumnoId);

    this.cargaDatos();
  }

  cargaDatos(): void {
    //    console.log("POSTULANTE: ", this.postulante);
    //    console.log(this.postulante.enrollNumber == 0);


    /*    if (this.postulante.enrollNumber == 0) {
          this.pdfUrl = this.postulanteService.getDocumentUrlByStudentId(this.alumnoId);
        } else {*/
    this.pdfUrl = this.postulanteService.getDocumentUrlByEnrollNumber(this.enrollNumber);
    //    }

    /**  
        this
          .postulanteService
          .findById(this.postulanteId)
          .subscribe(response => this.postulante = response);
    
        this.pdfUrl = this.postulanteService.getDocumentUrl(this.alumnoId);  // 'http://localhost:8080/api/lichan/postulante/1.0/document-enroll/' + this.alumnoId;
    */
  }

  goBack(): void {
    // localStorage.setItem('postulanteId', this.postulanteId);
    if (this.isStudent()) {
      localStorage.setItem('tabSelected', '4');
      this.router.navigate(['admission-enrollment']);
    } else {
      localStorage.setItem('tabSelected', '3');
      this.router.navigate(['admission-enrollment']);
    }

    // if (this.isStudent()) {
    //   this.router.navigate(['matricula-alumno']);
    // } else {
    //   this.router.navigate(['matricula-postulante']);
    // }
  }

  downloadDocument(): void {
    this
      .postulanteService
      .downloadPdf(this.enrollNumber)
      .subscribe((response: ArrayBuffer) => this.download(response,
        'application/pdf',
        `matricula-${this.enrollNumber}.pdf`));
  }

  download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
    const file: Blob = new Blob([binaryData], { type: fileType });
    const url: string = window.URL.createObjectURL(file);
    const anchorElement: HTMLAnchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = url;
    anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }
}
