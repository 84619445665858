import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RatingBookService } from 'src/app/api/rating-book.service';
import { Average } from 'src/app/model/average';
import { Exam, Exams } from 'src/app/model/exams';
import { AlertUtility, VerifyUserAndPasswordResponse } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-exam-values',
  templateUrl: './exam-values.component.html',
  styleUrls: ['./exam-values.component.css',
    '../calificaciones.component.css',
    '../../../style/table.css']
})

export class ExamValuesComponent implements OnInit, OnChanges {
  @Input() courseId: string | null = null;
  @Input() subjectId: string | null = null;

  public displayedColumns: string[] = ['num', 'studentName', 'exam-value', 'exam-percent'];
  public dataSource: Exam[] = [];
  private exams: Exams;

  constructor(private ratingBookService: RatingBookService,
    private alertUtility: AlertUtility) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ratingBookService.findExmasByCourseAndSubject(changes.courseId.currentValue, changes.subjectId.currentValue)
      .subscribe(
        (response: Exams) => {
          this.refreshMatrix(response);
        },
        problems => console.error(problems)
      );


  }

  private refreshMatrix(response: Exams) {
    this.exams = response;
    this.dataSource = this.exams.exams;
  }

  ngOnInit(): void {
  }

  public async onSaveExams(): Promise<void> {
    // console.log(this.exams);

    const response: VerifyUserAndPasswordResponse = await this.alertUtility.verifyUserAndPassword('Confirme clave para grabar notas de examenes', true, false);

    if (response.isValid) {
      this.ratingBookService.saveExams(this.exams)
        .subscribe(
          (response: Exams) => {
            this.refreshMatrix(response);
            this.alertUtility.fastMessage('Notas de examenes fueron grabadas exitosamente.');
          },
          problems => console.error(problems)
        );
    }
  }


  public onBlurScore(e: FocusEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    // console.log('input.id: ', input.id);

    const averageRequest: Average = new Average();
    averageRequest.values = [];
    averageRequest.value = input.value;
    averageRequest.index = 1;

    this.ratingBookService.average1_1(averageRequest)
      .subscribe(
        response => {
          // console.log(response);
          if (response.valid) {
            this.exams.exams
              .filter(exam => exam.studentId == input.id)[0]
              .score = response.value;
          } else {
            input.value = '';
          }
        },
        problems => console.error(problems)
      );

    /*
    const newValue = input.value;
    const inputId: string = input.id;
    const colNumber: number = this.getScoreId(inputId);
    const rating: Rating = this.getRatingByCompositeId(inputId, this.dataSource);
    const average: Average = new Average();
    const areSame: boolean = this.currentValue === newValue;

    let values: number[] = [];
    for (let i = 1; i <= 15; i++) {
      values[i - 1] = rating['score' + this.fix(i)];
    }
    average.values = values.map(value => "" + value);
    average.index = colNumber;
    average.value = newValue;

    this.ratingBookService.average1_1(average)
      .subscribe(response => {
        console.log('Message: ', response);
        rating.average2dec = response.average2dec;
        rating.average1dec = response.average1dec;
        if (response.valid) {
          rating.saved = false;
          rating['score' + this.fix(colNumber)] = response.value;
        } else {
          rating['score' + this.fix(colNumber)] = '';
          if (!areSame)
            this.snackBar.open(`${response.message}`, 'OK', { duration: 3000 });
        }
      },
        problems => console.error(problems)
      );
      */
  }


  public onFocusScore(e: FocusEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    // const inputId: string = input.id;
    const value: string = input.value;

    this.exams.exams
      .filter(exam => exam.studentId == input.id)[0]
      .score = value.replace('.', '');

    /*
    const rating: Rating = this.getRatingByCompositeId(inputId, this.dataSource);
    const index: number = this.getScoreId(inputId);
    let score: string = this.getScore(rating, index);

    // console.log('score', score);

    score = score.replace('.', '');
    this.setScore(rating, index, score);

    this.currentValue = score;
    */
  }

}
