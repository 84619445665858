import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubjectService } from 'src/app/api/subject.service';
import { UserService } from 'src/app/api/user.service';
// import { DialogData } from 'src/app/gui/alumno/alumno.component';
import { Subject } from 'src/app/model/subject';
import { User } from 'src/app/model/user';
// import { isNumericLiteral } from 'typescript';
import { CourseTeacherSubjectDialogParam } from '../course-teachers.component';

@Component({
  selector: 'app-course-teacher-subject-dialog',
  templateUrl: './course-teacher-subject-dialog.component.html',
  styleUrls: ['./course-teacher-subject-dialog.component.css']
})
export class CourseTeacherSubjectDialogComponent implements OnInit {
  teachers: User[];
  subjects: Subject[];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: CourseTeacherSubjectDialogParam,
    public dialogRef: MatDialogRef<CourseTeacherSubjectDialogComponent>,
    private userService: UserService,
    private subjectService: SubjectService) {
    console.log(dialogData);
  }

  ngOnInit(): void {
    this.userService.listTeachers().subscribe(response => this.teachers = response);

    if (this.dialogData.subjectId != null) {
      this
        .subjectService
        .listByNotCourseAndInclude(this.dialogData.courseId, this.dialogData.subjectId)
        .subscribe(response => this.subjects = response);
    } else {
      this.subjectService.listByNotCourse(this.dialogData.courseId).subscribe(response => this.subjects = response);
    }
    //    console.log(this.dialogData);

  }

}
