import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HourAndStatus } from 'src/app/model/hour-and-status';

@Component({
  selector: 'app-attendance-detail-dialog',
  templateUrl: './attendance-detail-dialog.component.html',
  styleUrls: ['./attendance-detail-dialog.component.css']
})
export class AttendanceDetailDialogComponent implements OnInit {
  public datasource: MatTableDataSource<HourAndStatus>;
  displayedColumns = ['hour', 'icon-status', 'status', 'comment'];

  constructor(public dialogRef: MatDialogRef<AttendanceDetailDialogParams>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AttendanceDetailDialogParams) { }

  ngOnInit(): void {

    this.dialogData.detail.forEach(row => {
      row.hour.start = row.hour.start.substring(0, 5);
      row.hour.finish = row.hour.finish.substring(0, 5);
    }
    );

    this.datasource = new MatTableDataSource(this.dialogData.detail);

  }

  public statusTranslate(row: HourAndStatus): string {
    // PRESENT, ABSENT, PARTIAL
    switch (row.status) {
      case 'UNASSISTED':
        return 'Sin asistencia';
      case 'PRESENT':
        return 'Presente'
      case 'ABSENT':
        return 'Ausente'
      case 'PARTIAL':
        return 'Tarde'
      default:
        return '?';
    }
  }

  public statusIcon(row: HourAndStatus): string {
    // PRESENT, ABSENT, PARTIAL
    switch (row.status) {
      case 'UNASSISTED':
        return 'check_box_outline_blank';
      case 'PRESENT':
        return 'verified';
      case 'ABSENT':
        return 'dangerous';
      case 'PARTIAL':
        return 'rule';
      default:
        return 'dangerous';
    }
  }

  public statusColor(row: HourAndStatus): string {
    switch (row.status) {
      case 'PRESENT':
        return 'primary';
      case 'ABSENT':
        return 'accent';
      case 'PARTIAL':
        return 'warn';
      default:
        return '';
    }

  }



}

export class AttendanceDetailDialogParams {
  detail: HourAndStatus[];
  studentName: string;
  dateSelected: string;
}