import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CalendarRow } from "../model/calendar-row";
import { AuthorService } from './author.service';
import { ValidateTimeDetail } from "../model/validate-time-detail";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ScheduleService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/schedule/1.0';

    private listByCourseUrl: string = '/find-by-course';
    private saveUrl: string = '/save';
    private validateUrl: string = '/validate';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listByTeacherAndSubject(teacherId: string, subjectId: string): Observable<CalendarRow[]> {
        const url = this.baseUrl + this.serviceUrl + `/find/${teacherId}/${subjectId}`;
        console.log(url);        
        return this.http.get<CalendarRow[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public save(teacherId: string, subjectId: string, calendarRows: CalendarRow[]): Observable<CalendarRow[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.saveUrl}/${teacherId}/${subjectId}`;
        return this.http.post<CalendarRow[]>(url, calendarRows, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public validate(calendarRows: CalendarRow[]): Observable<ValidateTimeDetail[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.validateUrl}`;
        return this.http.post<ValidateTimeDetail[]>(url, calendarRows, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByCourse(courseId: string): Observable<CalendarRow[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listByCourseUrl}/${courseId}`;
        return this.http.get<CalendarRow[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }


}