import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alumno } from 'src/app/model/alumno';
import { StudentHealth } from 'src/app/model/student-health';

@Component({
  selector: 'app-student-health-dialog',
  templateUrl: './student-health-dialog.component.html',
  styleUrls: ['./student-health-dialog.component.css']
})
export class StudentHealthDialogComponent implements OnInit {
  public healthStates: any[] = [
    { id: 'DISEASE', name: 'Enfermedad' },
    { id: 'DISABILITY', name: 'Discapacidad' },
    { id: 'CONDITION', name: 'Condición' }
  ];


  constructor(public dialogRef: MatDialogRef<StudentHealthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: StudentHealthDialogParams) { }

  ngOnInit(): void {
  }

}

export class StudentHealthDialogParams {
  studentHealth: StudentHealth;
  student: Alumno;
}
