<h1 mat-dialog-title>Cursos nuevos para el año {{this.nextYear}}</h1>

<div mat-dialog-content fxLayout="column">
    <mat-form-field appearance="fill" *ngIf="this.levels">
        <mat-label>Crear cursos del nivel</mat-label>
        <mat-select [(ngModel)]="this.dialogData.newLevelId" (selectionChange)="changeLevel($event)">
            <mat-option *ngFor="let level of this.levels" [value]="level.id">{{level.name}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.newLevelId!='' && this.isFirstLevel">
        <mat-label>Letras</mat-label>
        <input matInput placeholder="Letras de Cursos" [(ngModel)]="this.dialogData.letters">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.newLevelId && !this.isFirstLevel">
        <mat-label>Basado en el nivel anterior</mat-label>
        <input matInput placeholder="Letras de Cursos" [(ngModel)]="this.prevLevel.name" readonly="true">
    </mat-form-field>
    <mat-checkbox *ngIf="this.dialogData.newLevelId && !this.isFirstLevel"
        [(ngModel)]="this.dialogData.includeStudents" color="primary" selected>Incluir alumnos del nivel anterior
    </mat-checkbox>

</div>

<mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="this.dialogData">
        <mat-icon>save</mat-icon>Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>close</mat-icon>Cancelar
    </button>
</mat-dialog-actions>