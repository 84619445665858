import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Especialidad } from "../model/especialidad";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class EspecialidadService {
    private baseUrl: string = environment.apiUrl;
    private especialidadServiceUrl: string = '/api/lichan/especialidad/1.0';

    constructor(private http: HttpClient, private authorService: AuthorService) {
    }


    listAll(): Observable<Especialidad[]> {
        const url = this.baseUrl + this.especialidadServiceUrl + `/list`;
        return this.http.get<Especialidad[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}