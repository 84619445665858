<h1 *ngIf="nextYear">Listado postulantes, año {{nextYear}}</h1>
<mat-form-field appearance="standard">
  <mat-label>Buscar...</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Flores" #input [(ngModel)]="filterValue">
</mat-form-field>

<div>
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sel. </th>
      <td mat-cell *matCellDef="let row"> <mat-checkbox color="warn" [(ngModel)]="row.selected">  </mat-checkbox></td>
  </ng-container>

    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Rut </th>
      <td mat-cell *matCellDef="let row"> {{row.rut}} </td>
    </ng-container>

    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
      <td mat-cell *matCellDef="let row"> {{row.nombres}} {{row.paterno}} {{row.materno}}</td>
    </ng-container>

     <ng-container matColumnDef="especialidad">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Especialidad </th>
      <td mat-cell *matCellDef="let row"> {{row.especialidadNombre}} </td>
    </ng-container>

    <ng-container matColumnDef="canal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
      <td mat-cell *matCellDef="let row"> {{row.canal}} </td>
    </ng-container>
    <ng-container matColumnDef="curso">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Curso </th>
      <td mat-cell *matCellDef="let row"> {{  cursoService.formatLetra(row.courseLetra) }}</td>

    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No hay datos para el filtro "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Seleccione página"></mat-paginator>
<br>

<div>
  <mat-form-field appearance="standard" style="width: 200px;">
    <mat-label>Asignar curso:</mat-label>
    <mat-select [(ngModel)]="cursoId">
  <!--    <mat-option>-Sin curso-</mat-option> -->
      <mat-option *ngFor="let curso of cursos" value="{{curso.id}}">{{curso.letra.substring(0,1)+"-"+curso.letra.substring(1)}} ({{curso.especialidadNombre}})</mat-option>
      <!--
      <mat-option value="1B">1-B</mat-option>
      -->
    </mat-select>
  </mat-form-field>&nbsp;
  <button mat-raised-button color="primary" (click)="onAsignCursoClick()"><mat-icon>checklist_rtl</mat-icon>&nbsp;Asignar curso seleccionado</button>&nbsp;
  <button mat-raised-button color="warn" (click)="onAutomaticAsign()"><mat-icon>warning</mat-icon>&nbsp;Asignacion Automatica</button>&nbsp;
  
  <button mat-raised-button color="warn" disabled=true><mat-icon>info</mat-icon>&nbsp;Estadisticas</button>
  
</div>
&nbsp;

</div>
