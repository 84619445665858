import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CommonModule } from '@angular/common'
import { Router } from "@angular/router";


@Component({
  selector: 'dialog-select-course',
  templateUrl: './dialog-select-course.component.html',
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatSelectModule,ReactiveFormsModule,CommonModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class DialogSelectCourse implements OnInit {
selected: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogSelectCourse>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    //console.log(data);
  }

  ngOnInit(){
  }

  ngAfterViewInit(){
    setTimeout(async () => {
    },500);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelectCourseClick(): void {
   sessionStorage.setItem('selectedCourse',JSON.stringify(this.selected));
   this.dialogRef.close();
   this.router.navigate(['list-practices']);
  }

  trackedValue(index, item) {
    return item.value;
  }
}
