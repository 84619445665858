import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';

@Component({
  selector: 'app-attorney',
  templateUrl: './attorney.component.html',
  styleUrls: ['./attorney.component.css']
})
export class AttorneyComponent implements OnInit {
  public course: Course;

  constructor(private courseService: CourseService) { }

  ngOnInit(): void {
    this.loadCourse();
  }

  private async loadCourse() {
    this.courseService.getByCurrentUser()
      .subscribe(
        (response: Course) => this.course = response,
        problems => console.error(problems)
      );
  }

}
