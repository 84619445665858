<h1 mat-dialog-title>Cambio Nombres o apellidos de Alumno</h1>
<div mat-dialog-content>
    <strong>Alumno:</strong>{{changeNameDialogParams.paternal + ' ' + changeNameDialogParams.maternal + ' ' +
    changeNameDialogParams.names}}
    <br>

    <p>Confirme el nombre o apellidos del alumno. Los datos del alumno no serán desvinculados.</p>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Apellido paterno</mat-label>
        <input matInput [(ngModel)]="changeNameDialogParams.paternal">
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Apellido materno</mat-label>
        <input matInput [(ngModel)]="changeNameDialogParams.maternal">
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Nombres</mat-label>
        <input matInput [(ngModel)]="changeNameDialogParams.names">
    </mat-form-field>


</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="changeNameDialogParams" cdkFocusInitial color="primary">
        <mat-icon>save</mat-icon>Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">
        <mat-icon>close</mat-icon>Cancelar</button>
</div>