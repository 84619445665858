<h1 mat-dialog-title>Datos de accidente</h1>

<div mat-dialog-content>



    <input type="file" class="input-file" onchange="console.log(event.target.files)" (change)="onFileSelected($event)"
        #fileUpload>
    <div class="file-upload">
        <button mat-mini-fab color="primary" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
        {{this.dialogData.fileName || "Seleccione archivo de atención médica."}}
    </div>



</div>



<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary">
        <mat-icon>save</mat-icon>
        Grabar
    </button>
    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>