<div fxLayout="column" fxLayoutAlign="space-around center">
    <h1 *ngIf="this.course" fxFlexFill>Nómina para el cursos {{this.courseService.formatLetra(this.course.letra)}} del
        {{this.course.agno}}</h1>

    <table mat-table [dataSource]="dataSource" fxFlexFill multiTemplateDataRows>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef> Nro </th>
            <td mat-cell *matCellDef="let i = dataIndex" class="align-left"> {{i+1}} </td>
        </ng-container>
        <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef> Rut </th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.rut + '-' + row.dv}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.paterno + ' '+ row.materno + ' '+ row.nombres}}
            </td>
        </ng-container>
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef> Origen </th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.source=='OLD' ? 'Antiguo' : row.source}}
            </td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef class="align-left"> Estado </th>
            <td mat-cell *matCellDef="let row" class="align-left">
                {{this.resolveState(row.estado)}}
                <button mat-icon-button *ngIf="row.estado == 'XMT' || row.estado == 'MAT'"
                    (click)="changeStatusDialog(row.id, row.courseDto.id, row.estado)">
                    <mat-icon>edit_note</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="attorneyName">
            <th mat-header-cell *matHeaderCellDef class="align-left"> Nombre Apoderado </th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.nombreApoderado}} </td>
        </ng-container>
        <ng-container matColumnDef="attorneyPhone">
            <th mat-header-cell *matHeaderCellDef class="align-left"> Contacto Apoderado</th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.fonoApoderado}} {{ row.mailApoderado ? '/
                '+row.mailApoderado: row.mailApoderado}} </td>
        </ng-container>
        <ng-container matColumnDef="age">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Edad </th>
            <td mat-cell *matCellDef="let row" class="align-right"> {{row.edad}} </td>
        </ng-container>

    </table>
</div>

<br>
<br>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
    <button mat-raised-button color="primary" (click)="onCancelClick()">Volver a listado matriculas</button>
</div>