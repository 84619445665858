export class Questions {
    questions: Question[];
    status: string;
    allowSelfReview: boolean;
}

export class Question {
    id: string;
    detail: string;
    priority: number;
    area: Area;
    teacherAnswer: string;
    studentAnswer: string;
}

export class Area {
    id: string;
    name: string;
}

export class Replies {
    studentId: string;
    status: string;
    answers: Answer[];
}

export class Answer {
    questionId: string;
    teacherAnswer: string;
    studentAnswer: string;
}