export class Payments {
    payments: Payment[];
}

export class Payment {
    studentRut: string;
    receiptNumber: number;
    paymentDate: string;
    paymentAmount: number;
    paymentType: string;
    bankCode: string;
    documentNumber: string;
    year: string;
}