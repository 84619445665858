import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/api/config.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { InscripcionService } from 'src/app/api/inscripcion.service';
import { StudentService } from 'src/app/api/student.service';
import { Alumno } from 'src/app/model/alumno';
import { AlertUtility, DialogMessageType } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-associate-student-and-course',
  templateUrl: './associate-student-and-course.component.html',
  styleUrls: ['./associate-student-and-course.component.css',
    '../../../style/table.css'
  ]
})
export class AssociateStudentAndCourseComponent implements OnInit {
  public rut: string = ''; // '187263972';

  public student: Alumno = null;
  public courseId: string = null;
  public year: number = 0;


  constructor(private studentService: StudentService,
    private alertUtility: AlertUtility,
    public dateUtility: DateTimeUtility,
    private configService: ConfigService,
    private inscripcionService: InscripcionService) { }

  ngOnInit(): void {
    this.loadConfig();

  }

  private loadConfig(): void {
    this.configService.getValueByKey('matricula_actual')
      .subscribe(
        response => this.year = +response,
        problems => this.alertUtility.fastMessage(problems.error.message)
      );

  }


  public onSearchClick(): void {
    this.studentService.findByRut(this.rut)
      .subscribe(
        response => this.student = response,
        problems => {
          this.alertUtility.fastMessage(problems.error.message);
          this.student = null;
        }
      );
  }


  public onCourseChange(courseId) {
    console.log(courseId);
    this.courseId = courseId;
  }


  public onBind(): void {
    if (!this.validate()) return;

    this.inscripcionService.bindStudentAndCourse(this.student.id, this.courseId)
      .subscribe(
        response => this.showRelationResponse(response, 'vinculado a'),
        problems => this.alertUtility.fastMessage(problems.error.message)
      );
  }

  public onUnbind(): void {
    if (!this.validate()) return;

    this.inscripcionService.unbindStudentAndCourse(this.student.id, this.courseId)
      .subscribe(
        response => this.showRelationResponse(response, 'desvinculado de'),
        problems => this.alertUtility.fastMessage(problems.error.message)
      );
  }

  private showRelationResponse(student: Alumno, type: string) {
    console.log(student);
    const msg = `${student.paterno} ${student.materno}, ${student.nombres} ${type} ${student.courseDto.letra}`;
    this.alertUtility.dialogMessage(msg, 'Información', DialogMessageType.MESSAGE);
  }

  private validate(): boolean {
    if (this.student == null) {
      this.alertUtility.fastMessage('No se ha buscado el alumno');
      return false;
    }

    if (this.courseId == null) {
      this.alertUtility.fastMessage('No se ha seleccionado un curso');
      return false;
    }
    return true;
  }


}
