import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { GroupsDetail } from "../model/groups-detail";
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class GroupsService {
    private baseUrl: string = environment.apiUrl;
    // private countryServiceUrl: string = '/api/lichan/country/1.0';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public findGroupsDetail(courseId: string, includeStudents: boolean): Observable<GroupsDetail> {
        const url = environment.apiUrl + `/api/lichan/groups/V1?courseId=${courseId}&includeStudents=${includeStudents}`;
        return this.http.get<GroupsDetail>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public createGroups(courseId: string, amount: number): Observable<GroupsDetail> {
        const url = environment.apiUrl + `/api/lichan/groups/V1`;
        const createGroupsRequest: { courseId: string, amount: number } = { courseId: courseId, amount: amount };
        console.log(url);

        return this.http.post<GroupsDetail>(url, createGroupsRequest, { headers: this.authorService.headerWithAuthorization(null) });
    }


}