import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Participant } from '../model/participant.model';
import { MeetingService } from '../service/meeting.service';
import { CourseService } from 'src/app/api/course.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';

@Component({
  selector: 'app-attendance-dialog',
  templateUrl: './attendance-dialog.component.html',
  styleUrls: ['./attendance-dialog.component.css',
    '../../../../style/table.css'
  ]
})
export class AttendanceDialogComponent implements OnInit {
  public datasource: MatTableDataSource<Participant>;
  public displayedColumns: string[] = ['num', 'student', 'attorney', 'attendance'];

  constructor(public dialogRef: MatDialogRef<AttendanceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AttendanceDialogParams,
    private meetingService: MeetingService,
    public courseService: CourseService,
    public dtUtility: DateTimeUtility) { }

  ngOnInit(): void {
    console.log(this.dialogData.participant.length);
    this.datasource = new MatTableDataSource(this.dialogData.participant);

    console.log(
      this.dtUtility.dateToHumanString(this.dtUtility.stringToDate(this.dialogData.meetingDate))
    );

  }

  public changeAttendance(participantId: string, assist: boolean): void {
    console.log(participantId);
    this.meetingService.updateAttendance(participantId, assist)
      .subscribe(
        response => console.log(response),
        problems => console.error(problems)
      );

  }

}


export class AttendanceDialogParams {
  participant: Participant[];
  meetingDate: string;
  courseLeter: string;
  viewAsManager:boolean;
}