import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { User } from '../model/user';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class UserService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/user/1.0';

    private listTeachersUrl: string = '/list-teachers';
    private listTeachersExcludingHeadersUrl: string = '/list-teachers-exc-headers';
    private listAssignedTeachersUrl: string = '/list-assigned-teachers';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listTeachers(): Observable<User[]> {
        const url = this.baseUrl + this.serviceUrl + this.listTeachersUrl;
        return this.http.get<User[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findTeachersExcludingHeaders(courseId: string): Observable<User[]> {
        const url = this.baseUrl + this.serviceUrl + this.listTeachersExcludingHeadersUrl + `/${courseId}`;
        return this.http.get<User[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAssignedTeachers(): Observable<User[]> {
        const url = this.baseUrl + this.serviceUrl + this.listAssignedTeachersUrl;
        return this.http.get<User[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }
}