import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";
import { FailureProjectionReport } from "../model/failure-projection";
import { FailureProjectionCourse } from "../model/failure-projection-course";

@Injectable({
    providedIn: 'root'
})

export class FailureProjectionService {
    private baseUrl: string = environment.apiUrl;
    private managementServiceUrl: string = '/api/lichan/management/1.0';
    private retrieveFailureProjectionUrl: string = '/retrieve-failure-projection';
    private retrieveFailureProjectionCourseUrl: string = '/retrieve-failure-projection-course';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public getFailureProjection(refresh: boolean): Observable<FailureProjectionReport> {
        const url = this.baseUrl + this.managementServiceUrl + `${this.retrieveFailureProjectionUrl}/${refresh}`;
        return this.http.get<FailureProjectionReport>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getFailureProjectionCourse(courseId: string, includeAll: boolean): Observable<FailureProjectionCourse> {
        const url = this.baseUrl + this.managementServiceUrl + `${this.retrieveFailureProjectionCourseUrl}/${courseId}/${includeAll}`;
        return this.http.get<FailureProjectionCourse>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }


}