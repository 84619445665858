import { FileHandler } from "./file-handler";

export class Accident {
    id: string;
    studentId: string;
    accidentDate: string;
    attentionDate: string;
    returnsDate: string;
    year: number;
    description: string;
    healthPlace: string;
    escortsName: string;
    escortsRelationship: string;
    fileHandler: FileHandler;
}
