import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdultService } from 'src/app/api/adult.service';
import { PermissionService } from 'src/app/api/permission.service';
import { Adulto } from 'src/app/model/adulto';
import { StudentAdult } from 'src/app/model/student-adult';
import { RutUtility } from 'src/app/utility/rut.utility';
import { LegalGuardianDialogComponent, LegalGuardianDialogParam } from './legal-guardian-dialog/legal-guardian-dialog.component';
import { AlertUtility, DialogMessageType } from 'src/app/utility/alert.utility';
import { Status } from 'src/app/model/status';

@Component({
  selector: 'apoderados-list',
  templateUrl: './apoderados.component.html',
  styleUrls: ['./apoderados.component.css', '../../../style/table.css']
})
export class ApoderadosComponent implements OnInit {
  public displayedColumns: string[] = ['nombre', 'parentezco', 'telefono', 'direccion'];
  dataSource: MatTableDataSource<StudentAdult>;
  public canAdminContacts: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() studentId: string;

  constructor(private adultService: AdultService,
    private permissionService: PermissionService,
    public dialog: MatDialog,
    private rutUtility: RutUtility,
    private alert: AlertUtility) {
  }

  ngOnInit() {
    this.adultService.findByAlumno(this.studentId)
      .subscribe(
        (response: StudentAdult[]) => {
          this.dataSource = new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        problems => console.error(problems)
      );

    this.permissionService.hasPermissions(['STUDENT_RESUME.ADMIN_CONTACTS'])
      .subscribe(
        (response: boolean) => {
          this.canAdminContacts = response;
          this.refreshDisplayedColumns();
        },
        problems => console.error(problems)
      );
  }

  private refreshDisplayedColumns(): void {
    if (this.canAdminContacts) {
      this.displayedColumns.push('actions');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public async showDetailAdult(adultId: string) {
    console.log(adultId);
    const studentAdult: StudentAdult = adultId == null ? new StudentAdult() : await this.adultService.findByAlumnoIdAndAdultIdAsync(this.studentId, adultId);

    console.log('data: ', studentAdult);

    const legalGuardianDialogParam: LegalGuardianDialogParam = new LegalGuardianDialogParam();
    legalGuardianDialogParam.canAdminContacts = this.canAdminContacts;
    legalGuardianDialogParam.adultId = adultId;
    legalGuardianDialogParam.studentId = this.studentId;
    legalGuardianDialogParam.rut = adultId == null ? '' : studentAdult.adulto.rut + '-' + this.rutUtility.dv(+studentAdult.adulto.rut);
    legalGuardianDialogParam.name = studentAdult.adulto.nombre;
    legalGuardianDialogParam.relationshipId = studentAdult.relacion;
    legalGuardianDialogParam.relationshipName = this.translateRelationship(studentAdult.relacion);
    legalGuardianDialogParam.activity = studentAdult.adulto.actividad;
    legalGuardianDialogParam.studies = studentAdult.adulto.estudios;
    legalGuardianDialogParam.address = studentAdult.adulto.datosContacto['DIRECCION'];
    legalGuardianDialogParam.cellPhoneNumber = studentAdult.adulto.datosContacto['FONO_MOVIL'];
    legalGuardianDialogParam.email = studentAdult.adulto.datosContacto['EMAIL'];

    const legalGuardianDialogRef = this.dialog.open(LegalGuardianDialogComponent, { data: legalGuardianDialogParam });

    legalGuardianDialogRef.afterClosed()
      .subscribe(
        (result: LegalGuardianDialogParam) => this.saveAdult(result)
      );
  }

  private saveAdult(result: LegalGuardianDialogParam): void {
    const studentAdult: StudentAdult = new StudentAdult();
    console.log(result);

    if (result == undefined) return;
    if (result.relationshipId == null) {
      this.alert.dialogMessage('No seleccionó relacion de parentezco', 'ERROR', DialogMessageType.ERROR);
      return;
    }

    studentAdult.adulto.actividad = result.activity;
    studentAdult.adulto.datosContacto['DIRECCION'] = result.address;
    studentAdult.adulto.datosContacto['FONO_MOVIL'] = result.cellPhoneNumber;
    studentAdult.adulto.datosContacto['EMAIL'] = result.email;
    studentAdult.adulto.estudios = result.studies;
    studentAdult.adulto.id = result.adultId;
    studentAdult.adulto.nombre = result.name;
    studentAdult.adulto.rut = '' + this.rutUtility.extractNumber(result.rut);
    studentAdult.adultoId = result.adultId;
    studentAdult.id = result.id;
    studentAdult.relacion = result.relationshipId;
    studentAdult.studentId = result.studentId;

    console.log('Saving with: ', studentAdult);


    // return;

    this.adultService.save(studentAdult)
      .subscribe(
        (response: StudentAdult) => {
          console.log('After save: ', response);
          const temp: StudentAdult[] = this.dataSource.data.filter(row => row.id != response.id);
          temp.push(response);
          this.dataSource.data = temp;

        },
        problems => console.error(problems)
      );

  }


  private translateRelationship(relationship: string): string {
    let response: string;
    switch (relationship) {
      case 'APODERADO':
        response = 'Apoderado';
        break;
      case 'PADRE':
        response = 'Padre';
        break;
      case 'MADRE':
        response = 'Madre';
        break;
      case 'ABUELO':
        response = 'Abuelo/a';
        break;
      case 'TIO':
        response = 'Tío/a';
        break;
      case 'TUTOR':
        response = 'Tutor/a';
        break;
      case 'INSTRUCTOR':
        response = 'Instructor/a';
        break;
      case 'OTRO':
        response = 'Otro';
        break;
      default:
        response = 'Sin definición';
        break;
    }

    return response;
  }

  public async deleteRelation(relId: string): Promise<void> {
    const confirm: boolean = await this.alert.confirmMessage('Está seguro de retirar la relacion?');
    if (confirm) {
      this.adultService.removeRelation(relId)
        .subscribe(
          (response: Status) => {
            console.log(response);
            if (response.status = 'OK') {
              this.dataSource.data = this.dataSource.data.filter(row => row.id !== relId);
            }
          },
          problems => console.error(problems)
        );
    }
  }

}

