import { GenericObjective } from "./generic-objetive";
import { Module } from "./module";
import { SpecificObjective } from "./specific-objetive";
import { Subject } from "./subject";
import { User } from "./user";

export class LectionaryBody {
    id: string;
    genericObjectives: GenericObjective[];
    specificObjectives: SpecificObjective[];
    detail: string;
    lessonDate: string;
    signDate: string;
    signer: User;
    hourId: string;
    courseId: string;
    module: Module;
    subject: Subject;
    lastComment: string;
    previousSigned: boolean;

    signComment: string;
}