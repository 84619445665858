<h1 *ngIf="course">Curso {{courseService.formatLetra(course.letra)}}</h1>

<mat-tab-group animationDuration="0ms" *ngIf="course" selectedIndex="0" >
    <mat-tab label="Propiedades">
        <app-course-general-props [courseId]="course.id"></app-course-general-props>
    </mat-tab>
    <mat-tab label="Asignaturas y profesores">
        <app-course-teachers [courseId]="course.id"></app-course-teachers>
    </mat-tab>

  </mat-tab-group>




