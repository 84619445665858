<h1>Alumnos de la lista {{course.letra}}, total {{total}} año {{yearDb}}</h1>
<button mat-raised-button (click)="contractAllOnClick()"
 matTooltipShowDelay="250"
      matTooltipHideDelay="100" matTooltip="Imprimir set de contratos completo de practicas">
      <mat-icon>print</mat-icon>&nbsp;
    </button>
<h5>{{lstPractices.length}} Alumnos con prácticas ingresadas</h5>

<mat-form-field>
  <mat-label>Filtrar</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo Rut" #input>
</mat-form-field>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="NumeroLista">
      <th mat-header-cell *matHeaderCellDef>
          Lista
      </th>
      <td mat-cell *matCellDef="let element"> {{element.numeroLista}} </td>
    </ng-container>

    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef>
        Nombre
      </th>
      <td mat-cell *matCellDef="let element"> {{element.paterno}} {{element.materno}} {{element.nombres}}</td>
    </ng-container>

    <ng-container matColumnDef="Empresa">
      <th mat-header-cell *matHeaderCellDef>
        Empresa Práctica
      </th>
      <td mat-cell *matCellDef="let element"> {{getCompanyName(element.rut)}}</td>
    </ng-container>

    <ng-container matColumnDef="Instructor">
      <th mat-header-cell *matHeaderCellDef>
        Instructor Práctica
      </th>
      <td mat-cell *matCellDef="let element"> {{getInstructorName(element.rut)}}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef>
        Acción
      </th>
      <td mat-cell *matCellDef="let element" >
        <button *ngIf="existPractice(element.rut)" mat-raised-button color="success" matTooltipShowDelay="250"
          matTooltipHideDelay="100" matTooltip="Alumno con práctica">
          <mat-icon >done</mat-icon>&nbsp;
        </button>
        <span>&nbsp;</span>
        <button *ngIf="existPractice(element.rut)" mat-raised-button (click)="practicesOnClick(element.rut)" color="primary" matTooltipShowDelay="250"
        matTooltipHideDelay="100" matTooltip="Revisar">
        <mat-icon>visibility</mat-icon>&nbsp;
      </button>
      <span>&nbsp;</span>
      <button *ngIf="existPractice(element.rut)" mat-raised-button (click)="contractOnClick(element)" color="primary" matTooltipShowDelay="250"
      matTooltipHideDelay="100" matTooltip="Imprimir set de contratos completo">
      <mat-icon>print</mat-icon>&nbsp;
    </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator class="mat-elevation-z1" [pageSizeOptions]="[10,25, 30, 40, 50]" [pageSize]="10" showFirstLastButtons>
  </mat-paginator>
<br>
<button mat-raised-button color="basic" (click)="back()">Volver</button>
<br>

