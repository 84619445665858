import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { MineducSendParams } from "../model/mineduc-send-params";
import { MineducSummary } from "../model/mineduc-summary";
import { AuthorService } from './author.service';
import { Questions, Replies } from "../model/personality-report";

@Injectable({
    providedIn: 'root'
})

export class PersonalityReportService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/personality-report';
    private listQuestionsUrl: string = '/1.0/list';
    private replyUrl: string = '/1.0/reply';
    private downloadCourseReportUrl: string = '/1.0/download-course-report';


    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listQuestions(studentId: string): Observable<Questions> {
        const url = this.baseUrl + this.serviceUrl + `${this.listQuestionsUrl}/${studentId}`;
        return this.http.get<Questions>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public reply(replies: Replies): Observable<string> {
        const url = this.baseUrl + this.serviceUrl + `${this.replyUrl}`;
        return this.http.post<string>(url, replies, { headers: this.authorService.headerWithAuthorization(null) });
    }


public     downloadCourseReportAsPdf(courseId: string )  {
    const url = this.baseUrl + this.serviceUrl + this.downloadCourseReportUrl + `/${courseId}`;
//     console.log(url);

    return this.http.get(url, {
        responseType: 'arraybuffer',
        headers: new HttpHeaders()
            .append('Content-Type', 'application/pdf')
            .append(this.authorService.AUTHORIZATION,
                this.authorService.BEARER + this.authorService.token
            )
    });


}


}
