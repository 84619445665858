<h2 mat-dialog-title>Anotación en hoja de vida</h2>
<div fxLayout="column" fxLayoutAlign="space-around start">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="1" style="width:550px;" mat-dialog-content>

        <mat-form-field appearance="fill" style="width:550px;">
            <mat-label>Tipo de Anotación</mat-label>
            <mat-select [(ngModel)]="annotationTypeId" (selectionChange)="onChangeForm($event);">
                <mat-option *ngFor="let type of types" [value]="type.id">
                    {{type.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row">
        <div fxLayout="row" fxLayoutAlign="start start">
            <com-subject-selector [courseId]="dialogDataParam.courseId"
                (subjectChangeEvent)="onChangeSubject($event)"></com-subject-selector>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="fill" style="width:550px;">
            <mat-label>Plantilla de anotación</mat-label>
            <mat-select [(ngModel)]="id" (selectionChange)="onChangeTemplate();onChangeForm($event);">
                <mat-option *ngFor="let template of templates" [value]="template.id">
                    {{template.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row">
        <mat-form-field appearance="fill" style="width:550px;">
            <mat-label>Detalle de la anotación</mat-label>
            <textarea matInput [(ngModel)]="annotationDetail" #JsAnnotationDetail style="height: 100px;"
                (keyup)="onChangeForm($event)"
                placeholder="Escriba el detalle de la anotación para el alumno"></textarea>
            <mat-hint align="end">{{JsAnnotationDetail.value.length}} / 65535</mat-hint>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center">
        <button mat-raised-button color="primary" (click)="onAccept()" [disabled]="!isFormValid">Aceptar</button>

        <button mat-raised-button (click)="onCancel()">Cancelar</button>
    </div>
</div>