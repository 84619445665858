<mat-sidenav-container fullscreen>

    <mat-sidenav #sidenav mode="over" [fixedInViewport]="true">
        <img src="assets/img/logo.png" alt="Colegio" width="180px">
        <!-- (click)="onClickMenuItem(menuItems[0]);sidenav.toggle()"  style="cursor: pointer;" -->
        <mat-nav-list>
            <mat-list>
                <mat-list-item *ngFor="let menuItem of this.filterByRol(menuItems)"
                    (click)="onClickMenuItem(menuItem);sidenav.toggle()">
                    <div>{{menuItem.label}}
                        <mat-icon *ngIf="menuItem.icon" color="accent" matTooltip="Nuevo!">{{menuItem.icon}}&nbsp;</mat-icon>
                    </div>
                    <span class="spacer"></span>
                    <div>
                        <mat-icon mat-list-icon>arrow_right</mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button mat-icon-button *ngIf="authorService.isAuthenticated()" (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <h1>GES2</h1>
            <span class="spacer"></span>

            <div *ngIf="authorService.isAuthenticated()">
                {{this.getUserName()}}
                <small *ngIf="this.authorService.rols">({{this.getUserRols()}})</small>
                <button mat-icon-button aria-label="Mi menú" [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="this.onChangePassword()">
                        <mat-icon>key</mat-icon>
                        <span>Cambio de clave...</span>
                    </button>
                </mat-menu>
            </div>

            <button mat-flat-button *ngIf="authorService.isAuthenticated()" color="primary" (click)="onClickLogout()">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>
        <div style="text-align:center">
            <router-outlet></router-outlet>
        </div>
        <br>
        <br>
    </mat-sidenav-content>
</mat-sidenav-container>