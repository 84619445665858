import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AttendanceSummary } from "../model/attendance-summary";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";
import { MineducSummary } from "../model/mineduc-summary";
import { HourAndStatus } from "../model/hour-and-status";
import { ShortedLectionarySummary } from "../model/lectionary-summary";
 
@Injectable({
    providedIn: 'root'
})

export class ManagementService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/management';
    private retrieveAttendanceUrl: string = '/1.0/retrieve-attendance';
    private retrieveAttendanceDetailUrl: string = '/1.0/retrieve-attendance-detail';
    private retrieveMineducSummaryUrl: string = '/1.0/retrieve-mineduc-summmary'
    private retrieveEnrollingBookUrl: string = '/1.0/retrieve-enrolling-book';


    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public retrieveAttendance(courseId: string, month: number): Observable<AttendanceSummary> {
        const url = this.baseUrl + this.serviceUrl + `${this.retrieveAttendanceUrl}/${courseId}/${month}`;
        return this.http.get<AttendanceSummary>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public retrieveMineducSummary(courseId: string, month: number): Observable<MineducSummary[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.retrieveMineducSummaryUrl}/${courseId}/${month}`;
        return this.http.get<MineducSummary[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public retrieveAttendanceDetail(studentId: string, courseId: string, date: string): Observable<HourAndStatus[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.retrieveAttendanceDetailUrl}/${studentId}/${courseId}/${date}`;
        console.log(url);
        return this.http.get<HourAndStatus[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public retrieveEnrollingBook(year: number, format: string) {
        const url = this.baseUrl + this.serviceUrl + `${this.retrieveEnrollingBookUrl}/${year}/${format}`;
        console.log(url);

        const contentType: string = this.getContentType(format);

        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', contentType)
                .append(
                    this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }
    private getContentType(format: string): string {
        switch (format.toLowerCase()) {
            case 'pdf':
                return 'application/pdf';
            case 'excel':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            default:
                return 'application/none';
        }
    }

    public retrieveLectionaries(teacherUsername: string, startDate: string, finishDate: string, subjectId: string): Observable<ShortedLectionarySummary> {
        const url = this.baseUrl + this.serviceUrl + `/V1/retrieve-lectionaries/${teacherUsername}/${startDate}/${finishDate}/${subjectId}`;
        console.log(url);
        return this.http.get<ShortedLectionarySummary>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }




}