import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/api/course.service';
import { EspecialidadService } from 'src/app/api/especialidad.service';
import { UserService } from 'src/app/api/user.service';
import { Course } from 'src/app/model/course';
import { Especialidad } from 'src/app/model/especialidad';
import { User } from 'src/app/model/user';
import { AlertUtility } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-course-general-props',
  templateUrl: './course-general-props.component.html',
  styleUrls: ['./course-general-props.component.css']
})
export class CourseGeneralPropsComponent implements OnInit {
  @Input() courseId: string;

  course: Course;
  especialidades: Especialidad[];
  teachers: User[];

  constructor(public courseService: CourseService,
    private especialidadService: EspecialidadService,
    private userService: UserService,
    private router: Router,
    private alert: AlertUtility) { }

  ngOnInit(): void {
    this.courseService.get(this.courseId)
      .subscribe(
        response => {
          this.course = response;
        },
        problems => console.error(problems)
      );

    this.especialidadService.listAll()
      .subscribe(
        response => this.especialidades = response,
        problems => console.error(problems)
      );

    this
      .userService.findTeachersExcludingHeaders(this.courseId)
      .subscribe(
        response => {
          this.teachers = response;
        },
        problems => console.error(problems)
      )
  }

  onAceptarClick(): void {
    console.log(this.course);
    this.courseService.update(this.course)
      .subscribe(
        response => this.onCancelarClick(),
        problems => console.error(problems)
      );
  }

  public onCancelarClick(): void {
    this.router.navigate(['courses']);
  }

  public async onCalculateStudentsList() {
    const confirmed: boolean = await this.alert.confirmMessage(`¿Esta seguro de reordenar la lista de alumnos? 
    Esta accion no se puede deshacer. 
    El orden será en base a los apellidos y nombres de los alumnos.`, 'Ordenar lista');

    if (confirmed) {
      this.courseService.calculateOrderStudents(this.courseId)
        .subscribe(
          (course: Course) => {
            console.log(course);
            this.alert.fastMessage(`Lista de alumnos ordenada, ${course.studentsCount} alumnos`);
            this.onCancelarClick();
          },
          problems => console.error(problems)
        );
    }

  }

}
