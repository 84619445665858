import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Course } from "../model/course";
import { Signed } from "../model/signed";
import { Subject } from '../model/subject';
import { User } from "../model/user";
import { UserSign } from "../model/user-sign";
import { AuthorService } from './author.service';


@Injectable({
    providedIn: 'root'
})

export class SignatureService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/signature';
    private doSignUrl: string = '/1.0/do-sign';
    private isSignedUrl: string = '/1.0/is-signed';
    private getReportUrl: string = '/1.0/report';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public doSign(userSign: UserSign): Observable<UserSign> {
        const url = this.baseUrl + this.serviceUrl + `${this.doSignUrl}`;
        return this.http.post<UserSign>(url, userSign, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public isSigned(signed: Signed): Observable<UserSign> {
        const url = this.baseUrl + this.serviceUrl + `${this.isSignedUrl}`;
        return this.http.post<UserSign>(url, signed, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getReport(sig: SignatureReportRequest): Observable<SignaturesReport> {
        const url = this.baseUrl + this.serviceUrl + `${this.getReportUrl}/${sig.start}/${sig.finish}/${sig.teacherId}/${sig.courseId}/${sig.subjectId}`;
        console.log('Url:' + url);

        return this.http.get<SignaturesReport>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}

export class SignatureReportRequest {
    start: string | null = null;
    finish: string | null = null;
    teacherId: string | null = null;
    courseId: string | null = null;
    subjectId: string | null = null;
}

export class SignaturesReport {
    signatures: SignatureStatus[];
}

export class SignatureStatus {
    id:string;
    signatureDate: string;
    timeBlockDesc: string;
    timeBlockId: string;
    course: Course;
    subject: Subject;
    teacher: User;
    signer: User;
    lectionareSigned: boolean;
    attendanceSigned: boolean;
    bookSigned: boolean;
}