<div>
  <table border="0"  width="95%" align="center">
    <tr>
      <td valign="top" width="3%">
        <button mat-raised-button  (click)="goBackClick()">
          <mat-icon>navigate_before</mat-icon>Volver
        </button>
      </td>
      <td width="80%">
        <com-course-panel [course]="course"></com-course-panel>
      </td>
      <td width="10%" style="text-align: right;" vAlign="bottom">
        <mat-slide-toggle color="primary" [(ngModel)]="showAllStudents" (change)="onShowAllChange()">Mostrar todos</mat-slide-toggle>
      </td>
    </tr>
  </table>
  <table width="95%" align="center">
    <tr>
      <td *ngIf="this.subjects">

        <div *ngFor="let dataSource of dataSources; let i = index">

          <mat-card>
            <table mat-table [dataSource]="dataSource" fxFlexFill>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="align-left bordered"> Alumno </th>
                <td mat-cell *matCellDef="let element" class="align-left bordered"> {{element.studentName}} </td>
              </ng-container>
              <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef class="align-right bordered"> Periodo </th>
                <td mat-cell *matCellDef="let element" class="align-right bordered"> {{element.periodKey}} </td>
              </ng-container>

              <ng-container [matColumnDef]="this.addStash(subject.abbreviated)"
                *ngFor="let subject of this.subjects; let j = index">
                <th mat-header-cell *matHeaderCellDef class="align-center average-cell bordered">
                  {{subject.abbreviated}}
                </th>
                <td mat-cell *matCellDef="let element" class="align-right average-cell bordered"
                  [style]="redColorIfApply(+getAverage(element, subject.id, j), 4)">
                  {{getAverage(element, subject.id, j)}}</td>
              </ng-container>

              <ng-container matColumnDef="general">
                <th mat-header-cell *matHeaderCellDef class="align-right average-cell"> General </th>
                <td mat-cell *matCellDef="let element; let i = index" class="align-right average-cell bordered"
                  [style]="redColorIfApply(+getGeneralAverage(element, i), 4)">
                  {{getGeneralAverage(element, i)}} </td>
              </ng-container>

              <ng-container matColumnDef="attendance">
                <th mat-header-cell *matHeaderCellDef class="align-right bordered"> Asistencia </th>
                <td mat-cell *matCellDef="let element; let i = index" class="align-right bordered"
                  [style]="redColorIfApply(i == 0 ? element.attendance:0, 85)"> {{ i == 0 ? element.attendance:''}}</td>
              </ng-container>
            </table>
          </mat-card>
          <br>&nbsp;<br>
        </div>


      </td>
    </tr>
  </table>


</div>