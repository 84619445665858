import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthorService } from "./author.service";


@Injectable({
    providedIn: 'root'
})



export class PermissionService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/config';
    private hasPermissionUrl: string = '/1.0/has-permission';
    private hasPermissionsUrl: string = '/1.0/has-permissions';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public async hasPermissionAsync(feature: string): Promise<boolean> {
        const url = this.baseUrl + this.serviceUrl + this.hasPermissionUrl + `/${feature}`;
        return this.http.get<boolean>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public async hasPermissionsAsync(action: string[]): Promise<boolean> {
        const url = this.baseUrl + this.serviceUrl + this.hasPermissionsUrl;
        return this.http.post<boolean>(url, action, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }


    public hasPermissions(action: string[]): Observable<boolean> {
        const url = this.baseUrl + this.serviceUrl + this.hasPermissionsUrl;
        return this.http.post<boolean>(url, action, { headers: this.authorService.headerWithAuthorization(null) });
    }


}


