import {LiveAnnouncer} from '@angular/cdk/a11y';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import { Company } from 'src/app/model/company';
import { Course } from 'src/app/model/course';
import { CourseService } from 'src/app/api/course.service';
import { CompanyService } from 'src/app/api/company.service';
import { Alumno } from 'src/app/model/alumno';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import { Location } from '@angular/common'
import { PracticeService } from 'src/app/api/practice.service';
import { DownloadUtility } from 'src/app/utility/download.utility';
import { AlertUtility } from 'src/app/utility/alert.utility';


@Component({
  selector: 'app-list-practices',
  templateUrl: './list-practices.component.html',
  styleUrls: ['./list-practices.component.css'],
  standalone: true,
  imports: [MatTooltipModule,MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule,MatPaginatorModule, MatIconModule,NgIf, MatButtonModule]
})
export class ListPracticesComponent implements AfterViewInit {
  dataSource: MatTableDataSource<Alumno>;
  displayedColumns: string[] = ['NumeroLista', 'Nombre','Empresa','Instructor','Actions'];

  companiesData: Company[]  = [];
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  year: number = new Date().getFullYear();
  course:Course;
  total: number = 0;
  students: any;
  yearDb: string;
  lstPractices: any[] = [];

  constructor(
    private courseService: CourseService,
    private companyService: CompanyService,
    private router: Router,
    private location: Location,
    private _liveAnnouncer: LiveAnnouncer,
    private practiceService: PracticeService,
    private downloadUtility: DownloadUtility,
    private alert: AlertUtility) {
    this.course = JSON.parse(sessionStorage.getItem('selectedCourse')) as Course;
  }

  async ngOnInit() {1
    this.yearDb = localStorage.getItem('practica_actual');
    await this.courseService.listStudents(this.course.id)
    .subscribe(
      response => {
        let filtered = response.filter(item => item.estado  =='MAT');
        filtered.forEach(element => {

          this.companyService.getCompanyByStudentRun(element.rut,this.yearDb)
            .subscribe(
              responseStd => {
                if (responseStd!=null) {
                  this.lstPractices.push(
                    {
                      rut: element.rut,
                      empresa: responseStd.empresa!=null ? responseStd.empresa: "",
                      cod_empre: responseStd.cod_empre,
                      instructor: responseStd.instructor,
                      supervisor: responseStd.supervisor,
                      id: responseStd.id

                    }
                  );
                }
              },
              problems => {
                console.log(problems);
              }
            );
        });




        this.total = filtered.length;
        this.dataSource = new MatTableDataSource(filtered);
        this.dataSource.paginator = this.paginator;
      },
      problems => console.error(problems)
    );
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  practicesOnClick(rut: string){
    let company = new Company();
    company.rut_emp = this.getRutCompany(rut);
    company.empresa = this.getCompanyName(rut);
    sessionStorage.setItem('companySelected',JSON.stringify(company));
    sessionStorage.setItem('action','edit');
    sessionStorage.setItem('student',rut);
    this.router.navigate(['add-practice']);
  }

  getCompanyName(rut: string) {
    let practice = this.lstPractices.filter(student => student.rut === rut);
    let empresa = "";
    if(practice.length>0){
      empresa = this.lstPractices.filter(student => student.rut === rut)[0].empresa;
    }
    return empresa;
  }

  getRutCompany(rut: string) {
    let practice = this.lstPractices.filter(student => student.rut === rut);
    let empresa = "";
    if(practice.length>0){
      empresa = this.lstPractices.filter(student => student.rut === rut)[0].cod_empre;
    }
    return empresa;
  }

  getInstructorName(rut: string) {
    let practice = this.lstPractices.filter(student => student.rut === rut);
    let instructor = "";
    if(practice.length>0){
      instructor = this.lstPractices.filter(student => student.rut === rut)[0].instructor;
    }
    return instructor;
  }

  existPractice(rut: string) {
    let practice = this.lstPractices.filter(student => student.rut === rut);
    return practice.length > 0;
  }

  public contractOnClick(practice: any) {
    let contentType: string;
    let fileName: string;
    contentType = 'application/pdf';
    let practiceStudent = this.lstPractices.filter(student => student.rut === practice.rut);
    fileName = 'contrato_'+practiceStudent[0].id+'.pdf';
   this.practiceService.getContract(practiceStudent[0].id)
      .subscribe(
        (response: ArrayBuffer) => this.downloadUtility.download(response, contentType, fileName),
        problems => {
          this.alert.fastMessage(`Error al obtener archivo (${problems.message})`);
          console.error(problems);
        }
      );

  }
  contractAllOnClick() {
    let contentType: string;
    let fileName: string;
    contentType = 'application/pdf';

    fileName = 'contratoCurso_'+this.course.letra+'_'+this.yearDb+'.pdf';
   this.practiceService.getContractCourse(this.yearDb,this.course.letra)
      .subscribe(
        (response: ArrayBuffer) => this.downloadUtility.download(response, contentType, fileName),
        problems => {
          this.alert.fastMessage(`Error al obtener archivo (${problems.message})`);
          console.error(problems);
        }
      );
    }

  back(): void {
    this.location.back();
  }
}
