import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Alumno } from 'src/app/model/alumno';
import { Annotation } from 'src/app/model/annotation';

@Component({
  selector: 'app-annotation-list-dialog',
  templateUrl: './annotation-list-dialog.component.html',
  styleUrls: ['./annotation-list-dialog.component.css']
})
export class AnnotationListDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AnnotationListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AnnotationListDialogParameters) { }

  ngOnInit(): void {
  }
}

export class AnnotationListDialogParameters {
  dialogTitle: string;
  student: Alumno;
  annotations: Annotation[];
}