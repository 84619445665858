<h1 mat-dialog-title>Verificación de identidad.</h1>
<div mat-dialog-content fxLayout="column">
    <p *ngIf="this.dialogData.showMessage">
        {{this.dialogData.message}}
    </p>

    <mat-form-field appearance="fill">
        <mat-label>Usuario</mat-label>
        <input matInput [(ngModel)]="this.dialogData.username" readonly="true">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{this.dialogData.passwordLabel}}</mat-label>
        <input matInput [type]="this.dialogData.passwordType" [(ngModel)]="this.dialogData.password"
            autocomplete="new-password" [maxlength]="this.dialogData.passwordLen">
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="this.dialogData.requestComment">
        <mat-label>Comentario (opcional)</mat-label>
        <input matInput [(ngModel)]="this.dialogData.comment">
    </mat-form-field>


</div>
<div mat-dialog-actions>

    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" (click)="onCancelButton()">Cancelar</button>

</div>