import { SelectionModel } from '@angular/cdk/collections';
import {
  Component, Input, OnInit, ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/api/crud.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { Button } from 'src/app/model/button';
import { Column, CrudResponse } from 'src/app/model/crud-response';
import { EntityConfig, KeyValue } from 'src/app/model/entity-config';
import { MasterAndSlave } from 'src/app/model/master-and-slave';
import { ChildElementDialogParameter, ChildElementsDialogComponent } from './child-elements-dialog/child-elements-dialog.component';
import { ActionDialogParameter, InsertDialogComponent } from './insert-dialog/insert-dialog.component';
import {AlertUtility} from 'src/app/utility/alert.utility';
// import moment from 'moment';

@Component({
  selector: 'app-generic-crud',
  templateUrl: './generic-crud.component.html',
  styleUrls: ['./generic-crud.component.css',
    './../../../style/table.css']
})
export class GenericCrudComponent implements OnInit {
  @Input() entityId: string;

  @Input() tabSelected: string;

  @Input() navigateBack: string;

  crudResponse: CrudResponse;
  columnsId: string[] = [];
  columns: Column[] = [];
  dataSource: MatTableDataSource<any>;
  selection : SelectionModel<any>;
  // selection = new SelectionModel<any>(true, []);
  entityConfig: EntityConfig;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  private includeFirstColumn: boolean = true;

  constructor(
    private crudService: CrudService,
    private dialog: MatDialog,
    private dateTimeUtility: DateTimeUtility,
    private router: Router,
    private snackBar: MatSnackBar,
    private alertUtility : AlertUtility) { }

  ngOnInit(): void {
    this.loadDataAndConfig();
  }

  private async loadDataAndConfig() {
    console.log('before All');
    this.selection = new SelectionModel<any>(true, []);
    const data = await this.crudService.listAsync(this.entityId);
    const config: EntityConfig = await this.crudService.readConfigAsync(this.entityId);

    // const thereIsInsert: boolean = config.buttons.filter(button => button.name == 'insert').length > 0;
    const thereIsUpdate: boolean = config.buttons.filter(button => button.name == 'update').length > 0;
    const thereIsDelete: boolean = config.buttons.filter(button => button.name == 'delete').length > 0;

    this.includeFirstColumn = thereIsUpdate || thereIsDelete;

    this.processMainResponse(data);
    this.entityConfig = config;

  }

  private processMainResponse(crudResponse: CrudResponse): void {
    this.insertFirstColumnAsSelector(crudResponse);

    this.dataSource = new MatTableDataSource(crudResponse.data);
    this.dataSource.paginator = this.paginator;

    this.crudResponse = crudResponse;
    this.columns = this.crudResponse.columns;
    this.columnsId = this.crudResponse.columns
      .filter(column => column.showOnGrid)
      .map(column => column.id);
  }

  public isArray(value: any): boolean {
    return Array.isArray(value);
  }

  public representAsString(value: any, colId: string): string {
    if (typeof value == 'string') return value;
    if (typeof value == 'number') return value.toString();
    if (this.isArray(value)) return `...(${value.length})`;
    if (this.isDate(value)) return this.dateTimeUtility.localDateToHumanString(value);
    // if (this.dateTimeUtility.isLocalDate(value)) return this.dateTimeUtility.localDateToHumanString(value);
    if (typeof value == 'boolean') return value ? 'Si' : 'No';

    if (typeof value == 'object') {
      const column: Column = this.columns.filter(c => c.id == colId)[0];
      return value[column.externalChild.name];
    }
    return '';
  }

  private isDate(value: any) {
    return typeof value == 'object' && value['chronology'] && this.dateTimeUtility.isLocalDate(value);
  }

  public clickMenuArray(element, colId): void {
    const column: Column = this.columns.filter(col => col.id == colId)[0];

    const childElementDialogParameter: ChildElementDialogParameter = new ChildElementDialogParameter();
    childElementDialogParameter.column = column;
    childElementDialogParameter.entitiesSelected = element[colId];
    childElementDialogParameter.parentName = element[column.parentName];

    const dialogRef = this.dialog.open(ChildElementsDialogComponent, { data: childElementDialogParameter });

    dialogRef.afterClosed()
      .subscribe(
        (result: ChildElementDialogParameter) => {
          if (result == undefined) return;

          element[colId] = result.entitiesSelected;

          const masterAndSlave: MasterAndSlave = new MasterAndSlave();
          masterAndSlave.masterEntityId = this.entityId;
          masterAndSlave.masterRecordId = element.id;
          masterAndSlave.slaveEntityId = column.childEntityId;
          masterAndSlave.slavesRecordIds = result.entitiesSelected.map(selected => selected.id);

          this.crudService.saveMasterAndSlave(masterAndSlave).subscribe(
            response => {
              const record = this.dataSource.data.filter(row => row.id == response.id)[0];
              record[colId] = response[colId];
            },
            problems => console.error(problems)
          );

        }
      )
  }

  private insertFirstColumnAsSelector(crudResponse: CrudResponse) {
    if (this.includeFirstColumn) {
      const selectorColumn: Column = { id: '_select_', header: 'checkbox', showOnGrid: true } as Column;
      crudResponse.columns.unshift(selectorColumn); // se agrega al inicio
      //     debugger;
      crudResponse.data.forEach(row => row['_select_'] = 'checkbox');
    }
  }

  public onClickGenericButton(btn: Button): void {
    /**
     {name: 'insert', activateWhen: 'Ever', behaviour: 'OpenModal', label: 'Agregar', icon: 'add'}
     */
    const openInDialog: boolean = this.entityConfig.columns.filter(column => column.showOnForm).length < 6;
    //    let dialogComponent: any = null;
    const isInsert: boolean = btn.name == 'insert';
    const isDelete: boolean = btn.name == 'delete';
    const isUpdate: boolean = btn.name == 'update';

    if (isInsert) {
      if (openInDialog) {
        this.openDialogForInsert(btn);
      } else {
        this.entityConfig.dynamicInfo = [];
        this.entityConfig.dynamicInfo.push(new KeyValue('tabSelected', this.tabSelected));
        this.entityConfig.dynamicInfo.push(new KeyValue('navigateBack', this.navigateBack));

        localStorage.setItem('entityConfig', JSON.stringify(this.entityConfig));
        this.router.navigate(['insert-page']);
      }
    }

    if (isDelete) {
      if (this.selection.selected.length == 0) return;
      if (confirm(`Seguro de borrar ${this.selection.selected.length} registros?`)) {
        this.processDelete();
      }
    }

    if (isUpdate) {
      const selectedCount = this.selection.selected.length;

      if (selectedCount != 1) {
        console.log('Selected: ', selectedCount);
        this.snackBar.open('Es necesario seleccionar un registro...', 'OK', { duration: 3000 });
        return;
      }
// debugger;
      const selected = this.selection.selected[0];

      for (let attrName in selected) {
        const cols = this.entityConfig.columns
          .filter(col => col.id != '_select_')
          .filter(col => col.id == attrName);
        if (cols.length == 1) cols[0].value = selected[attrName];
      }

      if (openInDialog) {
        this.openDialogForEdit(btn.label);
      } else {
        this.entityConfig.dynamicInfo = [];
        this.entityConfig.dynamicInfo.push(new KeyValue('tabSelected', this.tabSelected));
        this.entityConfig.dynamicInfo.push(new KeyValue('navigateBack', this.navigateBack));
        localStorage.setItem('entityConfig', JSON.stringify(this.entityConfig));
        this.router.navigate(['insert-page']);
      }
    }
  }

  private openDialogForEdit(title: string) {
    const dialogComponent: any = InsertDialogComponent;
    const actionDialogoParam: ActionDialogParameter = new ActionDialogParameter();
    actionDialogoParam.title = title;
    actionDialogoParam.columns = this.entityConfig.columns;
    actionDialogoParam.action = 'update';

    const dialogRef = this.dialog.open(dialogComponent, {
      // width: '40%',
      data: actionDialogoParam
    });

    dialogRef.afterClosed()
      .subscribe((result: ActionDialogParameter) => {
        console.log('ActionDialogParameter: ', result);
        if (result == undefined) return;

        this.crudService.updateRecord(this.entityId, result.columns)
          .subscribe(
            response => {
              console.log(response);
              this.loadDataAndConfig();
              /*
                            const idValue = response.id;
                            this.dataSource.data
                              .forEach(rec => {
                                if (idValue == rec.id) {
                                  for (let attrName in response) {
                                    rec[attrName] = response[attrName];
                                  }
                                }
                              });
              */
            },
            problems => console.error(problems));
      });
  }

  private openDialogForInsert(btn: Button) {
    const dialogComponent: any = InsertDialogComponent;
    const actionDialogoParam: ActionDialogParameter = new ActionDialogParameter();
    actionDialogoParam.title = btn.label;
    actionDialogoParam.columns = this.entityConfig.columns;
    actionDialogoParam.action = 'insert';

    actionDialogoParam.columns.forEach(column => column.value = null);

    const dialogRef = this.dialog.open(dialogComponent, {
      data: actionDialogoParam
    });

    dialogRef.afterClosed()
      .subscribe((result: ActionDialogParameter) => {
        console.log('ActionDialogParameter', result);

        if (result == undefined) return;
        this.crudService.insertRecord(this.entityId, result.columns.filter(column => column.showOnForm))
          .subscribe(
            response => {
              console.log(response);
              this.loadDataAndConfig();
            },
            problems => console.error(problems));
        console.log(result);
      });
  }

  private processDelete() {
    const ids: string[] = this.selection.selected.map(select => select.id);
    this.crudService.deleteRecords(this.entityId, ids)
      .subscribe(response => {
        if (response) {
          const dataSourceTemp = this.dataSource.data.filter(record => !this.include(record.id, ids));
          this.dataSource.data = dataSourceTemp;
          while (this.selection.selected.length > 0)
            this.selection.selected.pop();
          this.selection = new SelectionModel<any>(true, []);
        }
      },
        problems => console.error(problems));
  }

  private include(id: string, ids: string[]) {
    return ids.includes(id);
  }

}
