<!--
<mat-progress-bar [mode]="progressBarMode" [value]="progressBarValue"></mat-progress-bar>
-->

<h1>Registro de calificaciones</h1>
<div fxLayout="column" fxLayoutAlign="center center">
    <div fxLayoutGap="20px" fxFlexOffset="20px">
        <div fxLayout="row" fxLayoutGap="20px" fxFlexOffset="20px">

            <mat-form-field appearance="fill" style="width:250px">
                <mat-label>Curso:</mat-label>
                <mat-select [(ngModel)]="courseId" (selectionChange)="onChangeCourse()" #courseSelect>
                    <mat-option *ngFor="let course of courses" [value]="course.id">
                        {{courseService.formatLetra(course.letra)}} ({{course.especialidadNombre}})
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width:250px">
                <mat-label>Asignatura:</mat-label>
                <mat-select [(ngModel)]="subjectId" (selectionChange)="onChangeSubject()">
                    <mat-option *ngFor="let subject of subjects" [value]="subject.id">
                        {{subject.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" style="width:350px" *ngIf="this.teacher">
                <mat-label>Profesor/Usuario</mat-label>

                <input matInput value="{{teacher.fatherSurname}} {{teacher.motherSurname}}, {{teacher.names}}"
                    readonly="true">

            </mat-form-field>

            <mat-form-field appearance="fill" style="width:75px">
                <mat-label>Período</mat-label>

                <input *ngIf="!this.canBackPeriod" matInput placeholder="" value="{{period}}" readonly="true">

                <mat-select *ngIf="this.canBackPeriod" [(ngModel)]="this.period" (selectionChange)="onChangePeriod()">
                    <mat-option *ngFor="let period of this.periods" [value]="period+1">
                        {{period+1}}
                    </mat-option>
                </mat-select>


            </mat-form-field>

            <div *ngIf="dataSource.length > 0">
                Llenado vertical
                <mat-slide-toggle disabled="false" color="primary" [(ngModel)]="verticalFill"></mat-slide-toggle>
                <br>
                <button mat-raised-button color="primary" *ngIf="blockAllScores">
                    <mat-icon>lock</mat-icon>&nbsp;
                    Bloquar todas las calificaciones
                </button>

            </div>
        </div>
    </div>

    <mat-spinner *ngIf="this.showSpinner" diameter="50"></mat-spinner>

    <mat-tab-group animationDuration="150ms" [selectedIndex]="0" style="width: 70%"
        *ngIf="dataSource.length > 0 && !this.showSpinner">
        <mat-tab label="Calificaciones del período">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlexOffset="10px" fxFlexFill>

                <table mat-table [dataSource]="dataSource">
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{'retired': row.student.estado == 'RET'}"></tr>


                    <ng-container matColumnDef="num">
                        <th mat-header-cell *matHeaderCellDef class="align-center"> Num </th>
                        <td mat-cell *matCellDef="let record" class="align-center">
                            {{ record.student.numeroLista}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="studentName">
                        <th mat-header-cell *matHeaderCellDef class="align-left"> Alumno </th>
                        <td mat-cell *matCellDef="let record" class="align-left"> {{record.student.paterno + ' ' +
                            record.student.materno + ' ' + record.student.nombres }} </td>
                    </ng-container>

                    <ng-container [matColumnDef]="'score'+n" *ngFor="let n of [1,2,3,4,5,6,7,8,9,10,11,12,13]">
                        <th mat-header-cell *matHeaderCellDef class="align-center" style="width: 35px;"> {{'N'+n}} </th>
                        <td mat-cell *matCellDef="let record" class="align-center">

                            <div
                                *ngIf="record.student.estado == 'MAT' && !record.locked;then content else other_content">
                            </div>
                            <ng-template #content>
                                <mat-form-field appearance="outline" style="width:'40px';">
                                    <!--
                            <mat-label></mat-label>
                            --><input matInput [(ngModel)]="record['score'+fix(n)]" type="text"
                                        [ngClass]="{'low-score':record['score'+fix(n)] < 4}"
                                        (blur)='onBlurScore($event)' (focus)='onFocusScore($event)'
                                        (keyup)='onKeyupScore($event)' [id]="n+'#'+record.id">

                                </mat-form-field>
                            </ng-template>

                            <ng-template #other_content>
                                <div [ngClass]="{'low-score':record['score'+fix(n)] < 4}">{{record['score'+fix(n)]}}
                                </div>
                            </ng-template>

                        </td>
                    </ng-container>


                    <ng-container matColumnDef="average2dec">
                        <th mat-header-cell *matHeaderCellDef class="align-right" style="width: 35px;">Prom</th>
                        <td mat-cell *matCellDef="let record" class="align-right">
                            <div [ngClass]="{'low-score':record.average2dec >= 2 && record.average2dec < 4}">
                                {{record.average2dec }}</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="average1dec">
                        <th mat-header-cell *matHeaderCellDef class="align-right" style="width: 35px;">Final</th>
                        <td mat-cell *matCellDef="let record" class="align-right">
                            <b [ngClass]="{'low-score':record.average1dec >= 2 && record.average1dec < 4}">{{record.average1dec
                                }}</b>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lockColumn">
                        <th mat-header-cell *matHeaderCellDef class="align-right" style="width: 35px;">Bloquear</th>
                        <td mat-cell *matCellDef="let record" class="align-right">

                            <div *ngIf="record.locked;then locked_content else no_locked_content"></div>
                            <ng-template #no_locked_content>
                                <button mat-mini-fab color="primary" [disabled]="record.average2dec==0 || !record.saved"
                                    matTooltipShowDelay="250" matTooltipHideDelay="100"
                                    matTooltip="Bloquear calificaciones" (click)="onBlock(record.id)">
                                    <mat-icon>lock_open</mat-icon>
                                </button>
                            </ng-template>
                            <ng-template #locked_content>
                                <button mat-mini-fab color="accent" [disabled]="!canUnblock" matTooltipShowDelay="250"
                                    matTooltipHideDelay="100" matTooltip="Desbloquear" (click)="onUnlock(record.id)">
                                    <mat-icon>lock</mat-icon>
                                </button>
                            </ng-template>
                        </td>
                    </ng-container>



                </table>
                <div class="button-row">
                    <button mat-raised-button color="primary" *ngIf="dataSource.length > 0"
                        (click)="onSaveRatings()">Grabar
                        todas las calificaciones</button>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Examen final" *ngIf="this.canAdminExams">
            <app-exam-values [courseId]="this.courseId" [subjectId]="this.subjectId"></app-exam-values>

        </mat-tab>
    </mat-tab-group>





</div>