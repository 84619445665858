import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Moment } from 'moment';
import { ComunaService } from 'src/app/api/comuna.service';
import { ConfigService } from 'src/app/api/config.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { FileService } from 'src/app/api/file.service';
import { PermissionService } from 'src/app/api/permission.service';
import { RatingBookService } from 'src/app/api/rating-book.service';
import { StudentService } from 'src/app/api/student.service';
import { Adulto } from 'src/app/model/adulto';
import { Alumno, CustomButton } from 'src/app/model/alumno';
import { Comuna } from 'src/app/model/comuna';
import { ScoresList } from 'src/app/model/scores-list';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { HttpUtility } from 'src/app/utility/http-utility';
import { RutUtility } from 'src/app/utility/rut.utility';
import { environment } from 'src/environments/environment';
import { AnnotationService } from '../../api/annotation.service';
import { AuthorService } from '../../api/author.service';
import { CourseService } from '../../api/course.service';
import { Annotation } from '../../model/annotation';
import { ChangeCourseDialogComponent } from './change-course-dialog/change-course-dialog.component';
import { ChangeNameDialogComponent, ChangeNameDialogParams } from './change-name-dialog/change-name-dialog.component';
import { ChangeRutDialogComponent, ChangeRutDialogParams } from './change-rut-dialog/change-rut-dialog.component';
import { UnenrollDialogComponent } from './unenroll-dialog/unenroll-dialog.component';

export interface DialogData {
  name: string;
}


@Component({
  selector: 'app-alumno',
  templateUrl: './alumno.component.html',
  styleUrls: ['./alumno.component.css', '../../style/table.css']
})
export class AlumnoComponent implements OnInit {
  alumno: Alumno;
  nacimiento: Moment;
  displayedColumns: string[] = ['nombre', 'parentezco', 'telefono', 'direccion', 'actividad'];
  //adultos:Adulto[];
  adultos = new MatTableDataSource<Adulto>([]);
  photosUrlService: string = `${environment.apiUrl}/api/lichan/alumno/1.0/foto`;
  changeCourseResponse: string;
  unenrollReason: string;
  unenrollComment: string;
  showChangeCourseButton: boolean = false;
  showUnenrollStudentButton: boolean = false;
  public annotations: Annotation[] = [];
  canSeeScores: boolean = false;
  canUpdateNameOrRut: boolean = false;
  //scores: string[][] = [];
  public scoresV2: ScoresList;
  public canEditPersonalData: boolean = false;
  public townships: Comuna[] = [];
  public canSeeLicenses: boolean = false;
  public canAdminHealth: boolean = false;
  public canViewHealth: boolean = false;
  public customButtons: CustomButton[] = [];
  public excludeSubject: string[];
  public canAdminAnnotation: boolean = false;
  public canAuditAnnotation: boolean = false;

  constructor(private studentService: StudentService,
    public dialog: MatDialog,
    public courseService: CourseService,
    public authorService: AuthorService,
    public permissionService: PermissionService,
    public utilsService: HttpUtility,
    public annotationsService: AnnotationService,
    private ratingBookService: RatingBookService,
    private townshipService: ComunaService,
    private alertUtility: AlertUtility,
    private dateTimeUtility: DateTimeUtility,
    private rutUtility: RutUtility,
    private configService: ConfigService,
    private fileService: FileService) { }

  ngOnInit() {
    const studentId = sessionStorage.getItem('alumnoId');

    this.studentService.findById(studentId)
      .subscribe(
        response => {
          this.alumno = response;
          this.nacimiento = this.dateTimeUtility.stringToMoment(this.alumno.nacimiento);
          this.loadRating();
        },
        problems => console.error(problems)
      );

    this.annotationsService.listAnnotations(studentId)
      .subscribe(
        (response: Annotation[]) => {
          console.log('Anotaciones: ', response);

          this.annotations = response;
        },
        problems => console.log(problems)
      );

    this.townshipService.listAll()
      .subscribe(
        (response: Comuna[]) => this.townships = response,
        problems => console.error(problems)
      );

    this.loadPermissions();
    this.loadCustomButtons();

    this.configService.getApplicationProperty2('cl.buildersoft.scores.dont-average')
      .subscribe(
        response => {
          // console.log(response);
          this.excludeSubject = response.valor.split(',');
          // console.log('ExcludeAsArray: ', this.excludeSubject);
        },
        problems => console.error(problems)
      );
  }

  private loadCustomButtons(): void {
    this.configService.getCustomStudentButtons()
      .subscribe(
        response => this.customButtons = response,
        problems => console.error(problems)
      );
  }

  private loadRating(): void {
    // this.ratingBookService.findByStudentAndCourse(this.alumno.id, this.alumno.courseDto.id)
    //   .subscribe(
    //     response => {
    //       this.scores = response;
    //       console.log('Scores: ', response);
    //     },
    //     problems => console.error(problems)
    //   );

    this.ratingBookService.findByStudentAndCourseV2(this.alumno.id, this.alumno.courseDto.id)
      .subscribe(
        (response: ScoresList) => {
          this.scoresV2 = response;

          // console.log('ScoresV2: ', response);
        },
        problems => console.error(problems)
      );

  }

  async loadPermissions() {
    this.showChangeCourseButton = await this.permissionService.hasPermissionAsync('change-course-to-student');
    this.showUnenrollStudentButton = await this.permissionService.hasPermissionAsync('unenroll-student');
    this.canSeeScores = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.VIEW_ALL_SCORE');
    this.canEditPersonalData = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.ALLOW_EDIT_STUDENT_DATA');
    this.canSeeLicenses = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.SEE_MEDICAL_LICENSE');
    this.canUpdateNameOrRut = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.UPDATE_NAME_AND_RUT');
    this.canAdminHealth = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.ADMIN_HEALTH');
    this.canViewHealth = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.VIEW_HEALTH');
    this.canAdminAnnotation = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.MANAGE_ANNOTATIONS');
    this.canAuditAnnotation = await this.permissionService.hasPermissionAsync('STUDENT_RESUME.AUDIT_ANNOTATIONS');

    console.log(`
  this.canAdminAnnotation: ${this.canAdminAnnotation}
  this.canAuditAnnotation: ${this.canAuditAnnotation}
  `);


  }

  openUnenrollDialog(): void {
    const unenrollDialogRef = this.dialog.open(UnenrollDialogComponent, {
      width: '500px',
      data: '',
    });

    unenrollDialogRef
      .afterClosed()
      .subscribe(
        result => {
          console.log('Result: ', result);

          this.unenrollReason = localStorage.getItem('reason');
          this.unenrollComment = localStorage.getItem('comment');

          localStorage.removeItem('reason');
          localStorage.removeItem('comment');

          if (this.unenrollReason != null) {
            this.saveAndDownloadUnenrollPdf(this.unenrollReason, this.unenrollComment);
          }
        }
      );
  }

  saveAndDownloadUnenrollPdf(reason: string, comment: string): void {
    this
      .studentService
      .unenroll(this.alumno.id, this.alumno.courseDto.id, reason, comment)
      .subscribe(response => {
        console.log('suscrito', response);
        this
          .studentService
          .getUnenrollPdf(response.id)
          .subscribe((response: ArrayBuffer) => {
            //            this.download(response, 'application/pdf', `comprobante-retiro.pdf`);
            this.utilsService.downloadPdf(response, 'retiro-alumno.pdf');
          }, problems => {
            console.error(problems);
          }
          )
        //this.utilsService.downloadPdf(response, 'retiro-alumno.pdf');
      },
        problems => console.log(problems)
      );

  }

  openChangeCourseDialog(): void {
    const changeCourseDialogRef = this.dialog.open(ChangeCourseDialogComponent, {
      width: '500px',
      data: '',
    });

    changeCourseDialogRef
      .afterClosed()
      .subscribe(result => {
        this.changeCourseResponse = result;
        console.log('Respuesta: ', this.changeCourseResponse);
        if (this.changeCourseResponse && this.changeCourseResponse.length > 0) {
          this
            .studentService
            .changeCourse(this.alumno.id, this.changeCourseResponse)
            .subscribe(response => {
              console.log(response);
              this.alumno.course = this.courseService.formatLetra(response.courseDto.letra);
            }
            )
        }
      });
  }

  public openChangeRutDialog(): void {
    const changeRutDialogParams: ChangeRutDialogParams = new ChangeRutDialogParams();
    changeRutDialogParams.rut = `${this.alumno.rut}-${this.rutUtility.dv(this.alumno.rut)}`;
    changeRutDialogParams.fullName = `${this.alumno.paterno} ${this.alumno.materno}, ${this.alumno.nombres}`;

    const changeRutDialogRef = this.dialog.open(ChangeRutDialogComponent, {
      data: changeRutDialogParams
    });

    changeRutDialogRef.afterClosed()
      .subscribe(
        (result: ChangeRutDialogParams) => {
          if (result == undefined) return;
          const student: Alumno = new Alumno();
          student.id = this.alumno.id;
          student.paterno = this.alumno.paterno;
          student.materno = this.alumno.materno
          student.nombres = this.alumno.nombres;
          student.rut = this.rutUtility.extractNumber(result.rut);

          this.updateStudentOnService(student, 'Rut cambiado con éxito');

        }
      );

  }

  private updateStudentOnService(student: Alumno, messageAfterUpdate: string) {
    console.log(`Save: ${JSON.stringify(student)}`);
    this.studentService.updateNameOrRut(student)
      .subscribe(
        (response: Alumno) => {
          this.alertUtility.fastMessage(messageAfterUpdate);
          this.alumno.rut = response.rut;
          this.alumno.dv = response.dv;
          this.alumno.paterno = response.paterno;
          this.alumno.materno = response.materno;
          this.alumno.nombres = response.nombres;
        },
        problems => console.log(problems)
      );
  }

  public openChangeNameDialog(): void {
    const changeNameDialogParams: ChangeNameDialogParams = new ChangeNameDialogParams();
    changeNameDialogParams.paternal = `${this.alumno.paterno.trim()}`;
    changeNameDialogParams.maternal = `${this.alumno.materno.trim()}`;
    changeNameDialogParams.names = `${this.alumno.nombres.trim()}`;

    const changeNameDialogRef = this.dialog.open(ChangeNameDialogComponent, {
      data: changeNameDialogParams
    });

    changeNameDialogRef.afterClosed()
      .subscribe(
        (result: ChangeNameDialogParams) => {
          if (result == undefined) return;
          console.log(`OnCloseDialog: ${JSON.stringify(result)}`);

          const student: Alumno = new Alumno();
          student.id = this.alumno.id;
          student.paterno = result.paternal;
          student.materno = result.maternal;
          student.nombres = result.names;
          student.rut = this.alumno.rut;

          this.updateStudentOnService(student, 'Nombres cambiado con éxito');

        }
      );

  }

  public downloadCertificate() {
    console.log('Descargar certificado...', this.alumno.id, this.alumno.courseDto.id);

    this
      .studentService
      .getCertificate(this.alumno.id, this.alumno.courseDto.id)
      .subscribe(
        (response: ArrayBuffer) => this
          .utilsService
          .downloadPdf(response, `certificado-${this.alumno.rut}.pdf`)
      );
  }

  public filter4student(annotations: Annotation[], its4student: Boolean, isEvent: boolean): Annotation[] {
    return annotations.filter(annotation => annotation.its4Student == its4student && annotation.annotationType.event == isEvent);
  }

  public update() {
    this.alumno.nacimiento = this.dateTimeUtility.momentToString(this.nacimiento);
    this.studentService.update(this.alumno)
      .subscribe(
        response => this.alertUtility.fastMessage('Datos grabados con éxito'),
        problems => console.log(problems)
      );
  }

  public downloadDocument(index: number): void {
    this.fileService.downloadCustomFile(this.alumno.id, this.alumno.courseDto.id, index);
  }

  public filterGT0(row: string[]): string[] {
    return row.slice(1);
  }

  public filterPeriods(averages: Map<string, number>, period: number): number[] {
    const periodsAv: number[] = [];
    if (period == 1) return periodsAv;

    for (let i = 0; i < 4; i++) {
      if (averages['Pr.' + i] && i != period) {
        periodsAv.push(averages['Pr.' + i]);
      }
    }

    return periodsAv;
  }

  public getCurrentAverage(averages: Map<string, number>, period: number): number {
    return averages['Pr.' + period] == 0 ? '-' : averages['Pr.' + period];
  }
}


