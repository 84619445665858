import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StudentHealth } from "../model/student-health";
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class StudentHealthService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/student-health/1.0';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public list(studentId: string): Observable<StudentHealth[]> {
        const url = this.baseUrl + this.serviceUrl + `/list/${studentId}`;
        return this.http.get<StudentHealth[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public uploadCertificateFile(healthId: string, file: File, username: string): Observable<HttpEvent<StudentHealth>> {
        const formData = new FormData();

        const url: string = this.baseUrl + this.serviceUrl + '/upload-certificate-file';
        formData.append('file', file);
        formData.append('healthId', healthId);
        formData.append('username', username);

        console.log(url);

        const req = new HttpRequest('POST', url, formData, {
            reportProgress: false
        });

        return this.http.request(req);
    }

    public add(studentHealth: StudentHealth): Observable<StudentHealth[]> {
        const url = this.baseUrl + this.serviceUrl + `/add`;
        return this.http.post<StudentHealth[]>(url, studentHealth, { headers: this.authorService.headerWithAuthorization(null) });

    }

    public update(studentHealth: StudentHealth): Observable<StudentHealth[]> {
        const url = this.baseUrl + this.serviceUrl + `/update`;
        console.log(url);

        return this.http.post<StudentHealth[]>(url, studentHealth, { headers: this.authorService.headerWithAuthorization(null) });

    }

    public remove(studentHealthId: string): Observable<StudentHealth[]> {
        const url = this.baseUrl + this.serviceUrl + `/remove/${studentHealthId}`;
        console.log(url);

        return this.http.delete<StudentHealth[]>(url, { headers: this.authorService.headerWithAuthorization(null) });

    }


}