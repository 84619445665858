<mat-form-field appearance="fill" fxFlexFill>
  <mat-label>Modulo / Unidad</mat-label>
  <mat-select [(ngModel)]="moduleId" (selectionChange)="onChangeModule()">
    <mat-option value="">- Sin seleccion -</mat-option>
    <mat-option *ngFor="let module of modules" [value]="module.id">
      {{module.name}}
    </mat-option>
  </mat-select>
</mat-form-field>

<mat-card fxLayout="column" fxLayoutAlign="start start" fxFlexFill *ngIf="module">
  <mat-card-title>{{module.name}}</mat-card-title>
  <mat-card-subtitle>{{module.hours}} horas</mat-card-subtitle>
  <mat-card-subtitle *ngIf="module.owner!=null">Preparado por: {{module.owner.fatherSurname}} {{module.owner.motherSurname}} {{module.owner.names}}</mat-card-subtitle>
  <mat-card-content fxFlexFill>
    {{module.objective}}
  </mat-card-content>
</mat-card>

<!--
  <div *ngIf="module"> 
    {{module.hours}} horas<br>
    {{module.objective}}
</div>
-->