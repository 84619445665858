import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppComponent } from './app.component';
import { CourseSelectorComponent } from './gui-comp/course-selector/course-selector.component';
import { LevelSelectorComponent } from './gui-comp/level-selector/level-selector.component';
import { ModuleSelectorComponent } from './gui-comp/module-selector/module-selector.component';
import { SubjectSelectorComponent } from './gui-comp/subject-selector/subject-selector.component';
import { GenericCrudComponent } from './gui/adm/generic-crud/generic-crud.component';
import { InsertDialogComponent } from './gui/adm/generic-crud/insert-dialog/insert-dialog.component';
import { MainCrudComponent } from './gui/adm/main/main-crud.component';
import { MatriculaAlumnoComponent } from './gui/admission-enrollment/matricula-alumno/matricula-alumno.component';
import { EnrollerDocumentViewerComponent } from './gui/admission-enrollment/matricula-postulante/enroller-document-viewer/enroller-document-viewer.component';
import { MatriculaFormComponent } from './gui/admission-enrollment/matricula-postulante/matricula-form.component';
import { MatriculaPostulanteComponent } from './gui/admission-enrollment/matricula-postulante/matricula-postulante.component';
import { PostulanteComponent } from './gui/admission-enrollment/postulante/postulante.component';
import { AddAnnotationDialogComponent } from './gui/alumno/add-annotation-dialog/add-annotation-dialog.component';
import { AlumnoComponent } from './gui/alumno/alumno.component';
import { ApoderadosComponent } from './gui/alumno/apoderados-list/apoderados.component';
import { ChangeCourseDialogComponent } from './gui/alumno/change-course-dialog/change-course-dialog.component';
import { UnenrollDialogComponent } from './gui/alumno/unenroll-dialog/unenroll-dialog.component';
import { CalificacionesComponent } from './gui/calificaciones/calificaciones.component';
import { CourseAssistanceComponent } from './gui/courses/course-assistance/course-assistance.component';
import { StudentListComponent } from './gui/courses/student-list/student-list.component';
import { CourseGeneralPropsComponent } from './gui/courses/course-prop/course-general-props/course-general-props.component';
import { CoursePropertiesComponent } from './gui/courses/course-prop/course-properties.component';
import { CourseTeacherSubjectDialogComponent } from './gui/courses/course-prop/course-teachers/course-teacher-subject-dialog/course-teacher-subject-dialog.component';
import { CourseTeachersComponent } from './gui/courses/course-prop/course-teachers/course-teachers.component';
import { CoursesComponent } from './gui/courses/courses.component';
import { FichaComponent } from './gui/ficha/ficha.component';
import { ActivitiesDialogComponent } from './gui/lectionary/evaluation-criteria-grid/activities-dialog/activities-dialog.component';
import { DatesClassDialogComponent } from './gui/lectionary/evaluation-criteria-grid/dates-class-dialog/dates-class-dialog.component';
import { EvaluationCriteriaGridComponent } from './gui/lectionary/evaluation-criteria-grid/evaluation-criteria-grid.component';
import { EvaluationsDialogComponent } from './gui/lectionary/evaluation-criteria-grid/evaluations-dialog/evaluations-dialog.component';
import { LectionaryPlanningComponent } from './gui/lectionary/lectionary-planning/lectionary-planning.component';
import { SpecificObjectiveSelectorComponent } from './gui/lectionary/specific-objective-selector/specific-objective-selector.component';
import { WelcomeComponent } from './gui/welcome/welcome.component';
import { MaterialModule } from './material.module';
import { LoginComponent } from './user/login/login.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AuthInterceptor } from './api/auth-interceptor.service';
import { ChangePasswordDialogComponent } from './gui-comp/change-password-dialog/change-password-dialog.component';
import { ChipsSelectorComponent } from './gui-comp/chips-selector/chips-selector.component';
import { ChildElementsDialogComponent } from './gui/adm/generic-crud/child-elements-dialog/child-elements-dialog.component';
import { InsertPageComponent } from './gui/adm/generic-crud/insert-page/insert-page.component';
import { AdmissionEnrollmentComponent } from './gui/admission-enrollment/admission-enrollment.component';
import { AdminStudentsComponent } from './gui/admission-enrollment/next-courses/admin-students/admin-students.component';
import { ChangeStatusDialogComponent } from './gui/admission-enrollment/next-courses/admin-students/change-status-dialog/change-status-dialog.component';
import { NewCoursesDialogComponent } from './gui/admission-enrollment/next-courses/new-courses-dialog/new-courses-dialog.component';
import { NextCoursesComponent } from './gui/admission-enrollment/next-courses/next-courses.component';
import { VerifyPasswordDialogComponent } from './gui/calificaciones/verify-password-dialog/verify-password-dialog.component';
import { LectionaryComponent } from './gui/courses/lectionary/lectionary.component';
import { AttendanceSummaryComponent } from './gui/management/attendance-summary/attendance-summary.component';
import { ManagementComponent } from './gui/management/management.component';
import { ScheduleComponent } from './gui/schedule/schedule.component';
import { SelectCourseTimeDialogComponent } from './gui/schedule/select-course-time-dialog/select-course-time-dialog.component';
import { ValidateDetailDialogComponent } from './gui/schedule/validate-detail-dialog/validate-detail-dialog.component';
import { HomeComponent } from './user/home/home.component';
import { MessageDialogComponent } from './utility/message-dialog/message-dialog.component';
import { SendToMineducDialogComponent } from './gui/management/attendance-summary/send-to-mineduc-dialog/send-to-mineduc-dialog.component';
import { AttendanceDetailDialogComponent } from './gui/management/attendance-summary/attendance-detail-dialog/attendance-detail-dialog.component';
import { AddLicenseDialogComponent } from './gui/courses/student-list/add-license-dialog/add-license-dialog.component';
import { LicensesListComponent } from './gui/alumno/licenses-list/licenses-list.component';
import { SummaryReportComponent } from './gui/welcome/summary-report/summary-report.component';
import { PersonalityReportComponent } from './gui/courses/student-list/personality-report/personality-report.component';
import { ManagementPracticesComponent } from './gui/management-practices/management-practices.component';
import { ListCompaniesComponent } from './gui/management-practices/companies/list-companies/list-companies.component';
import { ListAllCompaniesComponent } from './gui/management-practices/companies/list-all-companies/list-all-companies.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ListPracticesComponent } from './gui/management-practices/practices/list-practices/list-practices.component';
import { AddPracticeComponent } from './gui/management-practices/practices/add-practice/add-practice.component';
import { SignaturesComponent } from './gui/management/signatures/signatures.component';
import { TeacherSelectorComponent } from './gui-comp/teacher-selector/teacher-selector.component';
import { FailureProjectionComponent } from './gui/management/failure-projection/failure-projection.component';
import { CourseDetailComponent } from './gui/management/failure-projection/course-detail/course-detail.component';
import { CoursePanelComponent } from './gui-comp/course-panel/course-panel.component';
import { ExamValuesComponent } from './gui/calificaciones/exam-values/exam-values.component';
import { AddCompanyComponent } from './gui/management-practices/companies/add-company/add-company.component';
import { EditCompanyComponent } from './gui/management-practices/companies/edit-company/edit-company.component';
import { AssociateStudentAndCourseComponent } from './gui/admission-enrollment/associate-student-and-course/associate-student-and-course.component';
import { HoraryComponent } from './gui/courses/horary/horary.component';
import { LegalGuardianDialogComponent } from './gui/alumno/apoderados-list/legal-guardian-dialog/legal-guardian-dialog.component';
import { RutInputComponent } from './gui-comp/rut-input/rut-input.component';
import { ChangeRutDialogComponent } from './gui/alumno/change-rut-dialog/change-rut-dialog.component';
import { ChangeNameDialogComponent } from './gui/alumno/change-name-dialog/change-name-dialog.component';
import { AccidentFormDialogComponent } from './gui/courses/student-list/accident-form-dialog/accident-form-dialog.component';
import { StudentPanelComponent } from './gui-comp/student-panel/student-panel.component';
import { AccidentsListComponent } from './gui/alumno/accidents-list/accidents-list.component';
import { EditAccidentDialogComponent } from './gui/alumno/accidents-list/edit-accident-dialog/edit-accident-dialog.component';
import { UploadAccidentAttentionFileDialogComponent } from './gui/alumno/accidents-list/upload-dialog/upload-accident-attention-file-dialog.component';
import { HealthComponent } from './gui/alumno/health/health.component';
import { UploadFileDialogComponent } from './utility/upload-dialog/upload-file-dialog.component';
import { StudentHealthDialogComponent } from './gui/alumno/health/student-health-dialog/student-health-dialog.component';
import { CourseListComponent } from './gui/courses/course-list/course-list.component';
import { MeetingComponent } from './gui/attorney/meeting/meeting.component';
import { AttorneyComponent } from './gui/attorney/attorney.component';
import { MeetingDetailDialogComponent } from './gui/attorney/meeting/meeting-detail-dialog/meeting-detail-dialog.component';
import { AttendanceDialogComponent } from './gui/attorney/meeting/attendance-dialog/attendance-dialog.component';
import { AgreementsDialogComponent } from './gui/attorney/meeting/agreements-dialog/agreements-dialog.component';
import { AnnotationListDialogComponent } from './gui/courses/student-list/annotation-list-dialog/annotation-list-dialog.component';
import { LectionarySummaryComponent } from './gui/management/lectionary-summary/lectionary-summary.component';
import { CustomPaginatorIntl } from './utility/custom-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { AnnotationsComponent } from './gui/alumno/annotations/annotations.component';
import { PromptDialogComponent } from './utility/prompt-dialog/prompt-dialog.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'ficha', component: FichaComponent },
  { path: 'alumno', component: AlumnoComponent },
  { path: 'matricula-form', component: MatriculaFormComponent },
  { path: 'enroller-document', component: EnrollerDocumentViewerComponent },
  { path: 'courses', component: CoursesComponent },
  { path: 'courses-prop', component: CoursePropertiesComponent },
  { path: 'courses-assistance', component: CourseAssistanceComponent },
  { path: 'student-list', component: StudentListComponent },
  { path: 'calificaciones', component: CalificacionesComponent },
  { path: 'main-crud', component: MainCrudComponent },
  { path: 'lectionary-planning', component: LectionaryPlanningComponent },
  { path: 'insert-page', component: InsertPageComponent },
  { path: 'admission-enrollment', component: AdmissionEnrollmentComponent },
  { path: 'lectionary-course', component: LectionaryComponent },
  { path: 'admin-students', component: AdminStudentsComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'management', component: ManagementComponent },
  { path: 'personality-report', component: PersonalityReportComponent },
  { path: 'practices', component: ManagementPracticesComponent },
  { path: 'list-companies', component: ListCompaniesComponent },
  { path: 'list-all-companies', component: ListAllCompaniesComponent },
  { path: 'list-practices', component: ListPracticesComponent },
  { path: 'add-practice', component: AddPracticeComponent },
  { path: 'add-company', component: AddCompanyComponent },
  { path: 'edit-company', component: EditCompanyComponent },
  { path: 'horary', component: HoraryComponent },
  { path: 'attorney', component: AttorneyComponent },
];

@NgModule({
  declarations: [
    AppComponent, LoginComponent, WelcomeComponent, FichaComponent, AlumnoComponent,
    ApoderadosComponent, PostulanteComponent, MatriculaPostulanteComponent, MatriculaFormComponent,
    EnrollerDocumentViewerComponent, MatriculaAlumnoComponent, CoursesComponent, CoursePropertiesComponent,
    CourseAssistanceComponent, StudentListComponent, ChangeCourseDialogComponent, UnenrollDialogComponent,
    CalificacionesComponent, /**UsersComponent,*/ AddAnnotationDialogComponent, GenericCrudComponent, MainCrudComponent,
    SubjectSelectorComponent, CourseGeneralPropsComponent, CourseTeachersComponent, CourseTeacherSubjectDialogComponent,
    InsertDialogComponent, LectionaryPlanningComponent, CourseSelectorComponent, ModuleSelectorComponent, SpecificObjectiveSelectorComponent,
    EvaluationCriteriaGridComponent, DatesClassDialogComponent, LevelSelectorComponent, ActivitiesDialogComponent, EvaluationsDialogComponent,
    ChipsSelectorComponent, VerifyPasswordDialogComponent, ChangePasswordDialogComponent, ChildElementsDialogComponent, InsertPageComponent,
    AdmissionEnrollmentComponent, NextCoursesComponent, NewCoursesDialogComponent, LectionaryComponent, AdminStudentsComponent,
    ChangeStatusDialogComponent, ScheduleComponent, SelectCourseTimeDialogComponent, ValidateDetailDialogComponent, AttendanceSummaryComponent,
    ManagementComponent, HomeComponent, MessageDialogComponent, SendToMineducDialogComponent, AttendanceDetailDialogComponent,
    AddLicenseDialogComponent, LicensesListComponent, SummaryReportComponent, PersonalityReportComponent, ManagementPracticesComponent,
    SignaturesComponent, TeacherSelectorComponent, FailureProjectionComponent, CourseDetailComponent, CoursePanelComponent,
    ExamValuesComponent, AssociateStudentAndCourseComponent, HoraryComponent, LegalGuardianDialogComponent, RutInputComponent,
    ChangeRutDialogComponent, ChangeNameDialogComponent, AccidentFormDialogComponent, StudentPanelComponent, AccidentsListComponent,
    EditAccidentDialogComponent, UploadAccidentAttentionFileDialogComponent, HealthComponent, UploadFileDialogComponent, StudentHealthDialogComponent,
    CourseListComponent, MeetingComponent, AttorneyComponent, MeetingDetailDialogComponent, AttendanceDialogComponent,
    AgreementsDialogComponent, AnnotationListDialogComponent, LectionarySummaryComponent, AnnotationsComponent, PromptDialogComponent

  ],
  imports: [
    BrowserModule, BrowserAnimationsModule, MaterialModule, HttpClientModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    FormsModule, PdfViewerModule, MatMomentDateModule, ReactiveFormsModule, MatGridListModule, MatIconModule, MatButtonModule, MatMenuModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
