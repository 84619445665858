import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthorService } from 'src/app/api/author.service';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';
import { AnnotationDialogDataParam } from '../../alumno/add-annotation-dialog/annotation-dialog-data';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from 'src/app/api/permission.service';
import { ConfigService } from 'src/app/api/config.service';
import { DownloadUtility } from 'src/app/utility/download.utility';
import { PersonalityReportService } from 'src/app/api/personality-report.service';
import { AddAnnotationDialogComponent } from '../../alumno/add-annotation-dialog/add-annotation-dialog.component';
import { AlertUtility } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css', '../../../style/table.css']
})
export class CourseListComponent implements OnInit {
  @Input() readonly: boolean = true;
  @Input() year: number = 0;
  @Input() showAllCourses: boolean = false;

  dataSource: MatTableDataSource<Course>;
  canEditProperties: boolean = true;
  canDownloadSummary: boolean = false;
  canDownloadPersonalityReport: boolean = false;

  courses: Course[] = [];
  displayedColumns: string[] = ['letter', 'specialty', 'teacher', 'actions'];

  constructor(public courseService: CourseService,
    public auth: AuthorService,
    private router: Router,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private configService: ConfigService,
    private downloadUtility: DownloadUtility,
    private personalityReport: PersonalityReportService,
    private alert: AlertUtility,
  ) { }

  ngOnInit(): void {
    if (this.showAllCourses) {
      this.courseService.listByYearExcludingUser(this.year)
        .subscribe(
          response =>
            this.refreshCourses(response),
          problems => console.error(problems)
        );
    } else {
      this.courseService.listByYear(this.year)
        .subscribe(
          response =>
            this.refreshCourses(response),
          problems => console.error(problems)
        );
    }
    this.loadPermissions();
  }

  private refreshCourses(courses: Course[]) {
    this.courses = courses;
    this.dataSource = new MatTableDataSource(this.courses);
  }

  private async loadPermissions() {
    this
      .permissionService
      .hasPermissionAsync('cl.ges.permission.courses.properties')
      .then(response => {
        this.canEditProperties = response;
        console.log(response)
      });

    this.canDownloadSummary = await this.permissionService.hasPermissionAsync('COURSES.DOWNLOAD_SUMMARY');
    this.canDownloadPersonalityReport = await this.permissionService.hasPermissionAsync('COURSES.DOWNLOAD-PERSONALITY-REPORT');
  }

  alumnosOnClick(id: string): void {
    console.log(id);
    localStorage.setItem('courseId', id);
    localStorage.setItem('readonly', `${this.readonly}`);
    this.router.navigate(['student-list']);
  }

  asistenciaOnClick(id: string) {
    localStorage.setItem('courseId', id);
    localStorage.setItem('readonly', `${this.readonly}`);
    this.router.navigate(['courses-assistance']);
  }

  propertiesOnClick(id: string): void {
    localStorage.setItem('courseId', id);
    this.router.navigate(['courses-prop']);
  }

  public doLectionary(id: string): void {
    localStorage.setItem('courseId', id);
    localStorage.setItem('readonly', `${this.readonly}`);
    this.router.navigate(['lectionary-course']);
  }

  doAddAnnotation(courseId): void {
    const annotationDialogDataParam: AnnotationDialogDataParam = new AnnotationDialogDataParam();
    annotationDialogDataParam.courseId = courseId;
    annotationDialogDataParam.its4Student = false;
    annotationDialogDataParam.studentId = null;

    const annotationDialogRef = this.dialog.open(AddAnnotationDialogComponent, {
      data: annotationDialogDataParam,
    });
  }

  public showHoraryClick(courseId: string): void {
    localStorage.setItem('courseId', courseId);
    this.router.navigate(['horary']);
  }

  public downloadSummaryOnClick(id: string) {
    this
      .courseService
      .downloadSummaryAsPdf(id)
      .subscribe((response: ArrayBuffer) => this.downloadUtility.download(response,
        'application/pdf',
        `resumen-curso.pdf`),
        problems => {
          this.alert.fastMessage('Error al intentar obtener resumen del curso');
          console.error(problems);
        }
      );
  }

  public downloadPersonalityReportOnClick(id: string) {
    this
      .personalityReport
      .downloadCourseReportAsPdf(id)
      .subscribe(
        (response: ArrayBuffer) => this.downloadUtility.download(response, 'application/pdf', `informes-personalidad.pdf`),
        problems => {
          this.alert.fastMessage('Error al intentar obtener informes de personalidad');
          console.error(problems);
        }
      );
  }
}
