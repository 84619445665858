import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { CommonModule } from '@angular/common'
import { Router } from "@angular/router";
import {CdkTextareaAutosize, TextFieldModule} from '@angular/cdk/text-field';
import { take } from "rxjs/operators";
import { Practice } from "src/app/model/practice";
import { PracticeDelete } from "src/app/model/practiceDelete";
import { PracticeService } from "src/app/api/practice.service";
import { MatSnackBar,MatSnackBarModule } from "@angular/material/snack-bar";


@Component({
  selector: 'dialog-delete-practice',
  templateUrl: './dialog-delete-practice.component.html',
  standalone: true,
  imports: [MatSnackBarModule,MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatSelectModule,ReactiveFormsModule,CommonModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class DialogDeletePractice implements OnInit {
selected: any;
tipos: any[] = [
  {value: 'Renuncia del Alumno', viewValue: 'Renuncia del Alumno'},
  {value: 'Cambio de Empresa', viewValue: 'Cambio de Empresa'},
  {value: 'Alumno Retirado', viewValue: 'Alumno Retirado'},
  {value: 'Despido del alumno', viewValue: 'Despido del alumno'},
];
observation: any;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogDeletePractice>,
    private _ngZone: NgZone,
    private practiceService: PracticeService,
    private snakBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: Practice,
  ) {

  }

  ngOnInit(){
  }

  ngAfterViewInit(){
    setTimeout(async () => {
    },500);

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSelectCourseClick(): Promise<void> {
   await this.deletePractice()
  }

  trackedValue(index, item) {
    return item.value;
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  async deletePractice(){
    const practice = new PracticeDelete();
    practice.orden = this.data.id,
    practice.motivo = this.observation;
    practice.clasi = this.selected;
    console.log(practice);
    this.dialogRef.close();

   await this.practiceService.delPractice(practice).subscribe(
      response => {
        this.snakBar.open("Práctica eliminada correctamente!","OK");
        setTimeout(async () => {
          location.reload();
        },1000);

      },
      problems => {
        this.snakBar.open("La Práctica no ha sido eliminada correctamente","OK");
        console.log(problems);

      }
    );
  }
}

