import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Alumno } from "../model/alumno";
import { StopEnrolling } from '../model/stop-enrolling';
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class StudentService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl1_0: string = '/api/lichan/alumno/1.0';
    private serviceUrl1_1: string = '/api/lichan/alumno/1.1';
    private serviceUrl1_2: string = '/api/lichan/alumno/1.2';
    private enrollNewYearUrl1_0: string = '/enroll-new-year';
    private findByRutOrLastnameAndYearUrl1_0: string = '/find-by-rut-or-lastname-and-year';
    private findByRutOrLastnameForEnrollUrl1_0: string = '/find-by-rut-or-lastname-for-enroll';
    private changeCourseUrl1_0: string = '/change-course';
    private getCertificateUrl1_0: string = '/certificate';
    private unenrollUrl1_0: string = '/unenroll';
    private unenrollDocumentUrl1_0: string = '/unenroll-document';
    private dontContinueUrl1_0: string = '/dont_continue';
    private reprobateUrl1_0: string = '/reprobate';
    private stopEnrollingUrl1_0: string = '/stop-enrolling';
    private updateUrl1_0: string = '/update';
    private findByRutUrl: string = '/find-by-rut';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public findByRutOrName(rutOrName: string, currentYear: boolean): Observable<Alumno[]> {
        const url = this.baseUrl + this.serviceUrl1_0 + `/findByRutOrName/${rutOrName}/${currentYear}`;
        return this.http.get<Alumno[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByRutOrNameLastYear(rutOrName: string, currentYear: number, level: number): Observable<Alumno[]> {
        const url = this.baseUrl + this.serviceUrl1_0 + `/find-by-rut-or-lastname-and-year-and-level/${rutOrName}/${currentYear}/${level}`;
        return this.http.get<Alumno[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByRutOrLastnameAndYear(rutOrName: string, year: number): Observable<Alumno[]> {
        const url = this.baseUrl + this.serviceUrl1_0 + `${this.findByRutOrLastnameAndYearUrl1_0}/${rutOrName}/${year}`;
        return this.http.get<Alumno[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByRutOrLastnameForEnroll(rutOrName: string): Observable<Alumno[]> {
        const url = this.baseUrl + this.serviceUrl1_0 + `${this.findByRutOrLastnameForEnrollUrl1_0}/${rutOrName}`;
        return this.http.get<Alumno[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findById(id: string): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_1 + `/${id}/true`;
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findById2(id: string, fullInfo: boolean): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_1 + `/${id}/${fullInfo}`;
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async findByIdAsync(id: string): Promise<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_1 + `/${id}/true`;
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public async findByIdAsync2(id: string, fullInfo: boolean): Promise<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_1 + `/${id}/${fullInfo}`;
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public findByIdAndYear(id: string, year: number): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_2 + `/${id}/true/${year}`;
        console.log(url);
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public enrollNewYear(alumno: Alumno): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.enrollNewYearUrl1_0;
        console.log('calling to ', url);
        return this.http.post<Alumno>(url, alumno, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public changeCourse(studentId: String, newCourseId: string): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.changeCourseUrl1_0 + `/${studentId}/${newCourseId}`;
        return this.http.post<Alumno>(url, {}, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getCertificate(id: string, course: string): Observable<any> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.getCertificateUrl1_0 + `/${id}/${course}`;
        console.log(url);

        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION, this.authorService.BEARER + this.authorService.token)
        });
    }

    public unenroll(studentId: string, courseId: string, reason: string, comment: string): Observable<any> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.unenrollUrl1_0;
        console.log(url);

        return this.http.post<any>(url, { studentId: studentId, courseId: courseId, reason: reason, comment: comment },
            { headers: this.authorService.headerWithAuthorization(null) });

    }

    public getUnenrollPdf(id: string) {
        const url = this.baseUrl + this.serviceUrl1_0 + this.unenrollDocumentUrl1_0 + `/${id}`;
        console.log(url);

        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }

    public dontContinue(studentId: string, courseId: string): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.dontContinueUrl1_0 + `/${studentId}/${courseId}`;
        console.log(url);

        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public reprobate(studentId: string, notCourseId: string, newCourseId: string): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.reprobateUrl1_0 + `/${studentId}/${notCourseId}/${newCourseId}`;
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public stopEnrolling(stopEnrolling: StopEnrolling): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.stopEnrollingUrl1_0;
        return this.http.post<Alumno>(url, stopEnrolling, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public update(student: Alumno): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + this.updateUrl1_0;
        return this.http.post<Alumno>(url, student, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByRut(rut: string): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + `${this.findByRutUrl}/${rut}`;
        return this.http.get<Alumno>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public updateNameOrRut(student: Alumno): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl1_0 + `/update-name-or-rut`;
        return this.http.post<Alumno>(url, student, { headers: this.authorService.headerWithAuthorization(null) });
    }

}


