<table mat-table [dataSource]="datasource" fxFlexFill *ngIf="datasource">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="accidentDate">
        <th mat-header-cell *matHeaderCellDef class="align-left">Fecha Accidente </th>
        <td mat-cell *matCellDef="let element" class="align-left">
            {{this.dateTimeUtility.dateToHumanString(this.dateTimeUtility.stringToDate(element.accidentDate))}}
        </td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="align-left">Reseña</th>
        <td mat-cell *matCellDef="let element" class="align-left">{{element.description}}
        </td>
    </ng-container>

    <ng-container matColumnDef="healthPlace">
        <th mat-header-cell *matHeaderCellDef class="align-left">Centro Atención</th>
        <td mat-cell *matCellDef="let element" class="align-left">{{element.healthPlace}}
        </td>
    </ng-container>
    <ng-container matColumnDef="escorts">
        <th mat-header-cell *matHeaderCellDef class="align-left">Acompañante</th>
        <td mat-cell *matCellDef="let element" class="align-left">{{element.escortsName}}
            ({{element.escortsRelationship}})
        </td>
    </ng-container>

    <ng-container matColumnDef="attentionDate">
        <th mat-header-cell *matHeaderCellDef class="align-left">Fecha Atención </th>
        <td mat-cell *matCellDef="let element" class="align-left">{{element.attentionDate}}
        </td>
    </ng-container>
    <ng-container matColumnDef="returnsDate">
        <th mat-header-cell *matHeaderCellDef class="align-left">Fecha Retorno </th>
        <td mat-cell *matCellDef="let element" class="align-left">{{element.returnsDate}}
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="align-left">Acciones </th>
        <td mat-cell *matCellDef="let element" class="align-left">
            <button mat-icon-button color="primary" matTooltip="Editar información"
                (click)="editInformation(element.id)">
                <mat-icon>edit</mat-icon>
            </button>

            <button mat-icon-button color="primary" matTooltip="Subir documento de atención"
                (click)="uploadFile(element.id)" *ngIf="element.fileHandler == null">
                <mat-icon>upload</mat-icon>
            </button>

            <button mat-icon-button color="primary" matTooltip="Descargar documento de atención"
                (click)="downloadAttentionFile(element.id)" *ngIf="element.fileHandler != null">
                <mat-icon>download</mat-icon>
            </button>

        </td>
    </ng-container>

</table>