import { HttpEvent } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthorService } from 'src/app/api/author.service';
import { FileService } from 'src/app/api/file.service';
import { StudentHealthService } from 'src/app/api/student-health.service';
import { Alumno } from 'src/app/model/alumno';
import { StudentHealth } from 'src/app/model/student-health';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { UploadFileDialogDataParam } from 'src/app/utility/upload-dialog/upload-file-dialog.component';
import { StudentHealthDialogComponent, StudentHealthDialogParams } from './student-health-dialog/student-health-dialog.component';

@Component({
  selector: 'health-list',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css', '../../../style/table.css']
})
export class HealthComponent implements OnInit {
  @Input() student: Alumno = null;
  @Input() canAdminHealth: boolean;
  @Input() canViewHealth: boolean;

  public datasource: MatTableDataSource<StudentHealth>;
  public displayedColumns: string[] = ['type', 'detail', 'treatment', 'document'];


  constructor(private studentHealthService: StudentHealthService,
    private alertUtility: AlertUtility,
    private authorService: AuthorService,
    private fileService: FileService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.refresh(this.student.id);
    if (this.canAdminHealth) {
      this.displayedColumns.push('action');
    }
  }

  private refresh(studentId: string) {
    this.studentHealthService.list(studentId)
      .subscribe(
        response => {
          this.datasource = new MatTableDataSource(response);
        },
        problems => console.error(problems)
      );
  }

  public resolveState(state: string): string {
    switch (state) {
      case 'DISEASE':
        return 'Enfermedad';
      case 'DISABILITY':
        return 'Discapacidad';
      case 'CONDITION':
        return 'Condición';
      default:
        return '';
    }
  }

  public downloadFile(fileHandlerId: string) {
    this.fileService.download(fileHandlerId);
  }

  public async uploadFile(healthId: string) {
    const uploadFile: UploadFileDialogDataParam = await this.alertUtility.uploadFileDialog('Documento Certificado', 'Este documento certifica que lo declarado está avalado por un médico');

    if (uploadFile.file == null) return;

    this.studentHealthService.uploadCertificateFile(healthId, uploadFile.file, this.authorService.user.username)
      .subscribe(
        (response: HttpEvent<StudentHealth>) => {
          this.alertUtility.fastMessage(`Archivo '${uploadFile.fileName}' cargado con éxito`);
          this.refresh(this.student.id);
        },
        problems => console.error(problems)
      );
  }

  public openStudentHealthDialog(healthId: string) {
    const dialogParams: StudentHealthDialogParams = new StudentHealthDialogParams();
    dialogParams.student = this.student;
    dialogParams.studentHealth = new StudentHealth();
    if (healthId == null) {
      dialogParams.studentHealth.healthState = null;
      dialogParams.studentHealth.detail = '';
      dialogParams.studentHealth.treatment = '';
    } else {
      const studentHealthTemp = this.findRecord(healthId);
      dialogParams.studentHealth.healthState = studentHealthTemp.healthState;
      dialogParams.studentHealth.detail = studentHealthTemp.detail;
      dialogParams.studentHealth.treatment = studentHealthTemp.treatment;
      dialogParams.studentHealth.id = healthId;
    }

    const dialogRef = this.dialog.open(StudentHealthDialogComponent, { data: dialogParams });

    dialogRef.afterClosed()
      .subscribe(
        (response: StudentHealthDialogParams) => {
          console.log(response);
          if (response == null || response.studentHealth.healthState == null) return;

          if (healthId == null)
            this.saveNewHealth(response);
          else
            this.updateHealth(response);
        }
      );
  }

  public findRecord(healthId: string): StudentHealth {
    return this.datasource.data.filter(record => record.id == healthId)[0];
  }

  private saveNewHealth(response: StudentHealthDialogParams) {
    const studentHealth: StudentHealth = new StudentHealth();
    studentHealth.student = new Alumno();
    studentHealth.detail = response.studentHealth.detail;
    studentHealth.healthState = response.studentHealth.healthState;
    studentHealth.student.id = response.student.id;
    studentHealth.treatment = response.studentHealth.treatment;

    this.studentHealthService.add(studentHealth)
      .subscribe(
        response => this.refresh(studentHealth.student.id),
        problems => console.error(problems)
      );
  }


  public confirmUpdateHealth(healthId: string) {
    // const dialogParams: StudentHealthDialogParams = new StudentHealthDialogParams();
    // dialogParams.student = this.student;
    // dialogParams.studentHealth = new StudentHealth();
    // dialogParams.studentHealth.healthState = null;
    // dialogParams.studentHealth.detail = '';
    // dialogParams.studentHealth.treatment = '';

    this.openStudentHealthDialog(healthId);

  }

  private updateHealth(response: StudentHealthDialogParams) {
    const studentHealth: StudentHealth = new StudentHealth();
    studentHealth.student = new Alumno();
    studentHealth.detail = response.studentHealth.detail;
    studentHealth.healthState = response.studentHealth.healthState;
    studentHealth.student.id = response.student.id;
    studentHealth.treatment = response.studentHealth.treatment;
    studentHealth.id = response.studentHealth.id;

    this.studentHealthService.update(studentHealth)
      .subscribe(
        response => this.refresh(studentHealth.student.id),
        problems => console.error(problems)
      );
  }

  public async confirmRemove(id: string) {
    const studentHealth: StudentHealth = this.findRecord(id);
    const response: boolean = await this.alertUtility.confirmMessage('Seguro de eliminar la '
      + this.resolveState(studentHealth.healthState));

    console.log(response);

    if (response) {
      this.studentHealthService.remove(id)
        .subscribe(
          response => this.refresh(studentHealth.student.id),
          problems => console.error(problems)
        );
    }

  }

}
