import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/api/config.service';
import { PermissionService } from 'src/app/api/permission.service';

@Component({
  selector: 'app-admission-enrollment',
  templateUrl: './admission-enrollment.component.html',
  styleUrls: ['./admission-enrollment.component.css']
})
export class AdmissionEnrollmentComponent implements OnInit {
  public nextYear: number = 0;
  public selectedTab = 0;
  public canAssociateStudentAndCourse = false;
  // ADMISION.ASSOCIATE_STUDENT_AND_COURSE


  constructor(private configService: ConfigService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.selectedTab = +localStorage.getItem('tabSelected');
    this.configService.findByKey('inscripcion_actual')
      .subscribe(response => {
        // console.log(response);
        this.nextYear = +response.valor;
      });
    localStorage.removeItem('tabSelected');

    this.loadPermissions();

  }

  private async loadPermissions(): Promise<void> {
    this.canAssociateStudentAndCourse = await this.permissionService.hasPermissionAsync('ADMISION.ASSOCIATE_STUDENT_AND_COURSE');
    // this.selectedTab=6;
  }


}
