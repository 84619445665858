import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'src/app/api/config.service';
import { LevelService } from 'src/app/api/level.service';
import { Level } from 'src/app/model/level';

@Component({
  selector: 'app-new-courses-dialog',
  templateUrl: './new-courses-dialog.component.html',
  styleUrls: ['./new-courses-dialog.component.css']
})
export class NewCoursesDialogComponent implements OnInit {
  public nextYear: number = 0;
  public currentYear: number = 0;
  public levels: Level[] = [];
  // public newLevelId: string = '';
  public prevLevel: Level;

  // public leters: string = '';
  public isFirstLevel: boolean;

  constructor(private configService: ConfigService,
    private levelService: LevelService,
    @Inject(MAT_DIALOG_DATA) public dialogData: NewCoursesDialogParameter) { }

  ngOnInit(): void {
    this
      .configService
      .getCurrentYear()
      .subscribe(response => {
        this.currentYear = response;
      });

    this
      .configService
      .findByKey('inscripcion_actual')
      .subscribe(response => {
        this.nextYear = +response.valor;
      });

    this
      .configService
      .findByKey('nuevos_cursos')
      .subscribe(response => {
        this.dialogData.letters = response.valor
      });

    this
      .levelService
      .listAll()
      .subscribe(response => {
        this.levels = response;
      });
  }

  public changeLevel(e): void {
    const currentLevelId = e.value;
    this.isFirstLevel = currentLevelId == this.levels[0].id;
    // debugger;
    if (!this.isFirstLevel) {
      let i = 0;
      for (i = 0; i < this.levels.length; i++) {
        if (this.levels[i].id == currentLevelId) {
          i--;
          break;
        }
      }
      this.prevLevel = this.levels[i];
    }
  }
}

export class NewCoursesDialogParameter {
  newLevelId: string;
  prevLevelId: string;
  letters: string;
  includeStudents: boolean = true;
}