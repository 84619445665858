import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Average } from '../model/average';
import { Exams } from "../model/exams";
import { Rating } from '../model/rating';
import { RatingBook } from '../model/rating-book';
import { AuthorService } from './author.service';
import { ScoresList } from "../model/scores-list";

@Injectable({
    providedIn: 'root'
})

export class RatingBookService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/calificaciones';

    private findByCourseAndSubject1_0Url: string = '/1.0/find-by-course-and-subject';
    private findByCourseAndSubject1_1Url: string = '/1.1/find-by-course-and-subject';
    private save1_0Url: string = '/1.0/save';
    private average1_0Url: string = '/1.0/average';
    private average1_1Url: string = '/1.1/average';
    private lockRatingUrl1_0: string = '/1.0/lock-rating';
    private unlockRatingUrl1_0: string = '/1.0/unlock-rating';
    private findByStudentAndCourseUrl: string = '/1.0/find-by-student-and-course'
    private findExmasByCourseAndSubjectUrl: string = '/1.0/find-exmas'
    private saveExams1_0Url: string = '/1.0/save-exams';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public findByCourseAndSubject(courseId: string, subjectId: string): Observable<RatingBook> {
        const url = this.baseUrl + this.serviceUrl + `${this.findByCourseAndSubject1_0Url}/${courseId}/${subjectId}`;
        return this.http.get<RatingBook>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByCourseAndSubject_V2(courseId: string, subjectId: string, period: number): Observable<RatingBook> {
        const url = this.baseUrl + this.serviceUrl + `${this.findByCourseAndSubject1_1Url}/${courseId}/${subjectId}/${period}`;
        return this.http.get<RatingBook>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public save(ratingBook: RatingBook): Observable<RatingBook> {
        const url = this.baseUrl + this.serviceUrl + this.save1_0Url;
        return this.http.post<RatingBook>(url, ratingBook, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public average1_0(values: number[]): Observable<number> {
        const url = this.baseUrl + this.serviceUrl + this.average1_0Url;
        return this.http.post<number>(url, values, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public average1_1(average: Average): Observable<Average> {
        const url = this.baseUrl + this.serviceUrl + this.average1_1Url;
        return this.http.post<Average>(url, average, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public lockRaing1_0(rating: Rating): Observable<Rating> {
        const url = this.baseUrl + this.serviceUrl + this.lockRatingUrl1_0;
        return this.http.post<Rating>(url, rating, { headers: this.authorService.headerWithAuthorization(null) });
    }
    public unlockRaing1_0(ratingId: string): Observable<Rating> {
        const url = this.baseUrl + this.serviceUrl + this.unlockRatingUrl1_0 + `/${ratingId}`;
        return this.http.get<Rating>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByStudentAndCourse(studentId: string, courseId: string): Observable<string[][]> {
        const url = this.baseUrl + this.serviceUrl + `${this.findByStudentAndCourseUrl}/${studentId}/${courseId}`;
        return this.http.get<string[][]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByStudentAndCourseV2(studentId: string, courseId: string): Observable<ScoresList> {
        const url = this.baseUrl + this.serviceUrl + `/v2/find-by-student-and-course/${studentId}/${courseId}`;
        return this.http.get<ScoresList>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findExmasByCourseAndSubject(courseId: string, subjectId: string): Observable<Exams> {
        const url = this.baseUrl + this.serviceUrl + `${this.findExmasByCourseAndSubjectUrl}/${courseId}/${subjectId}`;
        return this.http.get<Exams>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public saveExams(exams: Exams): Observable<Exams> {
        const url = this.baseUrl + this.serviceUrl + this.saveExams1_0Url;
        return this.http.post<Exams>(url, exams, { headers: this.authorService.headerWithAuthorization(null) });
    }


}