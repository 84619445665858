<h2>Horario del curso</h2>
<com-course-panel [course]="this.course" *ngIf="this.course"></com-course-panel>

<table [border]="borderSize" fxFlexFill *ngIf="calendarRows">
    <tr>
        <td width="10%" class="align-center"><mat-card>Hora</mat-card></td>
        <td width="15%"><mat-card><mat-card-content> Lunes</mat-card-content></mat-card></td>
        <td width="15%"><mat-card><mat-card-content> Martes</mat-card-content></mat-card></td>
        <td width="15%"><mat-card><mat-card-content> Miércoles</mat-card-content></mat-card></td>
        <td width="15%"><mat-card><mat-card-content> Jueves</mat-card-content></mat-card></td>
        <td width="15%"><mat-card><mat-card-content> Viernes</mat-card-content></mat-card></td>
        <td width="15%"><mat-card><mat-card-content> Sábado</mat-card-content></mat-card></td>
    </tr>
    <tr *ngFor="let calendarRow of calendarRows">
        <td class="align-center" valign="top"><mat-card><mat-card-content>{{formatHour(calendarRow.hour.start)}} -
                    {{formatHour(calendarRow.hour.finish)}}</mat-card-content></mat-card></td>


        <td valign="top"><mat-card [matTooltip]="calendarRow.monday?.subjectName"><mat-card-content>
                    {{getCourseLeterAndSignatureName(calendarRow.monday)}}</mat-card-content></mat-card> </td>


        <td valign="top"><mat-card [matTooltip]="calendarRow.tuesday?.subjectName"><mat-card-content>
                    {{getCourseLeterAndSignatureName(calendarRow.tuesday)}}</mat-card-content></mat-card></td>


        <td valign="top"><mat-card [matTooltip]="calendarRow.wednesday?.subjectName"><mat-card-content>
                    {{getCourseLeterAndSignatureName(calendarRow.wednesday)}}</mat-card-content></mat-card></td>


        <td valign="top"><mat-card [matTooltip]="calendarRow.thursday?.subjectName"><mat-card-content>
                    {{getCourseLeterAndSignatureName(calendarRow.thursday)}}</mat-card-content></mat-card></td>


        <td valign="top"><mat-card [matTooltip]="calendarRow.friday?.subjectName"><mat-card-content>
                    {{getCourseLeterAndSignatureName(calendarRow.friday)}}</mat-card-content></mat-card></td>


        <td valign="top"><mat-card [matTooltip]="calendarRow.saturday?.subjectName"><mat-card-content>
                    {{getCourseLeterAndSignatureName(calendarRow.saturday)}}</mat-card-content></mat-card></td>
    </tr>
</table>

<table fxFlexFill>
    <tr>
        <td>&nbsp;</td>
    </tr>
    <tr>
        <td>&nbsp;</td>
        <td *ngIf="false"> <button mat-raised-button color="primary"><mat-icon>download</mat-icon>Imprimir</button></td>
        <td>&nbsp;</td>
        <td> <button mat-raised-button (click)="goBack()"><mat-icon>arrow_back</mat-icon>Volver</button></td>
        <td>&nbsp;</td>
    </tr>
</table>