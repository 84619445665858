import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../alumno.component';

@Component({
  selector: 'app-unenroll-dialog',
  templateUrl: './unenroll-dialog.component.html',
  styleUrls: ['./unenroll-dialog.component.css']
})
export class UnenrollDialogComponent implements OnInit {
  //  userComment : string = '';
  reason: string = '';
  comment: string = '';


  constructor(
    public dialogRef: MatDialogRef<UnenrollDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public unenrollResponse: DialogData) { }

  ngOnInit(): void {
    this.loadInitData();

  }

  loadInitData() {
    //    console.log('unenrollResponse', this.unenrollResponse);
  }

  onNoClick(): void {
    localStorage.removeItem('reason');
    localStorage.removeItem('comment');
    this.dialogRef.close();
  }

  onAccept() {
    localStorage.setItem('reason', this.reason);
    localStorage.setItem('comment',this.comment);
    this.dialogRef.close();
  }
}
