import { Component, Inject, OnInit } from '@angular/core';
import { Agreement } from '../model/agreement';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CourseService } from 'src/app/api/course.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-agreements-dialog',
  templateUrl: './agreements-dialog.component.html',
  styleUrls: ['./agreements-dialog.component.css',
    '../../../../style/table.css'
  ]
})
export class AgreementsDialogComponent implements OnInit {
  public datasource: MatTableDataSource<Agreement>;
  public displayedColumns: string[] = ['num', 'detail', 'action'];

  constructor(public dialogRef: MatDialogRef<AgreementsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AgreementDialogParams,
    public courseService: CourseService,
    public dtUtility: DateTimeUtility) { }

  ngOnInit(): void {
    this.datasource = new MatTableDataSource(this.dialogData.agreements);

  }

  public addAgreement() {
    const newRow: Agreement = { id: uuidv4(), detail: '', meetingId: this.dialogData.meetingId, order: this.datasource.data.length + 1 };
    this.datasource.data = [...this.datasource.data, newRow];
  }

  public removeAgreement(id: string): void {
    const dataTemp: Agreement[] = this.datasource.data.filter(agreement => agreement.id != id);
    this.datasource.data = [];
    this.datasource.data = dataTemp;
  }

  public beforeSave(): void {
    this.dialogData.agreements = this.datasource.data;
    this.dialogRef.close(this.dialogData);
  }

}

export class AgreementDialogParams {
  agreements: Agreement[];
  meetingDate: string;
  courseLeter: string;
  meetingId: string;
  viewAsManager:boolean;
}