import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/api/user.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'com-teacher-selector',
  templateUrl: './teacher-selector.component.html',
  styleUrls: ['./teacher-selector.component.css']
})
export class TeacherSelectorComponent implements OnInit {
  @Output() public teacherChange = new EventEmitter<User>();

  public teacherId: string = '';
  public teachers: User[] = [];

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.userService.listTeachers()
      .subscribe(
        (response: User[]) => this.teachers = response,
        problems => console.error(problems)
      );
  }

  public onChangeSelection() {
    const selected = this.teachers.filter(teacher => teacher.id == this.teacherId);
    this.teacherChange.emit(selected.length > 0 ? selected[0] : null);
  }

}
