import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { Level } from "../model/level";
import { environment } from "src/environments/environment";
import { License } from "../model/license";

@Injectable({
    providedIn: 'root'
})

export class LicenseService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/license/1.0';
    private createNewUrl: string = '/create-new';
    private listLicencesUrl: string = '/list';
    private downloadLicenseUrl : string='/download';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public createNew(license: License): Observable<License> {
        const url = this.baseUrl + this.serviceUrl + `${this.createNewUrl}`;
        return this.http.post<License>(url, license, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listLicences(studentId: string, year: number): Observable<License[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listLicencesUrl}/${studentId}/${year}`;
        return this.http.get<License[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public downloadLicense(id: string): Observable<any> {
        const url = this.baseUrl + this.serviceUrl + this.downloadLicenseUrl + `/${id}`;
        console.log(url);

        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION, this.authorService.BEARER + this.authorService.token)
        });
    }


 
}