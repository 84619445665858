<h1>Lista de Empresas Activas (Prácticas {{year}})</h1>
<br>
<mat-form-field>
  <mat-label>Filtrar</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo Rut" #input>
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="Empresa">
      <th mat-header-cell *matHeaderCellDef>
        Empresa
      </th>
      <td mat-cell *matCellDef="let element"> {{element.empresa}} </td>
    </ng-container>

    <ng-container matColumnDef="Rut">
      <th mat-header-cell *matHeaderCellDef>
        Rut
      </th>
      <td mat-cell *matCellDef="let element"> {{element.rut_emp}} </td>
    </ng-container>

    <ng-container matColumnDef="N° Alumnos">
      <th mat-header-cell *matHeaderCellDef>
        N° Alumnos
      </th>
      <td mat-cell *matCellDef="let element"> {{element.nalum}} </td>
    </ng-container>

    <ng-container matColumnDef="Pago">
      <th mat-header-cell *matHeaderCellDef>
        Pago
      </th>
      <td mat-cell *matCellDef="let element"> {{element.donacion == '0' || element.donacion == null ? 'No Paga' : element.donacion + ' UF'}} </td>
    </ng-container>

    <ng-container matColumnDef="Sueldo">
      <th mat-header-cell *matHeaderCellDef>
        Sueldo
      </th>
      <td mat-cell *matCellDef="let element"> {{element.sueldo}} </td>
    </ng-container>

    <ng-container matColumnDef="Acción">
      <th mat-header-cell *matHeaderCellDef>
        Acción
      </th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="practicesOnClick(element.rut_emp)" color="primary" matTooltipShowDelay="250"
          matTooltipHideDelay="100" matTooltip="Prácticas">
          <mat-icon>group</mat-icon>&nbsp;
        </button>
        <span>&nbsp;</span>
        <button mat-raised-button (click)="practicesOnClick(element.rut_emp)" color="primary" matTooltipShowDelay="250"
        matTooltipHideDelay="100" matTooltip="Editar">
        <mat-icon>edit</mat-icon>&nbsp;
      </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator class="mat-elevation-z1" [pageSizeOptions]="[10,25, 30, 40, 50]" [pageSize]="10" showFirstLastButtons>
  </mat-paginator>
</div>
