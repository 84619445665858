<h1 mat-dialog-title>{{dialogData.title}}</h1>

<div mat-dialog-content fxLayout="column">
    <mat-form-field appearance="fill">
        <mat-label>Profesor</mat-label>
        <mat-select [(ngModel)]="dialogData.teacherId">
            <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                {{teacher.fatherSurname + ' ' + teacher.motherSurname + ' ' + teacher.names}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Asignatura</mat-label>
        <mat-select [(ngModel)]="dialogData.subjectId">
            <mat-option *ngFor="let subject of subjects" [value]="subject.id">
                {{subject.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div>
        <mat-form-field appearance="fill" fxFlex="20">
            <mat-label>Grupo</mat-label>
            <mat-select [(ngModel)]="dialogData.group" [disabled]="this.freezeGroup">
                <mat-option *ngFor="let group of groups" [value]="group">
                    {{group}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="78" fxFlexOffset="2" >
            <mat-label>Rango de fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Fecha inicio" [(ngModel)]="this.dialogData.startDate" [disabled]="this.dialogData.group == null || this.dialogData.group.length == 0">
                <input matEndDate placeholder="Fecha termino" [(ngModel)]="this.dialogData.finishDate" [disabled]="this.dialogData.group == null || this.dialogData.group.length == 0">
            </mat-date-range-input>
            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field appearance="fill" fxFlex="20">
            <mat-label>Prioridad</mat-label>
            <input matInput [(ngModel)]="dialogData.priority" placeholder="Orden de aparicion" type="number" min="1"
                max="99">
        </mat-form-field>
    </div>

</div>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close]="dialogData">
        <mat-icon>done</mat-icon>Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>close</mat-icon>Cancelar
    </button>
</mat-dialog-actions>