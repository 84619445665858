import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from "./author.service";
import { Hour } from '../model/hour';
import { Config } from "../model/config";
import { environment } from "src/environments/environment";
import { CustomButton } from "../model/alumno";
import { Year } from "../model/year";


@Injectable({
    providedIn: 'root'
})

export class ConfigService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/config';
    private get1_0Url: string = '/1.0/get';
    private get1_1Url: string = '/1.1/get';
    private listTimesUrl: string = '/1.0/list-times';
    private timeBlockUrl: string = '/1.0/list-time-blocks';
    private applicationPropertyUrl: string = '/1.0/application-property';
    private applicationProperty1_1Url: string = '/1.1/application-property';
    private applicationPropertyAsListUrl: string = '/1.0/application-property-as-list';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public getValueByKey(key: string): Observable<string> {
        const url = this.baseUrl + this.serviceUrl + this.get1_0Url + "/" + key;
        return this.http.get<string>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public findByKey(key: string): Observable<Config> {
        const url = this.baseUrl + this.serviceUrl + this.get1_1Url + "/" + key;
        return this.http.get<Config>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getCurrentYear(): Observable<number> {
        const url = this.baseUrl + this.serviceUrl + this.get1_0Url + `/anio_actual`;
        return this.http.get<number>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listHours(): Observable<Hour[]> {
        const url = this.baseUrl + this.serviceUrl + this.listTimesUrl;
        return this.http.get<Hour[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getTimeBlocks(courseId: string, dateSelected: string): Observable<Hour[]> {
        const url = this.baseUrl + this.serviceUrl + this.timeBlockUrl + `/${courseId}/${dateSelected}`;
        return this.http.get<Hour[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getApplicationProperty(feature: string): Observable<string> {
        const url = this.baseUrl + this.serviceUrl + this.applicationPropertyUrl + `/${feature}`;
        return this.http.get<string>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listApplicationProperty(feature: string): Observable<string[]> {
        const url = this.baseUrl + this.serviceUrl + this.applicationPropertyAsListUrl + `/${feature}`;
        return this.http.get<string[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async findByKeyAsync(key: string): Promise<Config> {
        const url = this.baseUrl + this.serviceUrl + this.get1_1Url + "/" + key;
        return this.http.get<Config>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public getApplicationProperty2(feature: string): Observable<Config> {
        const url = this.baseUrl + this.serviceUrl + this.applicationProperty1_1Url + `/${feature}`;
        return this.http.get<Config>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getApplicationProperty2Async(feature: string): Promise<Config> {
        const url = this.baseUrl + this.serviceUrl + this.applicationProperty1_1Url + `/${feature}`;
        return this.http.get<Config>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public getCustomStudentButtons(): Observable<CustomButton[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/custom-student-buttons`;
        return this.http.get<CustomButton[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getYearConfig(year: number): Observable<Year> {
        const url = this.baseUrl + `/api/lichan/management/year/V1?year=${year}`;
        console.log(url);
        console.log('http://localhost:8080/api/lichan/management/year/V1?year=2024');
        
        return this.http.get<Year>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}