<h2 mat-dialog-title>Nueva registro de Licencia médica</h2>

<div mat-dialog-content fxLayout="column">
    <mat-form-field>
        <mat-label>Motivo</mat-label>
        <input matInput placeholder="Ej.: Resfrio fuerte" [(ngModel)]="this.dialogData.reason">
    </mat-form-field>

    <mat-form-field>
        <mat-select [(ngModel)]="this.dialogData.type">
            <mat-option value="ENFER">Enfermedad</mat-option>
            <mat-option value="ACCID">Accidente</mat-option>
            <mat-option value="ANUAL">Certificado anual de inacistencia</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Periodo de clases para ser enviada</mat-label>
        <!--
[dateFilter]="planningYearFilter" [min]="this.minDate"
            [max]="this.maxDate"
-->
        <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Fecha inicio" [(ngModel)]="this.dialogData.start">
            <input matEndDate placeholder="Fecha fin" [(ngModel)]="this.dialogData.finish">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>


    <input type="file" class="input-file" onchange="console.log(event.target.files)" (change)="onFileSelected($event)"
        #fileUpload>
    <div class="file-upload">
        <button mat-mini-fab color="primary" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
        {{dialogData.fileName || "Seleccione archivo con licencia."}}
    </div>
    <br>
</div>

<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="this.dialogData">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>Cerrar</button>
</div>