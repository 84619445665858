import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';
import { ChangeStatusDialogComponent, ChangeStatusDialogParam } from './change-status-dialog/change-status-dialog.component';
import { StudentService } from 'src/app/api/student.service';
import { Alumno } from 'src/app/model/alumno';
import { StopEnrolling } from 'src/app/model/stop-enrolling';

@Component({
  selector: 'app-admin-students',
  templateUrl: './admin-students.component.html',
  styleUrls: ['./admin-students.component.css', '../../../../style/table.css']
})
export class AdminStudentsComponent implements OnInit {
  //  nextYear: number;
  course: Course;

  dataSource: Alumno[];
  displayedColumns: string[] = ['num', 'rut', 'name', 'source', 'state', 'attorneyName', 'attorneyPhone', 'age'];

  constructor(public courseService: CourseService,
    private router: Router,
    private dialog: MatDialog,
    private studentService: StudentService) { }

  ngOnInit(): void {
    // this.nextYear = +localStorage.getItem('nextYear');
    const courseId = localStorage.getItem('courseId');
    this.courseService.get(courseId)
      .subscribe(
        response => this.course = response,
        problems => console.log(problems)
      );

    this.courseService.listStudentsForAdmission(courseId)
      .subscribe(
        response => {
          this.dataSource = response;
          console.log(response);
        },
        problems => console.error(problems)
      );
  }

  public resolveState(state: string): string {
    switch (state) {
      case 'XMT':
        return 'Por Matricular';
      case 'MAT':
        return 'Matriculado';
      case 'RET':
        return 'Retirado';
      case 'NOC':
        return 'No continuará';
      case 'NMT':
        return 'Matricula detenida';
      default:
        return state;
    }
  }

  public onCancelClick(): void {
    this.router.navigate(['admission-enrollment']);
  }

  public async changeStatusDialog(studentId: string, courseId: string, state: string) {
    //    console.log(studentId, courseId, state);

    const student = this.dataSource.filter(student => student.id == studentId)[0];
    // console.log(student);

    const changeStatusDialogParam: ChangeStatusDialogParam = new ChangeStatusDialogParam();
    changeStatusDialogParam.status = state;
    changeStatusDialogParam.studentId = studentId;
    changeStatusDialogParam.courseId = courseId;
    changeStatusDialogParam.studentName = `${student.paterno} ${student.materno}, ${student.nombres}`;
    changeStatusDialogParam.studentRut = `${student.rut}-${student.dv}`;

    const dialogRef = this.dialog.open(ChangeStatusDialogComponent, { data: changeStatusDialogParam });

    dialogRef.afterClosed().subscribe(
      (changeStatusDialogParam: ChangeStatusDialogParam) => {
        console.log('afterClosed', changeStatusDialogParam);
        if (changeStatusDialogParam == undefined) return;
        if (changeStatusDialogParam.action == 'NOC') {
          this.studentService.dontContinue(changeStatusDialogParam.studentId, changeStatusDialogParam.courseId)
            .subscribe(
              (studentUpdated: Alumno) =>
                this.updateDataSourceWithStudent(studentUpdated, ' '),
              problems => console.error(problems)
            );
        }

        if (changeStatusDialogParam.action == 'REP') {
          this.studentService.reprobate(studentId, this.course.id, changeStatusDialogParam.newCourseId)
            .subscribe((response: Alumno) =>
              this.updateDataSourceWithStudent(response, changeStatusDialogParam.courseId),
              problems => console.error(problems)
            );
        }

        if (changeStatusDialogParam.action == 'CHC') {
          this.studentService.changeCourse(changeStatusDialogParam.studentId, changeStatusDialogParam.newCourseId)
            .subscribe((response: Alumno) =>
              this.updateDataSourceWithStudent(response, changeStatusDialogParam.courseId),
              problems => console.error(problems)
            );

        }
        if (changeStatusDialogParam.action == 'NMT') {
          const stopEnrolling: StopEnrolling = new StopEnrolling();
          stopEnrolling.comment = changeStatusDialogParam.comment;
          stopEnrolling.courseId = changeStatusDialogParam.courseId;
          stopEnrolling.reason = changeStatusDialogParam.reason;
          stopEnrolling.studentId = changeStatusDialogParam.studentId;

          this.studentService.stopEnrolling(stopEnrolling)
            .subscribe((response: Alumno) =>
              this.updateDataSourceWithStudent(response, changeStatusDialogParam.courseId),
              problems => console.error(problems)
            );

        }


      }
    );
  }

  private updateDataSourceWithStudent(studentUpdated: Alumno, newCourseId: string): void {
    console.log('studentUpdated', studentUpdated);
    const dataSourceTemp: Alumno[] = [];
    // debugger;


    this.dataSource.forEach((eachStudent: Alumno) => {
      console.log(eachStudent.courseDto.id);

      if (eachStudent.id == studentUpdated.id) {
        if (studentUpdated.courseDto.id == newCourseId) {
          dataSourceTemp.push(studentUpdated);
        }
      } else {
        dataSourceTemp.push(eachStudent);
      }

    });
    this.dataSource = [];
    this.dataSource = dataSourceTemp;

  }

}
