import { Component, OnInit, Input } from '@angular/core';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';

@Component({
  selector: 'com-course-panel',
  templateUrl: './course-panel.component.html',
  styleUrls: ['./course-panel.component.css']
})
export class CoursePanelComponent implements OnInit {

  @Input() public course: Course | null = null;
  @Input() public showYear: boolean = true;
  @Input() public showTeacher: boolean = true;
  @Input() public showSpeciality: boolean = true;

  constructor(public courseService: CourseService) { }

  ngOnInit(): void {
  }

}
