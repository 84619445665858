import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { ScheduleService } from 'src/app/api/schedule.service';
import { CalendarCell } from 'src/app/model/calendar-cell';
import { CalendarRow } from 'src/app/model/calendar-row';
import { Course } from 'src/app/model/course';
import { Hour } from 'src/app/model/hour';

@Component({
  selector: 'app-horary',
  templateUrl: './horary.component.html',
  styleUrls: ['./horary.component.css']
})
export class HoraryComponent implements OnInit {
  public courseId: string;
  public course: Course;
  public borderSize: number = 0;

  public calendarRows: CalendarRow[] = [];

  constructor(private scheduele: ScheduleService,
    private configService: ConfigService,
    private courseService: CourseService,
    private router: Router) { }

  ngOnInit(): void {
    this.courseId = localStorage.getItem('courseId');

    this.scheduele.listByCourse(this.courseId)
      .subscribe(
        response => {
          console.log(response);
          this.calendarRows = response;
        },
        problems => console.error(problems)
      );

    this.configService.listHours()
      .subscribe(
        response => this.initCalendarRows(response),
        problems => console.error(problems)
      );

    this.courseService.get(this.courseId)
      .subscribe(
        response => this.course = response,
        problems => console.error(problems)
      );

  }

  private initCalendarRows(hours: Hour[]): void {
    let i: number = 0;
    hours.forEach(
      hour => {
        const calendarRow = new CalendarRow();
        calendarRow.hour = hour;
        this.calendarRows[i] = calendarRow;
        i++;
      }
    );
  }

  public formatHour(time: string): string {
    return time.substring(0, time.lastIndexOf(':'));
  }

  public getCourseLeterAndSignatureName(calendarCell: CalendarCell): string {
    if (calendarCell == null) return '-';
    
    return `${calendarCell.teacherName} (${calendarCell.subjectShortName})`;
  }

  public goBack():void{
    this.router.navigate(['courses']);
  }
}
