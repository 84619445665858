
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";
import { Practice } from "../model/practice";
import { PracticeDelete } from "../model/practiceDelete";


@Injectable({
  providedIn: 'root'
})
export class PracticeService {

  private baseUrl: string = environment.apiPracticesUrl;
  private serviceUrl: string = '/api/lichan/management/1.0';
  private getPracticeUrl: string = '/practices';
  private getContractUrl: string = '/practices/contract';


  constructor(private http: HttpClient,
      private authorService: AuthorService) {
  }

  public getPractices(): Observable<Practice[]> {
      const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}`;
      return this.http.get<Practice[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public countPractices(): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/count";
    return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public countPracticesCompany(run: string): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/count/company/" + run;
    return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public countPracticesCompanyYear(run: string, year: string): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/count/company/" + run + "/year/" + year;
    return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public countPracticesYear(year : string): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/count/year/" + year;
    return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getPracticeById(practiceId: number): Observable<Practice> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/" + practiceId;
    return this.http.get<Practice>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getPracticeByCompanyRun(run: string): Observable<Practice[]> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/company/" + run;
    return this.http.get<Practice[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getPracticeByCompanyRunYear(run: string, year: string): Observable<Practice[]> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/company/" + run + "/year/" + year;
    return this.http.get<Practice[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getPracticeByStudenRun(run: number): Observable<Practice[]> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/student/" + run;
    return this.http.get<Practice[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getPracticeByStudenRunYear(run: number, year : string): Observable<Practice[]> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/student/" + run + "/year/" + year;
    return this.http.get<Practice[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getPracticeByYear(year: string): Observable<Practice[]> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/year/" + year;
    return this.http.get<Practice[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public addPractice(practice:Practice): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}`;
    return this.http.post<any>(url,practice, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public updatePractice(practice:Practice): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}`;
    return this.http.put<any>(url,practice, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public topCompanyPractices(): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}` + "/companyPracticesTop";
    return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getContract(practiceId: number) {
    const url = this.baseUrl + this.serviceUrl + `${this.getContractUrl}/${practiceId}`;

    const contentType: string = 'application/pdf';

    return this.http.get(url, {
        responseType: 'arraybuffer',
        headers: new HttpHeaders()
            .append('Content-Type', contentType)
            .append(
                this.authorService.AUTHORIZATION,
                this.authorService.BEARER + this.authorService.token
            )
    });
  }

  public getContractCourse(year: string, course: string){
    const url = this.baseUrl + this.serviceUrl + `${this.getContractUrl}/${course}/year/${year}`;
    console.log(url);

    const contentType: string = 'application/pdf';

    return this.http.get(url, {
        responseType: 'arraybuffer',
        headers: new HttpHeaders()
            .append('Content-Type', contentType)
            .append(
                this.authorService.AUTHORIZATION,
                this.authorService.BEARER + this.authorService.token
            )
    });
  }

  public delPractice(practice:PracticeDelete): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getPracticeUrl}`+ "/delete";
    return this.http.post<any>(url,practice, { headers: this.authorService.headerWithAuthorization(null) });
  }

}
