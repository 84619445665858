<div class="table-container" fxLayout="column" style="text-align:left"  fxLayoutAlign="start start">
    <h1 mat-h1>Leccionarios completados</h1>


    <div fxLayout="row" style="width: 90%;">


        <com-teacher-selector (teacherChange)="onTeacherChange($event)" style="width: 30%;"></com-teacher-selector>
        
        &nbsp;
        <mat-form-field appearance="fill"  style="width: 30%;">
            <mat-label>Rango de fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Fecha inicio" [(ngModel)]="this.startDate">
                <input matEndDate placeholder="Fecha termino" [(ngModel)]="this.finishDate">
            </mat-date-range-input>
            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
          
        &nbsp;
        <com-subject-selector style="width: 30%;" 
        [year]="this.currentYear" 
        [showAll]="false"
        [teacherId]="this.teacher.id"
        (subjectChangeEvent)="onSubjectChange($event)" ></com-subject-selector>

        &nbsp;
        <button mat-raised-button color="primary" style="width: 10%;" (click)="onSearchButton($event)">
            <mat-icon>search</mat-icon>Buscar
        </button>
        </div>

    <div class="table-filter" fxLayout="column" *ngIf="true ||this.dataSource.data.length>0">
        <mat-form-field>
            <mat-label>Filtro</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Filtrar información">
        </mat-form-field>
    </div>

    <table mat-table [dataSource]="dataSource" matSort fxFlexFill  *ngIf="true || this.dataSource.data.length>0">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- Fecha Column -->
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element.lectionDate}} </td>
    </ng-container>

    <!-- Curso Column -->
    <ng-container matColumnDef="course">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Curso </th>
        <td mat-cell *matCellDef="let element"> {{element.course.letra}} </td>
    </ng-container>

    <!-- Asignatura Column -->
    <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Asignatura </th>
        <td mat-cell *matCellDef="let element"> {{element.subject.name}} </td>
    </ng-container>

    <!-- Unidad Column -->
    <ng-container matColumnDef="module">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad/modulo </th>
        <td mat-cell *matCellDef="let element"> {{element.module.name}} </td>
    </ng-container>

    <!-- Leccionario Column -->
    <ng-container matColumnDef="lectionary">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Leccionario </th>
        <td mat-cell *matCellDef="let element"> {{element.lectionary}} </td>
    </ng-container>

   
    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" [pageSize]="10" showFirstLastButtons fxFlexFill
    ></mat-paginator>

</div>
