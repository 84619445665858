import { Subject } from "./subject";

export class FailureProjectionCourse {
  studentsStatus: StudentsStatus[];
  subjects: Subject[];
}

export class StudentsStatus {
  studentId: string;
  studentName: string;
  periods: Period[];
  attendance: number;
}

export class Period {
  key: string;
  averages: Average[];
}

export class Average {
  subjectId: string;
  average: number;
}

export class StudentsStatus4View {
  studentId: string;
  studentName: string;
  periodKey: string;
  averages: Average4View[];
  attendance: number;
}

export class Average4View {
  subjectId: string;
  average: number;

}