import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { Level } from "../model/level";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class LevelService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/level';
    private listByYearUrl: string = '/list-by-year';
    private listAllUrl: string = '/list-all';
    private getPreviusUrl: string = '/get-previus';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listByYear(year: number): Observable<Level[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0/list-by-year/${year}`;
        return this.http.get<Level[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listByYearV2(year: number, username : string): Observable<Level[]> {
        const url = this.baseUrl + this.serviceUrl + `/v2/list-by-year/${year}/${username}`;
        return this.http.get<Level[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAll(): Observable<Level[]> {
        const url = this.baseUrl + this.serviceUrl + `/1.0${this.listAllUrl}`;
        return this.http.get<Level[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async getPreviusAsync(levelId: string): Promise<Level> {
        const url = this.baseUrl + this.serviceUrl + `/1.0${this.getPreviusUrl}/${levelId}`;
        return this.http.get<Level>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

}