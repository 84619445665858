<h1 mat-dialog-title>Conflicto de horario</h1>
<div mat-dialog-content fxLayout="column">

    <p>
        El horario y curso <u>{{courseService.formatLetra( this.dialogData.courseLeter)}}</u>, ya esta
        reservado segun el siguiente detalle.
    </p>

    <ul>
        <li *ngFor="let validateDetail of dialogData.validateDetails">
            <p>
                <b>{{validateDetail.hourStart}}-{{validateDetail.hourFinish}}:</b> {{validateDetail.subjectName}}
                ({{validateDetail.teacherFullName}}).
            </p>
        </li>
    </ul>


    <!-- 
    <table border="1" width="100%">
        <tr *ngFor="let validateDetail of dialogData.validateDetails">
            <td>
                Asignatura:{{validateDetail.subjectName}} / Profesor: {{validateDetail.teacherFullName}} / Horario:
                {{validateDetail.hourDesc}}
            </td>
        </tr>
    </table>

// detail += `Asignatura:${validateDetail.subjectName} / Profesor: ${validateDetail.teacherFullName} / Horario: ${validateDetail.hourDesc} \n`;
-->
</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="" color="primary">Aceptar</button>
</div>