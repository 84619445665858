<div fxLayout="column wrap" fxFlexAlign="center">
  <div>&nbsp;</div>
  <div>&nbsp;</div>
  <div>&nbsp;</div>
  <div>&nbsp;</div>
  
  <img src="assets/img/login2.jpg" alt="Login" width="120px" fxFlexAlign="center">

  <h1 fxFlexAlign="center">
    {{ title }}
  </h1>

  <main>
    <mat-card class="mat-elevation-z6" 
    fxFlex.xs="80"
    fxFlex.sm="65"
    fxFlex.md="50"
    fxFlex.lg="30"
    fxFlex.xl="25"
    >
      <form method="POST">
        <mat-form-field fxFlexFill>
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="user.username" name="username" type="text" placeholder="Ej. usuario@colegio.cl"
            autofocus required (change)="onChangeCredentials()" autocomplete="off">
        </mat-form-field>

        <mat-form-field fxFlexFill>
          <mat-label>Clave de acceso</mat-label>
          <input matInput [(ngModel)]="user.password" name="password" type="password" placeholder="Proteja su clave"
            (keydown.enter)="onClickLogin()" required (change)="onChangeCredentials()">
        </mat-form-field>

      </form>
      <button mat-raised-button color="primary" (click)="onClickLogin()" fxFlexFill>Acceder</button>
    </mat-card>
  </main>
</div>