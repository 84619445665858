import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthorService } from 'src/app/api/author.service';
import { ConfigService } from 'src/app/api/config.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { PermissionService } from 'src/app/api/permission.service';
import { Meeting } from 'src/app/gui/attorney/meeting/model/meeting';
import { MeetingService } from 'src/app/gui/attorney/meeting/service/meeting.service';
import { Course } from 'src/app/model/course';
import { AttendanceDialogComponent, AttendanceDialogParams } from './attendance-dialog/attendance-dialog.component';
import { MeetingDetailDialogComponent, MeetingDialogParams } from './meeting-detail-dialog/meeting-detail-dialog.component';
import { Participant } from './model/participant.model';
import { AgreementDialogParams, AgreementsDialogComponent } from './agreements-dialog/agreements-dialog.component';
import { Agreement } from './model/agreement';

@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css',
    '../../../style/table.css'
  ]
})

export class MeetingComponent implements OnInit {
  @Input() public course: Course;
  public datasource: MatTableDataSource<Meeting>;
  public displayedColumns: string[] = [];
  public year: number = 0;
  public viewAsManager: boolean = true;

  constructor(private permissionService: PermissionService,
    private meetingService: MeetingService,
    private authService: AuthorService,
    private configService: ConfigService,
    private dialog: MatDialog,
    public dtUtility: DateTimeUtility
  ) { }

  ngOnInit(): void {
    this.initComponent();
  }

  private async initComponent() {
    await this.loadPermisions();
    this.refreshData();

  }

  public courseChange(courseId: string): void {
    console.log(courseId);
    this.listMeetingsByCourseId(courseId);

  }

  private refreshData(): void {
    if (!this.viewAsManager) {
      this.listMeetingsByTeacherIdAndYear();
    }
  }

  private listMeetingsByTeacherIdAndYear() {
    this.meetingService.listByTeacherIdAndYear(this.authService.user.id, this.year)
      .subscribe(
        (response: Meeting[]) => {
          this.refreshDataSource(response);
        },
        problems => console.error(problems)
      );
  }

  private listMeetingsByCourseId(courseId: string) {
    this.meetingService.listByCourseId(courseId)
      .subscribe(
        (response: Meeting[]) => {
          this.refreshDataSource(response);
        },
        problems => console.error(problems)
      );
  }

  private refreshDataSource(meetings: Meeting[]): void {
    console.log(meetings);
    this.datasource = new MatTableDataSource(meetings);
  }


  private async loadPermisions(): Promise<void> {
    this.viewAsManager = await this.permissionService.hasPermissionAsync('ATTONEY.MEETING.VIEW_AS_MANAGER');
    this.year = await this.configService.getCurrentYear().toPromise();

    /*
    if (this.viewAsManager) {
      this.displayedColumns = ['select', 'meeting_date', 'summary', 'course', 'teacher', 'actions'];
    } else {
      this.displayedColumns = ['select', 'meeting_date', 'summary', 'actions'];
    }
    */
    this.displayedColumns = ['select', 'meeting_date', 'summary', 'actions'];

  }

  public newMeetDialog(): void {
    const meetingParams: MeetingDialogParams = new MeetingDialogParams();
    meetingParams.viewAsManager = this.viewAsManager;
    meetingParams.course = this.course;
    meetingParams.subject = 'MONTHLY';

    const dialogRef = this.dialog.open(MeetingDetailDialogComponent, { data: meetingParams })

    dialogRef.afterClosed()
      .subscribe(
        (result: MeetingDialogParams) => {
          if (result == undefined) return;
          console.log('After Dialog: ', result);

          const meeting: Meeting = new Meeting();

          meeting.courseId = this.course.id;
          meeting.courseLeter = this.course.letra;
          meeting.courseYear = this.course.agno;
          meeting.id = null;
          meeting.meetingDate = this.dtUtility.momentToString(result.dateTime);
          meeting.teacherId = this.authService.user.id;
          meeting.teacherNames = this.authService.user.names;
          meeting.teacherRut = this.authService.user.rut;
          meeting.teacherDv = this.authService.user.dv;
          meeting.teacherFatherSurname = this.authService.user.fatherSurname;
          meeting.teacherMotherSurname = this.authService.user.motherSurname;

          meeting.title = result.subject;

          this.meetingService.create(meeting)
            .subscribe(
              response => this.refreshData(),
              problems => console.error(problems)
            );
        }
      );

  }

  public async openAttendanceDialog(meeting: Meeting): Promise<void> {
    const attendanceDialogParams: AttendanceDialogParams = new AttendanceDialogParams();
    const participants: Participant[] = await this.meetingService.listParticipantsAsync(meeting.id);
    console.log(participants);
    attendanceDialogParams.participant = participants;
    attendanceDialogParams.courseLeter = meeting.courseLeter;
    attendanceDialogParams.meetingDate = meeting.meetingDate;
    attendanceDialogParams.viewAsManager = this.viewAsManager;

    const dialogRef = this.dialog.open(AttendanceDialogComponent, { data: attendanceDialogParams });
  }

  public async openAgreementDialog(meeting: Meeting): Promise<void> {
    const agreementDialogParams: AgreementDialogParams = new AgreementDialogParams();
    const agreements: Agreement[] = await this.meetingService.listAgreementsAsync(meeting.id);

    agreementDialogParams.agreements = agreements;
    agreementDialogParams.courseLeter = meeting.courseLeter;
    agreementDialogParams.meetingDate = meeting.meetingDate;
    agreementDialogParams.meetingId = meeting.id;
    agreementDialogParams.viewAsManager = this.viewAsManager;

    const dialogRef = this.dialog.open(AgreementsDialogComponent, { data: agreementDialogParams });

    dialogRef.afterClosed().subscribe(
      (response: AgreementDialogParams) => {
        console.log(response);
        if (response == undefined) return;

        this.meetingService.saveAgreements(meeting.id, response.agreements)
          .subscribe(
            response => console.log(response),
            problems => console.error(problems)
          );
      }


    );

  }

  public resolveTitle(title: string): string {
    switch (title) {
      case 'MONTHLY':
        return 'Reunión mensual';
      case 'EXTRAORDINARY':
        return 'Extraordinaria';
      case 'DEGREE':
        return 'Licenciatura';
      default:
        return title;
    }

  }
}
