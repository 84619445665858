import { HttpClient, HttpRequest } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { LicenseService } from 'src/app/api/license.service';
import { PermissionService } from 'src/app/api/permission.service';
import { License } from 'src/app/model/license';
import { environment } from 'src/environments/environment';
import { CourseService } from '../../../api/course.service';
import { Alumno } from '../../../model/alumno';
import { Course } from '../../../model/course';
import { AddAnnotationDialogComponent } from '../../alumno/add-annotation-dialog/add-annotation-dialog.component';
import { AnnotationDialogDataParam } from '../../alumno/add-annotation-dialog/annotation-dialog-data';
import { AddLicenseDialogComponent, AddLicenseDialogDataParam } from './add-license-dialog/add-license-dialog.component';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { AuthorService } from 'src/app/api/author.service';
import { AccidentFormDialogDataParam } from './accident-form-dialog/accident-form-dialog.component';
import { AccidentFormDialogComponent } from './accident-form-dialog/accident-form-dialog.component';
import { AccidentService } from 'src/app/api/accident.service';
import { Accident } from 'src/app/model/accident';
import { AnnotationService } from 'src/app/api/annotation.service';
import { Annotation } from 'src/app/model/annotation';
import { AnnotationListDialogComponent, AnnotationListDialogParameters } from './annotation-list-dialog/annotation-list-dialog.component';

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css', '../../../style/table.css']
})
export class StudentListComponent implements OnInit {
  course: Course;
  dataSource: MatTableDataSource<Alumno>;

  displayedColumns: string[] = ['NumeroLista', 'Rut', 'Nombre', 'Comment', 'Edad', 'Annotations', 'Negatives', 'Actions'];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  public canAddLicense: boolean = false;
  public canMakePersonalityReport: boolean = false;
  public canDownloadPersonalityReport: boolean = false;
  public readonly: boolean = true;
  private studentsAnnotations: Map<string, Annotation[]> = new Map();

  constructor(public courseService: CourseService,
    public dialog: MatDialog,
    private router: Router,
    private permissionService: PermissionService,
    private http: HttpClient,
    private licenseService: LicenseService,
    private dateTimeUtility: DateTimeUtility,
    private alert: AlertUtility,
    private auth: AuthorService,
    private accidentService: AccidentService,
    private annotationService: AnnotationService) { }

  ngOnInit(): void {
    const courseId = localStorage.getItem('courseId');
    this.readonly = localStorage.getItem('readonly') == 'true';
    this.loadConfig(courseId);
  }

  private async loadConfig(courseId: string) {
    this.course = await this.courseService.getAsync(courseId);

    this.canMakePersonalityReport = await this.permissionService.hasPermissionsAsync(['COURSES.MAKE-PERSONALITY-REPORT'])
      && this.auth.user.id == this.course.teacherId;

    this.courseService.listStudents(courseId)
      .subscribe(
        response => {
          this.dataSource = new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
        },
        problems => console.error(problems)
      );

    this.permissionService.hasPermissions(['COURSES.ADD_MEDICAL_LICENSE']).subscribe(
      response => this.canAddLicense = response,
      problems => console.error(problems)
    );


    this.permissionService.hasPermissions(['COURSES.DOWNLOAD-PERSONALITY-REPORT']).subscribe(
      response => this.canDownloadPersonalityReport = response,
      problems => console.error(problems)
    );
  }

  public doCancel(): void {
    this.router.navigate(['courses']);
  }

  doDownloadStudentsList() {
    this.courseService.downloadCourseListAsPdf(this.course.id)
      .subscribe((response: ArrayBuffer) => this.download(response,
        'application/pdf',
        `lista-curso.pdf`));
  }

  download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
    const file: Blob = new Blob([binaryData], { type: fileType });
    const url: string = window.URL.createObjectURL(file);
    const anchorElement: HTMLAnchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = url;
    anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }

  doAddAnnotation(id: string): void {
    const annotationDialogDataParam: AnnotationDialogDataParam = new AnnotationDialogDataParam();
    annotationDialogDataParam.courseId = this.course.id;
    annotationDialogDataParam.its4Student = true;
    annotationDialogDataParam.studentId = id;

    const annotationDialogRef = this.dialog.open(AddAnnotationDialogComponent, {
      data: annotationDialogDataParam
    });

  }

  public doAddLicense(id: string): void {
    const addLicenseDialogDataParam: AddLicenseDialogDataParam = new AddLicenseDialogDataParam();
    addLicenseDialogDataParam.reason = '';
    addLicenseDialogDataParam.type = 'ENFER';
    addLicenseDialogDataParam.studentId = id;

    const addLicenseDialogRef = this.dialog.open(AddLicenseDialogComponent, { data: addLicenseDialogDataParam });

    addLicenseDialogRef.afterClosed()
      .subscribe(
        (addLicenseDialogDataParam: AddLicenseDialogDataParam) => {
          console.log(addLicenseDialogDataParam);
          this.uploadingLicense(addLicenseDialogDataParam);
        }
      );

  }

  private uploadingLicense(dialogData: AddLicenseDialogDataParam): void {
    console.log('uploading...');

    if (dialogData == undefined) return;
    const license = new License();
    license.reason = dialogData.reason;
    license.start = this.dateTimeUtility.momentToString(dialogData.start);
    license.finish = this.dateTimeUtility.momentToString(dialogData.finish);
    license.type = dialogData.type;
    license.year = this.course.agno;
    license.studentId = dialogData.studentId;

    this.licenseService.createNew(license)
      .subscribe(
        (response: License) => {
          this.uploadFile(dialogData, response);
        },
        problems => console.error(problems)
      );
  }

  private uploadFile(dialogData: AddLicenseDialogDataParam, license: License) {
    console.log(license, dialogData);

    if (dialogData.fileName == '') {
      this.alert.fastMessage('Carga de licencia cargada con éxito, sin documento adjunto');
      return;
    }

    const formData = new FormData();

    const url: string = environment.apiUrl + '/api/lichan/license/1.0/add-file';
    formData.append('file', dialogData.file);
    formData.append('licenseId', license.id);
    formData.append('username', this.auth.user.username);

    console.log(url);

    const req = new HttpRequest('POST', url, formData, {
      reportProgress: false
    });

    this.http.request(req)
      .subscribe(
        response => {
          console.log(response);
          this.alert.fastMessage('Carga de licencia cargada con éxito');
        },
        problems => this.alert.dialogMessage(JSON.stringify(problems.error.message))
      );
  }

  public makePersonaliyReport(stundetId: string) {
    localStorage.setItem('studentId', stundetId);
    localStorage.setItem('courseId', this.course.id);
    this.router.navigate(['personality-report']);
  }

  public downloadPersonaliyReport(studentId: string) {

  }

  public openAccidentForm(studentId: string): void {
    const accidentFormDialogDataParam: AccidentFormDialogDataParam = new AccidentFormDialogDataParam();
    accidentFormDialogDataParam.accidentDate = this.dateTimeUtility.stringToMoment(this.dateTimeUtility.dateToString(new Date()));
    accidentFormDialogDataParam.student = this.dataSource.data.filter(student => student.id == studentId)[0];

    const accidentFormDialogRef = this.dialog.open(AccidentFormDialogComponent, { data: accidentFormDialogDataParam });

    accidentFormDialogRef.afterClosed()
      .subscribe(
        (accidentFormDialogDataParam: AccidentFormDialogDataParam) => {
          const accident: Accident = new Accident();
          console.log(accidentFormDialogDataParam);
          if (accidentFormDialogDataParam == null) return;

          accident.accidentDate = this.dateTimeUtility.momentToString(accidentFormDialogDataParam.accidentDate);
          accident.studentId = studentId;
          accident.description = accidentFormDialogDataParam.description;
          accident.healthPlace = accidentFormDialogDataParam.healthPlace;
          accident.escortsName = accidentFormDialogDataParam.escortsName;
          accident.escortsRelationship = accidentFormDialogDataParam.escortsRelationship;

          this.accidentService.saveNewAccident(accident)
            .subscribe(
              (response: Accident) => this.downloadAccidentForm(response),
              problems => console.error(problems)
            );

        }
      );
  }

  private downloadAccidentForm(accident: Accident): void {
    console.log(accident);

    this.accidentService.downloadAccidentForm(accident.id)
      .subscribe((response: ArrayBuffer) => this.download(response,
        'application/pdf',
        `formulario-accidente.pdf`));
  }

  public showAnnotations(studentId: string, type: string): void {
    const annotations = this.studentsAnnotations.get(studentId);
    const student: Alumno = this.dataSource.data.filter(student => student.id == studentId)[0];

    if (annotations == null) {
      this.annotationService.listAnnotations(studentId)
        .subscribe(
          (serviceAnnotations: Annotation[]) => {
            this.studentsAnnotations.set(studentId, serviceAnnotations);
            this.processShowAnnotations(serviceAnnotations, type, student);
          },
          problems => console.error(problems)
        );
    } else {
      console.log('- Retrieve from cache -');
      this.processShowAnnotations(annotations, type, student);
    }

  }
  private processShowAnnotations(annotations: Annotation[], type: string, student: Alumno): void {
    console.log('Type:', type);

    const annotationListDialogParameters: AnnotationListDialogParameters = new AnnotationListDialogParameters();
    annotationListDialogParameters.student = student;

    if (type == 'Neg') {
      annotationListDialogParameters.dialogTitle = 'Anotaciones negativas';
      annotationListDialogParameters.annotations = annotations
        .filter(annotation => annotation.its4Student)
        .filter(annotation => annotation.annotationType.negative && type == 'Neg');
    } else {
      annotationListDialogParameters.dialogTitle = 'Anotaciones positivas o eventos';
      annotationListDialogParameters.annotations = annotations
        .filter(annotation => annotation.its4Student)
        .filter(annotation => !annotation.annotationType.negative && type != 'Neg');
    }

    console.log(annotationListDialogParameters);

    this.dialog.open(AnnotationListDialogComponent, {
      data: annotationListDialogParameters,
      maxWidth: '90%',
      maxHeight: '700px'
    });
  }

}
