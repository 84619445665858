<h1 mat-dialog-title>{{this.dialogData.column.header}} de {{this.dialogData.parentName}}</h1>

<div mat-dialog-content fxLayout="column">
    <!--  
    <mat-form-field appearance="fill">
        <mat-label>Toppings</mat-label>
        <mat-autocomplete>
            <mat-option [value]="1">uno</mat-option>
            <mat-option [value]="2">dos</mat-option>
            <mat-option [value]="3">tres</mat-option>
            <mat-option value="4">cuatro</mat-option>
            <mat-option value="5">cinco</mat-option>
            <mat-option value="6">seis</mat-option>
            <mat-option value="7">siete</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <select   size="4">
        <option value="1">uno</option>
        <option value="2">dos</option>
        <option value="3">tres</option>
        <option value="4">cuatro</option>
        <option value="5">cinco</option>
        <option value="6">seis</option>
        <option value="7">siete</option>
      </select>
    -->

    <mat-list class="content">
        <mat-list-item *ngFor="let element of this.elements">
            <mat-checkbox [(ngModel)]="element.checked" color="primary">
                {{ element.label}}
            </mat-checkbox>
        </mat-list-item>
    </mat-list>



    <!-- 
        <mat-form-field appearance="fill" 
                *ngFor="let column of filterColumns(dialogData.columns); let i = index">
            <mat-label>{{i+1}}.- {{column.header}}</mat-label>
            <input matInput [type]="resolveType(column)"
                   [placeholder]="column.header" [(ngModel)]="column.value">
        </mat-form-field>
    -->
</div>

<mat-dialog-actions>

    <button mat-raised-button color="primary" (click)="this.onAccept()" [mat-dialog-close]="this.dialogData">
        <mat-icon>done</mat-icon>Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="" cdkFocusInitial>
        <mat-icon>close</mat-icon>Cancelar
    </button>
</mat-dialog-actions>