import {
  Component, OnInit, Input, SimpleChanges, OnChanges,
  Output, EventEmitter
} from '@angular/core';
import { PlanService } from 'src/app/api/plan.service';
import { Module } from 'src/app/model/module';

@Component({
  selector: 'com-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.css', '../../style/table.css']
})
export class ModuleSelectorComponent implements OnInit, OnChanges {
  @Input() subjectId: string = '';

  @Input() levelId: string = '';

  @Input() year: number = 0;

  @Output() moduleChangeEvent = new EventEmitter<string>();

  @Output() moduleLoadEvent = new EventEmitter<Module[]>();

  moduleId: string = '';
  modules: Module[] = [];
  module: Module = null;

  constructor(private planService: PlanService) { }

  ngOnInit(): void {
  }

  ngOnChanges(sc: SimpleChanges) {
    this.module = null;
    this.moduleId = '';
    this.modules = [];
    if (sc.subjectId?.firstChange || sc.subjectId?.currentValue == '') return;
    this.refreshModules();
  }

  onChangeModule(): void {
    this.module = this.modules.filter(module => module.id == this.moduleId)[0];
    this.moduleChangeEvent.emit(this.moduleId);
    this.moduleLoadEvent.emit(this.modules);
  }

  refreshModules() {
    this.module = null;
    this.moduleId = '';
    this.modules = [];
    if (this.subjectId == '' || this.levelId == '' || this.year == 0) return;
    this.planService.listModules(this.subjectId, this.levelId, this.year)
      .subscribe(
        (response: Module[]) => {
          this.modules = response;
          this.onChangeModule();
        },
        problems => console.error(problems)
      );
  }
}
