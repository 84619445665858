<h2>Evaluación Personal</h2>
<br><br>
<table *ngIf="this.student && this.course" style="text-align: left;">
    <tr>
        <td>Nomobre:</td>
        <td><strong>{{this.student.paterno + ' ' + this.student.materno + ', ' + this.student.nombres}}</strong></td>
        <td>Curso:</td>
        <td><strong>{{this.course.letra + ', '+ this.course.agno }}</strong></td>
    </tr>
    <tr>
        <td>Rut:</td>
        <td><strong>{{this.student.rut+'-'+this.student.dv}}</strong> </td>
        <td>Edad:</td>
        <td><strong>{{this.student.edad}}</strong></td>
    </tr>
</table>
<br>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z0" fxFlexFill>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="Nro">
        <th mat-header-cell *matHeaderCellDef class="align-center"> No. </th>
        <td mat-cell *matCellDef="let element" class="align-center"> {{element.priority}} </td>
    </ng-container>

    <ng-container matColumnDef="area">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Area </th>
        <td mat-cell *matCellDef="let element" class="align-left"> {{element.area.name}} </td>
    </ng-container>

    <ng-container matColumnDef="quiestion">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Pregunta</th>
        <td mat-cell *matCellDef="let element" class="align-left"> {{element.detail}} </td>
    </ng-container>

    <ng-container matColumnDef="teacherAnswer">
        <th mat-header-cell *matHeaderCellDef class="align-center" [matTooltip]="this.answersHint"> Resp. Prof. </th>
        <td mat-cell *matCellDef="let element" class="align-center">
            <mat-form-field style="width: 80px;">
                <mat-label>Resp</mat-label>
                <input matInput [(ngModel)]="element.teacherAnswer" maxlength="1" minlength="1"
                    [disabled]="this.answered" (keyup)="onKeypress($event)"  >
            </mat-form-field>
        </td>
    </ng-container>

    <ng-container matColumnDef="studentAnswer">
        <th mat-header-cell *matHeaderCellDef class="align-center" [matTooltip]="this.answersHint"> Resp. Alum. </th>
        <td mat-cell *matCellDef="let element" class="align-center">
            <mat-form-field style="width: 80px;">
                <mat-label>Resp</mat-label>
                <input matInput [(ngModel)]="element.studentAnswer" maxlength="1" minlength="1"
                    [disabled]="this.answered" (keyup)="onKeypress($event)">
            </mat-form-field>
        </td>
    </ng-container>
</table>
<br>

<table border="0" fxFlexFill class="mat-elevation-z0">
    <tr>
        <td>&nbsp;</td>
        <td style="width: 200px;">
            <button mat-raised-button fxFlexFill color="primary" (click)="saveAndFinish()"
                [disabled]="this.answered">Grabar y finalizar</button>
        </td>
        <td>&nbsp;</td>
        <td style="width: 200px;">
            <button mat-raised-button fxFlexFill (click)="saveAndContinue()" [disabled]="this.answered">Grabar y
                volver</button>
        </td>
        <td>&nbsp;</td>
        <td style="width: 200px;">
            <button mat-raised-button fxFlexFill (click)="goBack()">Volver</button>
        </td>
        <td>&nbsp;</td>
    </tr>
</table>