import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CourseService } from 'src/app/api/course.service';
import { LevelService } from 'src/app/api/level.service';
import { StudentService } from 'src/app/api/student.service';
import { Alumno } from 'src/app/model/alumno';
import { Course } from 'src/app/model/course';
import { Level } from 'src/app/model/level';

@Component({
  selector: 'app-change-status-dialog',
  templateUrl: './change-status-dialog.component.html',
  styleUrls: ['./change-status-dialog.component.css']
})
export class ChangeStatusDialogComponent implements OnInit {
  public options: ActionOption[] = [];
  public reasons: Reason[] = [];
  public changeStatusDialogForm: ChangeStatusDialogForm = new ChangeStatusDialogForm();
  public student: Alumno;
  public courses: Course[] = [];
  private sameLevelCourses: Course[] = [];
  private prevLevelCourses: Course[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ChangeStatusDialogParam,
    private studentService: StudentService,
    public courseService: CourseService,
    private levelService: LevelService) { }

  ngOnInit(): void {
    this.loadCourses();
    this.startupOptions();

    this.studentService.findById(this.dialogData.studentId)
      .subscribe(
        response => {
          console.log(response);
          this.student = response;
        },
        problems => console.error(problems)
      );


  }

  private async loadCourses(): Promise<void> {
    let  course: Course = await this.courseService.getAsync(this.dialogData.courseId);
    let  prevLevel: Level = await this.levelService.getPreviusAsync(course.level.id);
    this.sameLevelCourses = await this.courseService.listByLevelAndExcludeAsync(course.level.id, this.dialogData.courseId);
    this.prevLevelCourses = await this.courseService.listByLevelAndExcludeAsync(prevLevel.id, this.dialogData.courseId);
  }

  private startupOptions(): void {
    this.reasons[0] = { value: 'REPITENCIA', name: 'Repitencia' };
    this.reasons[1] = { value: 'ASISTENCIA', name: 'Asistencia' };
    this.reasons[2] = { value: 'CONDUCTA', name: 'Disciplina' };
    this.reasons[3] = { value: 'DEUDA', name: 'Deuda' };
    this.reasons[4] = { value: 'OTRO', name: 'Otro Motivo' };

    if (this.dialogData.status == 'XMT') {
      this.options[0] = { value: 'NOC', name: 'No continuará' };
      this.options[1] = { value: 'CHC', name: 'Cambio de curso' };
      this.options[2] = { value: 'REP', name: 'Reprobó año escolar' };
      this.options[3] = { value: 'NMT', name: 'Detener o cancelar matricula' };
    } else {
      this.options[0] = { value: 'CHC', name: 'Cambio de curso' };
    }

  }

  public changeAction(evt: MatSelectChange): void {
    this.processAction(evt.value);
  }

  public processAction(action: string): void {
    console.log('Change Action', action);

    switch (action) {
      case 'CHC':
        this.courses = [];
        this.sameLevelCourses.forEach(course => this.courses.push(course));
        break;
      case 'REP':
        this.courses = [];
        this.prevLevelCourses.forEach(course => this.courses.push(course));
        break;
      case 'NMT':
        this.dialogData.comment = '';
        this.dialogData.reason = null;
        break;
      default:
        break;
    }
    this.dialogData.newCourseId = null;
    this.validateForm();
  }

  public validateForm(): void {
    switch (this.dialogData.action) {
      case 'NOC':
        this.changeStatusDialogForm.validForm = (this.changeStatusDialogForm.rut == ('' + this.student.rut));
        break;
      case 'CHC':
      case 'REP':
        this.changeStatusDialogForm.validForm = !this.isNullOrEmpty(this.dialogData.newCourseId);
        break;
      case 'NMT':
        this.changeStatusDialogForm.validForm =
          this.dialogData.comment.length > 5 && !this.isNullOrEmpty(this.dialogData.reason);
        break;
      default:
        this.changeStatusDialogForm.validForm = false;
        break;
    }
  }

  private isNullOrEmpty(value: string): boolean {
    console.log('value', value);
    return value == null || value == '';
  }

}

export class ChangeStatusDialogForm {
  public rut: string;
  public validForm: boolean = false;
}

export interface ActionOption {
  value: string;
  name: string;
}

export interface Reason {
  value: string;
  name: string;
}

export class ChangeStatusDialogParam {
  status: string;
  action: string;
  studentId: string;
  courseId: string;
  newCourseId: string;
  comment: string;
  reason: string;
  studentName : string;
  studentRut: string;

}