import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-accident-attention-file-dialog',
  templateUrl: './upload-accident-attention-file-dialog.component.html',
  styleUrls: ['./upload-accident-attention-file-dialog.component.css']
})
export class UploadAccidentAttentionFileDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UploadAccidentAttentionFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: UploadAccidentAttentionFileDialogDataParam) { }

  ngOnInit(): void {
  }

  public onFileSelected(ev): void {
    this.dialogData.file = ev.target.files[0];
    if (this.dialogData.file) {
      this.dialogData.fileName = this.dialogData.file.name;
    }
  }


}

export class UploadAccidentAttentionFileDialogDataParam {
  file: File;
  fileName: string = '';
  accidentId: string;
}