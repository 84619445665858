import { HttpClient, HttpRequest } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { AuthorService } from 'src/app/api/author.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-license-dialog',
  templateUrl: './add-license-dialog.component.html',
  styleUrls: ['./add-license-dialog.component.css']
})
export class AddLicenseDialogComponent implements OnInit {
  //  public fileName: string = '';
  //  public file: File = null;

  constructor(public dialogRef: MatDialogRef<AddLicenseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: AddLicenseDialogDataParam,
    private http: HttpClient,
    private authorService: AuthorService) { }

  ngOnInit(): void {
    console.log(this.dialogData);
  }

  public onFileSelected(ev): void {
    this.dialogData.file = ev.target.files[0];
    if (this.dialogData.file) {
      this.dialogData.fileName = this.dialogData.file.name;
    }
  }

}


export class AddLicenseDialogDataParam {
  reason: string;
  type: string;
  start: Moment;
  finish: Moment;
  file: File;
  fileName: string = '';
  studentId: string;
}