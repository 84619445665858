import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import * as moment from 'moment';
import { ConfigService } from 'src/app/api/config.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { ManagementService } from 'src/app/api/management.service';
import { MineducService } from 'src/app/api/mineduc.service';
import { Alumno } from 'src/app/model/alumno';
import { AttendanceSummary, Day, StudentStatus } from 'src/app/model/attendance-summary';
import { Course } from 'src/app/model/course';
import { HourAndStatus } from 'src/app/model/hour-and-status';
import { MineducSummary } from 'src/app/model/mineduc-summary';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { AttendanceDetailDialogComponent, AttendanceDetailDialogParams } from './attendance-detail-dialog/attendance-detail-dialog.component';
import { SendToMineducDialogComponent, SendToMineducDialogParams } from './send-to-mineduc-dialog/send-to-mineduc-dialog.component';
// import * as moment from 'moment';

@Component({
  selector: 'app-attendance-summary',
  templateUrl: './attendance-summary.component1.html',
  styleUrls: ['./attendance-summary.component.css']
})
export class AttendanceSummaryComponent implements OnInit {
  public currentYear: number = 0;
  public month: number = 0;
  public months: Month[] = [];
  public courseId: string = '';
  public attendanceSummary: AttendanceSummary;
  private cells: Map<string, StudentStatus> = new Map();
  public mineducSummary: MineducSummary[];
  public sigeIntegrationEnable: boolean = false;

  constructor(private configService: ConfigService,
    private managementService: ManagementService,
    private dialog: MatDialog,
    private mineducService: MineducService,
    private dateTimeUtility: DateTimeUtility,
    private alertUtility: AlertUtility) { }

  ngOnInit(): void {
    this.months = [
      { number: 1, name: 'Enero' },
      { number: 2, name: 'Febrero' },
      { number: 3, name: 'Marzo' },
      { number: 4, name: 'Abril' },
      { number: 5, name: 'Mayo' },
      { number: 6, name: 'Junio' },
      { number: 7, name: 'Julio' },
      { number: 8, name: 'Agosto' },
      { number: 9, name: 'Septiembre' },
      { number: 10, name: 'Octubre' },
      { number: 11, name: 'Noviembre' },
      { number: 12, name: 'Diciembre' },
    ];

    this.configService.getCurrentYear().subscribe(response => this.currentYear = response);
    this.configService.getApplicationProperty('cl.buildersoft.sige.enable')
      .subscribe(response => {
        this.sigeIntegrationEnable = Boolean(response);

      });
  }

  public changeCourse(course: Course) {
    this.courseId = course.id;
    this.refreshMatrix();
  }

  public changeMonth(evt: MatSelectChange) {
    this.month = evt.source.value;
    this.refreshMatrix();
  }

  public refreshMatrix() {
    if (this.courseId == '' || this.month == 0) return;

    this.managementService.retrieveAttendance(this.courseId, this.month)
      .subscribe(
        (response: AttendanceSummary) => {
          this.processResponse(response);
          console.log(response);
        },
        problems => console.error(problems)
      );

    if (this.sigeIntegrationEnable) {
      this.managementService.retrieveMineducSummary(this.courseId, this.month)
        .subscribe(
          response => {
            this.mineducSummary = response;
            console.log(response);
          },
          problems => console.error(problems)
        );
    }
  }

  public filterDays(days: Day[]): Day[] {
    return days.filter(day => day.weekday != 'SUN');
  }

  public filterDaysForMineduc(days: MineducSummary[]): MineducSummary[] {
    return days.filter(day => day.weekday != 'SUN');
  }

  public convertWD(wd: string): string {
    switch (wd) {
      case 'MON':
        return 'Lu';
      case 'TUE':
        return 'Ma';
      case 'WED':
        return 'Mi';
      case 'THU':
        return 'Ju';
      case 'FRI':
        return 'Vi';
      case 'SAT':
        return 'Sa';
      case 'SUN':
        return 'Do';
      default:
        return '?';
    }
  }

  private getStatus(student: Alumno, day: Day): string {
    return this.getCell(student, day).status;
    //    return this.attendanceSummary.matrix.filter(cell => cell.studentId == student.id && cell.day.number == day.number)[0].status;
  }

  private getCell(student: Alumno, day: Day): StudentStatus {
    const key = `${student.id}#${day.number}`;
    return this.cells.get(key);
    //    return this.attendanceSummary.matrix.filter(cell => cell.studentId == student.id && cell.day.number == day.number)[0];
    // return this.attendanceSummary.matrix.filter(cell => cell.studentId == student.id && cell.day.number == day.number)[0];
  }

  public findCellColor(student: Alumno, day: Day): string {
    const status = this.getStatus(student, day);
    switch (status) {
      case 'UNASSISTED':
        return '';
      case 'PRESENT':
        return 'primary';
      case 'ABSENT':
        return 'accent';
      case 'PARTIAL':
      case 'RETIRED':
      case 'NON_WORK':
      case 'NOT_ENROLLED':
        return 'warn';
      default:
        return 'accent';
    }
  }

  public pointerForViewDetail(student: Alumno, day: Day): string {
    const status = this.getStatus(student, day);
    switch (status) {
      case 'PARTIAL':
        return 'cursor: pointer;';
      default:
        return '';
    }
  }


  public pointerDetailMineduc(item: MineducSummary): string {
    return item.status == 'S01' ? 'cursor: pointer;' : '';
  }

  public tooltipForMineducItem(item: MineducSummary): string {
    return item.status == 'S01' ? 'Actualizar estado...' : '';
  }


  public showDetailMineduc(item: MineducSummary): void {
    console.log(item);
    let detail: string = '';
    //    item.detail = ['uno', 'dos', 'tres'];

    if (item.status != 'S01') return;


    return;

    item.detail.forEach(element => {
      detail += element + '\n\r';
    });
    this.alertUtility.dialogMessage(detail);

    // return 'uno \n dos &#13; tres';
    // return detail;
  }

  public findCellDesc(student: Alumno, day: Day): string {
    const status: StudentStatus = this.getCell(student, day);
    switch (status.status) {
      case 'UNASSISTED':
      //        return 'Pendiente';
      case 'PRESENT':
      //        return 'Presente';
      case 'ABSENT':
        //        return 'Ausente';
        return ''; // `${status.absentCount} / ${status.lateCount} / ${status.marks}`;
      case 'PARTIAL':
        return `Parcial: ${status.absentCount}h ausente`;
      case 'RETIRED':
        return 'Retirado del establecimiento';
      case 'NON_WORK':
        return 'Día feriado';
      case 'NOT_ENROLLED':
        return 'Retirado del colegio';
      default:
        return 'Indeterminado';
    }
  }

  public findCellColorMineduc(mineducSummary: MineducSummary): string {
    switch (mineducSummary.status) {
      case 'S00':
        return '';
      case 'S01':
        return 'primary';
      case 'S02':
      case 'S03':
      case 'S04':
      case 'S05':
      case 'S06':
      case 'S07':
        return 'warn';
      case 'S11':
        return 'primary';
      case 'S12':
        return 'warn';
      case 'S13':
      case 'S14':
      case 'S15':
      case 'S16':
      case 'S17':
      case 'S18':
      case 'S19':
      case 'S20':
        return 'warn';
      default:
        return '';
    }
  }

  public findCellIconMineduc(mineducSummary: MineducSummary): string {
    switch (mineducSummary.status) {
      case 'S00':
        return 'check_box_outline_blank';
      case 'S01':
        return 'done';
      case 'S02':
      case 'S03':
      case 'S04':
      case 'S05':
      case 'S06':
      case 'S07':
        return 'cancel_schedule_send';
      case 'S11':
        return 'done_all';
      case 'S12':
        return 'chat';
      case 'S13':
      case 'S14':
      case 'S15':
      case 'S16':
      case 'S17':
      case 'S18':
      case 'S19':
      case 'S20':
        return 'error';
      default:
        return 'pending_actions';
    }

  }
  public findCellIcon(student: Alumno, day: Day): string {
    const status = this.getStatus(student, day);
    switch (status) {
      case 'UNASSISTED':
        return 'check_box_outline_blank';
      case 'PRESENT':
        return 'verified';
      case 'ABSENT':
        return 'dangerous';
      case 'PARTIAL':
        return 'rule';
      case 'RETIRED':
        return 'person_off';
      case 'NON_WORK':
        return 'event';
      case 'NOT_ENROLLED':
        return 'output';
      default:
        return 'help_center';
    }
  }

  private processResponse(attendanceSummary: AttendanceSummary) {
    this.attendanceSummary = attendanceSummary;
    this.attendanceSummary.matrix.forEach(cell => {
      const key = `${cell.studentId}#${cell.day.number}`;
      this.cells.set(key, cell);
    });
  }

  public doSendToMineduc(ev) {
    const dialogParam: SendToMineducDialogParams = new SendToMineducDialogParams();
    dialogParam.currentYear = this.currentYear;
    dialogParam.month = this.month;

    // let minDate: Moment = moment();
    // minDate.date(1);
    // minDate.month(this.month - 1);
    // minDate.year(this.currentYear);

    // let nextMonth: Moment = moment(minDate);
    // nextMonth.add(1, 'month');
    // dialogParam.startDate = moment();
    // dialogParam.startDate.date(nextMonth.add(-1, 'day').date());
    // dialogParam.startDate.month(this.month - 1);
    // dialogParam.startDate.year(this.currentYear);


    const dialogRef = this.dialog.open(SendToMineducDialogComponent, { data: dialogParam });
    dialogRef.afterClosed()
      .subscribe(
        (response: SendToMineducDialogParams) => {
          console.log(response);
          if (response == undefined || !response.doSend) return;
          if (response.startDate == undefined || response.finishDate == undefined) return;
          console.log('processing');

          const start: string = this.dateTimeUtility.momentToString(response.startDate);
          const finish: string = this.dateTimeUtility.momentToString(response.finishDate);


          this.mineducService.sendMineducInfo(start, finish, this.courseId, this.month)
            .subscribe(
              response => {
                console.log(response);
                this.mineducSummary = response;
              },
              problems => console.error(problems)
            );
        },
        problems => console.error(problems)
      )
  }


  public showDetailCell(student: Alumno, day: Day): void {
    const status = this.getStatus(student, day);
    console.log(status, student, day);

   if (status != 'PARTIAL') return;

    // const date:  Moment = moment(this.currentYear, this.month, day.number);
    const date = moment(this.currentYear + '-' + this.month + '-' + day.number, 'YYYY-MM-DD');

    this.managementService.retrieveAttendanceDetail(student.id, this.courseId, date.format('YYYY-MM-DD'))
      .subscribe(
        (response: HourAndStatus[]) => {

          console.log(response);

          const params: AttendanceDetailDialogParams = new AttendanceDetailDialogParams();
          params.detail = response;
          params.dateSelected = date.format('DD/MM/YYYY');
          params.studentName = `${student.paterno} ${student.materno}, ${student.nombres}`;
          this.dialog.open(AttendanceDetailDialogComponent, { data: params });
        },
        problems => console.log(problems)
      );
  }

}

export class Month {
  number: number;
  name: string;
}