import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../alumno.component';
// import { MatSelectModule } from '@angular/material/select';
import { Alumno } from '../../../model/alumno';
import { StudentService } from '../../../api/student.service';
import { CourseService } from '../../../api/course.service';
import { Course } from '../../../model/course';

@Component({
  selector: 'app-change-course-dialog',
  templateUrl: './change-course-dialog.component.html',
  styleUrls: ['./change-course-dialog.component.css']
})
export class ChangeCourseDialogComponent implements OnInit {
  courses: Course[] = [];


  constructor(
    public dialogRef: MatDialogRef<ChangeCourseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public changeCourseResponse: DialogData,
    public alumnoService: StudentService,
    public courseService: CourseService) { }

  ngOnInit(): void {
    this.loadInitData(sessionStorage.getItem('alumnoId'));

  }

  async loadInitData(alumnoId: string) {
    let alumno = await this.alumnoService.findByIdAsync(alumnoId);
    let cursos = await this.courseService.listAllByYearAsync(alumno.agnoActual);
    console.log("ALUMNO:", alumno);
    console.log("CURSOS:", cursos);

    cursos.forEach(curso => {
      if (curso.id != alumno.courseDto.id && curso.letra.charAt(0) == alumno.courseDto.letra.charAt(0)) {
        this.courses.push(curso);
      }
    });
  }

  onNoClick(): void {
//    this.changeCourseResponse = '';
    this.dialogRef.close();
  }

  onAccept() {
    this.dialogRef.close();
  }
}
