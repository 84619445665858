<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" fxFlexOffset="10px" fxFlexFill>

    <table mat-table [dataSource]="dataSource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'retired': row.studentState == 'RET'}">
        </tr>

        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="align-center"> Num </th>
            <td mat-cell *matCellDef="let record" class="align-center">
                {{ record.listNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="studentName">
            <th mat-header-cell *matHeaderCellDef class="align-left"> Alumno </th>
            <td mat-cell *matCellDef="let record" class="align-left"> {{record.studentName }} </td>
        </ng-container>


        <ng-container matColumnDef="exam-value">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Nota Examen </th>
            <td mat-cell *matCellDef="let record" class="align-right">

                <div *ngIf="record.studentState == 'MAT' ;then content else other_content">
                </div>
                <ng-template #content>
                    <mat-form-field appearance="outline" style="width:'50px';">
                        <input matInput [(ngModel)]="record.score" type="text" 
                        (blur)="onBlurScore($event)"
                            (focus)="onFocusScore($event)" 
                            [ngClass]="{'low-score':record.score < 4}" 
                            [id]="record.studentId">
                    </mat-form-field>
                </ng-template>

                <ng-template #other_content>
                    <div [ngClass]="{'low-score':record.score < 4}">{{record.score}}
                    </div>
                </ng-template>

            </td>
        </ng-container>

        <ng-container matColumnDef="exam-percent">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Porcentaje </th>
            <td mat-cell *matCellDef="let record" class="align-right"> {{record.percent }} </td>
        </ng-container>

    </table>
    <div class="button-row">
        <button mat-raised-button color="primary" *ngIf="dataSource.length > 0" (click)="onSaveExams()">Grabar
            resultados de examenes</button>
    </div>


</div>