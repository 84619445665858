import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthorService } from 'src/app/api/author.service';
import { ConfigService } from 'src/app/api/config.service';
import { PermissionService } from 'src/app/api/permission.service';
import { ChangePasswordDialogComponent, ChangePasswordParamDialog } from 'src/app/gui-comp/change-password-dialog/change-password-dialog.component';
import { ChangePassword } from 'src/app/model/change-password';
import { Role } from 'src/app/model/role';

@Component({
  selector: 'app-home',
  templateUrl: './home.component1.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public currentMenu: MenuItem;
  // public rols: Role[];

  public menuItems: MenuItem[] = [
    { label: 'Inicio', route: 'welcome' },
    { label: 'Ficha de alumno', route: 'ficha', actions: ['STUDENT_RESUME'] },
    { label: 'Admisión/Matricula', route: 'admission-enrollment', actions: ['ADMISION'], configured: 'habilita_matriculas' },
    { label: 'Cursos', route: 'courses', actions: ['COURSES'] },
    { label: 'Calificaciones', route: 'calificaciones', actions: ['SCORING'] },
    { label: 'Apoderados', route: 'attorney', icon: '' , actions: ['ATTONEY'] },
    { label: 'Planificación', route: 'lectionary-planning', actions: ['PLANNING'] },
    { label: 'Horarios', route: 'schedule', actions: ['SCHEDULE'] },
    { label: 'Gestión', route: 'management', actions: ['MANAGEMENT'] },
    { label: 'Administración', route: 'main-crud', actions: ['ADMINISTRATION'] },
    { label: 'Practicas', route: 'practices', actions: ['PRACTICS'] },

  ];

  constructor(public authorService: AuthorService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private permissionService: PermissionService,
    private configService: ConfigService) { }

  ngOnInit(): void {
    this.configureMenu();
  }

  private async configureMenu(): Promise<void> {
    this.menuItems
      .forEach(async (menuItem: MenuItem) => {
        menuItem.showMenu = true;
        if (menuItem.actions) {
          menuItem.showMenu = await this.permissionService.hasPermissionsAsync(menuItem.actions);
        }
        if (menuItem.configured) {
          menuItem.showMenu = menuItem.showMenu && (await this.configService.findByKeyAsync(menuItem.configured)).valor == 'true';
        }
      });

  }

  public onClickMenuItem(menuItem: MenuItem) {
    this.currentMenu = menuItem;
    this.router.navigate([menuItem.route]);
  }

  public filterByRol(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.filter((menuItem: MenuItem, i: number) => {
      if (menuItem.showMenu != null) return menuItem.showMenu;
      return menuItem.rols == null || this.authorService.hasAnyRol(menuItem.rols);
    });
  }

  public getUserRols(): string {
    // const user = this.authorService.user;
    const rols: Role[] = this.authorService.rols;
    let rolsName: string = '';

    rols.forEach(rol =>
      rolsName += rol.name + ', '
    );

    rolsName = rolsName.substring(0, rolsName.length - 2);
    return rolsName;
  }

  getUserName(): string {
    const user = this.authorService.user;
    return user.names + ' ' + user.fatherSurname;
  }

  onClickLogout(): void {
    this.authorService.logout();
    this.router.navigate(['login']);
  }

  onClickOption(opt: number) {
    //    console.log(this.menuItems[opt]);
    this.router.navigate([this.menuItems[opt]]);
  }

  public onChangePassword() {
    const changePasswordParamDialog: ChangePasswordParamDialog = new ChangePasswordParamDialog();
    changePasswordParamDialog.username = this.authorService.user.username;

    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, { data: changePasswordParamDialog, width: '60%' });

    dialogRef.afterClosed().subscribe((result: ChangePasswordParamDialog) => {
      let changePassword: ChangePassword = new ChangePassword();
      changePassword.oldPassword = result.oldPassword;
      changePassword.newPassword1 = result.newPassword1;
      changePassword.newPassword2 = result.newPassword2;
      changePassword.username = this.authorService.user.username;

      this
        .authorService
        .changePassword(changePassword)
        .subscribe(
          response => {
            if (!response.isChanged) {
              this.snackBar.open(response.failReason, 'OK', { duration: 5000 });
              return;
            }
            this.snackBar.open('Clave cambiada, le recomentamos acceder nuevamente con la nueva clave', 'OK', { duration: 5000 });
          },
          problems => this.snackBar.open(problems.error.message, 'OK', { duration: 5000 })
        );

    });
  }
}

export class MenuItem {
  label: string;
  route: string;
  rols?: string[];
  actions?: string[];
  showMenu?: boolean = null;
  configured?: string = null;
  icon?: string;
}
