<h1 mat-dialog-title>Cambio de contraseña</h1>
<div mat-dialog-content fxLayout="column">

    <mat-form-field appearance="fill">
        <mat-label>Usuario</mat-label>
        <input matInput [(ngModel)]="this.dialogData.username" readonly="true">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Contraseña anterior</mat-label>
        <input matInput [(ngModel)]="this.dialogData.oldPassword" readonly="false" type="password">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Contraseña nueva</mat-label>
        <input matInput [(ngModel)]="this.dialogData.newPassword1" readonly="false" type="password">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Confirmación de contraseña nueva</mat-label>
        <input matInput [(ngModel)]="this.dialogData.newPassword2" readonly="false" type="password">
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">Cancelar</button>
</div>