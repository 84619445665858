<div fxFlex class="container">
    <div fxLayout="column">
        <h1>Matricula de alumnos antiguos, año {{nextYear}}</h1>
        <div fxFlex fxFlexOffset="5">  </div>
    </div>
  
    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
        <div fxFlex fxFlexOffset="10" fxFlex="60">
            <mat-form-field fxFlex>
                <mat-label>Busque aqui por Apellido o Rut</mat-label>
                <input matInput placeholder="Ejemplo: Perez" autocomplete="off" 
                    [(ngModel)]="rutOrPaterno"
                    (keydown.enter)="onSearchClick()">
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" (click)="onSearchClick()">Buscar</button>
    </div>


    <div *ngIf="showResult" fxLayout="column" class="mat-elevation-z1" class="container">

        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="rut">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Rut </th>
              <td mat-cell *matCellDef="let row" class="align-center"> {{row.rut}}-{{row.dv}} </td>
            </ng-container>
       
            <ng-container matColumnDef="nombre">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Nombre </th>
              <td mat-cell *matCellDef="let row" class="align-left"><div>{{row.paterno}} {{row.materno}}, {{row.nombres}}</div></td>
            </ng-container>
        
            <ng-container matColumnDef="especialidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Especialidad </th>
              <td mat-cell *matCellDef="let row" class="align-left"> {{row.courseDto.especialidadNombre  }} </td>
            </ng-container>
        <!--
             <ng-container matColumnDef="canal">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Canal </th>
              <td mat-cell *matCellDef="let row"> {{row.canal}} </td>
            </ng-container>
        -->
            <ng-container matColumnDef="curso">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Curso para el {{this.nextYear}}</th>
              <td mat-cell *matCellDef="let row" class="align-center">{{row.course}} </td>
            </ng-container>
        
            <ng-container matColumnDef="nro_matricula">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Nro Matricula </th>
              <td mat-cell *matCellDef="let row" class="align-center"> {{ row.numeroMatricula }}</td>
            </ng-container>
         
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onPressNameClick(row)" style="cursor: pointer;"></tr>
          </table>
      
    </div>