<h1 mat-dialog-title>Detalle de reunión</h1>

<div mat-dialog-content>
    <table>
        <tr>
            <td>
                <strong>Curso:</strong> {{courseService.formatLetra(this.dialogData.course.letra)}}
            </td>
        </tr>
        <tr>
            <td>
                <strong>Profesor:</strong> {{this.authService.user.fatherSurname + ' '+
                this.authService.user.motherSurname + ', ' + this.authService.user.names}}
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field fxFlexFill>
                    <mat-label>Asunto</mat-label>

                    <mat-select [(ngModel)]="this.dialogData.subject">
                        <mat-option *ngFor="let summaryMeet of this.summaryMeets"
                            [value]="summaryMeet.key">{{summaryMeet.name}}</mat-option>
                    </mat-select>


                </mat-form-field>



            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field fxFlexFill>
                    <mat-label>Fecha de reunión</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="this.dialogData.dateTime">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </td>
        </tr>
    </table>

</div>

<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary"
        [disabled]="this.dialogData.viewAsManager">
        <mat-icon>save</mat-icon>
        Aceptar
    </button>
    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>