import {LiveAnnouncer} from '@angular/cdk/a11y';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import { CompanyService } from 'src/app/api/company.service';
import { Company } from 'src/app/model/company';


@Component({
  selector: 'app-list-companies',
  templateUrl: './list-companies.component.html',
  styleUrls: ['./list-companies.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatTableModule, MatSortModule,MatPaginatorModule, MatIconModule]
})
export class ListCompaniesComponent implements AfterViewInit {
  displayedColumns: string[] = ['Empresa', 'Rut', 'N° Alumnos', 'Pago','Sueldo','Acción'];
  dataSource: MatTableDataSource<Company>;
  companiesData: Company[]  = [];
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  year: number = new Date().getFullYear();

  constructor(private companyService: CompanyService,private _liveAnnouncer: LiveAnnouncer) {}

  async ngOnInit() {

    await this.companyService
    .getCompaniesYear(this.year)
    .subscribe(
      response => {
        this.companiesData = response;
        this.dataSource = new MatTableDataSource(response);
        this.dataSource.paginator = this.paginator;
      },
      problems => {
        console.log(problems);

      }
    );
  }

  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  practicesOnClick(rut_emp: string){
    console.log(rut_emp);
  }
}
