<mat-tab-group mat-align-tabs="start" animationDuration="400ms" [selectedIndex]="this.tabSelected">
    <mat-tab label="Configuración">
        <app-generic-crud entityId="f2bb4c34-5a48-11ed-a649-e86a642fcd99" tabSelected="0" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>

    <mat-tab label="Usuarios">
        <app-generic-crud entityId="b7b64a1f-0dc9-11ed-b9a1-00090faa0001" tabSelected="1" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>
    
    <mat-tab label="Roles">
        <app-generic-crud entityId="b5525dc6-2219-11ed-9ab7-00090faa0001" tabSelected="2" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>

    <mat-tab label="Paises/Gentilicios">
        <app-generic-crud entityId="3e9844cb-7bf1-11ed-9ea2-e86a642fcd99" tabSelected="3" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>

    <mat-tab label="Acciones">
        <app-generic-crud entityId="85c7d7e0-ab2a-11ed-ab67-e86a642fcd99" tabSelected="4" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>

    <mat-tab label="Asignaturas">
        <app-generic-crud entityId="a7e34c05-af0f-11ed-a007-e86a642fcd99" tabSelected="5" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>
    <mat-tab label="Tipos de Anotaciones">
        <app-generic-crud entityId="b7815373-bf86-11ed-90f9-0800277eb97e" tabSelected="6" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>
    <mat-tab label="Plantillas de Anotaciones">
        <app-generic-crud entityId="1d0cb66a-bf86-11ed-90f9-0800277eb97e" tabSelected="7" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>
    <mat-tab label="Objetivos Educativos Genericos">
        <app-generic-crud entityId="d1278e3d-c8ff-11ed-9278-0800277eb97e" tabSelected="8" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>

    <mat-tab label="Años escolares">
        <app-generic-crud entityId="29ce4ceb-b7f2-11ee-af89-6c2408faebe1" tabSelected="9" navigateBack="main-crud">
        </app-generic-crud>
    </mat-tab>
    
    


    <!--
    <mat-tab label="Tipos de eventos">Tipos de eventos</mat-tab>
    <mat-tab label="Especialidades">Especialidades</mat-tab>
    <mat-tab label="Comunas">Comunas</mat-tab>
    <mat-tab label="Provincias">Provincias</mat-tab>
    <mat-tab label="Asignaturas">Asignaturas</mat-tab>
    -->


</mat-tab-group>