import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Moment } from 'moment';

@Component({
  selector: 'app-dates-class-dialog',
  templateUrl: './dates-class-dialog.component.html',
  styleUrls: ['./dates-class-dialog.component.css']
})
export class DatesClassDialogComponent implements OnInit {

  public planningYearFilter = (d: Moment | null): boolean => {
    return d.year() == this.dialogData.planningYear;
  }


  constructor(public dialogRef: MatDialogRef<DatesClassDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DatesClassDialog) { }

  ngOnInit(): void {
  }
}

export class DatesClassDialog {
  detail: string;
  startDate: Date;
  finishDate: Date;
  planningYear: number;
  readonly : boolean;
}



