import { Course } from "./course";

export class FailureProjectionReport {
    failureProjection: FailureProjection[];

}


export class FailureProjection {
    course: Course;
    failureStudentsCount: number;
    studentSubjectsReprobedCount: number;
    periodStudentLessScores: PeriodStudentLessScore[];
    lessAttendanceStudentsCount: number;
}

export class PeriodStudentLessScore {
    period: number;
    studentIds: string[];
}