import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Assistance } from '../gui/courses/course-assistance/assistance';
import { AssistanceBlock } from '../gui/courses/course-assistance/assistance-block';
import { LocalDate } from '../model/local-date';
import { AuthorService } from './author.service';
import { DateTimeUtility } from "./date-time-utility.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class AssistanceService {
    private baseUrl: string = environment.apiUrl;
    private currentDateUrl: string = '/1.0/current-date';
    private firstDateUrl: string = '/1.0/first-date';


    constructor(private http: HttpClient,
        private authorService: AuthorService,
        private dtUtility: DateTimeUtility) {
    }

    public listAssistance(courseId: string, date: string, timeBlockId: string, teacherName: string, subjectId: string): Observable<Assistance[]> {
        const url = this.baseUrl + `/api/lichan/assistance/v2/list-assistance/${courseId}/${date}/${timeBlockId}/${teacherName}/${subjectId}`;
        console.log(url);
        return this.http.get<Assistance[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async getCurrentDateAsync(): Promise<string> {
        const url = this.baseUrl + `/api/lichan/assistance${this.currentDateUrl}`;

        const response: any = await this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
        const date: Date = new Date(response[0], response[1] - 1, response[2]);
        //        console.log('anio', this.dtUtility.dateToString(date));

        return this.dtUtility.dateToString(date);
    }

    public getFirstDate(): Observable<LocalDate> {
        const url = this.baseUrl + `/api/lichan/assistance${this.firstDateUrl}`;
        return this.http.get<LocalDate>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async getFirstDateAsync(): Promise<string> {
        const url = this.baseUrl + `/api/lichan/assistance${this.firstDateUrl}`;

        const response: any = await this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
        const date: Date = new Date(response[0], response[1] - 1, response[2]);

        return this.dtUtility.dateToString(date);
        // return this.http.get<LocalDate>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public saveAssistanceBlock(assistanceBlock: AssistanceBlock): Observable<string> {
        const url = this.baseUrl + '/api/lichan/assistance/1.0/save';
        return this.http.post<string>(url, assistanceBlock, { headers: this.authorService.headerWithAuthorization(null) });
    }

}