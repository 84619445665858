import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AdultService } from 'src/app/api/adult.service';
import { ContactDataService } from 'src/app/api/contact-data.service';
import { ValidateRut } from 'src/app/gui-comp/rut-input/rut-input.component';
import { Adulto } from 'src/app/model/adulto';
import { ContactData } from 'src/app/model/contact-data';
import { RutUtility } from 'src/app/utility/rut.utility';
import { map, startWith } from 'rxjs/operators';
import { DOWN_ARROW, UP_ARROW } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-legal-guardian-dialog',
  templateUrl: './legal-guardian-dialog.component.html',
  styleUrls: ['./legal-guardian-dialog.component.css']
})
export class LegalGuardianDialogComponent implements OnInit {
  public formControl: FormControl = new FormControl('');
  public filteredOptions: Observable<string[]>;
  public addresses: string[] = [];
  public relationships: { id: string; name: string; }[] = [];
  private forSearchAddress: string = '';

  constructor(public dialogRef: MatDialogRef<LegalGuardianDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public legalGuardianDialogParam: LegalGuardianDialogParam,
    private adultService: AdultService,
    private rutUtility: RutUtility,
    private contactDataService: ContactDataService) { }

  ngOnInit(): void {
    console.log('In Dialog: ', this.legalGuardianDialogParam);

    this.relationships = [
      { id: 'APODERADO', name: 'Apoderado/a' },
      { id: 'PADRE', name: 'Padre' },
      { id: 'MADRE', name: 'Madre' },
      { id: 'ABUELO', name: 'Abuelo/a' },
      { id: 'TIO', name: 'Tío/a' },
      { id: 'TUTOR', name: 'Tutor/a' },
      { id: 'INSTRUCTOR', name: 'Instructor/a' },
      { id: 'OTRO', name: 'Otro' }
    ];
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.addresses.filter(option => option.toLowerCase().includes(filterValue));
  }

  public validateRut(eve: ValidateRut): void {
    console.log(eve, this.legalGuardianDialogParam.rut);
    if (eve.rut == '') return;

    this.adultService.getByRut(this.rutUtility.extractNumber(eve.rut))
      .subscribe(
        (response: Adulto) => {
          console.log(response);
          if (response != null) {
            this.legalGuardianDialogParam.name = response.nombre;
            this.legalGuardianDialogParam.activity = response.actividad;
            this.legalGuardianDialogParam.studies = response.estudios;
            this.legalGuardianDialogParam.cellPhoneNumber = response.datosContacto?.['FONO_MOVIL'];
            this.legalGuardianDialogParam.email = response.datosContacto?.['EMAIL'];
            this.legalGuardianDialogParam.address = response.datosContacto?.['DIRECCION'];
            this.legalGuardianDialogParam.adultId = response.id;
            this.legalGuardianDialogParam.rut = '' + eve.rut;
          } else {
            this.legalGuardianDialogParam.rut = '' + eve.rut;
          }
        },
        problems => console.error(problems)
      );
  }

  public findAddress(eve: KeyboardEvent): void {
    if (this.legalGuardianDialogParam.address.length >= 3 &&
      this.forSearchAddress != this.legalGuardianDialogParam.address) {
      this.forSearchAddress = this.legalGuardianDialogParam.address;

      console.log('Buscando por', this.legalGuardianDialogParam.address);
      this.contactDataService.findByValue('DIRECCION', this.legalGuardianDialogParam.address)
        .subscribe(
          (response: ContactData[]) => {
            console.log(response);
            this.addresses = response.map(contactData => contactData.valor);
          },
          problems => console.error(problems)
        );
    } else {
      this.addresses = [];
    }
  }

}

export class LegalGuardianDialogParam {
  id: string;
  canAdminContacts: boolean = false;
  adultId: string;
  studentId: string;
  rut: string;
  name: string;
  relationshipName: string;
  relationshipId: string;
  cellPhoneNumber: string;
  email: string;
  address: string;
  activity: string;
  studies: string;

  landline: string;
  instagram: string;
  facebook: string;
  web: string;
  linkedin: string;
  other: string;
}