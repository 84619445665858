<h1 mat-dialog-title>Antecedentes médicos</h1>

<div mat-dialog-content>
    <table>
        <tr>
            <td>
                <com-student-panel [student]="this.dialogData.student" [showAge]="true" [showCourse]="true"
                    [showAttorneyData]="false" [showRut]="true"></com-student-panel>
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>Tipo</mat-label>
                    <mat-select [(ngModel)]="this.dialogData.studentHealth.healthState">
                        <mat-option *ngFor="let healthState of healthStates" [value]="healthState.id">
                            {{healthState.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>Detalle de condición</mat-label>
                    <textarea matInput rows="5" [(ngModel)]="this.dialogData.studentHealth.detail"
                        placeholder="Puede describir detalladamente la condición médica..."></textarea>
                </mat-form-field>

            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>Tratamiento</mat-label>
                    <textarea matInput rows="5" [(ngModel)]="this.dialogData.studentHealth.treatment"
                        placeholder="Puede describir detalladamente el tratamiento..."></textarea>
                </mat-form-field>

            </td>
        </tr>
    </table>
    <!--
    <br>
    

    <br>
    
-->
</div>

<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary"
        [disabled]="this.dialogData.studentHealth.healthState == null">
        <mat-icon>save</mat-icon>
        Grabar
    </button>
    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>