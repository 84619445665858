export class EnrollForm {
    nombres: string = '';
    paterno: string = '';
    materno: string = '';
    rut: string = '';
    nacimiento: string = '';
    direccion: string = '';
    comuna: string = '';
    especialidad: string = '';
    especialidadNombre: string = '';
    nombreApoderado: string = '';
    rutApoderado: string = '';
    fonoApoderado: string = '';
    mailApoderado: string = '';
    nombreColegioProcedencia: string = '';
    comunaColegioProcedencia: string = '';
    enrollNumber: number = 0;

    familia: number = 0;
    prevision: string = '';
    tieneInternet: string = "true";
    cuantosPc: number = 0;

    nationalityId: string;
    gender: string;
    takeReligion: boolean = true;
    requiresPsychologist: boolean = false;

    socialName: string = '';

}