<h1>Módulo de Gestión</h1>

<mat-tab-group mat-align-tabs="start" animationDuration="750ms" [selectedIndex]="this.indexSelected">
    <mat-tab label="Asistencia por curso">
        <app-attendance-summary></app-attendance-summary>
    </mat-tab>
   
    <mat-tab label="Firmas" *ngIf="this.showSignaturesTab">
        <app-signatures></app-signatures>
    </mat-tab>

    <mat-tab label="Proyección de reprobados" *ngIf="this.showFailProjection">
        <app-failure-projection></app-failure-projection>
    </mat-tab>

    <mat-tab *ngIf="this.showLectionaries">
        <ng-template mat-tab-label>
            Leccionarios realizados&nbsp;
            <mat-icon color="accent" matTooltip="Nuevo!" matTooltipPosition="above">new_releases</mat-icon>
        </ng-template>
        <app-lectionary-summary></app-lectionary-summary>
    </mat-tab>
    
</mat-tab-group>

