<h1 mat-dialog-title>Asistencia a la reunión
    {{this.dtUtility.dateToHumanString(this.dtUtility.stringToDate(this.dialogData.meetingDate))}}.
    {{this.courseService.formatLetra(this.dialogData.courseLeter)}}</h1>

<div mat-dialog-content>
    <table mat-table [dataSource]="datasource" fxFlexFill *ngIf="datasource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="num">
            <th mat-header-cell *matHeaderCellDef class="align-left">Num</th>
            <td mat-cell *matCellDef="let element; let i = index" class="align-left">{{i+1}} </td>
        </ng-container>

        <ng-container matColumnDef="student">
            <th mat-header-cell *matHeaderCellDef class="align-left">Alumno</th>
            <td mat-cell *matCellDef="let element" class="align-left">{{element.studentName }} </td>
        </ng-container>
        <ng-container matColumnDef="attorney">
            <th mat-header-cell *matHeaderCellDef class="align-left">Apoderado</th>
            <td mat-cell *matCellDef="let element" class="align-left">{{ element.attorneyName}} </td>
        </ng-container>
        <ng-container matColumnDef="attendance">
            <th mat-header-cell *matHeaderCellDef class="align-center">Asistencia</th>
            <td mat-cell *matCellDef="let element" class="align-center">
                <mat-checkbox [(ngModel)]="element.assist" (change)="changeAttendance(element.id, element.assist)"
                    [disabled]="this.dialogData.viewAsManager"></mat-checkbox>
            </td>
        </ng-container>


    </table>

</div>

<div mat-dialog-actions>

    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>