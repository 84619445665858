import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Resource } from "../model/resource";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ResourceService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/resource/1.0';
    private listAllUrl: string = '/list-all';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listAll(): Observable<Resource[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listAllUrl}`;
        return this.http.get<Resource[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }


}