import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionService } from 'src/app/api/permission.service';
import { TeacherService } from 'src/app/api/teacher.service';
import { Course } from 'src/app/model/course';
import { User } from 'src/app/model/user';
import { ConfigService } from '../../api/config.service';
import { CourseService } from '../../api/course.service';
import { RatingBookService } from '../../api/rating-book.service';
import { SubjectService } from '../../api/subject.service';
import { Average } from '../../model/average';
import { Rating } from '../../model/rating';
import { RatingBook } from '../../model/rating-book';
import { Subject } from '../../model/subject';
import { Config } from 'src/app/model/config';
import { AlertUtility, DialogMessageType } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-calificaciones',
  templateUrl: './calificaciones.component.html',
  styleUrls: ['./calificaciones.component.css', '../../style/table.css']
})
export class CalificacionesComponent implements OnInit {
  courses: Course[] = [];
  subjects: Subject[] = [];
  courseId: string = '';
  subjectId: string = '';
  userName: string = '';
  period: number = 0;
  periods: number[] = [];
  ratingBook: RatingBook = new RatingBook();
  verticalFill: boolean = true;
  canUnblock: boolean = false;
  canBackPeriod: boolean = false;
  blockAllScores: boolean = false;
  canAdminExams: boolean = false;

  displayedColumns: string[] = ['num', 'studentName'];
  dataSource: Rating[] = [];

  showSpinner: boolean = false;

  currentValue: string = '';
  public teacher: User = null;

  @ViewChild('courseSelect') courseSelect: MatSelect;

  constructor(public courseService: CourseService,
    private subjectService: SubjectService,
    private configService: ConfigService,
    private permissionService: PermissionService,
    private ratingBookService: RatingBookService,
    private snackBar: MatSnackBar,
    private teacherService: TeacherService,
    private alertUtility: AlertUtility) {
  }

  ngOnInit(): void {
    for (let n: number = 1; n <= 13; n++) {
      this.displayedColumns.push('score' + n);
    }
    this.displayedColumns.push('average2dec');
    this.displayedColumns.push('average1dec');
    this.displayedColumns.push('lockColumn');

    this.courseService.listAll()
      .subscribe(response => {
        this.courses = response;
      });

    this.configService.findByKey('periodo_actual')
      .subscribe(
        (response: Config) => this.period = +response.valor,
        problems => console.error(problems)
      );

    this.configService.findByKey('periodos')
      .subscribe(
        (response: Config) => {
          const periods = +response.valor;
          for (let index = 0; index < periods; index++) {
            this.periods.push(index);
          }
        },
        problems => console.error(problems)
      );

    this.retrievePermisions();
    this.userName = '';
  }

  private async retrievePermisions() {
    this.canUnblock = await this.permissionService.hasPermissionAsync('unlock-score');
    this.canBackPeriod = await this.permissionService.hasPermissionAsync('SCORING.BACK_PERIOD');
    this.canAdminExams     = await this.permissionService.hasPermissionAsync('SCORING.EXAMS');
  }

  public onChangeCourse(): void {
    this.subjectId = '';
    this.dataSource = [];
    this.subjectService.listByCourse(this.courseId)
      .subscribe(
        response => {
          this.subjects = response;
          this.refreshTeacherName();
        },
        problems => console.log(problems)
      );

  }

  public onChangeSubject(): void {
    this.refreshMatrix();
    this.refreshTeacherName();

  }

  public onChangePeriod(): void {
    this.refreshMatrix();
  }

  private refreshMatrix(): void {
    this.progressBarLoading(true);
    this.dataSource = [];

    this.ratingBookService.findByCourseAndSubject_V2(this.courseId, this.subjectId, this.period)
      .subscribe(response => {
        this.ratingBook = response;
        this.dataSource = this.ratingBook.ratings;
        this.dataSource.forEach(
          rating => {
            this.clearZeros(rating);
          }
        );
        this.progressBarLoading(false);
      },
        problems => console.error(problems)
      );

  }


  private refreshTeacherName(): void {
    if (this.courseId == '' || this.subjectId == '') {
      this.teacher = null;
      return;
    }

    this.teacherService.listByCourseAndSubject(this.courseId, this.subjectId)
      .subscribe(
        response => {
          this.teacher = response[0];
        },
        problems => console.error(problems)
      );

  }

  clearZeros(rating: Rating) {
    let value: number;
    for (let i = 1; i <= 15; i++) {
      value = rating['score' + this.fix(i)];
      if (value == 0) {
        rating['score' + this.fix(i)] = '';
      }
    }
  }

  fix(n: number): string {
    return n <= 9 ? '0' + n : '' + n;
  }

  progressBarLoading(loading: boolean): void {
    this.showSpinner = loading;
  }

  public async onSaveRatings(): Promise<void> {
    const passwordCorrect = await this.alertUtility.verifyUserAndPassword('¿Desea grabar las notas?', true, false);
    if (passwordCorrect.isValid) {
      await this.saveAllCalifications();
      this.alertUtility.fastMessage('Notas grabas correctamente');
    } else {
      this.alertUtility.dialogMessage('Clave invalida, las notas no fueron grabadas...', 'Atención', DialogMessageType.WARNING);
    }

  }

  private async saveAllCalifications(): Promise<void> {
    this.ratingBook.ratings = this.dataSource;

    this.progressBarLoading(true);

    this.ratingBookService.save(this.ratingBook)
      .subscribe(
        response => {
          this.ratingBook = response;
          this.dataSource = response.ratings;

          this.dataSource.forEach(
            rating => {
              this.clearZeros(rating);
            }
          );

          this.progressBarLoading(false);
          // this.snackBar.open(`Calificaciones grabadas!`, 'OK', { duration: 3000 });
        },
        problems => {
          console.error(problems);
          // this.snackBar.open(`${problems.error.message}`, 'OK');
          this.progressBarLoading(false);
        }
      );
  }

  onFocusScore(e: FocusEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    const inputId: string = input.id;
    const rating: Rating = this.getRatingByCompositeId(inputId, this.dataSource);
    const index: number = this.getScoreId(inputId);
    let score: string = this.getScore(rating, index);

    // console.log('score', score);

    score = score.replace('.', '');
    this.setScore(rating, index, score);

    this.currentValue = score;
  }

  onBlurScore(e: FocusEvent): void {
    this.onBlurScore1_1(e);
  }

  private onBlurScore1_1(e: FocusEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    const newValue = input.value;
    const inputId: string = input.id;
    const colNumber: number = this.getScoreId(inputId);
    const rating: Rating = this.getRatingByCompositeId(inputId, this.dataSource);
    const average: Average = new Average();
    const areSame: boolean = this.currentValue === newValue;

    let values: number[] = [];
    for (let i = 1; i <= 15; i++) {
      values[i - 1] = rating['score' + this.fix(i)];
    }
    average.values = values.map(value => "" + value);
    average.index = colNumber;
    average.value = newValue;

    this.ratingBookService.average1_1(average)
      .subscribe(response => {
        console.log('Message: ', response);
        rating.average2dec = response.average2dec;
        rating.average1dec = response.average1dec;
        if (response.valid) {
          rating.saved = false;
          rating['score' + this.fix(colNumber)] = response.value;
        } else {
          rating['score' + this.fix(colNumber)] = '';
          if (!areSame)
            this.snackBar.open(`${response.message}`, 'OK', { duration: 3000 });
        }
      },
        problems => console.error(problems)
      );
  }


  private onBlurScore1_0(e: FocusEvent): void {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    const newValue = input.value;
    const inputId: string = input.id;
    const colNumber: number = this.getScoreId(inputId);
    const rating: Rating = this.getRatingByCompositeId(inputId, this.dataSource);

    let values: number[] = [];
    values[0] = +newValue;
    for (let i = 1; i <= 15; i++) {
      values[i] = rating['score' + this.fix(i)];
    }

    this
      .ratingBookService
      .average1_0(values)
      .subscribe(response => {
        console.log(response);

        rating.average2dec = response[0];
        rating['score' + this.fix(colNumber)] = response[1];
      },
        problems => console.error(problems)
      );
  }


  onKeyupScore(e: KeyboardEvent) {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    const newValue = input.value;
    const currentInputId = input.id;
    let nextElementId: string = ''; //'3#c0a02153-9cff-4e62-923d-2aeddcfbe0ac';
    let nextRatingId: string = '';
    let nextScoreId: number = 0;

    if (newValue.length == 2) {
      const currentRatingId = this.getRatingId(currentInputId);
      if (this.verticalFill) {
        nextRatingId = this.getNextRating(currentRatingId, this.dataSource);
        nextScoreId = this.getScoreId(currentInputId);
      } else {
        nextRatingId = currentRatingId;
        nextScoreId = this.getScoreId(currentInputId) + 1;
      }

      nextElementId = nextScoreId + '#' + nextRatingId;

      document.getElementById(nextElementId).focus();

    }

  }

  getScore(rating: Rating, index: number): string {
    return rating['score' + this.fix(index)];
  }

  setScore(rating: Rating, index: number, value: string): void {
    rating['score' + this.fix(index)] = value;
  }

  getScoreId(inputId: string): number {
    return +inputId.split('#')[0];
  }

  getRatingId(inputId: string): string {
    return inputId.split('#')[1];
  }

  getRatingByCompositeId(inputId: string, ratings: Rating[]): Rating {
    const ratingId: string = inputId.split('#')[1];
    return ratings.filter(row => row.id == ratingId)[0]

  }

  getNextRating(currentRatingId: string, ratings: Rating[]): string {
    let nextRatingId: string = '';
    let found: boolean = false;
    for (let i = 0; i < ratings.length; i++) {
      const rating: Rating = ratings[i];
      if (rating.id == currentRatingId || found) {
        found = true;
        if (i < ratings.length - 1) {
          const nextRating: Rating = ratings[i + 1];
          if (nextRating.student.estado == 'MAT') {
            nextRatingId = nextRating.id;
            found = false;
            break;
          }
        }
      }
    }
    return nextRatingId;
  }

  onBlock(id: string): void {
    const rating: Rating = this.getRatingByCompositeId('1#' + id, this.dataSource);
    const message: string = `¿Seguro de bloquear las notas de ${rating.student.nombres}?\nPara volver a habilitar las notas, habrá que solicitarlo.`
    const response: boolean = confirm(message);

    if (response) {
      this
        .ratingBookService
        .lockRaing1_0(rating)
        .subscribe(
          response => {
            rating.locked = true;
          },
          problems => console.log(problems)
        );
    }

  }

  onUnlock(id: string): void {
    const rating: Rating = this.getRatingByCompositeId('1#' + id, this.dataSource);
    rating.locked = false;

    this
      .ratingBookService
      .unlockRaing1_0(rating.id)
      .subscribe(
        response => {
          console.log(response);
        },
        problems => console.error(problems)
      );

  }

}
