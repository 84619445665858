<h2 *ngIf="this.dataSource.length > 0">Contenidos</h2>

<mat-card *ngIf="this.dataSource.length > 0" fxLayout="column" fxLayoutAlign="start start">
    <mat-card-content fxFlexFill>
        <i> {{this.specificObjetive.detail}}</i>
    </mat-card-content>
</mat-card>

<br>
<table mat-table [dataSource]="dataSource" *ngIf="this.dataSource.length > 0" class="width-table borders" fxFlexFill>
    <ng-container matColumnDef="evaluation-criteria">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Definición ({{this.planningYear}})</th>
        <td mat-cell *matCellDef="let element" class="align-left"> {{this.cutText(element.detail,200)}} </td>
    </ng-container>
    <ng-container matColumnDef="generic-objectives">
        <th mat-header-cell *matHeaderCellDef class="align-center"> Objetivos Genéricos </th>
        <td mat-cell *matCellDef="let element" class="align-center">
            <button mat-icon-button color="primary" *ngFor="let letter of element.genericObjectives"
                [matTooltip]="getGenericObjetiveDetail(letter)">
                {{letter}}
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef class="align-center"> Período </th>
        <td mat-cell *matCellDef="let element" class="align-right"> {{element.startDate}} {{ element.startDate?'-':'' }}
            {{element.finishDate}}
            <button mat-icon-button [color]="element.periodStatus" (click)="openCalendarDialog(element.id)">
                <mat-icon>calendar_month</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="last-column" class="align-center"> Indicaciones </th>
        <td mat-cell *matCellDef="let element" class="last-column" class="align-center">
            <!--
                [ngClass]="{'retired': row.student.estado == 'RET'}"
Los colores pueden ser: warn, primary y ''
        -->
            <button mat-icon-button [color]="element.activitiesStatus" (click)="openActivitiesDialog(element.id)"
                matTooltip="Actividades de aprendizaje">
                <mat-icon>local_library</mat-icon>
            </button>
            <button mat-icon-button [color]="element.evaluationStatus" (click)="openEvaluationsDialog(element.id)"
                matTooltip="Evaluación">
                <mat-icon>fact_check</mat-icon>
            </button>

            <!--
            <button mat-icon-button color="primary" (click)="openCalendarDialog(element.id)">
                <mat-icon>calendar_month</mat-icon>
            </button>
            -->
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>