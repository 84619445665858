<div fxLayout="column" fxLayoutAlign="start center">

    <div fxLayout="column">
        <h1>Vincular alumnos y cursos</h1>
        <div fxFlex fxFlexOffset="5"> </div>
    </div>

    <table width="70%" border="0">
        <tr>
            <td fxLayoutGap="10px" fxLayoutAlign="start start" fxLayout="row" fxFlexOffset="10" width="70%">
                <!--
            <td colspam="2" fxLayoutGap="10px" fxLayoutAlign="center center" fxLayout="row">
              --> <mat-form-field fxFlex>
                    <mat-label>Busque por Rut</mat-label>
                    <input matInput placeholder="Ejemplo: 12345678" autocomplete="off" [(ngModel)]="rut"
                        (keydown.enter)="onSearchClick()">
                </mat-form-field>
                <div fxFlex="20">
                    <button mat-raised-button color="primary" (click)="onSearchClick()">Buscar</button>
                </div>
            </td>
        </tr>
        <tr>
            <td fxLayout="row" fxFlexAlign="start start" fxFlexOffset="10"> Nombre:&nbsp;<strong
                    *ngIf="this.student">{{this.student.paterno}} {{this.student.materno}},
                    {{this.student.nombres}}</strong>
            </td>
        </tr>
        <tr>
            <td fxLayout="row" fxFlexAlign="start start" fxFlexOffset="10"> Fecha Nacimiento:&nbsp;<strong
                    *ngIf="this.student">{{this.dateUtility.dateToHumanString(this.dateUtility.stringToDate(this.student.nacimiento))}}
                    ({{this.student.edad}})</strong>
            </td>
        </tr>
        <tr>
            <td fxLayout="row" fxFlexAlign="start start" fxFlexOffset="10"> Nacionalidad:&nbsp;<strong
                    *ngIf="this.student">{{this.student.nationalityName}}</strong>
            </td>
        </tr>
        <tr>
            <td fxLayout="row" fxFlexAlign="start start" fxFlexOffset="10"> Dirección:&nbsp;<strong
                    *ngIf="this.student">{{this.student.direccion}}</strong>
            </td>
        </tr>
        <tr>
            <td fxLayout="row" fxFlexAlign="start start" fxFlexOffset="10"> Comuna:&nbsp;<strong
                    *ngIf="this.student">{{this.student.comunaNombre}}</strong>
            </td>
        </tr>
        <tr>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td fxLayoutGap="10px" fxLayoutAlign="start start" fxLayout="row" fxFlexOffset="10" width="70%"
                *ngIf="this.year>0">
                <com-course-selector fxFlex="70" [showTeacherName]="true" (courseChangeEvent)="onCourseChange($event)"
                    [year]="this.year"></com-course-selector>

                <mat-form-field fxFlex>
                    <mat-label>Año</mat-label>
                    <input matInput placeholder="Ejemplo: 12345678" [(ngModel)]="year" disabled="">
                </mat-form-field>

            </td>
        </tr>

        <tr>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td>&nbsp;</td>
        </tr>


        <tr>
            <td fxLayoutGap="10px" fxLayoutAlign="space-evenly center" fxLayout="row" fxFlexOffset="10" width="70%">
                <button fxFlex="30" mat-raised-button color="primary" (click)="onBind()">Vincular</button>
                <button fxFlex="30" mat-raised-button (click)="onUnbind()">Desvincular</button>
            </td>
        </tr>

    </table>
</div>



<!--
<mat-grid-list cols="12">
    <mat-grid-tile>1</mat-grid-tile>
        <mat-grid-tile class="gris" colstrong="10">
            <div style="border:1px solid red; min-width:80%;">

                <mat-grid-list cols="4"  rowHeight="2:1">
                    <mat-grid-tile class="amarillo"  >
                       <div fxFill class="rosado" fxLayoutAlign="center start">1</div>  
                    </mat-grid-tile>
                    <mat-grid-tile class="rojo">2</mat-grid-tile>
                    <mat-grid-tile class="verde">3</mat-grid-tile>
                    <mat-grid-tile class="cafe">4</mat-grid-tile>
                </mat-grid-list>
            </div>
        </mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
</mat-grid-list>
-->