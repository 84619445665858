import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePassword } from '../model/change-password';
import { UserCredentials } from '../model/credentials';
import { Role } from '../model/role';
import { User } from '../model/user';
import { SecretKey } from '../model/secret-key';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {
  private baseUrl: string = environment.apiUrl;
  private adapterUrl: string = environment.apiAdapterUrl;
  private serviceUrl: string = '/api/lichan/user';
  private adapter2FAServiceUrl: string = '/api/adapter/2fa';
  private appUser: string = 'linchan-frnt';
  private appPassword: string = 'clave-linchan';
  private urlRequest: string = `${this.baseUrl}/oauth/token`;
  private passwordServiceUrl: string = `${this.baseUrl}/api/lichan/user`;
  private verifyPasswordUrl: string = '/1.0/verify-password';
  private changePasswordUrl: string = '/1.0/change-password';
  private listRolsUrl: string = '/1.0/list-rols';
  private verifyCodeUrl: string = '/1.0/verify-code';

  private _token: string;
  private _user: User;
  private _rols: Role[];

  private readonly ACCESS_TOKEN: string = 'AccessToken';
  private readonly USER: string = 'User';
  public readonly AUTHORIZATION: string = 'Authorization';
  public readonly BEARER: string = 'Bearer ';

  constructor(
    private http: HttpClient) { }

  public get user(): User {
    if (this._user != null) {
      return this._user;
    } else if (this._user == null && sessionStorage.getItem(this.USER) != null) {
      this._user = JSON.parse(sessionStorage.getItem(this.USER)) as User;
      return this._user;
    }
    return new User();
  }

  public get token(): string {
    if (this._token != null) {
      return this._token;
    } else if (this._token == null && sessionStorage.getItem(this.ACCESS_TOKEN) != null) {
      this._token = sessionStorage.getItem(this.ACCESS_TOKEN);
      return this._token;
    }
    return null;
  }

  logout(): void {
    this._user = null;
    this._token = null;
    this._rols = null;
    sessionStorage.clear();
  }

  public login(username: string, password: string): Observable<any> {
    const credentials: string = btoa(this.appUser + ':' + this.appPassword);
    const httpHeaders = this.getHeadersForLogin(credentials);

    let params = new URLSearchParams();
    params.set('grant_type', 'password');
    params.set('username', username);
    params.set('password', password);

    return this.http.post<any>(this.urlRequest, params.toString(), { headers: httpHeaders });
  }

  public verifyPassword(password: string): Observable<UserCredentials> {
    const url = this.passwordServiceUrl + this.verifyPasswordUrl;
    console.log(url);

    const credentials: UserCredentials = new UserCredentials();
    credentials.password = password;

    return this.http.post<UserCredentials>(url, credentials, { headers: this.headerWithAuthorization(null) });
  }

  public changePassword(changePassword: ChangePassword): Observable<ChangePassword> {
    const url = this.passwordServiceUrl + this.changePasswordUrl;
    return this.http.post<ChangePassword>(url, changePassword, { headers: this.headerWithAuthorization(null) });
  }

  private getHeadersForLogin(credentials: string) {
    return new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'Basic ' + credentials });
  }

  saveToken(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem(this.ACCESS_TOKEN, accessToken);
  }

  saveUser(accessToken: string): void {
    let payload = this.getTokenData(accessToken);
    this._user = new User();
    this._user = { ...payload };
    this._user.username = payload.user_name;
    this._user.roles = payload.authorities == undefined ? [] : payload.authorities;

    sessionStorage.setItem(this.USER, JSON.stringify(this._user));

  }

  getTokenData(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split('.')[1]));
    }
    return null;
  }

  isAuthenticated(): boolean {
    let user = this.getTokenData(this.token);
    return user != null && user.user_name && user.user_name.length > 0;   // sessionStorage.getItem(this.ACCESS_TOKEN) != null;
  }

  public headerWithAuthorization(httpHeaders: HttpHeaders): HttpHeaders {
    let token = this.token;
    let out: HttpHeaders = httpHeaders == null ? new HttpHeaders({ 'Content-Type': 'application/json' }) : httpHeaders;

    if (token != null) {
      out = out.append(this.AUTHORIZATION, this.BEARER + token);
    }
    return out;
  }

  hasRol(role: string): boolean {
    let threeLeters = this.user.roles.map(role => {
      return role.substring(0, 3);
    });
    return threeLeters.includes(role);
  }

  hasAnyRol(roles: string[]): boolean {
    let found = false;

    roles.forEach((role, i) => {
      if (this.user.roles.includes(role) && !found) {
        found = true;
      }
    });

    return found;
  }

  public get rols(): Role[] {
    if (this._rols != null) {
      return this._rols;
    } else if (this._rols == null && this._user != null) {
      this.listRols();
      return this._rols;
    }
    return [];
  }

  private async listRols(): Promise<void> {
    const url = this.baseUrl + this.serviceUrl + this.listRolsUrl;
    this._rols = await this.http.get<Role[]>(url, { headers: this.headerWithAuthorization(null) }).toPromise();
  }

  public verifyCode(secretKey: SecretKey): Observable<SecretKey> {
    const url = this.adapterUrl + this.adapter2FAServiceUrl + '/v1/verify-code';
    console.log(url);
    return this.http.post<SecretKey>(url, secretKey, { headers: this.headerWithAuthorization(null) });
  }

}
