<h1 mat-dialog-title>Datos de accidente</h1>

<div mat-dialog-content>

    <table border="0">
        <tr>
            <td>
                <strong>Alumno:</strong>
                {{this.dialogData.studentName}}
            </td>
        </tr>
        <tr>
            <td>
                <strong>Curso:</strong>
                {{this.dialogData.courseLeter}}
            </td>
        </tr>
        <tr>
            <td>
                <strong>Fecha accidente:</strong>
                {{this.dateTimeUtility.momentToHumanString(this.dialogData.accidentDate)}}
            </td>
        </tr>
        <tr>
            <td>
                <strong>Descripción</strong><br>
                {{this.dialogData.description}}
            </td>
        </tr>
        <tr>
            <td>
                <strong>Acompañante:</strong>{{this.dialogData.escortsName}} ({{this.dialogData.escortsRelationship}})
            </td>
        </tr>
        <tr>
            <td>
                <strong>Centro de atención:</strong>{{this.dialogData.healthPlace}}
            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill">
                    <mat-label>Fecha Atención</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="this.dialogData.attentionDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

            </td>
        </tr>
        <tr>
            <td>
                <mat-form-field appearance="fill">
                    <mat-label>Fecha Retorno</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="this.dialogData.returnsDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>

            </td>
        </tr>
    </table>

</div>

<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary">
        <mat-icon>save</mat-icon>
        Grabar
    </button>
    <button mat-raised-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon> Cerrar
    </button>
</div>