<p>
    <h2 style="text-align: center;">Retiro de alumno</h2>

    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Indique motivo para el retiro</mat-label>
        <input matInput  
            #reasonJs 
            placeholder="Ej. cambio de comuna" 
            maxlength="25"
            [(ngModel)]="reason"/>
        <mat-hint align="end">{{reasonJs.value.length}} / 25</mat-hint>
    </mat-form-field>


    <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Comentarios relativos al retiro del alumno</mat-label>
        <textarea matInput 
            #commentJs
            placeholder="Ej. Se retira por cambio de comuna..." 
            maxlength="120"
            [(ngModel)]="comment"></textarea>
        <mat-hint align="end">{{commentJs.value.length}} / 120</mat-hint>
    </mat-form-field>

<br>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="unenrollResponse" cdkFocusInitial
    (click)="onAccept()"><mat-icon>save</mat-icon>Retirar</button>
    &nbsp;
    <button mat-raised-button (click)="onNoClick()"><mat-icon>close</mat-icon>Volver</button>
</div>

 
