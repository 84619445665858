export class User {
    id: string;
    rut: number;
    dv: string;
    fatherSurname: string;
    motherSurname: string;
    names: string;
    address: string;
    townshipId: string;
    townshipName: string;

    password: string;
    username: string;

    roles: string[] = [];

    secretKey: string | null;

}