<h3>{{title}}</h3>

<div fxLayout="column">

    <app-course-detail *ngIf="showDetail" (goBack)="this.showDetail=false" (onShowAllStudents)="whenShowAllStudents($event)" 
        [courseId]="this.courseId"></app-course-detail>


    <div fxFlexFill fxLayoutAlign="space-evenly center" *ngIf="!showDetail">
        <mat-spinner *ngIf="this.showLoading"></mat-spinner>

        <table mat-table [dataSource]="dataSource" width="95%" *ngIf="!this.showLoading" class="mat-elevation-z1">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <ng-container matColumnDef="course">
                <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header> Curso </th>
                <td mat-cell *matCellDef="let row" class="align-right" nowrap>
                    {{courseService.formatLetra(row.course.letra)}} </td>
            </ng-container>

            <ng-container matColumnDef="stundentSubjectsReprobedCount">
                <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header> Alumnos con promedio<br>final
                    insuficiente
                </th>
                <td mat-cell *matCellDef="let row" class="align-right" nowrap> {{row.failureStudentsCount}} </td>
            </ng-container>

            <ng-container [matColumnDef]="'period'+i" *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
                <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header>Calificaciones<br>
                    pendientes, sem.
                    {{i}}
                </th>
                <td mat-cell *matCellDef="let row" class="align-right" nowrap> {{row['period'+i].stundetIds.length}}
                </td>
            </ng-container>

            <ng-container matColumnDef="deficientAttendance">
                <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header> Repitencias por <br>aistencia
                </th>
                <td mat-cell *matCellDef="let row" class="align-right" nowrap> {{row.lessAttendanceStudentsCount}} </td>
            </ng-container>

            <ng-container matColumnDef="failing">
                <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header> Repitencias
                    por<br>calificaciones
                </th>
                <td mat-cell *matCellDef="let row" class="align-right" nowrap>
                    {{row.studentSubjectsReprobedCount}}

                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="align-right" mat-sort-header> Detalle </th>
                <td mat-cell *matCellDef="let row" class="align-right" nowrap>
                    <button mat-icon-button color="primary" (click)="viewDetail(row.course.id)">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </td>
            </ng-container>


        </table>
    </div>

    <div style="z-index:5; position : fixed;display : flex; align-self : flex-end;bottom : 5%; margin-right: 7%;" *ngIf="!showDetail" >
        <a mat-fab color="primary" (click)="this.reloadData(true)">
            <mat-icon>refresh</mat-icon>
        </a>
    </div>
<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;<br>&nbsp;
</div>