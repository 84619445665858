<div fxLayout="column" fxLayoutAlign="space-around center">
    <h1 *ngIf="entityConfig" fxFlexFill>{{entityConfig.title}}</h1>

    <table mat-table [dataSource]="dataSource" fxFlexFill>
        <ng-container [matColumnDef]="col.id" *ngFor="let col of columns">
            <th mat-header-cell *matHeaderCellDef class="align-left">
                <div *ngIf="col.header=='checkbox'; else normalHeaderLabel">
                    Selección
                </div>
                <ng-template #normalHeaderLabel>
                    {{col.header}}
                </ng-template>
            </th>
            <td mat-cell *matCellDef="let element" class="align-left">
                <div *ngIf="element[col.id]=='checkbox'; else normalCellValue">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)">
                    </mat-checkbox>
                </div>
                <ng-template #normalCellValue>
                    <div *ngIf="this.isArray(element[col.id]); else onlyValue"> 
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <!-- (click)="clickMenuArray(element, col.id)"  -->
                            <mat-icon>menu</mat-icon>
                        </button>{{element[col.id].length}}
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item disabled *ngFor="let opt of element[col.id]">
                                <span>
                                    <mat-icon>account_box</mat-icon>
                                    {{opt.name}}
                                </span>
                            </button>
                            <mat-divider></mat-divider>
                            <div *ngIf="element[col.id].length!=-1">
                                <button mat-menu-item (click)="clickMenuArray(element, col.id)">
                                    <span>
                                        <mat-icon>edit</mat-icon> Editar
                                    </span>
                                </button>
                            </div>
                        </mat-menu>

                    </div>
                    <ng-template #onlyValue>
                        {{ this.representAsString(element[col.id], col.id)}}
                    </ng-template>
                </ng-template>


            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsId"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsId;"></tr>
    </table>

    <mat-paginator fxFlexFill [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="10" showFirstLastButtons>
    </mat-paginator>

    <p *ngIf="entityConfig" fxLayout="row" fxLayoutAlign="space-evenly center" fxFlexFill>
        <button *ngFor="let button of entityConfig.buttons; let i = index" mat-raised-button (click)="onClickGenericButton(button)"
            [id]="button.name" [color]="i==0?'primary':null">
            <mat-icon>{{button.icon}}</mat-icon>{{button.label}}
        </button>
    </p>

</div>