<h1 mat-dialog-title>Actividades de aprendizaje</h1>
<div mat-dialog-content fxLayout="column">
    <com-chips-selector label="Tipo de evaluación" placeholder="Más tipos..."
        (elementsUpdated)="elementsUpdated($event)" [elements]="this.evaluationTypeAvailableNames"
        [selectedElements]="this.evaluationTypeSelectedNames"></com-chips-selector>

    <mat-form-field appearance="fill">
        <mat-label>Indicadores de evaluación</mat-label>
        <textarea matInput [(ngModel)]="this.dialogData.evaluationIndicator" cdkTextareaAutosize></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Criterios para evaluar</mat-label>
        <textarea matInput [(ngModel)]="this.dialogData.criteriaToEvaluate" cdkTextareaAutosize></textarea>
    </mat-form-field>


</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary" [disabled]="this.dialogData.readonly">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">Cancelar</button>
</div>