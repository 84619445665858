import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthorService } from 'src/app/api/author.service';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { PermissionService } from 'src/app/api/permission.service';
import { ScheduleService } from 'src/app/api/schedule.service';
import { SignatureReportRequest, SignatureService, SignaturesReport } from 'src/app/api/signature.service';
import { SubjectService } from 'src/app/api/subject.service';
import { UserService } from 'src/app/api/user.service';
import { CalendarCell } from 'src/app/model/calendar-cell';
import { CalendarRow } from 'src/app/model/calendar-row';
import { Hour } from 'src/app/model/hour';
import { Subject } from 'src/app/model/subject';
import { User } from 'src/app/model/user';
import { ValidateTimeDetail } from 'src/app/model/validate-time-detail';
import { SelectCourseTimeDialogComponent, SelectCourseTimeParamDialog } from './select-course-time-dialog/select-course-time-dialog.component';
import { ValidateDetailDialogComponent, ValidateDetailParamDialog } from './validate-detail-dialog/validate-detail-dialog.component';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css', '../../style/table.css']
})
export class ScheduleComponent implements OnInit {
  public teacherId: string = '';
  public subjectId: string = '';
  public teachers: User[] = [];
  public subjects: Subject[] = [];
  public hours: Hour[] = [];

  public currentYear: number = 0;
  public borderSize = 0;
  public loading: boolean = true;
  public calendarRows: CalendarRow[] = [];

  public canChangeSchedule: boolean = false;
  public canReadAllSchedules: boolean = false;
  public canReadOwnSchedules: boolean = false;

  public selectOtherTeacher: boolean = false;

  public username: string = '';


  constructor(private configService: ConfigService,
    private userService: UserService,
    private subjectService: SubjectService,
    private scheduleService: ScheduleService,
    private courseService: CourseService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private permissionService: PermissionService,
    public authService: AuthorService,
    private signatureService: SignatureService,
    private dateTimeUtility: DateTimeUtility) { }

  ngOnInit(): void {
    this.configService.getCurrentYear().subscribe(
      response => this.currentYear = response,
      problems => console.error(problems)
    );

    this.userService.listAssignedTeachers().subscribe(
      response => this.teachers = response,
      problems => console.error(problems)
    );

    this.configService.listHours().subscribe(
      response => this.initCalendarRows(response),
      problems => console.error(problems)
    );


    this.readPermissions();
  }

  private async readPermissions() {
    this.canChangeSchedule = await this.permissionService.hasPermissionAsync('CHANGE-SCHEDULE');
    this.canReadAllSchedules = await this.permissionService.hasPermissionAsync('READ-ALL-SCHEDULE');
    this.canReadOwnSchedules = await this.permissionService.hasPermissionAsync('READ-OWN-SCHEDULE');
    this.username = this.authService.user.fatherSurname + this.authService.user.motherSurname + ", " + this.authService.user.names;

    this.selectOtherTeacher = this.canChangeSchedule || this.canReadAllSchedules;

    if (this.canReadOwnSchedules) {
      this.teacherId = this.authService.user.id;
      this.refreshSignatures();
      this.refreshCalendar();
    }

    this.loading = false;

  }
  private initCalendarRows(hours: Hour[]): void {
    let i: number = 0;
    this.hours = hours;
    hours.forEach(
      hour => {
        const calendarRow = new CalendarRow();
        calendarRow.hour = hour;
        this.calendarRows[i] = calendarRow;
        i++;
      }
    );
  }

  private newCalendarCell(): CalendarCell {
    const newCalendarCell = new CalendarCell();
    newCalendarCell.courseId = '';
    newCalendarCell.courseLeter = '';
    newCalendarCell.subjectId = '';
    newCalendarCell.subjectName = '';
    return newCalendarCell;
  }

  public cellClick(e: MouseEvent): void {
    const cell = e.target as HTMLInputElement;
    console.log(`CELL_ID: '${cell.id}'`);

    if (cell.id.length == 0 || !this.canChangeSchedule) return;

    const hourSelected: string = cell.id.split('.')[0];
    const weekday: string = cell.id.split('.')[1];

    if (this.teacherId == '' || this.subjectId == '') {
      this.snackBar.open(`Debe seleccionar Profesor y Asignatura`, 'OK', { duration: 3000 });
      return;
    };

    const selectCourseTimeParamDialog: SelectCourseTimeParamDialog = new SelectCourseTimeParamDialog();
    selectCourseTimeParamDialog.hours = this.hours;
    selectCourseTimeParamDialog.hourSelected = hourSelected;
    selectCourseTimeParamDialog.hourBusy = this.findBusyHourId(this.calendarRows, weekday, hourSelected);
    selectCourseTimeParamDialog.courseId = this.getCourseId(this.calendarRows, weekday, hourSelected);
    selectCourseTimeParamDialog.courseLeter = this.getCourseLeter(this.calendarRows, weekday, hourSelected);

    selectCourseTimeParamDialog.subjectName = this.subjects.filter(subject => subject.id == this.subjectId)[0].name;
    selectCourseTimeParamDialog.finishId = this.findLastHourId(this.calendarRows, weekday, hourSelected);
    selectCourseTimeParamDialog.teacherId = this.teacherId;
    selectCourseTimeParamDialog.subjectId = this.subjectId;

    const dialogRef = this.dialog.open(SelectCourseTimeDialogComponent, { data: selectCourseTimeParamDialog });

    dialogRef.afterClosed().subscribe(
      (selectCourseTime: SelectCourseTimeParamDialog) => {
        if (selectCourseTime == undefined) return;

        const calendarRows: CalendarRow[] = this.prepareValidationRequest(hourSelected, weekday, selectCourseTime);

        this.scheduleService.validate(calendarRows).subscribe(
          (validateDetails: ValidateTimeDetail[]) => {
            if (validateDetails.length > 0) {
              const validateDetailParamDialog: ValidateDetailParamDialog = new ValidateDetailParamDialog();
              validateDetailParamDialog.validateDetails = validateDetails;
              validateDetailParamDialog.courseLeter = selectCourseTime.courseLeter;

              this.dialog.open(ValidateDetailDialogComponent, { data: validateDetailParamDialog });

            } else {
              let isSelected: boolean = false;
              for (let i = 0; i < this.calendarRows.length; i++) {
                const row = this.calendarRows[i];
                if (row.hour.id == selectCourseTime.hourSelected) {
                  isSelected = true;
                }

                if (isSelected) {
                  if (selectCourseTime.courseId == '') {
                    row[weekday] = null;
                  } else {
                    const col: CalendarCell = new CalendarCell();
                    col.courseId = selectCourseTime.courseId;
                    col.courseLeter = selectCourseTime.courseLeter;
                    col.subjectId = this.subjectId;
                    col.subjectName = this.subjects.filter(subject => subject.id == this.subjectId)[0].name;

                    row[weekday] = col;
                  }
                }

                if (row.hour.id == selectCourseTime.finishId) {
                  break;
                }
              }


            }
          },
          problems => console.log(problems)
        );

      }

    );
  }
  private prepareValidationRequest(hourSelected: string, weekday: string, selectCourseTime: SelectCourseTimeParamDialog): CalendarRow[] {
    let isSelected: boolean = false;
    let index: number = 0;
    const calendarRows: CalendarRow[] = [];
    for (let i = 0; i < this.hours.length; i++) {
      const hour = this.hours[i];
      if (hour.id == hourSelected) {
        isSelected = true;
      }

      if (isSelected) {
        calendarRows[index] = new CalendarRow();
        calendarRows[index].hour = hour;
        calendarRows[index][weekday] = this.newCalendarCell();
        calendarRows[index][weekday].courseId = selectCourseTime.courseId;
        index++;
      }
      if (hour.id == selectCourseTime.finishId) {
        break;
      }
    }
    return calendarRows;
  }

  private getCourseId(calendarRows: CalendarRow[], weekday: string, hourSelected: string): string {
    const cell = this.getCalendarCell(calendarRows, weekday, hourSelected);
    if (cell != null) return cell.courseId;
    return null;
  }

  private getCourseLeter(calendarRows: CalendarRow[], weekday: string, hourSelected: string): string {
    const cell = this.getCalendarCell(calendarRows, weekday, hourSelected);
    if (cell != null) return cell.courseLeter;
    return null;
  }

  private getCalendarCell(calendarRows: CalendarRow[], weekday: string, hourSelected: string): CalendarCell {
    const row: CalendarRow = calendarRows.filter(row => row.hour.id == hourSelected)[0];
    return row[weekday];
  }

  private findLastHourId(calendarRows: CalendarRow[], weekday: string, hourSelected: string): string {
    let a: number = 0;
    let lastHourId: string = null;
    let courseId: string = null;
    for (let i = 0; i < calendarRows.length; i++) {
      const row = calendarRows[i];

      if (row.hour.id == hourSelected) {
        if (row[weekday] == null) return '';

        courseId = row[weekday].courseId;
        lastHourId = row.hour.id;
        a = i;
        break;
      }
    }

    for (let i = a + 1; i < calendarRows.length; i++) {
      const row: CalendarRow = calendarRows[i];
      const cell: CalendarCell = row[weekday];

      if (cell == null || cell.courseId == null) {
        break;
      } else {
        if (cell.courseId != courseId) {
          break;
        } else {
          lastHourId = row.hour.id;
        }
      }

    }

    return lastHourId;
  }

  private findBusyHourId(calendarRows: CalendarRow[], weekday: string, hourSelected: string): string {
    let a: number = 0, b: number = 0;
    let busyHourId: string = null;

    for (let i = 0; i < calendarRows.length; i++) {
      const row = calendarRows[i];
      if (row.hour.id == hourSelected) {
        a = i;
        break;
      }
    }

    for (let i = a + 1; i < calendarRows.length; i++) {
      const row: CalendarRow = calendarRows[i];
      const col: CalendarCell = row[weekday];
      if (col != null) {
        if (col.courseId != null) {
          busyHourId = row.hour.id;
          break;
        }
      }
    }
    return busyHourId;
  }

  public formatHour(time: string): string {
    return time.substring(0, time.lastIndexOf(':'));
  }

  public changeTeacher(e: MatSelectChange): void {
    console.log(this.teacherId);
    this.refreshSignatures();
    this.refreshCalendar();
  }

  private refreshSignatures(): void {
    this.subjectService.listByTeacher(this.teacherId).subscribe(
      (response: Subject[]) => {
        this.subjects = response;
        this.moreThanOneSignatures();

      },
      problems => console.error(problems)
    );
  }

  private moreThanOneSignatures(): void {
    if (this.subjects.length > 1 && this.canReadOwnSchedules) {
      console.log('more than one');
      this.subjectId = this.subjects[0].id;
      this.refreshCalendar();
    }
  }

  public changeSubject(e: MatSelectChange): void {
    this.refreshCalendar();
  }

  public doSaveSchedule(e: MouseEvent): void {
    // console.log(this.calendarRows);

    this.getSignatures();


    this.scheduleService.save(this.teacherId, this.subjectId, this.calendarRows).subscribe(
      response => {
        this.snackBar.open('Grabación exitosa de horario', 'OK', { duration: 3000 });
        console.log(response);
      },
      problems => console.error(problems)
    );
  }

  private async getSignatures(): Promise<number> {
    const srr: SignatureReportRequest = new SignatureReportRequest();
    srr.start = '2024-03-04';
    srr.finish = '2025-01-01';
    srr.subjectId = this.subjectId;
    srr.teacherId = this.teacherId;

    const signaturesReport: SignaturesReport = await this.signatureService.getReport(srr).toPromise();
    console.log('Subject:', this.subjectId);
    console.log('Teacher:', this.teacherId);
    console.log(`SignaturesReport : `, signaturesReport.signatures.filter(signature => signature.attendanceSigned));

    return 0;

  }


  private refreshCalendar() {
    console.log('refreshCalendar');
    
    if (this.teacherId && this.subjectId)
      this.scheduleService.listByTeacherAndSubject(this.teacherId, this.subjectId)
        .subscribe(
          response => {
            this.calendarRows = [];
            // console.log(response);
            this.calendarRows = response;
          },
          problems => console.error(problems)
        );

  }

  public getCourseLeterAndSignatureName(calendarCell: CalendarCell): string {
    if (calendarCell == null) return '-';

    if (calendarCell.subjectId == this.subjectId && this.canChangeSchedule) return this.courseService.formatLetra(calendarCell.courseLeter);
    if (!this.canChangeSchedule) return `${this.courseService.formatLetra(calendarCell.courseLeter)} / ${calendarCell.subjectShortName}`;

    return `${this.courseService.formatLetra(calendarCell.courseLeter)} (${calendarCell.subjectShortName})`;
  }

  public getColor(calendarCell: CalendarCell): string {
    if (calendarCell == null) return '';
    if (this.canReadAllSchedules || this.canReadOwnSchedules) return 'primary';
    if (calendarCell.subjectId == this.subjectId) return 'primary';
    return '';
  }

  public isDisabled(calendarCell: CalendarCell): boolean {
    if (calendarCell == null) return false;

    // if (this.canChangeSchedule) return true;
    if (this.canChangeSchedule && calendarCell.subjectId != this.subjectId) return true;
    if (this.canReadAllSchedules || this.canReadOwnSchedules) return false;
    return false;
  }

}
