import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { EvaluationTypeService } from 'src/app/api/evaluation-type.service';
import { PlanService } from 'src/app/api/plan.service';
import { ResourceService } from 'src/app/api/resource.service';
import { EvaluationCriteria, EvaluationCriteriaItem } from 'src/app/model/evaluation-criteria';
import { EvaluationType } from 'src/app/model/evaluation-type';
import { GenericObjective } from 'src/app/model/generic-objetive';
import { Resource } from 'src/app/model/resource';
import { SpecificObjective } from 'src/app/model/specific-objetive';
import { ActivitiesDialogComponent, ActivitiesParamDialog } from './activities-dialog/activities-dialog.component';
import { DatesClassDialog, DatesClassDialogComponent } from './dates-class-dialog/dates-class-dialog.component';
import { EvaluationsDialogComponent, EvaluationsParamDialog } from './evaluations-dialog/evaluations-dialog.component';

@Component({
  selector: 'com-evaluation-criteria-grid',
  templateUrl: './evaluation-criteria-grid.component.html',
  styleUrls: ['./evaluation-criteria-grid.component.css',
    '../../../style/table.css']
})
export class EvaluationCriteriaGridComponent implements OnInit {
  @Input() specificObjetiveId: string = '';

  @Input() specificObjetive: SpecificObjective = null;

  @Input() levelId: string = '';

  @Input() year: number = 0;

  @Input() subjectId: string = '';

  @Input() genericObjectives: GenericObjective[] = [];

  @Input() evaluationCriteria: EvaluationCriteria;

  @Input() planningYear: number;

  @Input() public readonly: boolean = false;


  @Output() evaluationCriteriaLoadedEvent = new EventEmitter<EvaluationCriteria>();

  @Output() evaluationCriteriaItemChangedEvent = new EventEmitter<EvaluationCriteriaItem>();
  dataSource: EvaluationCriteriaRecordGrid[] = [];

  resources: Resource[];
  evaluationTypes: EvaluationType[];

  displayedColumns: string[] = ['evaluation-criteria', 'generic-objectives', 'period', 'actions'];

  constructor(
    private planService: PlanService,
    private resourceService: ResourceService,
    private evaluationTypeService: EvaluationTypeService,
    private dialog: MatDialog,
    private dateTimeUtil: DateTimeUtility) { }


  ngOnInit(): void {
    this
      .resourceService.listAll()
      .subscribe(
        response => this.resources = response,
        problems => console.log(problems)
      );

    this.evaluationTypeService.listAll()
      .subscribe(
        response => this.evaluationTypes = response,
        problems => console.log(problems)
      );

  }

  ngOnChanges(sc: SimpleChanges): void {
    this.dataSource = [];
    if (this.specificObjetiveId == '' || this.levelId == '' || this.subjectId == '') return;

    this.refreshEvaluationCriteria();
  }

  public cutText(text: string, len: number) {
    return text.length >= len ? `${text.substring(0, len)}...` : text;
  }

  private refreshEvaluationCriteria(): void {
    this.planService.findEvaluationCritera(this.specificObjetiveId)
      .subscribe(
        response => {
          console.log(response);
          this.convertToEvaluationCriteria(response);
          //          this.evaluationCriteriaList = response;
        },
        problems => console.error(problems)
      );
  }

  private convertToEvaluationCriteria(evaluationCriteria: EvaluationCriteria): void {
    //    this.evaluationCriteria.relCourseSubjectId = evaluationCriteria.relCourseSubjectId;
    this.clearEvaluationCriteriaItems(this.evaluationCriteria.items);

    evaluationCriteria
      .items
      .forEach((evaluationCriteriaItem: EvaluationCriteriaItem) => {
        this.evaluationCriteria.items.push(evaluationCriteriaItem);
        const evaluationCriteriaRecordGrid = new EvaluationCriteriaRecordGrid();
        evaluationCriteriaRecordGrid.id = evaluationCriteriaItem.id;
        evaluationCriteriaRecordGrid.detail = evaluationCriteriaItem.detail;

        evaluationCriteriaRecordGrid.finishDate = evaluationCriteriaItem.finishDate;
        evaluationCriteriaRecordGrid.startDate = evaluationCriteriaItem.startDate;

        evaluationCriteriaRecordGrid.genericObjectives = this.getGenericObjectives(evaluationCriteriaItem.genericObjectiveIds);
        evaluationCriteriaRecordGrid.periodStatus = this.getPeriodStatus(evaluationCriteriaItem);
        evaluationCriteriaRecordGrid.activitiesStatus = this.getActivitiesStatus(evaluationCriteriaItem);
        evaluationCriteriaRecordGrid.evaluationStatus = this.getEvaluationsStatus(evaluationCriteriaItem);

        this.dataSource.push(evaluationCriteriaRecordGrid);

      });
    this.evaluationCriteriaLoadedEvent.emit(this.evaluationCriteria);
  }



  private getPeriodStatus(evaluationCriteriaItem: EvaluationCriteriaItem): string {
    return evaluationCriteriaItem.finishDate != null && evaluationCriteriaItem.startDate != null ? 'primary' : ''
  }

  private getActivitiesStatus(evaluationCriteriaItem: EvaluationCriteriaItem): string {
    const hasStart: boolean = evaluationCriteriaItem.activityStart?.length > 0;
    const hasDevelop: boolean = evaluationCriteriaItem.activityDevelop?.length > 0;
    const hasFinish: boolean = evaluationCriteriaItem.activityFinish?.length > 0;
    const hasResources: boolean = evaluationCriteriaItem.resourceIds?.length > 0;

    if (hasStart && hasDevelop && hasFinish && hasResources) return 'primary';
    if (!hasStart && !hasDevelop && !hasFinish && !hasResources) return '';

    return 'warn';
  }

  private getEvaluationsStatus(evaluationCriteriaItem: EvaluationCriteriaItem): string {
    const hasEvaluationIndicator: boolean = evaluationCriteriaItem.evaluationIndicator?.length > 0;
    const hasCriteriaToEvaluate: boolean = evaluationCriteriaItem.criteriaToEvaluate?.length > 0;
    const hasEvaluationTypeIds: boolean = evaluationCriteriaItem.evaluationTypeIds?.length > 0;

    if (hasEvaluationIndicator && hasCriteriaToEvaluate && hasEvaluationTypeIds) return 'primary';
    if (!hasEvaluationIndicator && !hasCriteriaToEvaluate && !hasEvaluationTypeIds) return '';

    return 'warn';
  }


  private clearEvaluationCriteriaItems(evaluationCriteriaItems: EvaluationCriteriaItem[]): void {
    while (evaluationCriteriaItems.length > 0) {
      evaluationCriteriaItems.pop();
    }
  }

  private getGenericObjectives(genericObjectiveIds: string[]): string[] {
    //const leters = 'A';
    const letters =
      genericObjectiveIds
        .map(genericObjectiveId => this
          .genericObjectives
          .filter(genericObjecitve => genericObjecitve.id == genericObjectiveId)[0]
          .letter);
    return letters;
  }

  public getGenericObjetiveDetail(letter: string): string {
    return this.genericObjectives.filter(genericObjective => genericObjective.letter == letter)[0].detail;
  }

  private findEvaluationCriteriaItem(itemId: string): EvaluationCriteriaItem {
    return this.evaluationCriteria.items.filter(item => item.id == itemId)[0];
  }

  private findEvaluationCriteriaRecordGridById(id: string): EvaluationCriteriaRecordGrid {
    return this.dataSource.filter(evaluationCriteria => evaluationCriteria.id == id)[0];
  }

  openCalendarDialog(evaluationCriteriaId: string) {
    const evaluationCriteria: EvaluationCriteriaRecordGrid = this.findEvaluationCriteriaRecordGridById(evaluationCriteriaId);
    let mm: moment.Moment = moment(evaluationCriteria.startDate, 'YYYY-MM-DD');
    let startDate = this.dateTimeUtil.momentToDate(mm);

    mm = moment(evaluationCriteria.finishDate, 'YYYY-MM-DD');
    let finishDate = this.dateTimeUtil.momentToDate(mm);

    const datesClassDialog: DatesClassDialog = new DatesClassDialog();
    datesClassDialog.detail = evaluationCriteria.detail;
    datesClassDialog.startDate = startDate;
    datesClassDialog.finishDate = finishDate;
    datesClassDialog.planningYear = this.planningYear;
    datesClassDialog.readonly = this.readonly;
    const dialogRef = this.dialog.open(DatesClassDialogComponent,
      { data: datesClassDialog }
    );

    dialogRef
      .afterClosed()
      .subscribe(result => {
        console.log('The dialog was closed', result);
        if (result == undefined) return;

        const evaluationCriteriaRecordGrid: EvaluationCriteriaRecordGrid = this.findEvaluationCriteriaRecordGridById(evaluationCriteriaId);
        const evaluationCriteriaItem: EvaluationCriteriaItem = this.findEvaluationCriteriaItem(evaluationCriteriaId); //   new EvaluationCriteriaItem();

        // debugger;

        if (result.startDate == null && result.finishDate == null) {
          evaluationCriteriaRecordGrid.startDate = null;
          evaluationCriteriaRecordGrid.finishDate = null;

          evaluationCriteriaItem.startDate = null;
          evaluationCriteriaItem.finishDate = null;
          evaluationCriteriaItem.id = evaluationCriteriaId;
        } else {
          console.log(result.startDate);
          console.log(result.finishDate);

          if (this.dateTimeUtil.isMoment(result.startDate)) {
            evaluationCriteriaRecordGrid.startDate = this.dateTimeUtil.momentToString(result.startDate);
            evaluationCriteriaItem.startDate = this.dateTimeUtil.momentToString(result.startDate);
          } else {
            evaluationCriteriaRecordGrid.startDate = this.dateTimeUtil.dateToString(result.startDate);
            evaluationCriteriaItem.startDate = this.dateTimeUtil.dateToString(result.startDate);
          }
          if (this.dateTimeUtil.isMoment(result.finishDate)) {
            evaluationCriteriaRecordGrid.finishDate = this.dateTimeUtil.momentToString(result.finishDate);
            evaluationCriteriaItem.finishDate = this.dateTimeUtil.momentToString(result.finishDate);
          } else {
            evaluationCriteriaRecordGrid.finishDate = this.dateTimeUtil.dateToString(result.finishDate);
            evaluationCriteriaItem.finishDate = this.dateTimeUtil.dateToString(result.finishDate);
          }

          evaluationCriteriaItem.id = evaluationCriteriaId;
        }
        evaluationCriteriaRecordGrid.periodStatus = this.getPeriodStatus(evaluationCriteriaItem);//  evaluationCriteria.finishDate != null && evaluationCriteria.startDate != null ? 'primary' : '';

        this.emitEvaluationCriteriaItemChanged(evaluationCriteriaItem);
      });
  }

  private emitEvaluationCriteriaItemChanged(evaluationCriteriaItem: EvaluationCriteriaItem): void {
    this.evaluationCriteriaItemChangedEvent.emit(evaluationCriteriaItem);
  }

  public openActivitiesDialog(evaluationCriteriaId: string): void {
    const item = this.findEvaluationCriteriaItem(evaluationCriteriaId);
    //    const evaluationCriteria: EvaluationCriteriaRecordGrid = this.findEvaluationCriteriaRecordGridById(evaluationCriteriaId);
    //    console.log('ITEM', item);

    const activitiesDialogData: ActivitiesParamDialog = new ActivitiesParamDialog();
    activitiesDialogData.activityDevelop = item.activityDevelop;
    activitiesDialogData.activityStart = item.activityStart;
    activitiesDialogData.activityFinish = item.activityFinish;
    activitiesDialogData.resourcesIds = item.resourceIds;
    activitiesDialogData.resources = this.resources;
    activitiesDialogData.readonly = this.readonly;

    const dialogRef = this.dialog.open(ActivitiesDialogComponent,
      {
        data: activitiesDialogData,
        width: '90%',
        height: '80%'
      }
    );

    dialogRef.afterClosed().subscribe(_result => {
      const result = _result as ActivitiesParamDialog;
      console.log('Cerrando Activities Dialog: ', _result);
      if (_result === undefined) return;

      const evaluationCriteriaItem = this.findEvaluationCriteriaItem(evaluationCriteriaId);
      evaluationCriteriaItem.activityStart = result.activityStart;
      evaluationCriteriaItem.activityDevelop = result.activityDevelop;
      evaluationCriteriaItem.activityFinish = result.activityFinish;
      evaluationCriteriaItem.resourceIds = result.resourcesIds;

      const evaluationCriteriaRecordGrid: EvaluationCriteriaRecordGrid = this.findEvaluationCriteriaRecordGridById(evaluationCriteriaId);
      evaluationCriteriaRecordGrid.activitiesStatus = this.getActivitiesStatus(evaluationCriteriaItem);
      this.emitEvaluationCriteriaItemChanged(evaluationCriteriaItem);
    });

  }

  public openEvaluationsDialog(evaluationCriteriaId: string): void {
    const evaluationsParamDialog: EvaluationsParamDialog = new EvaluationsParamDialog();
    const evaluationCriteriaItem: EvaluationCriteriaItem = this.findEvaluationCriteriaItem(evaluationCriteriaId);
    evaluationsParamDialog.evaluationIndicator = evaluationCriteriaItem.evaluationIndicator;
    evaluationsParamDialog.criteriaToEvaluate = evaluationCriteriaItem.criteriaToEvaluate;
    evaluationsParamDialog.evaluationTypes = this.evaluationTypes;
    evaluationsParamDialog.evaluationTypeIds = evaluationCriteriaItem.evaluationTypeIds;
    evaluationsParamDialog.readonly = this.readonly;

    console.log('openEvaluationsDialog', evaluationsParamDialog);

    const dialogRef = this.dialog.open(EvaluationsDialogComponent,
      {
        data: evaluationsParamDialog,
        width: '90%'
      }
    );

    dialogRef.afterClosed().subscribe(_result => {
      const result = _result as EvaluationsParamDialog;
      console.log('Cerrando Evaluations Dialog: ', _result);
      if (_result === undefined) return;

      const evaluationCriteriaItem: EvaluationCriteriaItem = this.findEvaluationCriteriaItem(evaluationCriteriaId);
      evaluationCriteriaItem.evaluationIndicator = result.evaluationIndicator;
      evaluationCriteriaItem.criteriaToEvaluate = result.criteriaToEvaluate;
      evaluationCriteriaItem.evaluationTypeIds = result.evaluationTypeIds;

      const evaluationCriteriaRecordGrid: EvaluationCriteriaRecordGrid = this.findEvaluationCriteriaRecordGridById(evaluationCriteriaId);
      evaluationCriteriaRecordGrid.evaluationStatus = this.getEvaluationsStatus(evaluationCriteriaItem);
      this.emitEvaluationCriteriaItemChanged(evaluationCriteriaItem);
    });

  }
}

export class EvaluationCriteriaRecordGrid {
  id: string;
  detail: string;
  genericObjectives: string[];
  startDate: string;
  finishDate: string;
  periodStatus: string;
  activitiesStatus: string;
  evaluationStatus: string;

}