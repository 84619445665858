import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { Router } from '@angular/router';
import { StudentService } from 'src/app/api/student.service';
import { AuthorService } from 'src/app/api/author.service';
import { Alumno } from 'src/app/model/alumno';
import { AlertUtility } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-ficha',
  templateUrl: './ficha.component.html',
  styleUrls: ['./ficha.component.css']
})
export class FichaComponent implements OnInit, AfterViewInit {
  searchInput: string = '';
  currentYeardCheckbox: boolean = true;
  showNotFound: boolean = false;

  showProgressBar: boolean = false;

  showPaginator: boolean = true;
  displayedColumns: string[];
  alumnos: MatTableDataSource<Alumno> = new MatTableDataSource<Alumno>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private alumnoService: StudentService,
    private alert: AlertUtility
  ) {
  }

  ngOnInit() {
    this.defineColumns();
  }
  
  ngAfterViewInit() {
    this.alumnos = new MatTableDataSource([]);
    this.alumnos.data = [];
    this.alumnos.paginator = this.paginator;
    /*
    this.paginator._intl.firstPageLabel = "Primara pagina";
    this.paginator._intl.itemsPerPageLabel = "Alumnos por página";
    */
  }

  defineColumns() {
    if (this.currentYeardCheckbox) {
      this.displayedColumns = ['rut', 'name', 'phone', 'year', 'lebel', 'birthday', 'age', 'action'];
    } else {
      this.displayedColumns = ['rut', 'name', 'phone', 'year', 'lebel', 'matricula', 'age', 'action'];
    }
  }

  clearInput(): void {
    this.searchInput = '';
    this.alumnos.data = [];
    this.showProgressBar = false;
  }

  onKeyPressSearchInput(e: Event) {
    if (this.searchInput.length < 3) {
      // this.alert.confirmMessage('Debe indicar al menos 3 dígitos para buscar', 'Cuidado');
      this.alert.dialogMessage('Debe indicar al menos 3 dígitos para buscar', 'Advertencia!');
      // this.alert.fastMessage('Debe indicar al menos 3 dígitos para buscar');

      return;
    }

    this.showProgressBar = true;

    this.defineColumns();
    this.alumnoService
      .findByRutOrName(this.searchInput, this.currentYeardCheckbox)
      .subscribe(
        response => {
          console.log(response);

          this.showProgressBar = false;
          if (response.length == 0) {
            this.showNotFound = true;
            this.alumnos.data = [];
          } else {
            this.showNotFound = false;
            this.alumnos = new MatTableDataSource(response);
            this.alumnos.paginator = this.paginator;
          }
        },
        problems => {
          console.log(problems);
          this.showProgressBar = false;
        }
      );
  }

  fichaPersoal(id: string): void {
    sessionStorage.setItem('alumnoId', id);
    this.router.navigate(['alumno'])
  }



}
