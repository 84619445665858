import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { ManagementService } from 'src/app/api/management.service';
import { PermissionService } from 'src/app/api/permission.service';
import { Course } from 'src/app/model/course';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { DownloadUtility } from 'src/app/utility/download.utility';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  public establishmentName: string = '';
  public envUrl: string = '';
  public canSeeSummaryReport: boolean = false;
  public ver = packageJson.version;
  public course: Course = null;
  public courseUserMessage: string = '';
  public showingSummaryReport: boolean = false;
  public showEnrollingBookButton: boolean = false;
  private year: number = 0;
  public currentPeriod: number = 0;

  constructor(private configService: ConfigService,
    private permissionService: PermissionService,
    public courseService: CourseService,
    private alert: AlertUtility,
    private managementService: ManagementService,
    private downloadUtility: DownloadUtility) {
  }

  ngOnInit() {
    this.envUrl = environment.apiUrl;
    this.configService.findByKey('nombre_establecimiento')
      .subscribe(
        response => this.establishmentName = response.valor,
        problems => console.error(problems)
      );

    this.permissionService.hasPermissions(['HOME.SUMMARY-REPORT'])
      .subscribe(
        response => {
          this.canSeeSummaryReport = response;
        },
        problems => console.error(problems)
      );

    this.permissionService.hasPermissions(['HOME.ENROLLING-BOOK-BUTTON'])
      .subscribe(
        response => {
          this.showEnrollingBookButton = response;
        },
        problems => console.error(problems)
      );

    this.courseService.getByCurrentUser()
      .subscribe(
        response => {
          //          console.log(response);
          this.course = response;
          this.courseUserMessage = this.course == null ? 'Resumen de cursos' : 'Resumen de mi curso ' + this.courseService.formatLetra(this.course.letra);
        },
        problems => console.error(problems)
      );

    this.configService.getCurrentYear()
      .subscribe(
        response => this.year = response,
        problems => console.error(problems)
      );

    this.configService.findByKey('periodo_actual')
      .subscribe(
        response => this.currentPeriod = +response.valor,
        problems => console.error(problems)
      );

  }

  public toggleShowSummaryReport(): void {
    this.showEnrollingBookButton = false;
    this.showingSummaryReport = !this.showingSummaryReport;
  }

  public downloadEnrollerBook(format: string) {
    let contentType: string;
    let fileName: string;

    switch (format) {
      case 'pdf':
        contentType = 'application/pdf';
        fileName = `libro-matriculas.pdf`;

        break;
      case 'excel':
        contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        fileName = `libro-matriculas.xlsx`;

        break;
      default:
        this.alert.fastMessage('Formato desconocido');
        return;
    }

    this.managementService.retrieveEnrollingBook(this.year, format.toUpperCase())
      .subscribe(
        (response: ArrayBuffer) => this.downloadUtility.download(response, contentType, fileName),
        problems => {
          this.alert.fastMessage(`Error al obtener archivo (${problems.message})`);
          console.error(problems);
        }
      );

  }


}
