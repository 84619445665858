import {
  Component, OnInit, Input, Output, EventEmitter,
  SimpleChanges
} from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { PlanService } from 'src/app/api/plan.service';
import { SpecificObjective } from 'src/app/model/specific-objetive';

@Component({
  selector: 'com-specific-objective-selector',
  templateUrl: './specific-objective-selector.component.html',
  styleUrls: ['./specific-objective-selector.component.css',
    '../../../style/table.css'
  ]
})
export class SpecificObjectiveSelectorComponent implements OnInit {
  @Input()
  moduleId: string = '';

  @Output()
  SpecificObjectiveChangeEvent = new EventEmitter<SpecificObjective>();

  specificObjectiveId: string = '';
  specificObjectives: SpecificObjective[] = [];

  selectionList: MatSelectionList;

  constructor(private planService: PlanService) { }

  ngOnInit(): void {
  }

  ngOnChanges(sc: SimpleChanges): void {
    //    console.log('SpecificObjetive Change', sc);
    this.specificObjectives = [];
    if (sc.moduleId.firstChange || sc.moduleId.currentValue == null || sc.moduleId.currentValue == '') return;
    this.refreshSpecificObjetive();
  }

  refreshSpecificObjetive(): void {
    this.specificObjectives = [];

    if (this.selectionList) this.selectionList.deselectAll();

    this
      .planService.listSpecificObjectives(this.moduleId)
      .subscribe(
        response => {
          console.log(response);
          this.specificObjectives = response;
        },
        problems => console.error(problems)

      )
  }

  onSelectionChange(ev): void {
    console.log('SPECIFIC OBJETIVE CHANGE: ', ev); // , ev.option.value, ev.option.value.id);
    console.log('SPECIFIC OBJETIVE VALUE: ', ev.source._value[0].id);

    this.SpecificObjectiveChangeEvent.emit(ev.source._value[0]);
  }

  public cutText(value: string, len: number): string {
    let newValue = value;
    if (value.length > len) {
      newValue = value.substring(0, len).concat('...');
    }
    return newValue;
  }

}