import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Comuna } from "../model/comuna";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class ComunaService {
    private baseUrl: string = environment.apiUrl;
    private comunaServiceUrl: string = '/api/lichan/comuna/1.0';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    listAll(): Observable<Comuna[]> {
        const url = this.baseUrl + this.comunaServiceUrl + `/list`;
        return this.http.get<Comuna[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }
}