
<div fxFlex class="xcontainer">
  <div fxLayout="column">
    <h1>Lista de postulantes para matricular para año {{this.year}}</h1>
    <div fxFlex fxFlexOffset="5">  </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
    <div fxFlex fxFlexOffset="10" fxFlex="60">
      <mat-form-field fxFlex>
        <mat-label>Busque aqui por Apellido o Rut</mat-label>
        <input matInput placeholder="Ejemplo: Perez" autocomplete="off" 
          [(ngModel)]="rutOrPaterno"
          (keydown.enter)="onSearchClick()">
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" (click)="onSearchClick()">Buscar</button>
  </div>
  <div fxLayout="column">
    <div fxFlex fxFlexOffset="1">
  </div>


<div *ngIf="showResult" fxLayout="column" class="mat-elevation-z1" class="container">
  <!--
      <mat-form-field appearance="standard">
    <mat-label>Filtro rápido</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ejemplo: Lopez" #input autocomplete="off">
  </mat-form-field>
-->

    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Rut </th>
        <td mat-cell *matCellDef="let row" class="align-center"> {{row.rut}} </td>
      </ng-container>
 
      <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Nombre </th>
        <td mat-cell *matCellDef="let row" class="align-left"><div>{{row.paterno}} {{row.materno}}, {{row.nombres}}</div></td>
      </ng-container>
  
      <ng-container matColumnDef="especialidad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Especialidad </th>
        <td mat-cell *matCellDef="let row" class="align-left"> {{row.especialidadNombre}} </td>
      </ng-container>
  
       <ng-container matColumnDef="canal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Canal </th>
        <td mat-cell *matCellDef="let row" class="align-center"> {{row.canal}} </td>
      </ng-container>
  
      <ng-container matColumnDef="curso">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Curso </th>
        <td mat-cell *matCellDef="let row" class="align-center"> {{  cursoService.formatLetra(row.courseLetra) }}</td>
      </ng-container>
  
      <ng-container matColumnDef="nro_matricula">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Nro Matricula </th>
        <td mat-cell *matCellDef="let row" class="align-center"> {{ row.enrollNumber }}</td>
      </ng-container>
   
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onPressNameClick(row)" style="cursor: pointer;"></tr>
  <!--
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
      -->
    </table>
  <!--    
    <mat-paginator [pageSizeOptions]="[5, 10, 15]" aria-label="Select page of users"></mat-paginator>
-->

  </div>
</div>
<!--
  <div fxFlex class="container">
  <div fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <mat-card class="child-1" fxFlex="20">1. One</mat-card> 
    <mat-card class="child-2" fxFlex="20">2. Two</mat-card> 
    <mat-card class="child-3" fxFlex="20">3. Three</mat-card>
  </div>
</div>
 
<div fxFlex class="container">
  <div fxFlex fxLayout="row" fxLayoutAlign="space-evenly center"  fxLayoutGap="10px">
  <mat-form-field fxFlex>
      <label fxFlex="30" class="child-1">Buscar postulante por Apellido o Rut:</label>
      <input matInput fxFlex="50" type="text" class="child-2" 
        placeholder="Buscar postulante por Apellido o Rut" autocomplete="off">
      <button>buscar</button>
    </mat-form-field>
  </div>
</div>
-->
 