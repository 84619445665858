import { AnnotationType } from './annotation-type';
import { User } from './user';
export class Annotation {
    id: string;
    annotationType: AnnotationType;
    annotationTypeId: string;
    its4Student: boolean;

    createdAt: string;
    courseId: string;
    subjectId: string;
    studentId: string;
    text: string;
    creator: User;

    deleted: boolean;
    deletedAt: string;
    deleter: User;

    inTimeForWrite: boolean;
    historyCounter: number;

    showHistoryDetail: boolean = false;
    history: AnnotationHistory[];
}

export class AnnotationHistory {
    id: string;
    annotation: Annotation;
    modifier: User;
    modifiedAt: string;
    previousText: string;
}