<mat-form-field *ngIf="false">
    <mat-label>Busqueda rápida</mat-label>
    <input matInput (keyup)="applyFilter($event)" autocomplete="off"
        placeholder="Buscar alumno por algún dato encontrado">
</mat-form-field>

<table mat-table [dataSource]="dataSource" matSort *ngIf="dataSource">
    <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Nombre </th>
        <td mat-cell *matCellDef="let row" nowrap class="align-left">
            <a href="javascript:void(0)" (click)="showDetailAdult(row.adulto.id)">{{row.adulto.nombre}}</a>
        </td>
    </ng-container>

    <ng-container matColumnDef="parentezco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Parentezco </th>
        <td mat-cell *matCellDef="let row" class="align-left"> {{row.relacion}} </td>
    </ng-container>

    <ng-container matColumnDef="telefono">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Teléfono </th>
        <td mat-cell *matCellDef="let row" class="align-left"> {{row.adulto.datosContacto.FONO_MOVIL}} </td>
    </ng-container>

    <ng-container matColumnDef="direccion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Correo Electronico </th>
        <td mat-cell *matCellDef="let row" class="align-left"> {{row.adulto.datosContacto.EMAIL}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center">Acciones </th>
        <td mat-cell *matCellDef="let row" class="align-center">
            <button mat-icon-button color="accent" matTooltip="Borrar relación" (click)="this.deleteRelation(row.id)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">Sin datos para la busqueda</td>
    </tr>
</table>

<mat-paginator *ngIf="dataSource && dataSource.data.length > 3" [pageSizeOptions]="[3, 5, 10, 50]"></mat-paginator>

<br>
<button mat-raised-button color="primary" (click)="showDetailAdult(null)"  *ngIf="this.canAdminContacts">
    <mat-icon>add</mat-icon> Agregar
</button>

 