import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ComunaService } from 'src/app/api/comuna.service';
import { ConfigService } from 'src/app/api/config.service';
import { CountryService } from 'src/app/api/country.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { EspecialidadService } from 'src/app/api/especialidad.service';
import { PaymentsService } from 'src/app/api/payments.service';
import { PostulanteService } from 'src/app/api/postulante.service';
import { StudentService } from 'src/app/api/student.service';
import { Alumno } from 'src/app/model/alumno';
import { Comuna } from 'src/app/model/comuna';
import { Country } from 'src/app/model/country';
import { EnrollForm } from 'src/app/model/enroll-form';
import { Especialidad } from 'src/app/model/especialidad';
import { Payments } from 'src/app/model/payment';
import { Postulante } from 'src/app/model/postulant';
import { Year } from 'src/app/model/year';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { RutUtility } from 'src/app/utility/rut.utility';

@Component({
  selector: 'app-matricula-form',
  templateUrl: './matricula-form.component.html',
  styleUrls: ['./matricula-form.component.css', '../../../style/table.css']
})
export class MatriculaFormComponent implements OnInit {
  DELETE_ID_AFTER_START: boolean = false;
  enrollingTo: string;
  // postulanteId: string;
  postulante: Postulante;
  alumno: Alumno;
  enrollForm: EnrollForm = new EnrollForm();
  comunas: Comuna[];
  especialidades: Especialidad[];
  countries: Country[];
  nextYear: number;
  public validatePayments: boolean = true;
  private annualTariff: number = 0;
  public activities: string[] = ['Handbol', 'Jiujitsu', 'Tenis de mesa', 'Fútbol', 'Basquetbol',
    'Voleibol', 'Teatro', 'Robótica', 'Ajedrez', 'Musica'];
  public selectedActivities: string[] = [];

  constructor(private router: Router,
    private postulanteService: PostulanteService,
    private alumnoService: StudentService,
    private comunaService: ComunaService,
    private especialidadService: EspecialidadService,
    private rutService: RutUtility,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private configService: ConfigService,
    private countryService: CountryService,
    private dateTimeUtility: DateTimeUtility,
    private alertUtility: AlertUtility,
    private paymentService: PaymentsService) {
  }

  ngOnInit(): void {
    this.enrollingTo = localStorage.getItem("enrollingTo");
    this.loadData();
  }

  public haveCourse(): boolean {
    return this.alumno?.course != null;
  }

  public getCourse(): string {
    return this.alumno?.course != null ? this.alumno.course : '';
  }

  public isStudent(): boolean {
    return this.enrollingTo == 'A';
  }

  private async loadData(): Promise<void> {
    const postulanteId = localStorage.getItem("postulantId");
    const alumnoId = localStorage.getItem("alumnoId");

    if (this.DELETE_ID_AFTER_START) {
      localStorage.removeItem("postulantId");
      localStorage.removeItem("alumnoId");
    }
    if (!this.isStudent() && postulanteId == null) {
      this.router.navigate(['matricula-postulante']);
    }
    if (this.isStudent() && alumnoId == null) {
      this.router.navigate(['matricula-alumno']);
    }

    this.comunaService.listAll()
      .subscribe(
        response => this.comunas = response,
        problems => console.error(problems)
      );
    this.especialidadService.listAll()
      .subscribe(
        response => this.especialidades = response,
        problems => console.error(problems)
      );
    this.countryService.listAll()
      .subscribe(
        response => this.countries = response,
        problems => console.error(problems)
      );

    this.configService.getValueByKey('matricula_actual')
      .subscribe(async response => {
        this.nextYear = +response;

        if (this.isStudent()) {
          this.alumno = await this.alumnoService.findByIdAndYearAsync(alumnoId, this.nextYear);

          this.fillEnrollForm();
          if (this.validatePayments) {

            this.configService.getYearConfig(this.nextYear - 1)
              .subscribe(
                (response: Year) => {
                  console.log('Year:', response);
                  this.annualTariff = response.annualTariff;

                  this.paymentService.listPayments('' + this.alumno.rut, '' + (this.nextYear - 1))
                    .subscribe(
                      async (response: Payments) => {
                        console.log('Payments: ', response);
                        const sumPayments = response.payments.reduce(
                          (accumulator, currentValue) => {
                            return accumulator + currentValue.paymentAmount;
                          }, 0);

                        if (sumPayments < this.annualTariff) {
                          const sumPaymentsFormated = sumPayments.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
                          const annualTariffFormated = this.annualTariff.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
                          const diff = this.annualTariff - sumPayments;
                          const diffFormated = diff.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });

                          const message = `El alumno ha pagado ${sumPaymentsFormated} del pago anual de ${annualTariffFormated}, debe pagar una diferencia de ${diffFormated} ¿Esta de acuerdo con continuar con la Matricula?.`

                          const doContinue = await this.alertUtility.confirmMessage(message);

                          if (!doContinue) this.onCancelClick();
                        }

                      },
                      problems => console.error(problems)
                    );
                },
                problems => console.error(problems)
              );
          }
        } else {
          this.postulanteService.findById(postulanteId)
            .subscribe(
              response => {
                console.log("POSTULANTE", response);
                this.postulante = response;
                this.postulante.tieneInternet = response.tieneInternet ? 'true' : 'false';
                this.fillEnrollForm();
              },
              problems => console.error(problems)
            );
        }
      });

  }

  private fillEnrollForm(): void {
    this.enrollForm = new EnrollForm();
    if (this.isStudent()) {
      this.enrollForm.direccion = this.alumno.direccion;
      this.enrollForm.comuna = this.alumno.comunaId;

      this.enrollForm.enrollNumber = this.alumno.numeroMatricula;
      this.enrollForm.especialidad = this.alumno.courseDto.especialidadId;
      this.enrollForm.especialidadNombre = this.alumno.courseDto.especialidadNombre;
      this.enrollForm.familia = this.alumno.familia;
      this.enrollForm.fonoApoderado = this.alumno.fonoApoderado;
      this.enrollForm.mailApoderado = this.alumno.mailApoderado;
      this.enrollForm.materno = this.alumno.materno;
      console.log('Nacimiento 1', this.alumno.nacimiento);

      this.enrollForm.nacimiento = this.alumno.nacimiento;
      this.enrollForm.nombreApoderado = this.alumno.nombreApoderado;
      this.enrollForm.nombreColegioProcedencia = '';
      this.enrollForm.nombres = this.alumno.nombres;
      this.enrollForm.paterno = this.alumno.paterno;
      this.enrollForm.prevision = this.alumno.prevision;
      this.enrollForm.rut = '' + this.alumno.rut + '-' + this.alumno.dv;
      this.enrollForm.rutApoderado = this.alumno.rutApoderado;
      this.enrollForm.tieneInternet = this.alumno.tieneInternet.toString();
      this.enrollForm.cuantosPc = this.alumno.cuantosPc;
      this.enrollForm.takeReligion = this.alumno.takeReligion;
      this.enrollForm.requiresPsychologist = this.alumno.requiresPsychologist;
      this.enrollForm.nationalityId = this.alumno.nationalityId;
      this.enrollForm.gender = this.alumno.gender;
      this.enrollForm.enrollNumber = this.alumno.numeroMatricula;
      this.enrollForm.socialName = this.alumno.socialName;
      if (this.alumno.activities != null && this.alumno.activities.length > 0) {
        this.selectedActivities = this.alumno.activities;
      }
      console.log('Activities: ', this.alumno.activities);


    } else {
      this.enrollForm = { ...this.postulante };
      this.enrollForm.takeReligion = true;

    }

    console.log('Formulario Cargado: ', this.enrollForm);
  }

  private fillReturnForm(): void {
    if (this.isStudent()) {
      this.alumno.courseDto.especialidadId = this.enrollForm.especialidad;
      this.alumno.familia = this.enrollForm.familia;
      this.alumno.fonoApoderado = this.enrollForm.fonoApoderado;
      this.alumno.mailApoderado = this.enrollForm.mailApoderado;
      this.alumno.materno = this.enrollForm.materno;

      this.alumno.nacimiento = moment(this.enrollForm.nacimiento).format('YYYY-MM-DD');
      this.alumno.nombreApoderado = this.enrollForm.nombreApoderado;
      this.alumno.nombres = this.enrollForm.nombres;
      this.alumno.paterno = this.enrollForm.paterno;
      this.alumno.prevision = this.enrollForm.prevision;
      this.alumno.rut = this.rutService.extractNumber(this.enrollForm.rut);
      this.alumno.dv = this.rutService.dv(this.alumno.rut);
      this.alumno.rutApoderado = this.enrollForm.rutApoderado;
      this.alumno.tieneInternet = this.enrollForm.tieneInternet == "true";
      this.alumno.direccion = this.enrollForm.direccion;
      this.alumno.comunaId = this.enrollForm.comuna;
      this.alumno.gender = this.enrollForm.gender;
      this.alumno.takeReligion = this.enrollForm.takeReligion;
      this.alumno.nationalityId = this.enrollForm.nationalityId;
      this.alumno.requiresPsychologist = this.enrollForm.requiresPsychologist;
      this.alumno.socialName = this.enrollForm.socialName;

      this.alumno.activities = this.selectedActivities;

      console.log('Alumno para enviar:', this.alumno);
    } else {
      this.postulante.especialidad = this.enrollForm.especialidad;
      this.postulante.especialidadNombre = '';
      this.postulante.familia = this.enrollForm.familia;
      this.postulante.fonoApoderado = this.enrollForm.fonoApoderado;
      this.postulante.mailApoderado = this.enrollForm.mailApoderado;
      this.postulante.materno = this.enrollForm.materno;

      this.postulante.nacimiento = this.dateTimeUtility.momentToString(this.dateTimeUtility.stringToMoment(this.enrollForm.nacimiento));

      this.postulante.nombreApoderado = this.enrollForm.nombreApoderado;
      this.postulante.nombres = this.enrollForm.nombres;
      this.postulante.paterno = this.enrollForm.paterno;
      this.postulante.prevision = this.enrollForm.prevision;
      this.postulante.rut = this.enrollForm.rut;
      this.postulante.rutApoderado = this.enrollForm.rutApoderado;
      this.postulante.tieneInternet = this.enrollForm.tieneInternet;
      this.postulante.direccion = this.enrollForm.direccion;
      this.postulante.comuna = this.enrollForm.comuna;
      this.postulante.comunaColegioProcedencia = this.enrollForm.comunaColegioProcedencia;
      this.postulante.nombreColegioProcedencia = this.enrollForm.nombreColegioProcedencia;
      this.postulante.gender = this.enrollForm.gender;
      this.postulante.takeReligion = this.enrollForm.takeReligion;
      this.postulante.nationalityId = this.enrollForm.nationalityId;
      this.postulante.requiresPsychologist = this.enrollForm.requiresPsychologist;
      this.postulante.socialName = this.enrollForm.socialName;
      this.postulante.activities = this.selectedActivities;

      console.log('Postulante para enviar:', this.postulante);
    }
  }

  validaRut(): void {
    if (!this.rutService.rutValido(this.enrollForm.rutApoderado)) {
      this.openSnack(`El Rut "${this.enrollForm.rutApoderado}" no es valido`);
    }
  }

  openSnack(message: string): void {
    this.snackBar.open(message, 'Aceptar');
  }

  onCancelClick(): void {
    if (this.isStudent()) {
      localStorage.setItem('tabSelected', '4');
      this.router.navigate(['admission-enrollment']);
    } else {
      localStorage.setItem('tabSelected', '3');
      this.router.navigate(['admission-enrollment']);
    }
  }

  public onMatricularClick(): void {
    if (this.isStudent()) {
      console.log("Saving student: ", this.alumno);
      this.fillReturnForm();

      this.alumnoService.enrollNewYear(this.alumno)
        .subscribe(
          response => {
            console.log('matriculando: ', response);
            this.gotoDocument(response.numeroMatricula);
          },
          problems => {
            console.error("ERROR:" + JSON.stringify(problems));
            if (problems.error.message.indexOf('Invalid date') > -1) {
              alert('Fecha de nacimiento incorrecta');
            } else {
              alert(problems.error.message);
            }
          }
        );
    } else {
      console.log("this.postulante: ", this.postulante);
      this.fillReturnForm();
      this.postulanteService.enroll(this.postulante)
        .subscribe(
          response => {
            console.log('respuesta de postulante matriculado', response);
            this.gotoDocument(response.numeroMatricula);
          },
          problems => {
            console.error("ERROR:" + JSON.stringify(problems));
            alert(problems.error.message);
          }
        );
    }
  }



  private gotoDocument(enrollNumber: number): void {
    const openSelf: boolean = true;

    if (openSelf) {
      const pdfUrl = this.postulanteService.getDocumentUrlByEnrollNumber(enrollNumber);
      console.log(pdfUrl);

      this.postulanteService.downloadPdf(enrollNumber)
        .subscribe((response: ArrayBuffer) => this.download(response,
          'application/pdf',
          `matricula-${enrollNumber}.pdf`));



      return;
    }
    console.log("enrollNumber:", enrollNumber);
    localStorage.setItem('enrollNumber', '' + enrollNumber);
    this.router.navigate(['enroller-document']);

  }

  onEnrollDocumentClick(): void {
    this.gotoDocument(this.enrollForm.enrollNumber);
  }

  download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
    const file: Blob = new Blob([binaryData], { type: fileType });
    const url: string = window.URL.createObjectURL(file);
    const anchorElement: HTMLAnchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = url;
    anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }

}
