import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Column } from "../model/crud-response";
import { EntityConfig } from "../model/entity-config";
import { MasterAndSlave } from '../model/master-and-slave';
import { AuthorService } from './author.service';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})

export class CrudService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/crud/1.0';

    private listUrl: string = '/listAll';
    private readConfigUrl: string = '/read-config';
    private insertRecordUrl: string = '/insert-record';
    private updateRecordUrl: string = '/update-record';
    private deleteRecordUrl: string = '/delete-record';
    private saveMasterAndSlaveUrl: string = '/save-master-and-slave';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public list(alias: string): Observable<any> {
        const url = this.baseUrl + this.serviceUrl + `${this.listUrl}/${alias}`;
        return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async listAsync(alias: string): Promise<any> {
        const url = this.baseUrl + this.serviceUrl + `${this.listUrl}/${alias}`;
        return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public readConfig(alias: string): Observable<EntityConfig> {
        const url = this.baseUrl + this.serviceUrl + `${this.readConfigUrl}/${alias}`;
        return this.http.get<EntityConfig>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public readConfigAsync(alias: string): Promise<EntityConfig> {
        const url = this.baseUrl + this.serviceUrl + `${this.readConfigUrl}/${alias}`;
        return this.http.get<EntityConfig>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public insertRecord(alias: string, columns: Column[]): Observable<any> {
        const url = this.baseUrl + this.serviceUrl + `${this.insertRecordUrl}/${alias}`;
        console.log(columns);

        return this.http.post<any>(url, columns, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public updateRecord(alias: string, columns: Column[]): Observable<any> {
        const url = this.baseUrl + this.serviceUrl + `${this.updateRecordUrl}/${alias}`;
        return this.http.post<any>(url, columns, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public deleteRecords(alias: string, ids: string[]): Observable<boolean> {
        const url = this.baseUrl + this.serviceUrl + `${this.deleteRecordUrl}/${alias}`;
        return this.http.post<boolean>(url, ids, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public saveMasterAndSlave(masterAndSlave: MasterAndSlave): Observable<any> {
        const url = this.baseUrl + this.serviceUrl + `${this.saveMasterAndSlaveUrl}`;
        return this.http.post<boolean>(url, masterAndSlave, { headers: this.authorService.headerWithAuthorization(null) });
    }

}
