import { Rating } from './rating';
import { Subject } from './subject';
import { Course } from './course';

export class RatingBook {
    subject: Subject;
    course: Course;
    period: number;
    ratings: Rating[];
}
