import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CourseService } from 'src/app/api/course.service';
import { TeacherAndSubject } from 'src/app/model/teacher-and-subject';
import { AlertUtility, DialogMessageType } from 'src/app/utility/alert.utility';
import { CourseTeacherSubjectDialogComponent } from './course-teacher-subject-dialog/course-teacher-subject-dialog.component';
import { Moment } from 'moment';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';

@Component({
  selector: 'app-course-teachers',
  templateUrl: './course-teachers.component.html',
  styleUrls: ['./course-teachers.component.css', './../../../../style/table.css']
})
export class CourseTeachersComponent implements OnInit, AfterViewInit {
  @Input()
  courseId: string;

  dataSource: MatTableDataSource<TeacherAndSubject> = new MatTableDataSource([]);
  columnsToDisplay: string[] = ['selector', 'teacher', 'subject', 'mineduc-code', 'group', 'priority'];
  selection = new SelectionModel<TeacherAndSubject>(true, []);

  currentSelected: TeacherAndSubject = null;
  currentGroup: string = null;

  constructor(private courseService: CourseService, public dialog: MatDialog, public alert: AlertUtility, public dateTimeUtility: DateTimeUtility) { }

  ngAfterViewInit() {
  }

  onSelectOne(row: TeacherAndSubject) {
    // console.log(row);
    console.log(row);
    this.currentGroup = row.group;
    this.currentSelected = row;
  }

  ngOnInit(): void {
    this.refreshGrid();
  }

  private refreshGrid(): void {
    this.courseService.listTeachersAndSubjects(this.courseId)
      .subscribe(
        (response: TeacherAndSubject[]) => {
          console.log('Response: ', response);
          this.dataSource = new MatTableDataSource(response);
        },
        problems => console.error(problems)
      );

  }

  nothingSelected() {
    return this.currentSelected == null;
  }

  public onOpenDialogForAdd(): void {
    const courseTeacherSubjectDialogParam: CourseTeacherSubjectDialogParam = new CourseTeacherSubjectDialogParam();
    courseTeacherSubjectDialogParam.title = 'Agregar asignatura y profesor';
    courseTeacherSubjectDialogParam.courseId = this.courseId;

    courseTeacherSubjectDialogParam.subjectId = null;
    courseTeacherSubjectDialogParam.teacherId = null;
    courseTeacherSubjectDialogParam.priority = this.maxPriority(this.dataSource.data) + 1;
    courseTeacherSubjectDialogParam.group = '';
    // courseTeacherSubjectDialogParam.freezeGroup = false;
    courseTeacherSubjectDialogParam.action = 'create';

    const dialogRef = this.dialog.open(CourseTeacherSubjectDialogComponent,
      { data: courseTeacherSubjectDialogParam, width: '50%' });

    dialogRef.afterClosed().subscribe(responseFromDialog => {
      // console.log(responseFromDialog.startDate);

      if (responseFromDialog != undefined) {
        const teacherAndSubjectToSave: TeacherAndSubject = new TeacherAndSubject();
        teacherAndSubjectToSave.priority = responseFromDialog.priority;
        teacherAndSubjectToSave.teacherId = responseFromDialog.teacherId;
        teacherAndSubjectToSave.subjectId = responseFromDialog.subjectId;
        teacherAndSubjectToSave.group = responseFromDialog.group;
        if (responseFromDialog.group.length > 0) {
          console.log(responseFromDialog.startDate);
          if (responseFromDialog.startDate == undefined || responseFromDialog.finishDate == undefined) {
            this.alert.dialogMessage('Rango de fechas no fue definido correctamente', 'FECHAS', DialogMessageType.ERROR);
            return;
          }
          teacherAndSubjectToSave.startDate = this.dateTimeUtility.momentToString(responseFromDialog.startDate);
          teacherAndSubjectToSave.finishDate = this.dateTimeUtility.momentToString(responseFromDialog.finishDate);
        }

        this.courseService.relateTeacherSubject(this.courseId, teacherAndSubjectToSave)
          .subscribe(
            response => {
              this.refreshGrid();

              /*
                            const tempData = this.dataSource.data;
                            tempData.push(response);
                            // this.dataSource.data = tempData;
                            this.dataSource.data = this.sortByPriority(tempData);
              */

            },
            problems => console.error(problems)
          );
      }
    });
  }

  public onUpdate(): void {
    const courseTeacherSubjectDialogParam: CourseTeacherSubjectDialogParam = new CourseTeacherSubjectDialogParam();
    courseTeacherSubjectDialogParam.title = 'Actualizar asignatura y profesor';
    courseTeacherSubjectDialogParam.courseId = this.courseId;
    courseTeacherSubjectDialogParam.subjectId = this.currentSelected.subject.id;
    courseTeacherSubjectDialogParam.teacherId = this.currentSelected.teacher.id;
    courseTeacherSubjectDialogParam.priority = this.currentSelected.priority;
    courseTeacherSubjectDialogParam.newGroup = this.currentSelected.group;
    courseTeacherSubjectDialogParam.group = this.currentGroup;
    courseTeacherSubjectDialogParam.action = 'update';


    if (this.currentSelected.group != null && this.currentSelected.group.length > 0) {
      courseTeacherSubjectDialogParam.startDate = this.dateTimeUtility.stringToMoment(this.currentSelected.startDate);
      courseTeacherSubjectDialogParam.finishDate = this.dateTimeUtility.stringToMoment(this.currentSelected.finishDate);
    }

    const dialogRef = this.dialog.open(CourseTeacherSubjectDialogComponent,
      { data: courseTeacherSubjectDialogParam, width: '80%' });

    dialogRef.afterClosed()
      .subscribe(responseFromDialog => {
        if (responseFromDialog == undefined) return;

        console.log('responseFromDialog: ', responseFromDialog);


        const teacherAndSubjectToSave: TeacherAndSubject = new TeacherAndSubject();
        teacherAndSubjectToSave.id = this.currentSelected.id;
        teacherAndSubjectToSave.priority = responseFromDialog.priority;
        teacherAndSubjectToSave.teacherId = responseFromDialog.teacherId;
        teacherAndSubjectToSave.subjectId = responseFromDialog.subjectId;
        teacherAndSubjectToSave.newGroup = responseFromDialog.group;
        teacherAndSubjectToSave.group = this.currentGroup;

        if (teacherAndSubjectToSave.group != null && teacherAndSubjectToSave.group.length > 0) {
          teacherAndSubjectToSave.startDate = this.dateTimeUtility.momentToString(responseFromDialog.startDate);
          teacherAndSubjectToSave.finishDate = this.dateTimeUtility.momentToString(responseFromDialog.finishDate);
        }

        console.log('teacherAndSubjectToSave', teacherAndSubjectToSave);


        this.courseService.updateTeacherSubject(this.courseId, teacherAndSubjectToSave)
          .subscribe(
            response => {
              this.refreshGrid();

              /*
              console.log(response);


              const tempData = this.removeFormList(this.dataSource.data, response.id);
              tempData.push(response);
              this.dataSource.data = this.sortByPriority(tempData);
            */
            },
            problems => console.error(problems)
          );

      });
  }



  public async onRemove(): Promise<void> {
    const confirm = await this.alert.confirmMessage('Desea retirar esta relacion?', 'CONFIRMAR')

    if (confirm) {
      const group = this.currentGroup != null && this.currentGroup.trim().length > 0 ? this.currentSelected.group : "0";
      this.courseService.removeRelateTeacherSubject(this.currentSelected.id, group)
        .subscribe(
          response => {
            if (response) {
              // this.dataSource.data = this.removeFormList(this.dataSource.data, this.currentSelected.id);
              this.refreshGrid();
              this.currentSelected = null;
            } else {
              this.alert.dialogMessage('No se quitó la al profesor, es posible que exista un horario asociasdo');
            }
          },
          problems => {
            console.error(problems);
            this.alert.dialogMessage(problems.error.message, 'Error', DialogMessageType.ERROR);
          }
        )
    }
  }



  removeFormList(list: TeacherAndSubject[], id: string): TeacherAndSubject[] {
    const tempData = list.slice();
    return tempData.filter(tas => tas.id != id);
  }

  sortByPriority(list: TeacherAndSubject[]): TeacherAndSubject[] {
    const tempData = list.slice();
    tempData.sort((o1, o2) => {
      if (o1.priority > o2.priority) return 1;
      if (o1.priority < o2.priority) return -1;
      return 0;
    });
    return tempData;
  }

  maxPriority(list: TeacherAndSubject[]): number {
    let max: number = 0;
    let first: boolean = true;
    if (list.length == 0) {
      return max;
    }

    list.forEach(tas => {
      if (first) {
        max = tas.priority;
        first = false;
      } else {
        if (tas.priority >= max) max = tas.priority;
      }
    });
    return max;
  }
}

export class CourseTeacherSubjectDialogParam {
  title: string;
  courseId: string;
  teacherId: string;
  subjectId: string;
  priority: number;
  group: string;
  newGroup: string;
  startDate: Moment;
  finishDate: Moment;
  action: string;
}
