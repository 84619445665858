import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogMessageType } from '../alert.utility';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {
  public dialogMessageTypeEnum = DialogMessageType;


  constructor(public dialogRef: MatDialogRef<DialogMessageParameters>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogMessageParameters) { }

  ngOnInit(): void {
  }

  public doClickButton(accept: boolean) {
    this.dialogData.response = accept ? 'OK' : 'NO';
  }

}

export class DialogMessageParameters {
  message: string;
  response: string;
  showCancelButton: boolean = false;
  title: string;
  type: DialogMessageType;

}