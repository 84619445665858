<h3 *ngIf="student" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px">
    <ul>
        <li *ngIf="this.showName">Alumno: {{this.student.paterno + ' ' +
            this.student.materno + ', '+this.student.nombres}}</li>
        <li *ngIf="this.showRut">Rut: {{this.student.rut +'-' + this.student.dv}}</li>
        <li *ngIf="this.showCourse">Curso: {{this.student.course}}, {{this.student.courseDto.agno}}</li>
        <li *ngIf="this.showAge && this.student.edad">Edad: {{this.student.edad}}</li>
        <li *ngIf="this.showAttorneyData && this.student.nombreApoderado">Apoderado: {{this.student.nombreApoderado}}
        </li>
        <li *ngIf="this.showAttorneyData && this.student.fonoApoderado">Teléfono: {{this.student.fonoApoderado}}</li>
        <li *ngIf="this.showAttorneyData && this.student.nombreApoderado">Mail: {{this.student.mailApoderado}}</li>
    </ul>
</h3>