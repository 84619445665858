import { Component, OnInit, ViewChild } from '@angular/core';
import { Company } from 'src/app/model/company';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormControl, FormGroup, FormsModule,ReactiveFormsModule, Validators} from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgFor,NgIf, JsonPipe } from '@angular/common';
import moment, { Moment } from 'moment'
import { StudentService } from 'src/app/api/student.service';
import { PracticeService } from 'src/app/api/practice.service';
import { Alumno } from 'src/app/model/alumno';
import { Observable } from 'rxjs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import { Practice } from 'src/app/model/practice';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { Location } from '@angular/common'
import { DownloadUtility } from 'src/app/utility/download.utility';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeletePractice } from './del-practice/dialog-delete-practice.component';


@Component({
  selector: 'app-add-practice',
  templateUrl: './add-practice.component.html',
  styleUrls: ['./add-practice.component.css'],
  standalone: true,
  imports: [MatDividerModule,MatTooltipModule,MatPaginatorModule,MatSortModule, MatTableModule,MatIconModule,
    MatCardModule,MatSnackBarModule,MatButtonModule,MatAutocompleteModule,FormsModule, MatFormFieldModule, MatInputModule,MatSelectModule, NgFor,MatDatepickerModule,MatNativeDateModule,ReactiveFormsModule,NgIf, JsonPipe]
})
export class AddPracticeComponent implements OnInit {
  company: Company;
  year: number = new Date().getFullYear();
  yearDb : string;
  today: Date = new Date();
  formattedDate = (moment(this.today)).format('DD-MM-YYYY')
  entradaValue: string;
  diaLaboralValue: string;
  salidaValue: string;
  phoneNumber: string;
  studentRut: number;
  studentName: string;
  instructor: string;
  supervisor: string;
  total : number = 0;
  dataSource: MatTableDataSource<Practice>;
  displayedColumns: string[] = ['Id', 'Alumno','Instructor','Supervisor','Curso','Dias','Inicio','Actions'];
  companiesData: Company[]  = [];
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  dias: any[] = [
    {value: 'Lunes-Martes-Miercoles', viewValue: 'Lunes-Martes-Miercoles'},
    {value: 'Miercoles - Jueves - Viernes', viewValue: 'Miercoles - Jueves - Viernes'}
  ];

  students: Alumno[];
  filteredOptions: Observable<any>;
  hideUpdate : boolean = false;
  hideAdd : boolean = true;

  studentsInfo : any[] = [];

  horarios: any[] = [
    {value: '07:00:00', viewValue: '07:00:00'},
    {value: '07:30:00', viewValue: '07:30:00'},
    {value: '08:00:00', viewValue: '08:00:00'},
    {value: '08:30:00', viewValue: '08:30:00'},
    {value: '09:00:00', viewValue: '09:00:00'},
    {value: '09:30:00', viewValue: '09:30:00'},
    {value: '10:00:00', viewValue: '10:00:00'},
    {value: '10:30:00', viewValue: '10:30:00'},
    {value: '11:00:00', viewValue: '11:00:00'},
    {value: '11:30:00', viewValue: '11:30:00'},
    {value: '12:00:00', viewValue: '12:00:00'},
    {value: '12:30:00', viewValue: '12:30:00'},
    {value: '13:00:00', viewValue: '13:00:00'},
    {value: '13:30:00', viewValue: '13:30:00'},
    {value: '14:00:00', viewValue: '14:00:00'},
    {value: '14:30:00', viewValue: '14:30:00'},
    {value: '15:00:00', viewValue: '15:00:00'},
    {value: '15:30:00', viewValue: '15:30:00'},
    {value: '16:00:00', viewValue: '16:00:00'},
    {value: '16:30:00', viewValue: '16:30:00'},
    {value: '17:00:00', viewValue: '17:00:00'},
    {value: '17:30:00', viewValue: '17:30:00'},
    {value: '18:00:00', viewValue: '18:00:00'},
    {value: '18:30:00', viewValue: '18:30:00'},
    {value: '19:00:00', viewValue: '19:00:00'},
    {value: '19:30:00', viewValue: '19:30:00'}
  ];
  selectedPractice: Practice;

  constructor(private studentService: StudentService,
    private practiceService: PracticeService,
    private snakBar: MatSnackBar,
    private location: Location,
    private downloadUtility: DownloadUtility,
    private alert: AlertUtility,
    public dialog: MatDialog) {
    this.company = JSON.parse(sessionStorage.getItem('companySelected')) as Company;

   }

  async ngOnInit() {
    this.yearDb = localStorage.getItem('practica_actual');
    setTimeout(async () => {
    await this.practiceService.getPracticeByCompanyRunYear(this.company.rut_emp,this.yearDb).subscribe(
      response => {
        let responseFiltered = response;
       this.total = response.length;
       response.forEach(async element => {
          await this.studentService
            .findByRutOrName(element.run_alum.toString(), true)
            .subscribe(
              responseStd => {
                if (responseStd.length != 0) {
                  if(responseStd[0].estado=="MAT"){
                    this.studentsInfo.push({
                      rut : responseStd[0].rut,
                      nombres : responseStd[0].paterno + " " + responseStd[0].materno + " " +  responseStd[0].nombres,
                      curso : responseStd[0].course,
                      cursoDto : responseStd[0].courseDto
                  });
                  }else{
                    console.log("ALUMNO RETIRADO: "+ element.cod_run);
                    responseFiltered.splice(responseFiltered.findIndex(item => item.cod_run === element.cod_run),1)

                  }
                }
              },
              problems => {
                console.log(problems);
              }
            );
        });


       this.dataSource = new MatTableDataSource(responseFiltered);
       this.dataSource.paginator = this.paginator;
      },
      problems => {
        console.log(problems);

      }
    );
  },500);

    setTimeout(() => {
      if(sessionStorage.getItem('action') === 'edit'){
        this.practicesEditOnClick(this.dataSource.filteredData.filter(data => data.run_alum.toString() == sessionStorage.getItem('student'))[0]);
        sessionStorage.removeItem('action');
        sessionStorage.removeItem('student');
      }
    },1000);
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.students.filter(option => option.paterno.toLowerCase().includes(filterValue));
  }

  setRut(userId) {
    this.studentRut = userId.split(' - ')[1];
    this.studentName = userId.split(' - ')[0];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (typeof filterValue === 'string' && filterValue.trim().length === 0) {
    } else {
      this.studentService
        .findByRutOrNameLastYear(filterValue, Number(this.yearDb)-1,11)
        .subscribe(
          response => {
            let filtered = response.filter(option => option.estado =="MAT");
            if (filtered.length != 0) {

              this.students = filtered;
            }
          },
          problems => {
            console.log(problems);
          }
        );
        }
  }

  setPhone(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.phoneNumber = value;
  }

  setInstructor(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.instructor = value;
  }

  setSupervisor(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.supervisor = value;
  }

  async addPractice(){
    await this.practiceService.getPracticeByStudenRunYear(this.studentRut,this.yearDb).subscribe( async response => {
      if(response==null){
          const practice = new Practice();
          practice.agno = this.yearDb;
          practice.cod_empre = this.company.rut_emp;
          practice.cod_run = this.company.rut_emp;
          practice.dias = this.diaLaboralValue;
          practice.estado = 'A';
          practice.fono = this.phoneNumber;
          practice.ini = this.entradaValue;
          practice.instructor = this.instructor;
          practice.run_alum = this.studentRut;
          practice.sal = this.salidaValue;
          practice.supervisor = this.supervisor;
          practice.inicio = moment(this.registerForm.value.inicio).format('YYYY-MM-DDTHH:mm:ss-04:00');
          practice.termino = moment(this.registerForm.value.termino).format('YYYY-MM-DDTHH:mm:ss-04:00');

            await this.practiceService.addPractice(practice).subscribe(
                response => {
                  this.snakBar.open("Práctica creada correctamente!","OK");
                  setTimeout(async () => {
                    location.reload();
                  },1000);

                },
                problems => {
                  console.log(problems);

                }
              );
        }else{
          var practicaActual : any = response;
          this.snakBar.open("Alumno ya tiene práctica activa en empresa : " + practicaActual.cod_empre,"OK");
        }
    },
    problems => {
      console.log(problems);

    });
  }

  async cancelUpdatePractice(){
    location.reload();
  }

  async updatePractice(){
    const practice = new Practice();
    practice.id=this.selectedPractice.id;
    practice.agno = this.yearDb;
    practice.cod_empre = this.company.rut_emp;
    practice.cod_run = this.company.rut_emp;
    practice.dias = this.diaLaboralValue;
    practice.estado = 'A';
    practice.fono = this.phoneNumber;
    practice.ini = this.entradaValue;
    practice.instructor = this.instructor;
    practice.run_alum = this.studentRut;
    practice.sal = this.salidaValue;
    practice.supervisor = this.supervisor;
    practice.inicio = moment(this.registerForm.value.inicio).format('YYYY-MM-DDTHH:mm:ss-04:00');
    practice.termino = moment(this.registerForm.value.termino).format('YYYY-MM-DDTHH:mm:ss-04:00');

   await this.practiceService.updatePractice(practice).subscribe(
      response => {
        this.snakBar.open("Práctica actualizada correctamente!","OK");
        setTimeout(async () => {
          location.reload();
        },1000);

      },
      problems => {
        console.log(problems);

      }
    );

  }

  public contractOnClick(practice: Practice) {
    let contentType: string;
    let fileName: string;
    contentType = 'application/pdf';
    fileName = 'contrato_'+practice.id+'.pdf';

    this.practiceService.getContract(practice.id)
      .subscribe(
        (response: ArrayBuffer) => this.downloadUtility.download(response, contentType, fileName),
        problems => {
          this.alert.fastMessage(`Error al obtener archivo (${problems.message})`);
          console.error(problems);
        }
      );

  }
  practicesOnClick(rut_emp: string){
   // console.dir(this.registerForm);
  }

  formatDate(date: string) {
    return (moment(date)).format('DD-MM-YYYY');
  }

   getStudentName(rut: string) {
    let studentName =this.studentsInfo.filter(student => student.rut === rut)[0];
    return studentName != undefined ? (studentName.nombres!=undefined ? studentName.nombres: "") : "";

  }

  getStudentCourse(rut: string) {
    let studentName =this.studentsInfo.filter(student => student.rut === rut)[0];
    return studentName != undefined ? (studentName.curso!=undefined ? studentName.curso: "") : "";
  }

  back(): void {
    this.location.back();
  }

  practicesEditOnClick(practice: Practice){
    this.registerForm.get('name').disable();
    this.selectedPractice = practice;
    this.hideAdd = false;
    this.hideUpdate = true;
    this.entradaValue = practice.ini;
    this.diaLaboralValue = practice.dias;
    this.salidaValue = practice.sal;
    this.phoneNumber = practice.fono;
    this.studentRut = practice.run_alum;
    this.instructor = practice.instructor;
    this.supervisor = practice.supervisor;
    this.studentName = this.studentsInfo.filter(student => student.rut === practice.run_alum)[0].nombres;
    this.registerForm.patchValue({
                        inicio : new Date(practice.inicio),
                        termino : new Date(practice.termino)
                        });
  }

  registerForm = new FormGroup({
    name: new FormControl('',  Validators.required),
    instructor: new FormControl('',  Validators.required),
    supervisor: new FormControl('',  Validators.required),
    fono: new FormControl('',  Validators.required),
    dias: new FormControl('',  Validators.required),
    salida: new FormControl('',  Validators.required),
    entrada: new FormControl('',  Validators.required),
    inicio: new FormControl<Date | null>(null,Validators.required),
    termino: new FormControl<Date | null>(null,Validators.required)
  });

  openDialog(practice: Practice) {
    const dialogRef = this.dialog.open(DialogDeletePractice, { data:practice});
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngAfterViewInit(){

  }

}
