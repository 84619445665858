import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { StudentService } from 'src/app/api/student.service';
import { Alumno } from 'src/app/model/alumno';

@Component({
  selector: 'app-matricula-alumno',
  templateUrl: './matricula-alumno.component.html',
  styleUrls: ['./matricula-alumno.component.css',
'../../../style/table.css']
})
export class MatriculaAlumnoComponent implements OnInit {
  displayedColumns: string[] = ['rut', 'nombre', 'especialidad', 'curso' /*, 'nro_matricula'*/];
  dataSource: MatTableDataSource<Alumno>;
  filterValue: string = '';
  showResult: boolean = false;
  rutOrPaterno: string = '';
  nextYear: number = 0;
  currentYear: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private alumnoService: StudentService,
    private cursoService: CourseService,
    private router: Router,
    private configService: ConfigService) { }

  ngOnInit(): void {
    this
      .configService
      .getValueByKey("matricula_actual")
      .subscribe(
        response => this.nextYear = +response,
        problems => alert('No se pudo recuperar año para matriculas desde la configuracion.' + problems.errors.message)
      );
    this
      .configService
      .getValueByKey("anio_actual")
      .subscribe(
        response => this.currentYear = +response,
        problems => alert('No se pudo recuperar año actual desde la configuracion.' + problems.errors.message)
      );
  }

  onSearchClick(): void {
    if (this.rutOrPaterno.length < 3) {
      alert('Debe ingresar al menos 3 caracteres!');
      return;
    }
    this
      .alumnoService
      .findByRutOrLastnameForEnroll(this.rutOrPaterno)
      // .findByRutOrLastnameAndYear(this.rutOrPaterno, this.currentYear)
      //      .findByRutOrName(this.rutOrPaterno, true)
      .subscribe(
        response => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response as Alumno[]);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.applyFilter(null);
          this.showResult = true;
        },
        problems => console.error(problems)
      );
  }

  applyFilter(e: Event) {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onPressNameClick(row) {
    localStorage.setItem("alumnoId", row.id);
    localStorage.setItem("enrollingTo", "A");
    this.router.navigate(['matricula-form']);

  }
}
