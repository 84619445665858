import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseService } from 'src/app/api/course.service';
import { InscripcionService } from 'src/app/api/inscripcion.service';
import { CreateCoursesFromLevel } from 'src/app/model/create-from-level';
// import { Level } from 'src/app/model/level';
import { NewCoursesDialogComponent, NewCoursesDialogParameter } from './new-courses-dialog/new-courses-dialog.component';
import { CourseSummary } from 'src/app/model/course-summary';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/api/permission.service';
import { ConfigService } from 'src/app/api/config.service';
import { Especialidad } from 'src/app/model/especialidad';
import { EspecialidadService } from 'src/app/api/especialidad.service';
import { AlertUtility } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-next-courses',
  templateUrl: './next-courses.component.html',
  styleUrls: ['./next-courses.component.css',
    '../../../style/table.css'
  ]
})
export class NextCoursesComponent implements OnInit {
  @Input() nextYear: number;

  public dataSource: CourseSummary[] = []; 
  public specialties: Especialidad[] = [];

  public displayedColumns: string[] = ['level', 'letter', 'year', 'ins', 'xmt', 'tot', 'mat', 'act'];
  public expandedElement: CourseSummary;

  public canCreateNewCourses: boolean = false;
  public canChangeSpecialty: boolean = false;
  public creating: boolean = false;
  public specialtySelector: boolean = false;
  public specialtyId: string = '';

  constructor(private inscriptionService: InscripcionService, private dialog: MatDialog,
    public courseService: CourseService, private snackBar: MatSnackBar,
    private router: Router, private permissionService: PermissionService,
    private specialitiesService: EspecialidadService,
    private alertUtility: AlertUtility) { }

  ngOnInit(): void {
    this.refreshGrid();
    this.loadPermissions();
    this.specialitiesService.listAll()
      .subscribe(
        response => this.specialties = response,
        problems => console.error(problems)
      );
  }

  private async loadPermissions() {
    this.canCreateNewCourses = await this.permissionService.hasPermissionAsync('ADMISION.CREATE_COURSES');
    this.canChangeSpecialty = await this.permissionService.hasPermissionAsync('ADMISION.CHANGE_SPECIALTY_TO_COURSE');
  }

  public onShowSpecialtySelector(courseSummary: CourseSummary): void {
    this.toggleButtons(courseSummary, true);
  }

  public onCommitSpecialtySelector(courseSummary: CourseSummary): void {
    this.toggleButtons(courseSummary, false);
    this.courseService.updateSpecialty(courseSummary.course.id, courseSummary.course.especialidadId)
      .subscribe(
        response => {
          console.log(response);
          courseSummary.course.especialidadId = response.especialidadId;
          courseSummary.course.especialidadNombre = response.especialidadNombre;
          this.alertUtility.fastMessage('Asignación grabada con éxito');
        },
        problems => console.error(problems)
      );
  }

  public onCancelSpecialtySelector(courseSummary: CourseSummary): void {
    this.toggleButtons(courseSummary, false);
  }

  private toggleButtons(courseSummary: CourseSummary, showSpecialtySelector: boolean) {
    this.dataSource.forEach(row => {
      row.showSpecialtySelector = false;
      row.showEditButton = !showSpecialtySelector;
    });
    courseSummary.showSpecialtySelector = showSpecialtySelector;
  }


  private refreshGrid(): void {
    this.inscriptionService.listCoursesSummary()
      .subscribe((response: CourseSummary[]) => {
        this.dataSource = response;
        console.log(response);
        this.dataSource.forEach(row => {
          row.showEditButton = true;
          row.showSpecialtySelector = false;
        });
      });

  }

  public addCourses(): void {
    const newCoursesDialogParameter: NewCoursesDialogParameter = new NewCoursesDialogParameter();
    const dialogRef = this.dialog.open(NewCoursesDialogComponent, { data: newCoursesDialogParameter });

    dialogRef.afterClosed()
      .subscribe(
        (result: NewCoursesDialogParameter) => {
          if (result == undefined || !result.newLevelId) return;
          this.creating = true;
          const createFromLevel: CreateCoursesFromLevel = new CreateCoursesFromLevel();
          createFromLevel.includeStudents = result.includeStudents;
          createFromLevel.letters = result.letters;
          createFromLevel.newLevelId = result.newLevelId;
          createFromLevel.prevLevelId = result.prevLevelId;

          this.courseService.createFromLevel(createFromLevel)
            .subscribe(response => {
              this.creating = false;
              this.refreshGrid();
            },
              problems => {
                this.snackBar.open(problems.error.message, 'OK', { duration: 5000 });
                this.creating = false;
              }
            );

        });
  }

  public expandCourse(courseId: string) {
    console.log('asi no mas', courseId);
    // localStorage.setItem('nextYear', '' + this.nextYear);
    localStorage.setItem('courseId', courseId);
    this.router.navigate(['admin-students']);
  }

}
