<h1 mat-dialog-title>Detalle de asistencia</h1>


<div mat-dialog-content fxLayout="column">

    <div fxlayout="row">
        <div>Alumno: <strong>{{this.dialogData.studentName}}</strong> </div>
        <div>Fecha: <strong>{{this.dialogData.dateSelected}}</strong> </div>
    </div>


    <table mat-table [dataSource]="this.datasource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="hour">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> Hora </th>
            <td mat-cell *matCellDef="let row" class="align-center"> {{row.hour.start}} - {{row.hour.finish}} </td>
        </ng-container>

        <ng-container matColumnDef="icon-status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-center"> </th>
            <td mat-cell *matCellDef="let row" class="align-center">
                <mat-icon [color]="this.statusColor(row)">{{statusIcon(row)}}</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Estado </th>
            <td mat-cell *matCellDef="let row" class="align-left">
                {{statusTranslate(row)}}&nbsp;&nbsp;&nbsp;
            </td>
        </ng-container>
        <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="align-left"> Comentario </th>
            <td mat-cell *matCellDef="let row" class="align-left"> <strong>{{row.comment}}</strong>   </td>
        </ng-container>

    </table>



</div>



<div mat-dialog-actions>
    <button mat-raised-button cdkFocusInitial [mat-dialog-close]="this.dialogData" color="primary">Cerrar</button>
</div>