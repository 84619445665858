import { Subject } from "./subject";
import { User } from "./user";

export class TeacherAndSubject {
    id: string;
    teacher: User;
    subject: Subject;
    teacherId: string;
    subjectId: string;
    group: string;
    newGroup: string;
    startDate: string;
    finishDate: string;
    priority: number;
}