import { Injectable } from "@angular/core";
import { LocalDate } from '../model/local-date';
import { Moment } from 'moment';
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})

export class DateTimeUtility {
    constructor() {
    }

    public isLocalDate(localDate: LocalDate): boolean {
        let isDateValid = true;
        try {
            this.localDateToDate(localDate);
        } catch (ex) {
            isDateValid = false;
        }
        return isDateValid;
    }

    public localDateToHumanString(localDate: LocalDate): string {
        return moment(this.localDateToDate(localDate)).format("DD/MM/YYYY");
    }
    public stringToMoment(date: string): Moment {
        return moment(date);
    }

    public localDateToDate(localDate: LocalDate): Date {
        return new Date(localDate.year, localDate.monthValue - 1, localDate.dayOfMonth);
    }

    public stringToDate(date: string): Date {
        return this.momentToDate(this.stringToMoment(date));
    }

    public dateToString(date: Date): string {
        return `${date.getFullYear()}-${this.fixNum(date.getMonth() + 1)}-${this.fixNum(date.getDate())}`;
    }

    public dateToHumanString(date: Date): string {
        return `${this.fixNum(date.getDate())}/${this.fixNum(date.getMonth() + 1)}/${date.getFullYear()}`;
    }

    public momentToString(dateMmnt: Moment): string {
        return this.dateToString(this.momentToDate(dateMmnt));
    }

    public momentToHumanString(dateMmnt: Moment): string {
        return this.dateToHumanString(this.momentToDate(dateMmnt));
    }

    public isMoment(dateMmnt: any): boolean {
        try {
            dateMmnt.year();
            return true;
        } catch (e) {
            return false;
        }
    }

    // Este metodo se usa para transformar una fecha antes de llamar al back.
    public momentToDate(dateMmnt: Moment): Date {
        const out: Date = new Date();
        out.setFullYear(dateMmnt.year());
        out.setMonth(dateMmnt.month());
        out.setDate(dateMmnt.date());
        return out;
    }

    private fixNum(n: number): string {
        return n < 10 ? '0' + n : '' + n;
    }

    public weekdayHumanString(dateMmnt: Moment): string {
        const weekday = dateMmnt.weekday()

        switch (weekday) {
            case 1:
                return 'Lun';
            case 2:
                return 'Mar';
            case 3:
                return 'Mie';
            case 4:
                return 'Jue';
            case 5:
                return 'Vie';
            case 6:
                return 'Sab';
            case 7:
                return 'Dom';
        }
    }

}
