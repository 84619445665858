import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { NgFor, NgIf, JsonPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComunaService } from 'src/app/api/comuna.service';
import { CompanyService } from 'src/app/api/company.service';
import { Location } from '@angular/common'
import { Company } from 'src/app/model/company';
import moment from 'moment';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css'],
  standalone: true,
  imports: [MatDividerModule,MatTooltipModule,MatPaginatorModule,MatSortModule, MatTableModule,MatIconModule,
    MatCardModule,MatSnackBarModule,MatButtonModule,MatAutocompleteModule,FormsModule, MatFormFieldModule, MatInputModule,MatSelectModule, NgFor,MatDatepickerModule,MatNativeDateModule,ReactiveFormsModule,NgIf, JsonPipe]
})

export class EditCompanyComponent implements OnInit {
  company = JSON.parse(sessionStorage.getItem('companySelected')) as Company;

  today: Date = new Date();
  formattedDate = (moment(this.today)).format('YYYY-MM-DD')
  nombreEmpresa: any;
  rutEmpresa: any;
  comunas:any;
  registerForm = new FormGroup({
    giro: new FormControl('',  Validators.required),
    email: new FormControl('',  [Validators.email, Validators.required]),
    contacto: new FormControl('',  Validators.required),
    representante: new FormControl('',  Validators.required),
    telefono: new FormControl('',  Validators.required),
    socio: new FormControl('',  Validators.required),
    rubro: new FormControl('',  Validators.required),
    direccion: new FormControl('',  Validators.required),
    sueldo: new FormControl('', Validators.required),
    paga: new FormControl('', Validators.required),
    comuna: new FormControl('',  Validators.required),
    web: new FormControl('',  null)
  });

rubros: any[] = [
  {value: '1', viewValue: 'CONSTRUCTORAS'},
  {value: '2', viewValue: 'CONTRATISTA'},
  {value: '2', viewValue: 'ESCUELAS'},
  {value: '2', viewValue: 'PLANTAS TRATA.'},
  {value: '2', viewValue: 'CARNICERIAS'},
  {value: '2', viewValue: 'SUPERMERCADOS'},
  {value: '3', viewValue: 'SERVICENTROS'},
  {value: '3', viewValue: 'MUNICIPALIDADES'},
  {value: '3', viewValue: 'MALL'},
  {value: '4', viewValue: 'INDUSTRIA'},
  {value: '3', viewValue: 'PARTICULAR'},
  {value: '3', viewValue: 'VIÑAS'}
];
giroEmpresa: any;
emailEmpresa: any;
representante: any;
rubroValue: any;
contacto: any;
socioValue: any;
direccion: any;
sueldo: any;
comunaValue: any;
web: any;
fono: any;
pagaValue: any;

  constructor(
    private comunaService:ComunaService,
    private location: Location,
    private companyService:CompanyService,
    private snakBar: MatSnackBar
  ) {
    this.company = JSON.parse(sessionStorage.getItem('companySelected')) as Company;
    this.emailEmpresa = this.company.email;
    this.giroEmpresa = this.company.giro;
    this.representante = this.company.repre_legal;
    this.rubroValue = this.company.rubro;
    this.contacto = this.company.contacto;
    this.socioValue = this.company.convenio;
    this.direccion = this.company.direccion;
    this.comunaValue = this.company.comuna;
    this.pagaValue = this.company.donacion.toString();
    this.sueldo = this.company.sueldo;
    this.web = this.company.web;
    this.fono = this.company.fono;

  }

  async ngOnInit() {
    await this.comunaService.listAll().subscribe(
      response => {
        this.comunas = response;
      },
      problems => {
        console.log(problems);

      }
    );
  }

    cancelAddCompany() {
      location.reload();
    }
    back() {
      this.location.back();
    }

    async editCompany(){
      const company = new Company();
      company.in_emp = this.company.in_emp,
      company.empresa = this.nombreEmpresa;
      company.comuna = this.comunaValue;
      company.rut_emp = this.rutEmpresa;
      company.giro = this.giroEmpresa;
      company.email = this.emailEmpresa;
      company.repre_legal = this.representante;
      company.rubro = this.rubroValue;
      company.contacto = this.contacto;
      company.convenio = this.socioValue;
      company.direccion = this.direccion;
      company.comuna = this.comunaValue;
      company.web = this.web;
      company.fono = this.fono;
      company.sueldo = this.sueldo;
      company.donacion = this.pagaValue;

     await this.companyService.editCompany(company).subscribe(
        response => {
          this.snakBar.open("Empresa "+this.company.empresa+" editada correctamente!","OK");
          setTimeout(async () => {
            this.location.back();
          },1000);

        },
        problems => {
          console.log(problems);

        }
      );
    }

}
