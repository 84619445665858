<h3>Gestion de firmas</h3>

<div fxLayout="column">

    <div fxLayoutAlign="space-evenly center" fxLayout="row">

        <mat-form-field appearance="fill">
            <mat-label>Seleccione rango de fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Fecha inicio" [(ngModel)]="this.startDate">
                <input matEndDate placeholder="Fecha término" [(ngModel)]="this.finishDate">
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <com-teacher-selector (teacherChange)="onTeacherChange($event)"></com-teacher-selector>
        <com-course-selector courseId="" (courseChangeEvent)="onCourseSelect($event)"></com-course-selector>
        <com-subject-selector [showAll]="true" [year]="this.currentYear" levelId=""
            (subjectChangeEvent)="onSubjectSelect($event)"></com-subject-selector>
        <button mat-raised-button color="primary" (click)="onSearchClick($event)">Buscar</button>

    </div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div class="mat-elevation-z1" fxFlexFill fxLayoutAlign="space-evenly center">
        <table mat-table [dataSource]="dataSource" width="90%">
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Fecha </th>
                <td mat-cell *matCellDef="let row" class="align-left" nowrap>
                    {{dateTimeUtility.weekdayHumanString(dateTimeUtility.stringToMoment( row.signatureDate)) }}
                    {{dateTimeUtility.momentToHumanString(dateTimeUtility.stringToMoment( row.signatureDate))}}
                </td>
            </ng-container>

            <ng-container matColumnDef="block">
                <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Bloque H. </th>
                <td mat-cell *matCellDef="let row" class="align-left" nowrap> {{row.timeBlockDesc}} </td>
            </ng-container>

            <ng-container matColumnDef="course">
                <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Curso </th>
                <td mat-cell *matCellDef="let row" class="align-left" nowrap> {{row.course.letra}} </td>
            </ng-container>

            <ng-container matColumnDef="subject">
                <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Asignatura </th>
                <td mat-cell *matCellDef="let row" class="align-left" nowrap> {{row.subject.name}} </td>
            </ng-container>

            <ng-container matColumnDef="teacher">
                <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Profesor </th>
                <td mat-cell *matCellDef="let row" class="align-left" nowrap> {{row.teacher.fatherSurname}}
                    {{row.teacher.motherSurname}}, {{row.teacher.names}} </td>
            </ng-container>

            <ng-container matColumnDef="signer">
                <th mat-header-cell *matHeaderCellDef class="align-left" mat-sort-header> Firmante </th>
                <td mat-cell *matCellDef="let row" class="align-left" nowrap> {{row}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef class="align-center" mat-sort-header> Estados <br> Leccionario |
                    Asistencia | Libro</th>
                <td mat-cell *matCellDef="let row" class="align-center" nowrap>

                    <button mat-icon-button [color]="this.primaryColor(row.lectionareSigned)" style="cursor:default">
                        <mat-icon
                            [color]="this.primaryColor(row.lectionareSigned)">{{row.lectionareSigned?'check_circle':'warning'}}
                        </mat-icon>
                    </button>
                    |
                    <button mat-icon-button [color]="this.primaryColor(row.attendanceSigned)" style="cursor:default">
                        <mat-icon>{{row.attendanceSigned?'check_circle':'warning'}}
                        </mat-icon>
                    </button>
                    |

                    <button mat-icon-button *ngIf="!row.bookSigned" color="accent" (click)="onBookButtonClick(row.id)">
                        <mat-icon>warning</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="row.bookSigned">
                        <mat-icon *ngIf="row.bookSigned" color="primary">check_circle</mat-icon>
                    </button>
                </td>
            </ng-container>

        </table>
    </div>
</div>