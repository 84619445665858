import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { AuthorService } from 'src/app/api/author.service';
import { CourseService } from 'src/app/api/course.service';
import { Course } from 'src/app/model/course';

@Component({
  selector: 'app-meeting-detail-dialog',
  templateUrl: './meeting-detail-dialog.component.html',
  styleUrls: ['./meeting-detail-dialog.component.css']
})
export class MeetingDetailDialogComponent implements OnInit {

  // Mensual, extraordinaria y de licenciatura.
  public summaryMeets: SummaryMeet[] = [
    { key: 'MONTHLY', name: 'Reunión mensual' },
    { key: 'EXTRAORDINARY', name: 'Extraordinaria' },
    { key: 'DEGREE', name: 'Licenciatura' }
  ];

  constructor(public dialogRef: MatDialogRef<MeetingDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: MeetingDialogParams,
    public courseService: CourseService,
    public authService: AuthorService) { }

  ngOnInit(): void {
  }

}

export class MeetingDialogParams {
  dateTime: Moment;
  subject: string ;
  viewAsManager: boolean;
  course: Course;
}

export class SummaryMeet {
  key: string;
  name: string;
}
