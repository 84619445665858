<!--
<mat-progress-bar *ngIf="showProgressBar" mode="indeterminate"></mat-progress-bar>
<mat-progress-bar *ngIf="!showProgressBar" mode="determinate"></mat-progress-bar>
-->
<h1>Ficha de alumno</h1>
<br>

<mat-form-field [style.width.px]=400>
    <mat-label>Búsqueda de alumno</mat-label>
    <input matInput placeholder="Digite Rut(sin guión) o apellido" type="text" autocomplete="false" name="SearchInput"
        [(ngModel)]="searchInput" (keydown.enter)="onKeyPressSearchInput($event)">
    <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Borrar" (click)="clearInput()">
        <mat-icon>close</mat-icon>
    </button>

</mat-form-field>
<br>
<mat-checkbox [(ngModel)]="currentYeardCheckbox" color="primary" [disabled]="false" (click)="defineColumns()">Solo año
    actual.</mat-checkbox>
<br>

<p>
    <mat-error *ngIf="showNotFound" matTooltip="'{{searchInput}}' no encontró resultados..." matTooltipShowDelay="250"
        matTooltipHideDelay="250">
        Alumnos no encontrados</mat-error>
</p>

<!--
     [ dataSource ] = "alumnos"
*ngIf="alumnos.data.length>0"
    -->

<div fxLayout="center center" fxFlexFill style="text-align: center;">
    <mat-spinner *ngIf="this.showProgressBar"></mat-spinner>
</div>
<div class="mat-elevation-z5" *ngIf="alumnos.data.length>0 && !this.showProgressBar">
    <table mat-table matSort [dataSource]="alumnos" width="100%">

        <ng-container matColumnDef="rut">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rut </th>
            <td mat-cell *matCellDef="let row" nowrap>
                {{row.rut}}-{{row.dv}}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
            <td mat-cell *matCellDef="let row">{{row.paterno}} {{row.materno}}, {{row.nombres}}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Teléfono </th>
            <td mat-cell *matCellDef="let row">{{row.fonoApoderado}}</td>
        </ng-container>

        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
            <td mat-cell *matCellDef="let row"> {{row.agnoActual}} </td>
        </ng-container>
        <ng-container matColumnDef="lebel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Curso </th>
            <td mat-cell *matCellDef="let row"> {{row.course}} </td>
        </ng-container>

        <ng-container matColumnDef="matricula">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nro. Matricula</th>
            <td mat-cell *matCellDef="let row"> {{row.numeroMatricula}} </td>
        </ng-container>


        <ng-container matColumnDef="birthday">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>F.Nacimiento</th>
            <td mat-cell *matCellDef="let row"> {{row.nacimiento}} </td>
        </ng-container>

        <ng-container matColumnDef="age">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Edad</th>
            <td mat-cell *matCellDef="let row"> {{row.edad}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Acción</th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="fichaPersoal(row.id)">
                        <mat-icon>face</mat-icon>
                        <span>Ficha Personal</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr *matNoDataRow>
            <td mat-cell colspan="8">Sin datos...</td>
        </tr>

    </table>

    <mat-paginator *ngIf="showPaginator" [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>

</div>