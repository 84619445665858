import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Alumno } from '../model/alumno';
import { AsignCurso } from "../model/asign-curso";
import { Postulante } from "../model/postulant";
import { AuthorService } from './author.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class PostulanteService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/postulante/1.0';
    private listUrl: string = '/list';
    private asignCursoUrl: string = '/asign-curso';
    private automaticAsignUrl: string = '/automatic-asign';
    private findByRutOrPaternoUrl: string = '/find-by-rut-or-paterno';
    private findByIdUrl: string = '/find-by-id';
    private enrollUrl: string = '/enroll';
    // private document_enroll_by_student_id: string = '/document-enroll-by-student';
    private document_enroll_by_enroll_number: string = '/document-enroll-by-enroll-number';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listAll(): Observable<Postulante[]> {
        const url = this.baseUrl + this.serviceUrl + this.listUrl;
        return this.http.get<Postulante[]>(url, { headers: this.authorService.headerWithAuthorization(null) }
        );
    }

    public asignCurso(postulantesId: string[], curso: string): Observable<Postulante[]> {
        const url = this.baseUrl + this.serviceUrl + this.asignCursoUrl;
        const asignCurso: AsignCurso = new AsignCurso();
        asignCurso.posutlantesId = postulantesId;
        asignCurso.course = curso;
        return this.http.post<Postulante[]>(
            url,
            asignCurso,
            { headers: this.authorService.headerWithAuthorization(null) }
        );
    }

    public automaticAsign(): Observable<Postulante[]> {
        const url = this.baseUrl + this.serviceUrl + this.automaticAsignUrl;
        return this.http.get<Postulante[]>(url, { headers: this.authorService.headerWithAuthorization(null) }
        );
    }

    public findByRutOrPaterno(rutOrPaterno: string): Observable<Postulante[]> {
        const url = this.baseUrl + this.serviceUrl + this.findByRutOrPaternoUrl + "/" + rutOrPaterno;
        return this.http.get<Postulante[]>(url, { headers: this.authorService.headerWithAuthorization(null) }
        );
    }

    public findById(id: string): Observable<Postulante> {
        const url = this.baseUrl + this.serviceUrl + this.findByIdUrl + "/" + id;
        return this.http.get<Postulante>(url, { headers: this.authorService.headerWithAuthorization(null) }
        );
    }

    public enroll(postulante: Postulante): Observable<Alumno> {
        const url = this.baseUrl + this.serviceUrl + this.enrollUrl;
        console.log(url);
        return this.http.post<Alumno>(url, postulante, { headers: this.authorService.headerWithAuthorization(null) }
        );
    }

    public getDocumentUrlByEnrollNumber(enrollNumber: number): string {
        const url = this.baseUrl + this.serviceUrl + this.document_enroll_by_enroll_number + `/${enrollNumber}`;
        return url;
    }

    public downloadPdf(enrollNumber: number) {
        const url = this.getDocumentUrlByEnrollNumber(enrollNumber);
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION, this.authorService.BEARER + this.authorService.token)
        });
    }

}