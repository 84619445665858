import { Component, Input, OnInit } from '@angular/core';
import { Alumno } from 'src/app/model/alumno';

@Component({
  selector: 'com-student-panel',
  templateUrl: './student-panel.component.html',
  styleUrls: ['./student-panel.component.css']
})
export class StudentPanelComponent implements OnInit {

  @Input() student: Alumno = null;
  @Input() public showName: boolean = true;
  @Input() public showCourse: boolean = false;
  @Input() public showRut: boolean = false;
  @Input() public showAge: boolean = false;
  @Input() public showAttorneyData: boolean = false;



  constructor() { }

  ngOnInit(): void {
  }

}
