<h1 mat-dialog-title>{{this.dialogData.title}}</h1>

<div mat-dialog-content>
  

  <table>
    <tr>
      <td> 
        <mat-icon color="accent"
          *ngIf="this.dialogData.type == this.dialogMessageTypeEnum.WARNING">warning</mat-icon>
        <mat-icon color="warn" *ngIf="this.dialogData.type == this.dialogMessageTypeEnum.ERROR">error</mat-icon>
        <mat-icon color="primary" *ngIf="this.dialogData.type == this.dialogMessageTypeEnum.MESSAGE">comment</mat-icon>
      </td>
      <td>{{this.dialogData.message}}</td>
    </tr>
  </table>


  
  <!--
  <mat-form-field appearance="fill">
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field>
  -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button cdkFocusInitial (click)="doClickButton(true)" [mat-dialog-close]="dialogData"
    color="primary"><mat-icon>check_circle</mat-icon>&nbsp;Aceptar</button>
  <button mat-raised-button (click)="doClickButton(false)" [mat-dialog-close]="dialogData"
    *ngIf="this.dialogData.showCancelButton"><mat-icon>cancel</mat-icon>&nbsp;Cancelar</button>

</div>