<h1>Agregar Empresa
</h1>
<br>
<mat-card>
  <mat-card-content>
    <form >
      <table class="example-full-width">
        <tr>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Empresa</mat-label>
              <input matInput [(ngModel)]="nombreEmpresa" formControlName="empresa" name="empresa">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Rut</mat-label>
              <input matInput  [(ngModel)]="rutEmpresa" formControlName="rut" name="rut">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Giro</mat-label>
              <input matInput [(ngModel)]="giroEmpresa" formControlName="giro" name="giro">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Email</mat-label>
              <input matInput  [(ngModel)]="emailEmpresa" formControlName="email" name="email">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Representante Legal</mat-label>
              <input matInput [(ngModel)]="representante" formControlName="representante" name="representante">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Rubro</mat-label>
            <mat-select [(ngModel)]="rubroValue" formControlName="rubro" name="rubro">
              <mat-option *ngFor="let rubro of rubros" [value]="rubro.value">
                {{rubro.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field></td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Contacto</mat-label>
              <input matInput [(ngModel)]="contacto" formControlName="contacto" name="contacto">
            </mat-form-field>
          </td>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Socio</mat-label>
            <mat-select [(ngModel)]="socioValue" formControlName="socio" name="socio">
              <mat-option value="NO">
                NO
              </mat-option>
              <mat-option value="SI">
                SI
              </mat-option>
            </mat-select>
          </mat-form-field></td>
        </tr>
        <tr>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Dirección</mat-label>
            <input matInput [(ngModel)]="direccion" formControlName="direccion" name="direccion">
          </mat-form-field></td>
          <td><mat-form-field class="example-full-width" [formGroup]="registerForm">
            <mat-label>Comuna</mat-label>
            <mat-select [(ngModel)]="comunaValue" formControlName="comuna" name="comuna">
              <mat-option *ngFor="let comuna of comunas" [value]="comuna.id">
                {{comuna.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field></td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width">
              <mat-label>Sitio web</mat-label>
              <input matInput [(ngModel)]="web" name="web">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Fono</mat-label>
              <input matInput  [(ngModel)]="fono" formControlName="telefono" name="telefono">
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Sueldo</mat-label>
              <input matInput type="number" [(ngModel)]="sueldo" formControlName="sueldo" name="sueldo">
            </mat-form-field>
          </td>
          <td>
            <mat-form-field class="example-full-width" [formGroup]="registerForm">
              <mat-label>Paga</mat-label>
            <mat-select [(ngModel)]="pagaValue" formControlName="paga" name="paga">
              <mat-option value="0">
                NO PAGA
              </mat-option>
              <mat-option value="1">
                1 UF
              </mat-option>
              <mat-option value="1.5">
                1.5 UF
              </mat-option>
            </mat-select>
            </mat-form-field>
          </td>
        </tr>
      </table>
      <mat-divider></mat-divider>
      <br/>
        <button mat-raised-button color="primary" (click)="addCompany()" [disabled]="registerForm.invalid">Crear empresa</button>
        <span>&nbsp;</span>
        <button mat-raised-button color="warn" (click)="cancelAddCompany()">Cancelar</button>
        <span>&nbsp;</span>
        <button mat-raised-button color="basic" (click)="back()">Volver</button>

    </form>
  </mat-card-content>

</mat-card>
