<h1 mat-dialog-title>Formulario de accidentes.</h1>
<div mat-dialog-content>
  <com-student-panel [student]="this.dialogData.student" [showRut]="false" [showCourse]="true" [showAge]="false"
    [showAttorneyData]="true"></com-student-panel>

  <mat-form-field appearance="fill">
    <mat-label>Fecha accidente</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="this.dialogData.accidentDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" fxFlexFill>
    <mat-label>Detalle del accidente</mat-label>
    <textarea matInput rows="5" [(ngModel)]="this.dialogData.description"
      placeholder="Describa con detalles que pueden servir al centro de asistencia médica..."></textarea>
  </mat-form-field>
  <br>

  <mat-form-field style="width: 100%" appearance="fill">
    <mat-label>Centro de atención medica</mat-label>
    <input matInput placeholder="Nombre del hospital, clínica o lugar de asistencia médica."
      [(ngModel)]="this.dialogData.healthPlace">
  </mat-form-field>

  <mat-form-field style="width: 100%" appearance="fill">
    <mat-label>Acompañante</mat-label>
    <input matInput placeholder="Nombre de la persona que acompaña al menos accidentado"
      [(ngModel)]="this.dialogData.escortsName">
  </mat-form-field>

  <mat-form-field style="width: 100%" appearance="fill">
    <mat-label>Relación con el menor</mat-label>
    <input matInput placeholder="Cual es el parentezco que tiene con el menor"
      [(ngModel)]="this.dialogData.escortsRelationship">
  </mat-form-field>


</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary"><mat-icon>picture_as_pdf</mat-icon>
   Grabar y descargar documento</button>
  <button mat-raised-button [mat-dialog-close]="null"> <mat-icon>close</mat-icon> Cerrar</button>
</div>