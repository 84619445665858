<h1 mat-dialog-title>Actividades de aprendizaje</h1>
<div mat-dialog-content fxLayout="column">
 
    <com-chips-selector label="Elementos educativos" placeholder="Nuevo elemento..." (elementsUpdated)="elementsUpdated($event)"
     [elements]="this.resourceAvailableNames" [selectedElements]="this.resourceSelectedNames"></com-chips-selector>
    

    <mat-form-field appearance="fill">
        <mat-label>Inicio</mat-label>
        <textarea matInput cdkTextareaAutosize [(ngModel)]="this.dialogData.activityStart"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Desarrollo</mat-label>
        <textarea matInput cdkTextareaAutosize  [(ngModel)]="this.dialogData.activityDevelop"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Cierre</mat-label>
        <textarea matInput cdkTextareaAutosize  [(ngModel)]="this.dialogData.activityFinish"></textarea>
    </mat-form-field>


</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary" [disabled]="this.dialogData.readonly">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">Cancelar</button>
</div>