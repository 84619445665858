<mat-tab-group animationDuration="200ms" [selectedIndex]="this.selectedTab">
    <mat-tab label="Cursos {{nextYear}}">
        <app-next-courses [nextYear]="this.nextYear"></app-next-courses>
    </mat-tab>

    <mat-tab label="Postulantes">
        <app-generic-crud entityId="ef2347bc-5fc3-11ed-a091-e86a642fcd99" tabSelected="1"
            navigateBack="admission-enrollment"></app-generic-crud>
    </mat-tab>

    <mat-tab label="Asignar postulantes a Cursos">
        <app-postulante></app-postulante>
    </mat-tab>
    <mat-tab label="Matricula Postulantes">
        <app-matricula-postulante></app-matricula-postulante>
    </mat-tab>
    <mat-tab label="Matricula Alumnos">
        <app-matricula-alumno></app-matricula-alumno>
    </mat-tab>
    <mat-tab label="Asociar alumnos y cursos" *ngIf="canAssociateStudentAndCourse">
        <app-associate-student-and-course></app-associate-student-and-course>
    </mat-tab>
</mat-tab-group>

