import { formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges, Inject } from '@angular/core';
import { CourseService } from 'src/app/api/course.service';
import { FailureProjectionService } from 'src/app/api/failure-projection.service';
import { Course } from 'src/app/model/course';
import { Average4View, FailureProjectionCourse, Period, StudentsStatus, StudentsStatus4View } from 'src/app/model/failure-projection-course';
import { Subject } from 'src/app/model/subject';
import { AlertUtility, DialogMessageType } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css', '../../../../style/table.css']
})
export class CourseDetailComponent implements OnInit, OnChanges {
  @Input() public courseId: string | null;
  @Output() public goBack = new EventEmitter<boolean>();
  @Output() public onShowAllStudents = new EventEmitter<boolean>();
  public course: Course | null = null;

  public displayedColumns: string[] = [];
  public dataSources: any[] = [];
  public subjects: Subject[] = [];

  public showAllStudents = false;

  constructor(private failureProjection: FailureProjectionService,
    private courseService: CourseService,
    @Inject(LOCALE_ID) public locale: string,
    private alertUtility: AlertUtility) { }

  public onShowAllChange(): void {
    this.refreshData(this.course.id);
    this.onShowAllStudents.emit(this.showAllStudents);
  }

  ngOnInit(): void {
    this.courseService.get(this.courseId)
      .subscribe(
        response => {
          this.course = response
        },
        problems => console.error(problems)
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshData(changes.courseId.currentValue);
  }

  public goBackClick(): void {
    this.goBack.emit(true);
  }

  public refreshData(courseId: string): void {
    this.failureProjection.getFailureProjectionCourse(courseId, this.showAllStudents)
      .subscribe(
        (response: FailureProjectionCourse) => {
          console.log(response.studentsStatus.length);
          this.drowData(response);
        },
        problems => console.error(problems)
      );

  }

  private drowData(response: FailureProjectionCourse) {
    if (response.studentsStatus.length == 0) {
      this.alertUtility.dialogMessage('No hay alumnos en riesgo de repitencia.', 'Atención', DialogMessageType.MESSAGE);
    }

    if (this.displayedColumns.length == 0) {
      this.defineCols(response);
    }

    response.studentsStatus.forEach(
      (recordDb: StudentsStatus, i: number) => {
        let record: StudentsStatus4View[] = [];
        record = this.processRecord(recordDb);
        this.dataSources[i] = record;
      }
    );
  }

  private processRecord(recordDb: StudentsStatus): StudentsStatus4View[] {
    let response: StudentsStatus4View[] = [];

    recordDb.periods.forEach(
      (period: Period, i: number) => {
        response[i] = new StudentsStatus4View();
        response[i].studentName = i == 0 ? recordDb.studentName : '';
        response[i].studentId = recordDb.studentId;

        response[i].periodKey = period.key;
        response[i].averages = period.averages;
        response[i].attendance = recordDb.attendance;

      }
    );

    return response;
  }

  private defineCols(response: FailureProjectionCourse): void {
    this.subjects.push(...response.subjects);

    this.displayedColumns = [];

    this.displayedColumns.push('name');
    this.displayedColumns.push('period');

    this.displayedColumns.push(...this.subjects.map(subject => this.addStash(subject.abbreviated)));
    // this.displayedColumns.push('general');
    this.displayedColumns.push('attendance');
  }

  public addStash(shortName: string): string {
    return shortName.replace(' ', '_');
  }

  public removeStash(shortName: string): string {
    return shortName.replace('_', ' ');
  }

  public getAverage(data: StudentsStatus4View, subjectId: string, i: number): string {
    const avgRow = data.averages.filter(subject => subject.subjectId == subjectId);

    if (avgRow.length == 0) return '';

    const avg: number = avgRow[0].average;

    return this.formatNumber(avg); // avg == 0 ? '' : formatNumber(avg, this.locale, '1.1-1');
  }

  private formatNumber(n: number): string {
    return n == 0 ? '' : formatNumber(n, this.locale, '1.1-1');
  }

  public redColorIfApply(n: number, limit: number): string {
    const style: string = '';
    const value: number = +n.toFixed(1);
    return style + (value < limit ? 'color:red' : '');
  }

  public getGeneralAverage(ele: StudentsStatus4View, i: number): string {
    if (i > 0) return '';
    const averages4View: StudentsStatus4View[] = this.dataSources.filter(ds => ds[0].studentId == ele.studentId)[0];
    const average4View: StudentsStatus4View = averages4View[averages4View.length - 1];
    const average: Average4View[] = average4View.averages.filter(avg => avg.subjectId == 'AVERAGE');

    return this.formatNumber(
      average.length > 0 ? average[0].average : 0
    );

    /*
        if (ele.periodKey != 'Promedio') return '';
        return this.formatNumber(
          ele.averages.filter(average => average.subjectId == 'AVERAGE')[0].average);
          */
  }

}
