<div fxLayout="column wrap" fxLayoutAlign="space-around start">

    <h1 fxFlexFill>Resumen de curso</h1>

    <div fxLayout="column">
        <div *ngIf="!canChangeCourse && this.report" fxFlexFill style="text-align: left;">
            Curso:<strong>{{this.report.course.courseLeter}}</strong></div>
        <div *ngIf="canChangeCourse" fxFlexFill style="text-align: left;">
            <mat-form-field *ngIf="this.courses">
                <mat-label>Selección de curso</mat-label>
                <mat-select [(ngModel)]="this.course" (selectionChange)="onChangeCourse($event)">
                    <mat-option *ngFor="let course of this.courses" [value]="course">
                        {{this.courseService.formatLetra(course.letra)}}
                        {{course.teacherName != '' && course.teacherName!=null ? '('+course.teacherName+')':''}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlexFill *ngIf="this.report" style="text-align: left;vertical-align: middle; border: 1px;">
            <mat-form-field fxFlex="50">
                <mat-label>Período</mat-label>
                <mat-select [(ngModel)]="this.periodSelected" (selectionChange)="onChangePeriod($event)">
                    <mat-option *ngFor="let period of this.periods" [value]="period">
                        {{period==0?'Anual':period }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <span>
                {{this.report.course.periodName}}, año
                {{this.report.course.year}}
            </span>
        </div>
        <div fxFlexFill *ngIf="this.report" style="text-align: left;">
            Profesor: <strong>{{this.report.course.teacherName}}</strong>
            &nbsp;&nbsp;
            <button mat-raised-button color="primary" (click)="this.downloadAsExcel()" *ngIf="this.periodSelected>0">
                <mat-icon>download</mat-icon>
                Descargar como Excel...
            </button>
        </div>
    </div>


    <div *ngIf="this.report" style="text-align: left;" fxFlex>
        <hr>
        <table [border]="this.borderSize" fxFlexFill class="withData" *ngIf="showTables">
            <caption style="text-align: left;">
                <h3>Promedios por alumno</h3>
            </caption>
            <tr>
                <td style="width: 10px;" valign="top">Nº</td>
                <td style="width: 350px;" valign="top">Nombre</td>

                <td *ngFor="let subject of this.filterDoesSubject(this.report.subjects)" [matTooltip]="subject.longName"
                    valign="top" align="center">{{removeStash(subject.shortName)}}
                </td>
                <td valign="top">Suma</td>
                <td style="width: 20px;" valign="top" align="center" *ngFor="let period of filterPeriods()">
                    {{period== 0?'Prom. Anual': ('Prom '+period)}}
                </td>
                <td style="width: 20px;" valign="top" align="center"
                    *ngFor="let subject of this.filterDoesSubject(this.report.subjects, false)"
                    [matTooltip]="subject.longName">
                    {{removeStash(subject.shortName)}}
                </td>

                <td style="width: 20px;" align="center" valign="top" *ngIf="showAttendance">
                    Asist.
                </td>

                <td style="width: 20px;" align="center" valign="top">
                    Repro.
                </td>

            </tr>
            <tr *ngFor="let student of this.report.students">
                <td align="right">{{student.listNumber}}</td>
                <td [ngStyle]="{'text-decoration': (student.status=='RET') ? 'line-through' : ''}">
                    {{student.studentName}}
                </td>

                <td *ngFor="let score of this.filterDoesAverage(notAverage(student.scores)); let i = index"
                    [matTooltip]="this.getSubjectNameAndExam(student.studentId,  score.subjectId, score.average)"
                    [style]="redColorIfApply(score.average, 4, i)">
                    {{numberOrEmpty(score.average) }}
                </td>

                <td>{{this.sumAverages(student)}}</td>

                <td *ngFor="let period of filterPeriods()" style="text-align: center; width: 50px;"
                    [style]="redColorIfApply(onlyAverage(student.scores, period), 4)">
                    <strong>
                        {{numberOrEmpty(onlyAverage(student.scores, period))| number:'1.1-1'}}
                    </strong>
                </td>
                <td style="text-align: center; width: 50px;"
                    *ngFor="let subject of this.filterDoesAverage(student.scores, false)">
                    <!--  
 [style]="redColorIfApply(onlyAverage(student.scores, period), 4)"
-->
                    {{translateToLeter(subject)}}

                </td>

                <td [style]="redColorIfApply(student.attendancePercent, 85)" *ngIf="showAttendance">
                    {{student.attendancePercent| number:'1.1-1'}}%
                </td>
                <td style="width: 20px;" align="center" valign="top">
                    {{this.failedSubjects(student)}}
                </td>
            </tr>


            <!--
            <tr>
                <td colspan="2" style="text-align: left;"><strong>Promedios del curso</strong> </td>
                <td style="text-align: right;" *ngFor="let average of notAverage(this.report.averages)"
                    [style]="redColorIfApply(average.average, 4)">
                    <strong>{{average.average| number:'1.1-1'}}</strong>
                </td>
                <td></td>
                <td style="text-align: center;"><strong>
                        {{onlyAverage(this.report.averages,1 )| number:'1.1-1'}}</strong>
                </td>
                <td style="text-align: center;" *ngIf="showAttendance">{{this.report.attendances.attendancePercent|
                    number:'1.1-1'}}%</td>
            </tr>
-->



        </table>

        <hr>

        <table [border]="this.borderSize" class="withData" width="100%" *ngIf="showTables">

            <caption style="text-align: left;">
                <h3>Resumen de Calificaciones</h3>
            </caption>

            <tr>
                <td style="width: 360px;">&nbsp;</td>
                <td *ngFor="let subject of this.filterDoesSubject(this.report.subjects)" [matTooltip]="subject.longName"
                    valign="top" align="center">
                    {{removeStash(subject.shortName)}}
                </td>
                <td style="width: 20px;" valign="top" align="center">
                    Grl.{{this.periodSelected}}
                </td>
            </tr>
            <tr>
                <td style="text-align: left;">
                    <strong>Promedios</strong>
                </td>
                <td style="text-align: center;"
                    *ngFor="let average of this.filterDoesAverage(notAverage(this.report.averages))">
                    {{average.average| number:'1.1-1'}}
                </td>
                <td style="width: 20px;" valign="top" align="center">
                    {{this.getGeneralAverage(this.periodSelected)}}
                </td>
            </tr>

            <tr>
                <td><strong>Insuficientes</strong></td>
                <td style="text-align: center;width: 50px;"
                    *ngFor="let subject of this.filterDoesAverage(notAverage(this.report.scoreSummary.unenough))">
                    {{subject.average}}
                </td>
                <td style="text-align: center">
                    {{this.getUnenoughCounter()}}
                </td>


            </tr>
            <tr>
                <td><strong>Insuficientes %</strong></td>
                <td style="text-align: center;width: 50px;"
                    *ngFor="let subject of this.filterDoesAverage(notAverage(this.report.scoreSummary.unenoughPercentage))">
                    {{subject.average| number:'1.1-1'}}%
                </td>
                <td style="text-align: center">
                    {{this.getUnenoughPercentCounter()| number:'1.1-2'}}
                </td>
            </tr>

            <tr>
                <td><strong>Cantidas de notas</strong></td>
                <td style="text-align: center;width: 50px;"
                    *ngFor="let subject of this.filterDoesAverage(notAverage(this.report.scoreSummary.scoresCount))">
                    {{subject.average}}
                </td>
                <td style="text-align: center">
                    {{this.getScoresCounter() }}
                </td>

            </tr>

        </table>
        <hr>

        <table [border]="this.borderSize" class="withData" width="100%" style="text-align: right;">
            <caption style="text-align: left;">
                <h3>Resumen de Asistencia</h3>
            </caption>
            <tr>
                <td>Horas del periodo</td>
                <td>Horas inasistentes</td>
                <td>Alumnos bajo el 85%</td>
                <td>Porcentage general de Asistencia</td>
            </tr>
            <tr>
                <td align="right">{{this.report.attendances.hoursCount}}</td>
                <td align="right">{{this.report.attendances.hoursAbsence}}</td>
                <td align="right">{{this.report.attendances.studentsUnder85}}</td>
                <td align="right">{{this.report.attendances.attendancePercent}}%</td>
            </tr>

        </table>
        <br>
        <br>


    </div>
</div>