import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseService } from 'src/app/api/course.service';
import { ValidateTimeDetail } from 'src/app/model/validate-time-detail';

@Component({
  selector: 'app-validate-detail-dialog',
  templateUrl: './validate-detail-dialog.component.html',
  styleUrls: ['./validate-detail-dialog.component.css']
})
export class ValidateDetailDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ValidateDetailParamDialog,
    public courseService: CourseService) { }

  ngOnInit(): void {
  }
}

export class ValidateDetailParamDialog {
  validateDetails: ValidateTimeDetail[] = [];
  courseLeter: string;
}
