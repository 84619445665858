<div *ngIf="course" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <mat-card class="mat-elevation-z0 center" style="width: 90%;" >
        <mat-card-subtitle>Datos Curso</mat-card-subtitle>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlex.xs="90" fxFlexOffset="5">
                <mat-label>Año de curso</mat-label>
                <input matInput [(ngModel)]="course.agno" readonly="true">
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Letra</mat-label>
                <input matInput [(ngModel)]="course.letra" readonly="true">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="20" fxFlex.xs="90" fxFlexOffset="5">
                <mat-label>Pago</mat-label>
                <input matInput [(ngModel)]="course.pago">
            </mat-form-field>
            
            <mat-form-field appearance="fill" fxFlex="20" fxFlex.xs="90" fxFlexOffset="5">
                <mat-label>Alumnos matriculados</mat-label>
                <input matInput [(ngModel)]="course.studentsCount" readonly="true">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Cupos</mat-label>
                <input matInput placeholder="Cantidad de alumnos para el curso" [(ngModel)]="course.capacity">
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <mat-form-field appearance="fill" fxFlex="45" fxFlex.xs="90" fxFlexOffset="5">
                <mat-label>Especialidad</mat-label>

                <mat-select [(ngModel)]="course.especialidadId">
                    <mat-option *ngFor="let especialidad of especialidades" [value]="especialidad.id">
                        {{especialidad.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="45" fxFlexOffset="5">
                <mat-label>Profesor Jefe</mat-label>
                <mat-select [(ngModel)]="course.teacherId">
                    <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                        {{teacher.fatherSurname + ' ' + teacher.motherSurname + ', ' + teacher.names}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card>
    <mat-card class="mat-elevation-z0 center" style="width: 90%;" > 
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start">
            <button mat-raised-button color="primary" (click)="onAceptarClick()"><mat-icon>save</mat-icon>&nbsp;Grabar</button>
            <button mat-raised-button color="accent" (click)="onCalculateStudentsList()"><mat-icon>calculate</mat-icon>&nbsp;Calcular número de lista</button> 
            <button mat-raised-button (click)="onCancelarClick()"><mat-icon>arrow_back</mat-icon>&nbsp;Volver</button>
        </div>
    </mat-card>
</div>