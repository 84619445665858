import { Level } from "./level";

export class Course {
    id: string;
    letra: string;
    pago: number;
    agno: number;
    teacherId: string;
    teacherName:string;
    especialidadId: string;
    especialidadNombre: string;
    capacity: number;
    studentsCount: number;
    inTime: boolean;
    btnColor:string;
    level : Level;
}