import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Adulto } from "../model/adulto";
import { AuthorService } from './author.service';
import { environment } from 'src/environments/environment';
import { StudentAdult } from "../model/student-adult";
import { Status } from "../model/status";

@Injectable({
    providedIn: 'root'
})

export class AdultService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/adulto/1.0';
    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public findByAlumno(alumnoId: string): Observable<StudentAdult[]> {
        const url = this.baseUrl + this.serviceUrl + `/listByAlumno/${alumnoId}`;
        return this.http.get<StudentAdult[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public async findByAlumnoIdAndAdultIdAsync(alumnoId: string, adultId: string): Promise<StudentAdult> {
        const url = this.baseUrl + this.serviceUrl + `/student-and-adult/${alumnoId}/${adultId}`;
        return this.http.get<StudentAdult>(url, { headers: this.authorService.headerWithAuthorization(null) }).toPromise();
    }

    public removeRelation(relId: string): Observable<Status> {
        const url = this.baseUrl + this.serviceUrl + `/remove-student-adult-relation/${relId}`;
        return this.http.get<Status>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public getByRut(rut: number): Observable<Adulto> {
        const url = this.baseUrl + this.serviceUrl + `/get-by-rut/${rut}`;
        return this.http.get<Adulto>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public save(studentAdult: StudentAdult): Observable<StudentAdult> {
        const url = this.baseUrl + this.serviceUrl + `/save`;
        return this.http.post<StudentAdult>(url, studentAdult, { headers: this.authorService.headerWithAuthorization(null) });

    }

}