import {
  Component, EventEmitter, Input, OnInit, Output,
  OnChanges, SimpleChanges
} from '@angular/core';
import { Subject } from '../../model/subject';
import { SubjectService } from '../../api/subject.service';
import { ConfigService } from 'src/app/api/config.service';

@Component({
  selector: 'com-subject-selector',
  templateUrl: './subject-selector.component.html',
  styleUrls: ['./subject-selector.component.css']
})
export class SubjectSelectorComponent implements OnInit, OnChanges {
  @Input() courseId: string | null = null;

  @Input() levelId: string | null = null;

  @Input() year: number = 0;

  @Input() public showAll: boolean = false;

  @Input() username: string | null = null;
  @Input() teacherId: string | null = null;

  @Output() subjectChangeEvent = new EventEmitter<string>();

  subjects: Subject[];
  subjectId: string = '';
  usingLevel: boolean = null;


  constructor(private subjectService: SubjectService) { }

  ngOnInit(): void {

  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.levelId?.firstChange) return;
    this.usingLevel = simpleChanges.levelId != null;

    this.subjects = [];

    if (this.showAll) {
      this.refreshAllSubjects();
    } else {
      if (this.usingLevel) {
        this.subjectId = '';
        this.changeFromLevel(simpleChanges);
      } else {
        if (this.teacherId == null) {
          this.changeFromCourse(simpleChanges);
        } else {
          this.changeFromTeacher(simpleChanges);
        }

      }
    }
  }

  refreshSubjectsFromCourseOrTeacher(fromCourse: boolean) {
    if (this.courseId == '') return;

    const listByCourseObservable =
      fromCourse ?
        this.username == null ?
          this.subjectService.listByCourse(this.courseId) :
          this.subjectService.listByCourseV2(this.courseId, this.username)
        :
        this.subjectService.listByTeacher(this.teacherId);

    listByCourseObservable
      .subscribe(
        (response: Subject[]) => {
          this.subjects = response;
          let existsCurrent: boolean = this.subjects.filter(subject => subject.id == this.subjectId).length > 0;
          if (!existsCurrent) this.subjectId = '';
        },
        problems => console.error(problems)
      );
  }

  refreshSubjectsFromLevel() {
    if (this.levelId == '') return;

    const listByLevelIdObservable = this.username == null ?
      this.subjectService.listByLevelId(this.levelId, this.year) :
      this.subjectService.listByLevelIdV2(this.levelId, this.year, this.username);

    listByLevelIdObservable.subscribe(
      (response: Subject[]) => {
        this.subjects = response;
      },
      problems => console.error(problems)
    );
  }

  changeFromLevel(sc: SimpleChanges) {
    this.refreshSubjectsFromLevel();
  }

  changeFromCourse(sc: SimpleChanges) {
    if (!sc.courseId) return;

    this.refreshSubjectsFromCourseOrTeacher(true);
    this.subjectSelectedMethod();
  }

  changeFromTeacher(sc: SimpleChanges) {
    if (!sc.teacherId) return;

    this.refreshSubjectsFromCourseOrTeacher(false);
    this.subjectSelectedMethod();
  }

  onChangeSelection(): void {
    this.subjectSelectedMethod();
  }

  subjectSelectedMethod() {
    this.subjectChangeEvent.emit(this.subjectId);
  }

  private refreshAllSubjects(): void {
    this.subjectService.listAll()
      .subscribe(
        (response: Subject[]) => this.subjects = response,
        problems => console.error(problems)
      );
  }


}
