import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from 'src/app/api/config.service';
import { AnnotationService } from '../../../api/annotation.service';
import { Annotation } from '../../../model/annotation';
import { AnnotationTemplate } from '../../../model/annotation-template';
import { AnnotationType } from '../../../model/annotation-type';
import { AnnotationDialogDataParam } from './annotation-dialog-data';

@Component({
  selector: 'app-add-annotation-dialog',
  templateUrl: './add-annotation-dialog.component.html',
  styleUrls: ['./add-annotation-dialog.component.css']
})
export class AddAnnotationDialogComponent implements OnInit {
  templates: AnnotationTemplate[];
  types: AnnotationType[];

  id: string;
  annotationTypeId: string;
  annotationDetail: string = '';
  isFormValid: boolean = false;

  //  studentId: string;
  //  courseId: string;
  subjectId: string = '';
  year: number = 0;

  constructor(private annotationService: AnnotationService,
    public dialogRef: MatDialogRef<AddAnnotationDialogComponent>,
    private snackBar: MatSnackBar,
    private configService: ConfigService,
    @Inject(MAT_DIALOG_DATA) public dialogDataParam: AnnotationDialogDataParam) { }

  ngOnInit(): void {
    this.annotationService.listAllTemplates()
      .subscribe(response => {
        this.templates = response;
      },
        problems => console.error(problems)
      );

    this.annotationService.listAllTypes()
      .subscribe(
        response => this.types = response,
        problems => console.error(problems)
      );

    this.configService.getCurrentYear()
      .subscribe(
        response => this.year = response,
        problems => console.error(problems)
      );

  }

  onAccept(): void {
    const annotation: Annotation = new Annotation();
    annotation.courseId = this.dialogDataParam.courseId;
    annotation.studentId = this.dialogDataParam.studentId;
    annotation.text = this.annotationDetail;
    annotation.annotationTypeId = this.annotationTypeId;
    annotation.its4Student = this.dialogDataParam.its4Student;
    annotation.subjectId = this.subjectId == '' ? null : this.subjectId;

    this.annotationService.append(annotation)
      .subscribe(response => {
        this.snackBar.open(`Anotación grabada!`, 'OK', { duration: 3000 });
      });
    this.onCancel();
  }

  onCancel(): void {
    this.dialogRef.close()
  }

  onChangeForm(e?: any): void {
    this.isFormValid = this.id != null
      && this.annotationTypeId != null
      && this.annotationDetail?.length > 0;
  }

  onChangeTemplate(): void {
    this.annotationDetail = this.templates.filter(template => template.id == this.id)[0].text;
  }


  onChangeSubject(subjectId: string) {
    this.subjectId = subjectId;
  }
}
