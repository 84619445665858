import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-name-dialog',
  templateUrl: './change-name-dialog.component.html',
  styleUrls: ['./change-name-dialog.component.css']
})
export class ChangeNameDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ChangeNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public changeNameDialogParams: ChangeNameDialogParams) { }

  ngOnInit(): void {
  }

}

export class ChangeNameDialogParams {
  rut: string;
  paternal: string;
  maternal: string;
  names: string;
}