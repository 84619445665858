<div *ngIf="specificObjectives.length>0">
  <h2>Objetivos de aprendizaje</h2>
  <mat-selection-list #so [multiple]="false" (selectionChange)="onSelectionChange($event)" style="border: solid 1px grey">
    <mat-list-option *ngFor="let specificObjetive of specificObjectives" [value]="specificObjetive">
      <div>
        <mat-icon color="primary" inline="true">chevron_right</mat-icon>
        {{this.cutText(specificObjetive.detail, 300)}}
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>
