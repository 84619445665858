<div>
    <table mat-table [dataSource]="datasource" fxFlexFill *ngIf="datasource">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef class="align-left">Tipo</th>


            <td mat-cell *matCellDef="let element" class="align-left" valign="top">
                <a href="javascript:void(0)" (click)="confirmUpdateHealth(element.id)" *ngIf="canAdminHealth">
                    {{resolveState(element.healthState)}}
                </a>
                <div *ngIf="!canAdminHealth">
                    {{resolveState(element.healthState)}}
                </div>

            </td>
        </ng-container>

        <ng-container matColumnDef="detail">
            <th mat-header-cell *matHeaderCellDef class="align-left">Detalle</th>
            <td mat-cell *matCellDef="let element" class="align-left" valign="top">
                {{element.detail}}
            </td>
        </ng-container>
        <ng-container matColumnDef="treatment">
            <th mat-header-cell *matHeaderCellDef class="align-left">Tratamiento</th>
            <td mat-cell *matCellDef="let element" class="align-left" valign="top">
                {{element.treatment}}
            </td>
        </ng-container>
        <ng-container matColumnDef="document">
            <th mat-header-cell *matHeaderCellDef class="align-left">Certificados</th>
            <td mat-cell *matCellDef="let element" class="align-left" valign="top">
                <button mat-raised-button color="primary" matTooltip="Descargar certificado"
                    (click)="downloadFile(element.fileHandlerId)" *ngIf="element.fileHandlerId != null">
                    <mat-icon>download</mat-icon>
                    {{element.fileHandlerName}}
                </button>

                <button mat-raised-button color="primary" matTooltip="Cargar certificado"
                    (click)="uploadFile(element.id)" *ngIf="element.fileHandlerId == null && this.canAdminHealth">
                    <mat-icon>upload</mat-icon>
                    {{element.fileHandlerName}}
                </button>
                <div *ngIf="element.fileHandlerId == null && !this.canAdminHealth">
                    <i>Pendiente...</i>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="align-left">Acciones</th>
            <td mat-cell *matCellDef="let element" class="align-left" valign="top">
                <button mat-icon-button color="accent" matTooltip="Eliminar registro"
                    (click)="this.confirmRemove(element.id)" *ngIf="canAdminHealth">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>


    </table>

    <br>
    <button mat-raised-button color="primary" *ngIf="this.canAdminHealth" (click)="openStudentHealthDialog(null)">
        <mat-icon>add</mat-icon> Agregar
    </button>

</div>