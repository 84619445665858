<h1 mat-dialog-title>Selección de curso</h1>
<div mat-dialog-content fxLayout="column">

    <table border="0" width="100%">
        <tr>
            <td colspan="3"><mat-card>Asignatura: {{dialogData.subjectName}}</mat-card></td>
        </tr>
        <tr>
            <td colspan="3">
                <com-course-selector [courseId]="dialogData.courseId" [teacherId]="this.dialogData.teacherId"
                    [subjectId]="this.dialogData.subjectId"
                    (courseSelectEvent)="courseChange($event)"></com-course-selector>
            </td>
        </tr>
        <tr>
            <td width="45%">
                <mat-card>Hora de inicio: {{ findHour( this.dialogData.hourSelected)}}</mat-card>
            </td>
            <td width="10%"></td>
            <td width="45%">
                <mat-form-field appearance="fill" fxFlexFill *ngIf="this.finishHours.length>0">
                    <mat-label>Horario de término</mat-label>
                    <mat-select [(ngModel)]="dialogData.finishId">
                        <mat-option *ngFor="let hour of this.finishHours" [value]="hour.id">
                            {{this.formatHour(hour.finish)}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
    </table>


</div>
<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary"
        [disabled]="this.disableAcceptButton()">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">Cancelar</button>
</div>