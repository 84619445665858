<div fxLayout="column" *ngIf="this.entityConfig">
    <h1 fxFlexFill>{{this.entityConfig.name}}</h1>

    <div mat-dialog-content fxLayoutAlign="space-around center" fxLayout="column"
        *ngFor="let column of filterColumn(this.entityConfig.columns); let i = index">

        <div *ngIf="isBooleanField(column); else notBooleanFields">
            <mat-slide-toggle [(ngModel)]="column.value">{{i+1}}.- {{column.header}}</mat-slide-toggle>
        </div>
        <ng-template #notBooleanFields>
            <mat-form-field fxFlex appearance="fill">
                <mat-label>{{i+1}}.- {{column.header}}</mat-label>

                <div *ngIf="this.isSelectField(column); else notSelectFields">
                    <mat-select [(ngModel)]="column.value">
                        <mat-option *ngFor="let data of column.externalChild.data"
                            [value]="data[column.externalChild.id]">
                            {{data[column.externalChild.name]}}
                        </mat-option>
                    </mat-select>
                </div>
                <ng-template #notSelectFields>
                    <div *ngIf="this.isDateField(column); else notDateFields" style="width: 400px;">
                        <input matInput [matDatepicker]="picker" [(ngModel)]="column.value">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </div>

                    <ng-template #notDateFields>
                        <input matInput [type]="this.resolveType(column)" [placeholder]="column.header"
                            [maxLength]="column.length" [(ngModel)]="column.value">
                    </ng-template>
                </ng-template>
            </mat-form-field>

        </ng-template>

    </div>
    <p fxLayout="row" fxLayoutAlign="space-evenly center" fxFlexFill>
        <button mat-raised-button color="primary" (click)="doSave()">
            <mat-icon>save</mat-icon>Aceptar
        </button>
        <button mat-raised-button (click)="goBack()">
            <mat-icon>close</mat-icon>Cancelar
        </button>

    </p>
</div>