export class Meeting {
    courseId: string;
    courseLeter: string;
    courseYear: number;
    id: string;
    meetingDate: string;
    teacherDv: string;
    teacherFatherSurname: string;
    teacherId: string;
    teacherMotherSurname: string;
    teacherNames: string;
    teacherRut: number;
    title: string;
}