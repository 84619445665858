<div>
    <h1 fxFlexFill>Configuración de Grupos</h1>

    <div class="container" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start stretch">
        <!-- Fila 1: Etiqueta, Input y Botón -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <label>Curso:</label>
            <strong>{{this.courseService.formatLetra(course.letra)}}, {{course.agno}}</strong>

            <mat-form-field appearance="fill" fxFlex="30%">
                <input matInput type="number" [(ngModel)]="groupCounter"
                    placeholder="Ingrese cantidad de grupos a crear" min="0" max="25" [disabled]="this.groupsAlreadyExists"/>
            </mat-form-field>

            <button mat-raised-button color="primary" (click)="createGroupClick()" [disabled]="this.groupCounter == 0 || groupsAlreadyExists">
                <mat-icon>add</mat-icon> Crear grupos
            </button>
        </div>

        <!-- Fila 2: Grilla con Datos de Alumnos -->
        <div fxFlex>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" fxFlex>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <!-- Columna Número -->
                <ng-container matColumnDef="numero">
                    <th mat-header-cell *matHeaderCellDef class="align-left">Número</th>
                    <td mat-cell *matCellDef="let element" class="align-left">{{ element.numero }}</td>
                </ng-container>

                <!-- Columna RUT -->
                <ng-container matColumnDef="rut">
                    <th mat-header-cell *matHeaderCellDef class="align-left">RUT</th>
                    <td mat-cell *matCellDef="let element" class="align-left">{{ element.rut }}</td>
                </ng-container>

                <!-- Columna Nombre del Alumno -->
                <ng-container matColumnDef="nombre">
                    <th mat-header-cell *matHeaderCellDef class="align-left">Nombre del Alumno</th>
                    <td mat-cell *matCellDef="let element" class="align-left">{{ element.nombre }}</td>
                </ng-container>

                <!-- Columna Grupo -->
                <ng-container matColumnDef="grupo">
                    <th mat-header-cell *matHeaderCellDef class="align-center">Grupo</th>
                    <td mat-cell *matCellDef="let element" class="align-center">{{ element.grupo }}</td>
                </ng-container>

            </table>
        </div>
    </div>


</div>