<h1 mat-dialog-title>Fechas para criterio de evaluación ({{dialogData.planningYear}})</h1>
<div mat-dialog-content fxLayout="column">


    <mat-form-field appearance="fill">
        <mat-label>Descripción del objetivo</mat-label>
        <textarea matInput cdkTextareaAutosize readonly="true">{{this.dialogData.detail}}</textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Periodo de clases</mat-label>
        <mat-date-range-input [rangePicker]="picker"  [dateFilter]="planningYearFilter">
            <input matStartDate   placeholder="Fecha inicio" [(ngModel)]="this.dialogData.startDate"  >
            <input matEndDate placeholder="Fecha fin" [(ngModel)]="this.dialogData.finishDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

</div>

<div mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="this.dialogData" color="primary" [disabled]="this.dialogData.readonly">Aceptar</button>
    <button mat-raised-button [mat-dialog-close]="">Cancelar</button>
</div>