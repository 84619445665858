import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'com-chips-selector',
  templateUrl: './chips-selector.component.html',
  styleUrls: ['./chips-selector.component.css']
})
export class ChipsSelectorComponent implements OnChanges {
  @Input() elements: string[];

  @Input() label: string;

  @Input() placeholder: string;

  @Input() selectedElements: string[]; //  = [];

  @Input() readonly: boolean = false;

  @Output() elementsUpdated = new EventEmitter<string[]>();

  formControl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  elementCtrl = new UntypedFormControl('');
  filteredElements: Observable<string[]>;
  allElements: string[] = [];

  @ViewChild('elementInput') elementInput: ElementRef<HTMLInputElement>;

  constructor() {
    this.resetFiltredElements();
  }

  resetFiltredElements() {
    this.filteredElements = this.elementCtrl.valueChanges
      .pipe(
        startWith(null),
        map((element: string | null) => (element ? this._filter(element) : this.allElements.slice())),
      );
  }

  ngOnChanges(sc: SimpleChanges): void {
    //  console.log('Changes:', sc);
    if (sc.elements?.currentValue == null) return;

    if (this.readonly)
      this.formControl.disable();
    else
      this.formControl.enable();

    this.allElements = sc.elements?.currentValue;
    if (sc.selectedElements?.currentValue != null /*&& sc.selectedElements?.currentValue.length > 0*/) {
      console.log(sc.selectedElements?.currentValue);

      //  sc.selectedElements.currentValue.forEach((selectedElement: string) => this.moveToSelected(selectedElement));
    }
  }

  add(event: MatChipInputEvent): void {
    console.log('add', event);

    const value = (event.value || '').trim();

    if (!this.valueExists(value)) return;

    if (value) {
      this.moveToSelected(value);
    }
    event.chipInput!.clear();
    this.elementCtrl.setValue(null);

  }
  valueExists(value: string) {
    return this.allElements.includes(value);
  }

  moveToSelected(value: string) {
    console.log(value);

    this.selectedElements.push(value);
    this.allElements = this.allElements.filter(element => element != value);
    //console.log(this.allElements);
  }

  remove(elementRemoved: string): void {
    console.log('remove chip', elementRemoved);

    const index = this.selectedElements.indexOf(elementRemoved);
    if (index >= 0) {
      this.selectedElements.splice(index, 1);
      this.allElements.push(elementRemoved);
      this.resetFiltredElements();
    }

    this.emitEvent();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('selecetd', event);
    // debugger;
    // document.getElementById('chip-label').focus();

    // this.selectedElements.push(event.option.viewValue);
    this.moveToSelected(event.option.viewValue);

    this.elementInput.nativeElement.value = '';
    this.elementCtrl.setValue(null);
    this.emitEvent();


  }

  emitEvent() {
    this.elementsUpdated.emit(this.selectedElements);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allElements.filter(fruit => fruit.toLowerCase().includes(filterValue));
  }
}

