<div fxLayout="column wrap" fxLayoutAlign="space-around center" fxLayoutGap="10px">
  <table *ngIf="!this.showingSummaryReport" fxFlex.xs="80" border="0" fxFlex.sm="65" fxFlex.md="50" fxFlex.lg="30"
    fxFlex.xl="25">
    <tr>
      <td fxFlexFill>
        <h2>{{establishmentName}} - Libro de Clases Digital</h2>
        <small [matTooltip]="this.envUrl" matTooltipShowDelay="10" matTooltipHideDelay="10">v{{ver}}</small><br>
      </td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>

    <tr *ngIf="this.canSeeSummaryReport">
      <td>
        <button mat-raised-button (click)="toggleShowSummaryReport()">
          <mat-icon>group</mat-icon>&nbsp; {{ this.courseUserMessage }} </button>
      </td>
    </tr>
  </table>
  <p>
    <app-summary-report *ngIf="this.showingSummaryReport" [course]="this.course" [periodSelected]="this.currentPeriod"
      [canChangeCourse]="this.course == null"></app-summary-report>
  </p>
  <p *ngIf="this.showEnrollingBookButton">
    <button mat-raised-button [matMenuTriggerFor]="menu"><mat-icon>book</mat-icon>Libro de Matriculas</button>
    <mat-menu #menu="matMenu" xPosition="after">
      <button mat-menu-item (click)="this.downloadEnrollerBook('pdf')"><mat-icon>download</mat-icon>Descargar como PDF</button>
      <button mat-menu-item (click)="this.downloadEnrollerBook('excel')"><mat-icon>download</mat-icon>Descargar como Excel</button>
    </mat-menu>

  </p>
 
</div>