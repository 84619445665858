import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Course } from 'src/app/model/course';
import { Hour } from 'src/app/model/hour';

@Component({
  selector: 'app-select-course-time-dialog',
  templateUrl: './select-course-time-dialog.component.html',
  styleUrls: ['./select-course-time-dialog.component.css']
})

export class SelectCourseTimeDialogComponent implements OnInit {
  public startHours: Hour[] = [];
  public finishHours: Hour[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: SelectCourseTimeParamDialog) { }

  ngOnInit(): void {
    console.log('dialogData:', this.dialogData);
    if (this.dialogData.finishId == '') {
      this.refreshHoursToFinish();
    } else {
      this.refreshRangeHours();
    }
  }

  private refreshRangeHours(): void {
    this.finishHours = [];
    let found: boolean = false;
    let i: number = 0;

    for (let index = 0; index < this.dialogData.hours.length; index++) {
      const hour = this.dialogData.hours[index];

      if (!found && hour.id == this.dialogData.hourSelected) {
        found = true;
      }

      if (found) {
        this.finishHours[i] = hour;
        i++;
      }

      if (this.dialogData.finishId == hour.id) {
        break;
      }
    }
  }

  // private findHourById(id: string): Hour {
  //   return this.dialogData.hours.filter(h => h.id == id)[0];
  // }

  private refreshHoursToFinish(): void {
    this.finishHours = [];
    let found: boolean = false;
    let i: number = 0;

    for (let index = 0; index < this.dialogData.hours.length; index++) {
      const hour = this.dialogData.hours[index];

      if (this.dialogData.hourBusy != null && this.dialogData.hourBusy == hour.id) {
        break;
      }

      if (!found && hour.id == this.dialogData.hourSelected) {
        found = true;
      }

      if (found) {
        this.finishHours[i] = hour;
        i++;
      }
    }
  }

  public courseChange(course: Course): void {
    if (course != null) {
      this.dialogData.courseId = course.id;
      this.dialogData.courseLeter = course.letra;
    } else {
      this.dialogData.courseId = '';
      this.dialogData.courseLeter = '';
    }
  }

  public findHour(hourId: string): string {
    let hourFormated = '';
    try {
      hourFormated = this.formatHour(this.dialogData.hours.filter(hour => hour.id == hourId)[0].start);
    } catch (e) {
      console.error(e);
      throw e;
    }
    return hourFormated;
  }
  public formatHour(time: string): string {
    return time.substring(0, time.lastIndexOf(':'));
  }
  public disableAcceptButton(): boolean {
    return false;
  }
}
export class SelectCourseTimeParamDialog {
  courseId: string;
  courseLeter: string;
  subjectName: string;
  startId: string;
  finishId: string;
  hours: Hour[];
  hourSelected: string;
  hourBusy: string;

  teacherId: string;
  subjectId: string;
}