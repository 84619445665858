<h1 *ngIf="course">Curso {{courseService.formatLetra(course.letra)}}</h1>

<mat-tab-group animationDuration="0ms" *ngIf="course" selectedIndex="0<">
    <mat-tab label="Propiedades">
        <app-course-general-props [courseId]="course.id"></app-course-general-props>
    </mat-tab>


    <mat-tab>
        <ng-template mat-tab-label>
            Grupos&nbsp;
            <mat-icon color="accent" matTooltip="Nuevo!" matTooltipPosition="above">new_releases</mat-icon>
        </ng-template>

        <app-course-groups [course]="course"></app-course-groups>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            Asignaturas y Profesores
        </ng-template>
        <app-course-teachers [courseId]="course.id"></app-course-teachers>
    </mat-tab>

</mat-tab-group>


<!--
    <mat-icon color="accent" matTooltip="Nuevo!" matTooltipPosition="above">new_releases</mat-icon>
-->