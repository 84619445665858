<table mat-table [dataSource]="datasource" fxFlexFill>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Motivo </th>
        <td mat-cell *matCellDef="let element" class="align-left">{{this.resolveType(element.type)}}: {{element.reason}}
        </td>
    </ng-container>
    <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Inicio </th>
        <td mat-cell *matCellDef="let element" class="align-left"> {{element.start}} </td>
    </ng-container>
    <ng-container matColumnDef="finish">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Termino </th>
        <td mat-cell *matCellDef="let element" class="align-left"> {{element.finish}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="align-left"> Documento </th>
        <td mat-cell *matCellDef="let element" class="align-left">
            <button *ngIf="element.filename" mat-raised-button (click)="doDownload(element.id, element.filename)"
                color="primary">
                <mat-icon style="cursor:pointer">download</mat-icon> {{element.filename}}
            </button>
        </td>
    </ng-container>


</table>