<h1 mat-dialog-title>Envio información a Mineduc</h1>
<div mat-dialog-content fxLayout="column">


    <mat-form-field appearance="fill">
        <mat-label>Periodo de clases para ser enviada</mat-label>
        <mat-date-range-input [rangePicker]="picker" [dateFilter]="planningYearFilter" [min]="this.minDate"
            [max]="this.maxDate">
            <input matStartDate placeholder="Fecha inicio" [(ngModel)]="this.dialogData.startDate">
            <input matEndDate placeholder="Fecha fin" [(ngModel)]="this.dialogData.finishDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>


</div>


<div mat-dialog-actions>
    <button mat-raised-button cdkFocusInitial (click)="doClickButton(true)" [mat-dialog-close]="dialogData"
        color="primary">Confirmo envio</button>
    <button mat-raised-button (click)="doClickButton(false)" [mat-dialog-close]="dialogData">Cancelar</button>

</div>