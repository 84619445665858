<h1 mat-dialog-title>Motivo de cancelación de la práctica {{data.id}}
</h1>
<div mat-dialog-content>
  <table class="example-full-width">
    <tr>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Observación</mat-label>
          <textarea matInput
            required
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            [(ngModel)]="observation"></textarea>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field class="example-full-width">
          <mat-label>Clasificación</mat-label>
          <mat-select required [(ngModel)]="selected">
            <mat-option *ngFor="let tipo of tipos" [value]="tipo.value">
              {{tipo.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>
    </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cerrar</button>
  <button mat-button (click)="onSelectCourseClick()" [disabled]="selected==undefined || observation==undefined" cdkFocusInitial>Aplicar</button>
</div>
