import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';

@Component({
  selector: 'app-edit-accident-dialog',
  templateUrl: './edit-accident-dialog.component.html',
  styleUrls: ['./edit-accident-dialog.component.css']
})
export class EditAccidentDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditAccidentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: EditAccidentDialogDataParam,
    public dateTimeUtility: DateTimeUtility) { }

  ngOnInit(): void {
  }

}

export class EditAccidentDialogDataParam {
  accidentId: string;
  studentName: string;
  courseLeter: string;

  accidentDate: Moment;
  description: string;
  healthPlace: string;
  escortsName: string;
  escortsRelationship: string;

  attentionDate: Moment;
  returnsDate: Moment;

}