import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { PostulanteService } from 'src/app/api/postulante.service';
import { Course } from 'src/app/model/course';
import { Postulante } from 'src/app/model/postulant';

@Component({
  selector: 'app-postulante',
  templateUrl: './postulante.component.html',
  styleUrls: ['./postulante.component.css']
})
export class PostulanteComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['id', 'rut', 'nombre', 'especialidad', 'canal', 'curso'];
  dataSource: MatTableDataSource<Postulante>;
  //  postulantes: Postulante[] = [];
  cursos: Course[] = [];
  cursoId: string = '';
  filterValue: string = '';
  nextYear: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private postulanteService: PostulanteService,
    public cursoService: CourseService,
    private configSerice: ConfigService) { }


  ngOnInit() {
    this
      .postulanteService
      .listAll()
      .subscribe(
        response => {
          // console.log(response);
          this.refreshTable(response);
/*          this.dataSource = new MatTableDataSource(response);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        */      },
        problems => console.error(problems)
      );
    this
      .cursoService
      .listForPostulant()
      .subscribe(
        response => {
          //        console.log(response);
          this.cursos = response;
        },
        problems => console.error(problems)
      );
    this
      .configSerice
      .getValueByKey('inscripcion_actual')
      .subscribe(
        response => this.nextYear = +response,
        problems => console.error(problems)

      )
  }

  ngAfterViewInit(): void {
  }

  formatLetra(letra: string): string {
    return this.cursoService.formatLetra(letra);
  }


  applyFilter(event: Event) {
    //    const filterValue = (event.target as HTMLInputElement).value;
    //console.log(this.filterValue);

    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onAsignCursoClick(): void {
    /*
    if (this.curso.trim().length == 0) {
      alert('Tiene que seleccionar un curso!');
      return;
    }*/
    let postulantesId: string[] = [];
    this
      .dataSource
      .data
      .forEach(e => {
        if (e.selected) {
          postulantesId.push(e.id);
        }
        //      console.log('"' + this.curso + '"');
        //      console.log( e.selected ? e.id : "");
      });

    if (postulantesId.length == 0) {
      alert('No se han seleccionado postulantes');
      return;
    }

    this
      .postulanteService
      .asignCurso(postulantesId, this.cursoId)
      .subscribe(
        response => {
          console.log(response);
          this.refreshTable(response);
        },
        problems => console.error(problems)
      );
  }

  onAutomaticAsign(): void {
    let message: string = '¿Esta seguro de ejecutar asignacion automatica?\n';
    message += 'Esto aplicará solo para los postulantes que no tienen curso asignado.';
    if (confirm(message)) {
      this
        .postulanteService
        .automaticAsign()
        .subscribe(
          response => {
            console.log(response);
            this.refreshTable(response);
          },
          problems => console.error(problems)
        );
    }
  }

  refreshTable(postulantes: Postulante[]) {
    this.dataSource = new MatTableDataSource(postulantes);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilter(null);
    //    this.dataSource.filter = '';
    //this.filterValue='';
  }
}
