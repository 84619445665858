import { Alumno } from "./alumno";

export class StudentHealth {
    id: string;
    student: Alumno;
    year: number;
    healthState: string;
    detail: string;
    treatment: string;
    fileHandlerId: string;
    fileHandlerName:string;
}
