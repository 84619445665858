import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { EvaluationCriteria } from "../model/evaluation-criteria";
import { GenericObjective } from "../model/generic-objetive";
import { Module } from "../model/module";
import { SpecificObjective } from "../model/specific-objetive";
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class PlanService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/plan/1.0';
    private listModulesUrl: string = '/list-modules';
    private listGenericObjectivesUrl: string = '/list-generic-objectives';
    private listSpecificObjectivesUrl: string = '/list-specific-objectives';
    private listEvaluationCriteraUrl: string = '/list-evaluation-criteria';
    private savePlanUrl: string = '/save-plan';


    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listModules(subjectId: string, levelId: string, year: number): Observable<Module[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listModulesUrl}/${subjectId}/${levelId}/${year}`;
        return this.http.get<Module[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listGenericObjectives(): Observable<GenericObjective[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listGenericObjectivesUrl}`;
        return this.http.get<GenericObjective[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listSpecificObjectives(moduleId: string): Observable<SpecificObjective[]> {
        const url = this.baseUrl + this.serviceUrl + `${this.listSpecificObjectivesUrl}/${moduleId}`;
        console.log(url);
        return this.http.get<SpecificObjective[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }


    public findEvaluationCritera(specificObjetiveId: string): Observable<EvaluationCriteria> {
        const url = this.baseUrl + this.serviceUrl + `${this.listEvaluationCriteraUrl}/${specificObjetiveId}`;
        return this.http.get<EvaluationCriteria>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public savePlan(evaluationCriteria: EvaluationCriteria): Observable<EvaluationCriteria> {
        const url = this.baseUrl + this.serviceUrl + `${this.savePlanUrl}`;
        return this.http.post<EvaluationCriteria>(url, evaluationCriteria, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public duplicateModulesFromPrevYear(subjectId: string, levelId: string, year: number): Observable<Module[]> {
        const url = this.baseUrl + this.serviceUrl + `/duplicate-from-prev-Year/${subjectId}/${levelId}/${year}`;
        return this.http.get<Module[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}