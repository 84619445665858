import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-crud',
  templateUrl: './main-crud.component.html',
  styleUrls: ['./main-crud.component.css']
})
export class MainCrudComponent implements OnInit {
  tabSelected: string = '0';
  constructor() { }

  ngOnInit(): void {
    const tabSelected = localStorage.getItem('tabSelected');
    if (tabSelected != null)
      this.tabSelected = tabSelected;
      localStorage.removeItem('tabSelected');
  }
  
}
