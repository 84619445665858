<div fxLayoutAlign="space-evenly center" style="text-align: left;">


    <table [border]="borderSize" width="90%">
        <tr>
            <td colspan="3">
                <h1>Calendario de clases, año {{this.currentYear}}</h1>
            </td>
        </tr>
        <tr>
            <td width="100%" *ngIf="this.loading" style="text-align: center;">
                <mat-spinner></mat-spinner>
            </td>

            <td width="45%" *ngIf="!this.loading">
                <mat-form-field appearance="fill" fxFlexFill *ngIf="this.selectOtherTeacher">
                    <mat-label>Profesor</mat-label>
                    <mat-select [(ngModel)]="this.teacherId" (selectionChange)="changeTeacher($event)">
                        <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                            {{teacher.fatherSurname}} {{teacher.motherSurname}}, {{teacher.names}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" fxFlexFill *ngIf="!this.selectOtherTeacher">
                    <mat-label>Profesor</mat-label>
                    <input matInput [(ngModel)]="this.username" readonly="true">
                </mat-form-field>

            </td>
            <td width="10%" *ngIf="!this.loading"></td>
            <td width="45%" *ngIf="!this.loading">
                <mat-form-field appearance="fill" fxFlexFill *ngIf="this.canChangeSchedule">
                    <mat-label>Asignatura</mat-label>
                    <mat-select [(ngModel)]="this.subjectId" (selectionChange)="changeSubject($event)">
                        <mat-option *ngFor="let subject of subjects" [value]="subject.id">
                            {{subject.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </tr>
        <tr *ngIf="!this.loading">
            <td colspan="3">
                <table [border]="borderSize" fxFlexFill>
                    <tr>
                        <td width="10%" class="align-center"><mat-card>Hora</mat-card></td>
                        <td width="15%"><mat-card>Lunes</mat-card></td>
                        <td width="15%"><mat-card>Martes</mat-card></td>
                        <td width="15%"><mat-card>Miércoles</mat-card></td>
                        <td width="15%"><mat-card>Jueves</mat-card></td>
                        <td width="15%"><mat-card>Viernes</mat-card></td>
                        <td width="15%"><mat-card>Sábado</mat-card></td>
                    </tr>
                    <tr *ngFor="let calendarRow of calendarRows">
                        <td class="align-center"><mat-card>{{formatHour(calendarRow.hour.start)}} -
                                {{formatHour(calendarRow.hour.finish)}}</mat-card></td>

                        <td><button fxFlexFill mat-raised-button [color]="getColor(calendarRow.monday)"
                                [disabled]="isDisabled(calendarRow.monday)" [id]="calendarRow.hour.id+'.monday'"
                                (click)="cellClick($event)">{{getCourseLeterAndSignatureName(calendarRow.monday)}}</button>
                        </td>

                        <td><button fxFlexFill mat-raised-button [color]="getColor(calendarRow.tuesday)"
                                [disabled]="isDisabled(calendarRow.tuesday)" [id]="calendarRow.hour.id+'.tuesday'"
                                (click)="cellClick($event)">{{getCourseLeterAndSignatureName(calendarRow.tuesday)}}</button>
                        </td>

                        <td><button fxFlexFill mat-raised-button [color]="getColor(calendarRow.wednesday)"
                                [disabled]="isDisabled(calendarRow.wednesday)" [id]="calendarRow.hour.id+'.wednesday'"
                                (click)="cellClick($event)">{{getCourseLeterAndSignatureName(calendarRow.wednesday)}}</button>
                        </td>

                        <td><button fxFlexFill mat-raised-button [color]="getColor(calendarRow.thursday)"
                                [disabled]="isDisabled(calendarRow.thursday)" [id]="calendarRow.hour.id+'.thursday'"
                                (click)="cellClick($event)">{{getCourseLeterAndSignatureName(calendarRow.thursday)}}</button>
                        </td>

                        <td><button fxFlexFill mat-raised-button [color]="getColor(calendarRow.friday)"
                                [disabled]="isDisabled(calendarRow.friday)" [id]="calendarRow.hour.id+'.friday'"
                                (click)="cellClick($event)">{{getCourseLeterAndSignatureName(calendarRow.friday)}}</button>
                        </td>

                        <td><button fxFlexFill mat-raised-button [color]="getColor(calendarRow.saturday)"
                                [disabled]="isDisabled(calendarRow.saturday)" [id]="calendarRow.hour.id+'.saturday'"
                                (click)="cellClick($event)">{{getCourseLeterAndSignatureName(calendarRow.saturday)}}</button>
                        </td>
                    </tr>
                </table>

            </td>
        </tr>
        <tr>
            <td colspan="3">&nbsp;</td>
        </tr>
        <tr *ngIf="this.canChangeSchedule">
            <td colspan="3">

                <table fxFlexFill [border]="borderSize">
                    <tr>
                        <td width="35%">&nbsp;</td>
                        <td width="30%">
                            <button fxFlexFill mat-raised-button (click)="doSaveSchedule($event)"
                                color="primary">Grabar</button>
                        </td>
                        <td width="35%">&nbsp;</td>
                    </tr>
                </table>

            </td>

        </tr>
    </table>
</div>