export class EvaluationCriteria {
    items: EvaluationCriteriaItem[] = [];
    specificObjectiveId: string = '';
}

export class EvaluationCriteriaItem {
    id: string = '';
    genericObjectiveIds: string[] = [];
    startDate: string = '';
    finishDate: string = '';
    detail: string = '';
    activityStart: string = '';
    activityDevelop: string = '';
    activityFinish: string = '';
    evaluationIndicator: string = '';
    criteriaToEvaluate: string = '';
    resourceIds: string[];
    evaluationTypeIds: string[];
}

