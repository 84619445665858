
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthorService } from './author.service';
import { environment } from "src/environments/environment";
import { Company } from "../model/company";
import { CompanyPractice } from "../model/company-practice";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private baseUrl: string = environment.apiPracticesUrl;
  private serviceUrl: string = '/api/lichan/management/1.0';
  private getCompaniesUrl: string = '/companies';


  constructor(private http: HttpClient,
      private authorService: AuthorService) {
  }

  public getCompanies(year: string): Observable<Company[]> {
      const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}/actualYear/${year}`;
      return this.http.get<Company[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public addCompany(company: Company): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}`;
    return this.http.post<Company[]>(url, company,{ headers: this.authorService.headerWithAuthorization(null) });

}

public editCompany(company: Company): Observable<any> {
  const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}`;
  return this.http.put<Company[]>(url, company,{ headers: this.authorService.headerWithAuthorization(null) });

}

  public getCompaniesYear(year:number): Observable<Company[]> {
    const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}` + "/year/" + year;
    return this.http.get<Company[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
}

  public countCompanies(): Observable<any> {
    const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}` + "/count";
    return this.http.get<any>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getCompanyById(companyId: number): Observable<Company> {
    const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}` + "/" + companyId;
    return this.http.get<Company>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }

  public getCompanyByStudentRun(run: number,year:string): Observable<CompanyPractice> {
    const url = this.baseUrl + this.serviceUrl + `${this.getCompaniesUrl}` + "/student/" + run + "/year/" + year;
    return this.http.get<CompanyPractice>(url, { headers: this.authorService.headerWithAuthorization(null) });
  }
}
