<mat-form-field appearance="fill" fxFlexFill>
    <mat-label>Curso</mat-label>
    <mat-select [(ngModel)]="this.courseId" (selectionChange)="onChangeCourse()" #courseSelect>
        <mat-option value="">- Seleccione curso -</mat-option>
        <mat-option *ngFor="let course of courses" [value]="course.id">
            {{this.courseService.formatLetra(course.letra)}}, {{course.agno}}
            {{showSpecialityName && course.especialidadNombre != null ? '- Esp: ' + course.especialidadNombre:''}}
            {{showTeacherName && course.teacherName != null ? '- Prof: ' + course.teacherName:''}}
        </mat-option>
    </mat-select>
</mat-form-field>