import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthorService } from "../api/author.service";

@Injectable({
    providedIn: 'root'
})

export class DownloadUtility {
    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public download(binaryData: ArrayBuffer, fileType: string, fileName: string): void {
        const file: Blob = new Blob([binaryData], { type: fileType });
        const url: string = window.URL.createObjectURL(file);
        const anchorElement: HTMLAnchorElement = document.createElement('a');
        anchorElement.download = fileName;
        anchorElement.href = url;
        anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    }

    public downloadById(url: string) {
        // const url = `${this.baseUrl}${this.serviceUrl}/download-attention-file/${accidentId}`;
        return this.http.get(url, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
//                .append('Content-Type', 'application/pdf')
                .append(this.authorService.AUTHORIZATION,
                    this.authorService.BEARER + this.authorService.token
                )
        });
    }


}