import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Column } from 'src/app/model/crud-response';

@Component({
  selector: 'app-insert-dialog',
  templateUrl: './insert-dialog.component.html',
  styleUrls: ['./insert-dialog.component.css']
})
export class InsertDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ActionDialogParameter) { }

  ngOnInit(): void {
    console.log('dialogData: ', this.dialogData);

    this.dialogData.columns
      .filter(c => this.isSelectField(c) && this.dialogData.action != 'insert')
      .forEach(c => c.value = c.value.id);
    console.log(this.dialogData.columns);

  }

  public filterColumns(columns: Column[]): Column[] {
    return columns.filter(column => column.showOnForm && column.id != 'id');
  }

  public isSelectField(column: Column): boolean {
    return column.externalChild != null;
  }

  public resolveType(column: Column): string {
    let htmlType: string = 'text';

    switch (column.dataType) {
      case 'java.lang.Integer':
        htmlType = 'number';
        break;

      default:
        break;
    }
    return htmlType;
  }

  public isBooleanField(column: Column): boolean {
    if (column.format == null) return false;
    const format = column.format.toLowerCase();
    return format.startsWith('bool') || format.startsWith('logic');
  }

  public isTextarea(column: Column): boolean {
    if (column.format == null) return false;
    const format = column.format.toLowerCase();
    return format.startsWith('text');
  }

  public isDateField(column: Column): boolean {
    if (column.dataType == null) return false;
    const dataType = column.dataType.toLowerCase();
    return dataType.endsWith('date');
  }

}

export class ActionDialogParameter {
  title: string;
  action: string;
  columns: Column[];
}

