import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Alumno } from "../model/alumno";
import { Comprobante } from "../model/comprobante";
import { Comuna } from "../model/comuna";
import { CourseSummary } from "../model/course-summary";
import { DatosPostulante } from "../model/datos-postulante";
import { DatosUsuario } from "../model/datos-usuario";
import { Especialidad } from "../model/especialidad";
import { AuthorService } from "./author.service";

@Injectable({
    providedIn: 'root'
})

export class InscripcionService {
    private baseUrl: string = environment.apiUrl;
    private inscripcionServiceUrl: string = '/public/lichan/inscripcion/1.0';
    private apiInscripcionServiceUrl: string = '/api/lichan/inscripcion/1.0';
    private comunaServiceUrl: string = '/api/lichan/comuna/1.0';
    private especialidadServiceUrl: string = '/api/lichan/especialidad/1.0';
    private listCourseSummaryUrl: string = '/list-courses-summary';
    private bindUrl: string = '/bind';
    private unbindUrl: string = '/unbind';

    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    grabarInscriptor(datosUsuario: DatosUsuario): Observable<DatosUsuario> {
        const url = this.baseUrl + this.inscripcionServiceUrl + `/graba-datos-usuario`;
        return this.http.post<DatosUsuario>(url, datosUsuario);
    }

    verifySecurityCode(id: string, code: string): Observable<boolean> {
        const url = this.baseUrl + this.inscripcionServiceUrl + `/verifica-codigo`;
        let datosUsuario: DatosUsuario = new DatosUsuario();
        datosUsuario.id = id;
        datosUsuario.codigoSeguridad = code;
        return this.http.post<boolean>(url, datosUsuario);
    }

    grabaPostulante(datosPostulante: DatosPostulante): Observable<Comprobante> {
        const url = this.baseUrl + this.inscripcionServiceUrl + `/graba-postulante`;
        return this.http.post<Comprobante>(url, datosPostulante);
    }

    buscaPostulante(rut: string): Observable<DatosPostulante> {
        const url = this.baseUrl + this.inscripcionServiceUrl + `/busca-postulante/${rut}`;
        return this.http.get<DatosPostulante>(url);
    }

    listaComunas(): Observable<Comuna[]> {
        const url = this.baseUrl + this.comunaServiceUrl + `/list`;
        return this.http.get<Comuna[]>(url);
    }

    listaEspecialidades(): Observable<Especialidad[]> {
        const url = this.baseUrl + this.especialidadServiceUrl + `/list`;
        return this.http.get<Especialidad[]>(url);
    }

    public listCoursesSummary(): Observable<CourseSummary[]> {
        const url = this.baseUrl + this.apiInscripcionServiceUrl + `${this.listCourseSummaryUrl}`;
        return this.http.get<CourseSummary[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public bindStudentAndCourse(studentId: string, courseId: string): Observable<Alumno> {
        const url = this.baseUrl + this.apiInscripcionServiceUrl + this.bindUrl;
        const rel: RelStudentCourse = new RelStudentCourse();
        rel.studentId = studentId;
        rel.courseId = courseId;
        return this.http.post<Alumno>(url, rel, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public unbindStudentAndCourse(studentId: string, courseId: string): Observable<Alumno> {
        const url = this.baseUrl + this.apiInscripcionServiceUrl + this.unbindUrl;
        const rel: RelStudentCourse = new RelStudentCourse();
        rel.studentId = studentId;
        rel.courseId = courseId;
        return this.http.post<Alumno>(url, rel, { headers: this.authorService.headerWithAuthorization(null) });
    }
}

export class RelStudentCourse {
    studentId: string;
    courseId: string;
}