<div fxLayout="column" fxLayoutAlign="space-around center">
    <h1 fxFlexFill>Cursos para el año {{this.nextYear}}</h1>


    <table mat-table [dataSource]="dataSource" fxFlexFill multiTemplateDataRows>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <ng-container matColumnDef="level">
            <th mat-header-cell *matHeaderCellDef> Nivel </th>
            <td mat-cell *matCellDef="let row" class="align-left"> {{row.course.level.name}} </td>
        </ng-container>
        <ng-container matColumnDef="letter">
            <th mat-header-cell *matHeaderCellDef> Letra </th>
            <td mat-cell *matCellDef="let row" class="align-left">

                {{courseService.formatLetra(row.course.letra)}}
                {{ !row.showSpecialtySelector && row.course.especialidadNombre?
                '('+row.course.especialidadNombre+')':''}}

                <button mat-icon-button (click)="onShowSpecialtySelector(row)" color="primary"
                    matTooltip="Cambiar especialidad" *ngIf="canChangeSpecialty && row.showEditButton">
                    <mat-icon style="font-size:22px;">edit</mat-icon>
                </button>


                <mat-form-field [name]="row.course.id" *ngIf="row.showSpecialtySelector">
                    <mat-label>Especialidad</mat-label>
                    <mat-select [(ngModel)]="row.course.especialidadId">
                        <mat-option *ngFor="let specialty of this.specialties" [value]="specialty.id">
                            {{specialty.nombre}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button mat-icon-button (click)="onCommitSpecialtySelector(row)" color="primary"
                    *ngIf="!row.showEditButton && row.showSpecialtySelector">
                    <mat-icon color="primary" style="font-size:22px;">check_circle</mat-icon>
                </button>
                <button mat-icon-button (click)="onCancelSpecialtySelector(row)"
                    *ngIf="!row.showEditButton && row.showSpecialtySelector">
                    <mat-icon color="primary" style="font-size:22px;">cancel</mat-icon>
                </button>
 

            </td>
        </ng-container>
        <ng-container matColumnDef="year">
            <th mat-header-cell *matHeaderCellDef class="align-center"> Año </th>
            <td mat-cell *matCellDef="let row" class="align-center"> {{row.course.agno}} </td>
        </ng-container>
        <ng-container matColumnDef="ins">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Inscritos/postulantes </th>
            <td mat-cell *matCellDef="let row" class="align-right"> {{row.ins}} </td>
        </ng-container>
        <ng-container matColumnDef="xmt">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Antiguos x matricular</th>
            <td mat-cell *matCellDef="let row" class="align-right"> {{row.xmt}} </td>
        </ng-container>
        <ng-container matColumnDef="mat">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Matriculados </th>
            <td mat-cell *matCellDef="let row" class="align-right"> {{row.mat}} </td>
        </ng-container>
        <ng-container matColumnDef="tot">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Total </th>
            <td mat-cell *matCellDef="let row" class="align-right"> {{row.tot}} </td>
        </ng-container>

        <ng-container matColumnDef="act">
            <th mat-header-cell *matHeaderCellDef class="align-right"> Accion </th>
            <td mat-cell *matCellDef="let row" class="align-right">
                <button mat-icon-button aria-label="Editar" (click)="expandCourse(row.course.id)" color="primary">
                    <mat-icon style="font-size:22px;">edit_note</mat-icon>
                </button>
            </td>
        </ng-container>



    </table>
</div>

<div *ngIf="this.canCreateNewCourses"
    style="z-index:5; position : fixed;display : flex; align-self : flex-end;bottom : 10%; margin-bottom : 0px; margin-left: 90%;">

    <div style="margin-right : 14px;" fxLayout="column" fxLayoutAlign="start center" *ngIf="creating">
        <mat-spinner diameter="50"></mat-spinner>
        <div> Creando cursos... </div>
    </div>

    <a mat-fab style="margin-right : 14px;" color="primary" (click)="this.addCourses()" *ngIf="!creating">
        <mat-icon>add</mat-icon>
    </a>
</div>