import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../alumno.component';
import { ValidateRut } from 'src/app/gui-comp/rut-input/rut-input.component';

@Component({
  selector: 'app-change-rut-dialog',
  templateUrl: './change-rut-dialog.component.html',
  styleUrls: ['./change-rut-dialog.component.css']
})
export class ChangeRutDialogComponent implements OnInit {
  public rutValid: boolean = true;


  constructor(public dialogRef: MatDialogRef<ChangeRutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public changeRutDialogParams: ChangeRutDialogParams) { }

  ngOnInit(): void {
  }

  public validateRutEvent(valid: ValidateRut): void {
    this.rutValid = valid.valid;
    console.log(`AfterInput: ${valid.rut}`);
    if (this.rutValid) {
      this.changeRutDialogParams.rut = valid.rut;
    }
  }

}

export class ChangeRutDialogParams {
  rut: string;
  fullName: string;
}