import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-verify-password-dialog',
  templateUrl: './verify-password-dialog.component.html',
  styleUrls: ['./verify-password-dialog.component.css']
})
export class VerifyPasswordDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<VerifyPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: VerifyPasswordParamDialog) { }

  ngOnInit(): void {
    console.log(this.dialogData);
    this.dialogData.password = '';

  }

  public onCancelButton() {
    this.dialogData.password = '';
  }

}

export class VerifyPasswordParamDialog {
  message: string;
  username: string;
  password: string;
  comment: string;
  showMessage: boolean;
  requestComment: boolean;

  passwordLabel: string;
  passwordType: string;
  passwordLen: number;

}
