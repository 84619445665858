<h1>Listado de alumnos</h1>

<com-course-panel [course]="course"></com-course-panel>

<div fxLayout="column" fxLayoutAlign="center center">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="NumeroLista">
      <th mat-header-cell class="align-center" *matHeaderCellDef>Numero </th>
      <td mat-cell *matCellDef="let element" class="align-center"> {{element.numeroLista}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Rut">
      <th mat-header-cell *matHeaderCellDef class="align-center"> Rut </th>
      <td mat-cell *matCellDef="let element" class="align-center"> {{element.rut + '-'+element.dv}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Nombre">
      <th mat-header-cell *matHeaderCellDef class="align-left"> Nombre </th>
      <td mat-cell *matCellDef="let element" class="align-left"> {{element.paterno + ' '+element.materno + ' '+
        element.nombres}} </td>
    </ng-container>

    <ng-container matColumnDef="Comment">
      <th mat-header-cell *matHeaderCellDef class="align-left">Estado</th>
      <td mat-cell *matCellDef="let element" class="align-left"> {{element.estado==='RET'? 'Retirado (' +
        element.retired+')' : 'Matr. Vigente'}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Edad">
      <th mat-header-cell *matHeaderCellDef class="align-left">Edad</th>
      <td mat-cell *matCellDef="let element" class="align-left"> {{element.edad}} </td>
    </ng-container>

    <ng-container matColumnDef="Annotations">
      <th mat-header-cell *matHeaderCellDef class="align-left">Ant.Positivas/Eventos</th>
      <td mat-cell *matCellDef="let element" class="align-left">{{element.annotations}}
        <button mat-icon-button (click)="showAnnotations(element.id, 'Pos')" *ngIf="element.annotations>0">
          <mat-icon color="primary">preview</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="Negatives">
      <th mat-header-cell *matHeaderCellDef class="align-left">Ant.Negativas</th>
      <td mat-cell *matCellDef="let element" class="align-left"> {{element.negatives}}
        <button mat-icon-button (click)="showAnnotations(element.id, 'Neg')" *ngIf="element.negatives>0">
          <mat-icon color="primary">preview</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell *matHeaderCellDef class="align-center">Acciones</th>
      <td mat-cell *matCellDef="let element" class="align-center">

        <button mat-raised-button (click)="doAddAnnotation(element.id)" color="primary"
          matTooltip="Nueva anotacion o evento..." >
          <mat-icon>assignment_add</mat-icon>
        </button>&nbsp;

        <button mat-raised-button *ngIf="canAddLicense" (click)="doAddLicense(element.id)"
          matTooltip="Agregar Licencia..." [disabled]="this.readonly">
          <mat-icon>history_edu</mat-icon>
        </button>&nbsp;


        <button mat-raised-button *ngIf="canMakePersonalityReport" (click)="makePersonaliyReport(element.id)"
          matTooltip="Informe de Personalidad..." [disabled]="this.readonly">
          <mat-icon>checklist</mat-icon>
        </button>&nbsp;

        <button mat-raised-button (click)="openAccidentForm(element.id)" matTooltip="Formulario de Accidentes"
          [disabled]="this.readonly">
          <mat-icon>medical_information</mat-icon>
        </button>&nbsp;

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 25, 30, 40, 50]" [pageSize]="10" showFirstLastButtons>

  </mat-paginator>

  <div>&nbsp;</div>

  <div fxLayoutAlign="space-around center" style="width: 98%;">
    <button mat-raised-button color="primary" (click)="doDownloadStudentsList()">
      <mat-icon>picture_as_pdf</mat-icon> Descargar listado
    </button>

    <button mat-raised-button (click)="doCancel()">
      <mat-icon>arrow_back</mat-icon>Volver</button>
  </div>

</div>