export class UserSign {
    // username: string;
    // userSecretCode: string;
    courseId: string;
    subjectId: string;
    teacherId: string;
    type: string;
    signatureDate: string;
    blockTime: string;
    comment: string;
    scheduleId: string;
}
