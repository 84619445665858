import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CourseService } from 'src/app/api/course.service';
import { FailureProjectionService } from 'src/app/api/failure-projection.service';
import { Course } from 'src/app/model/course';
import { FailureProjection, FailureProjectionReport, PeriodStudentLessScore } from 'src/app/model/failure-projection';
import { AlertUtility } from 'src/app/utility/alert.utility';

@Component({
  selector: 'app-failure-projection',
  templateUrl: './failure-projection.component.html',
  styleUrls: ['./failure-projection.component.css', '../../../style/table.css']
})
export class FailureProjectionComponent implements OnInit {
  public dataSource: MatTableDataSource<FailureProjection4View>;
  public displayedColumns: string[] = [];
  public showLoading: boolean = true;
  public showDetail: boolean = false;
  public courseId: string | null = null;
  public title: string = 'Proyección de repitencias';

  constructor(private failureProjectionService: FailureProjectionService,
    private alertUtility: AlertUtility,
    public courseService: CourseService) { }

  ngOnInit(): void {
    this.reloadData(false);
  }

  public whenShowAllStudents(show: boolean): void {
    this.title = show ? 'Proyección Anual' : 'Proyección de repitencias';
  }

  public reloadData(refresh: boolean): void {
    this.displayedColumns = [];
    this.showLoading = true;

    this.failureProjectionService.getFailureProjection(refresh)
      .subscribe(
        response => {
          console.log(response);
          try {
            this.dataSource = this.refreshDataSource(response);
          } catch (e) {
            this.alertUtility.fastMessage(e);
          }
          //  this.dataSource.data[0].periodStudentLessScores[0].studentIds.length
          this.showLoading = false;
        },
        problems => {
          console.error(problems);
          this.alertUtility.fastMessage(problems.message);
          this.showLoading = false;
        }
      );

  }
  refreshDataSource(response: FailureProjectionReport): MatTableDataSource<FailureProjection4View> {
    const dataSource: FailureProjection4View[] = [] // new FailureProjection4View[0]; // [response.failureProjection.length];

    response.failureProjection.forEach(
      (dbRecord: FailureProjection) => {
        const newRecord: FailureProjection4View = new FailureProjection4View();
        newRecord.course = dbRecord.course;
        newRecord.failureStudentsCount = dbRecord.failureStudentsCount;
        newRecord.lessAttendanceStudentsCount = dbRecord.lessAttendanceStudentsCount;
        newRecord.studentSubjectsReprobedCount = dbRecord.studentSubjectsReprobedCount;

        dbRecord.periodStudentLessScores.forEach((period: PeriodStudentLessScore, i: number) => {
          // console.log('Data: ', newRecord , ' period.period ', period.period);
          // console.log(period, i, data);
          newRecord['period' + period.period] = new PeriodStudentLessScore();
          newRecord['period' + period.period].stundetIds = period.studentIds;
          newRecord['period' + period.period].period = period.period;
        }
        );

        dataSource.push(newRecord);
      }
    );

    this.displayedColumns.push('course');
    this.displayedColumns.push('stundentSubjectsReprobedCount');

    if (response.failureProjection.length > 0) {
      response.failureProjection[0].periodStudentLessScores
        .forEach(
          (period: PeriodStudentLessScore, i: number) => this.displayedColumns.push('period' + (i + 1))
        );
    }

    this.displayedColumns.push('deficientAttendance');
    this.displayedColumns.push('failing');
    this.displayedColumns.push('action');

    console.log(this.displayedColumns);
    return new MatTableDataSource(dataSource);
  }

  public getPeriodName(periodStudent: PeriodStudentLessScore): string {
    return 'period' + periodStudent.period;
  }

  public viewDetail(courseId: string): void {
    // this.alertUtility.fastMessage(courseId);
    this.showDetail = true;
    // localStorage.setItem('courseId', courseId);
    this.courseId = courseId;
  }


}

export class FailureProjection4View {
  course: Course;
  failureStudentsCount: number;
  studentSubjectsReprobedCount: number;
  period1: PeriodStudentLessScore;
  period2: PeriodStudentLessScore;
  period3: PeriodStudentLessScore;
  period4: PeriodStudentLessScore;
  period5: PeriodStudentLessScore;
  period6: PeriodStudentLessScore;
  period7: PeriodStudentLessScore;
  period8: PeriodStudentLessScore;
  period9: PeriodStudentLessScore;
  lessAttendanceStudentsCount: number;
}