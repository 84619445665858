import { HttpEvent } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AccidentService } from 'src/app/api/accident.service';
import { AuthorService } from 'src/app/api/author.service';
import { DateTimeUtility } from 'src/app/api/date-time-utility.service';
import { Accident } from 'src/app/model/accident';
import { Alumno } from 'src/app/model/alumno';
import { AlertUtility } from 'src/app/utility/alert.utility';
import { DownloadUtility } from 'src/app/utility/download.utility';
import { EditAccidentDialogComponent, EditAccidentDialogDataParam } from './edit-accident-dialog/edit-accident-dialog.component';
import { UploadAccidentAttentionFileDialogComponent, UploadAccidentAttentionFileDialogDataParam } from './upload-dialog/upload-accident-attention-file-dialog.component';
import { HttpUtility } from 'src/app/utility/http-utility';
import { Moment } from 'moment';
import { FileHandler } from 'src/app/model/file-handler';

@Component({
  selector: 'accidents-list',
  templateUrl: './accidents-list.component.html',
  styleUrls: ['./accidents-list.component.css',
    '../../../style/table.css'
  ]
})
export class AccidentsListComponent implements OnInit {
  @Input() public student: Alumno;
  @Input() public year: number;

  public datasource: MatTableDataSource<Accident>;
  public displayedColumns: string[] = ['accidentDate', 'description', 'healthPlace', 'escorts', 'attentionDate', 'returnsDate', 'actions'];

  constructor(private accidentService: AccidentService,
    public dateTimeUtility: DateTimeUtility,
    private dialog: MatDialog,
    private authService: AuthorService,
    private downloadUtility: DownloadUtility,
    private alert: AlertUtility,
    public utilsService: HttpUtility) { }

  ngOnInit(): void {
    this.accidentService.listAccidents(this.student.id)
      .subscribe(
        (response: Accident[]) => {
          this.datasource = new MatTableDataSource(response);
        },
        problems => console.error(problems)
      );
  }

  public editInformation(id: string): void {
    const params: EditAccidentDialogDataParam = new EditAccidentDialogDataParam();

    const record: Accident = this.datasource.data.filter(row => row.id == id)[0];

    params.accidentId = id;
    params.studentName = `${this.student.paterno} ${this.student.materno}, ${this.student.nombres}`;
    params.courseLeter = this.student.course;
    params.description = record.description;
    params.escortsName = record.escortsName;
    params.escortsRelationship = record.escortsRelationship;
    params.accidentDate = this.dateTimeUtility.stringToMoment(record.accidentDate);
    params.attentionDate = this.dateTimeUtility.stringToMoment(record.attentionDate);
    params.returnsDate = this.dateTimeUtility.stringToMoment(record.returnsDate);
    params.healthPlace = record.healthPlace;

    const dialogRef = this.dialog.open(EditAccidentDialogComponent, { data: params });
    dialogRef.afterClosed()
      .subscribe(
        (response: EditAccidentDialogDataParam) => {
          if (response == null) return;
          console.log(response);

          record.returnsDate = this.convertDate(response.returnsDate, true); // this.dateTimeUtility.momentToHumanString(response.returnsDate);
          record.attentionDate = this.convertDate(response.attentionDate, true); // this.dateTimeUtility.momentToHumanString(response.attentionDate);

          const accident: Accident = new Accident();
          accident.id = response.accidentId;
          accident.returnsDate = this.convertDate(response.returnsDate, false); // this.dateTimeUtility.momentToString(response.returnsDate);
          accident.attentionDate = this.convertDate(response.attentionDate, false); // this.dateTimeUtility.momentToString(response.attentionDate);

          this.accidentService.updateAccident(accident)
            .subscribe(
              response => console.log(response),
              problems => console.error(problems)
            );
        }
      );
  }

  private convertDate(date: Moment, asHumanFormat: boolean): string {
    if (date == null) return null;
    if (!date.isValid()) return null;

    if (asHumanFormat) {
      return this.dateTimeUtility.momentToHumanString(date)
    } else {
      return this.dateTimeUtility.momentToString(date)
    }

  }


  public uploadFile(id: string): void {
    const params = new UploadAccidentAttentionFileDialogDataParam();
    params.accidentId = id;

    const dialogRef = this.dialog.open(UploadAccidentAttentionFileDialogComponent, { data: params });

    dialogRef.afterClosed()
      .subscribe(
        (responseDialog: UploadAccidentAttentionFileDialogDataParam) => {
          if (responseDialog == null) return;

          this.uploadAttentionFile(responseDialog);

        }
      );
  }

  private async uploadAttentionFile(responseDialog: UploadAccidentAttentionFileDialogDataParam) {
    const response: HttpEvent<Accident> = await this.accidentService
      .uploadAttentionFile(responseDialog.accidentId, responseDialog.file, this.authService.user.username)
      .toPromise();

    const accident: Accident = await this.accidentService.getAsync(responseDialog.accidentId);

    const accidentGrid = this.datasource.data.filter(acc => acc.id == responseDialog.accidentId)[0]; //
    accidentGrid.fileHandler = new FileHandler();

    accidentGrid.fileHandler.id = accident.fileHandler.id;
    accidentGrid.fileHandler.contentType = accident.fileHandler.contentType;
    accidentGrid.fileHandler.originalFilename = accident.fileHandler.originalFilename;

  }

  public async downloadAttentionFile(id: string): Promise<void> {
    const accident: Accident = await this.accidentService.getAsync(id);
    console.log(accident);

    this.accidentService.downloadAttentionFile(id)
      .subscribe(
        (response: ArrayBuffer) => this.utilsService.download(response, accident.fileHandler.originalFilename),
        problems => {
          this.alert.fastMessage('Error al intentar obtener archivo de atención.');
          console.error(problems);
        }
      );

  }

}
