import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/api/config.service';
import { CourseService } from 'src/app/api/course.service';
import { PostulanteService } from 'src/app/api/postulante.service';
import { Postulante } from 'src/app/model/postulant';

@Component({
  selector: 'app-matricula-postulante',
  templateUrl: './matricula-postulante.component.html',
  styleUrls: ['./matricula-postulante.component.css',
    '../../../style/table.css']
})
export class MatriculaPostulanteComponent implements OnInit {
  displayedColumns: string[] = ['rut', 'nombre', 'especialidad', 'canal', 'curso', 'nro_matricula'];
  dataSource: MatTableDataSource<Postulante>;
  filterValue: string = '';
  showResult: boolean = false;
  rutOrPaterno: string = '';
  public year: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private postulanteService: PostulanteService,
    public cursoService: CourseService,
    private router: Router,
    private configService: ConfigService) { }

  ngOnInit() {
    this.configService.findByKey("matricula_actual").subscribe(response => this.year = +response.valor);
  }

  onSearchClick(): void {
    if (this.rutOrPaterno.length < 3) {
      alert('Debe ingresar al menos 3 caracteres!');
      return;
    }

    this
      .postulanteService
      .findByRutOrPaterno(this.rutOrPaterno)
      .subscribe(
        response => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response as Postulante[]);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.applyFilter(null);
          this.showResult = true;
        },
        problems => console.error(problems)
      );
  }

  applyFilter(e: Event) {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onPressNameClick(row) {
    //if (row.enrollNumber == 0) {
    localStorage.setItem("postulantId", row.id);
    localStorage.setItem("enrollingTo", "P");

    this.router.navigate(['matricula-form']);
    //} else {
    //  alert('Alumno ya esta matriculado');
    // }

  }
}
