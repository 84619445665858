import { CalendarCell } from "./calendar-cell";
import { Hour } from "./hour";

export class CalendarRow {
    hour: Hour;
    monday: CalendarCell;
    tuesday: CalendarCell;
    wednesday: CalendarCell;
    thursday: CalendarCell;
    friday: CalendarCell;
    saturday: CalendarCell;
    sunday: CalendarCell;
}