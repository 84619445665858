import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'src/app/api/company.service';
import { PracticeService } from 'src/app/api/practice.service';
import { CourseService } from 'src/app/api/course.service';
import { Chart } from 'chart.js/auto';
import { MatDialog} from '@angular/material/dialog';
import { DialogSelectCourse } from './dialog-select-course.component';
import { ConfigService } from 'src/app/api/config.service';


@Component({
  selector: 'app-management-practices',
  templateUrl: './management-practices.component.html',
  styleUrls: ['./management-practices.component.css']
})
export class ManagementPracticesComponent implements OnInit {
  public chart: any;
  totalCompany: string = '';
  totalPractices: string = '';
  year: number = new Date().getFullYear() + 1;
  practicesData: { year: number; practices: string; }[]  = [];
  practicesCompanyData: any[]  = [];
  levelCourses: any;
  levelCoursesByYear: any;
  practicaActual  : string;
  levelId : string = 'dd8aff58-50a4-11ed-95c1-eb77947fc043';

  constructor(private router: Router,
    private companyService: CompanyService,
    private practiceService: PracticeService,
    private courseService: CourseService,
    public dialog: MatDialog,
    private configService: ConfigService) {
  }

  async ngOnInit() {
    sessionStorage.removeItem('ACTION_COMPANY');

   await this
      .configService
      .getValueByKey("practica_actual")
      .subscribe(response => {
        localStorage.setItem('practica_actual', response);
        this.practicaActual = response;

        setTimeout(async () => {
          for(var i = 0; i < 5; i++) {
            let yearQuery = this.year - i;
            await this.practiceService
            .countPracticesYear(yearQuery.toString())
            .subscribe(
              response => {
               if(yearQuery.toString() == this.practicaActual){
                this.totalPractices = response.total;
               }
                this.practicesData.push({year:yearQuery,practices:response.total});
              },
              problems => {
                console.log(problems);

              }
            );
          }},100);
      });





    await this.companyService
    .countCompanies()
    .subscribe(
      response => {
        this.totalCompany = response.total;
      },
      problems => {
        console.log(problems);

      }
    );

    /*await this.practiceService
    .countPractices()
    .subscribe(
      response => {
        this.totalPractices = response.total;
      },
      problems => {
        console.log(problems);

      }
    );*/


    //this.levelCourses = await this.courseService.listByLevelAndExcludeAsync(this.levelId, 'ed5d3600-c623-46f5-a60e-261c9fc1ccbc');
    setTimeout(async () => {
      this.levelCoursesByYear = await this.courseService.listAllByYearAsync(Number(this.practicaActual)-1);
      this.levelCourses = this.levelCoursesByYear.filter(c => c.letra.includes('3'));
    }, 500);
  }

  ngAfterViewInit(){
    setTimeout(async () => {
      const practicesDataSorted = this.practicesData.sort((a, b) => a.year - b.year);
      this.createChartPractices();

      this.practiceService
      .topCompanyPractices()
      .subscribe(
        response => {
          this.practicesCompanyData = response;
          setTimeout(async () => {
            this.createChartCompany();
          },500);
        },
        problems => {
          console.log(problems);

        }
      );
    },500);

  }

  createChartPractices(){
    this.chart = new Chart("MyChart", {
      type: 'bar',//doughnut
      data: {
        labels: this.practicesData.map(row => row.year),
        datasets: [
          {
            label: 'Practicas por año',
            data: this.practicesData.map(row => row.practices),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)'
            ],
            borderColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)'
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
       aspectRatio:3.0,
       indexAxis: 'y'
      }

    });
  }

  createChartCompany(){
    this.chart = new Chart("MyChart2", {
      type: 'bar',
      data: {
        labels: this.practicesCompanyData.map(row => row.empresa),
        datasets: [
          {
            label: 'Histórico de practicas',
            data: this.practicesCompanyData.map(row => row.total),
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(216, 101, 23, 0.2)',
              'rgba(70, 101, 33, 0.2)',
              'rgba(142, 142, 142, 0.2)',
              'rgba(10, 201, 83, 0.2)',
              'rgba(109, 71, 104, 0.2)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(216, 101, 23, 0.2)',
              'rgba(70, 101, 33, 0.2)',
              'rgba(142, 142, 142, 0.2)',
              'rgba(10, 201, 83, 0.2)',
              'rgba(109, 71, 104, 0.2)'
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
       aspectRatio:3.0,
       indexAxis: 'y'
      }

    });
  }

  listCompaniesOnClick(): void {
    this.router.navigate(['list-companies']);
  }

  listAllCompaniesOnClick(action : string = undefined): void {
    this.router.navigate(['list-all-companies']);
    sessionStorage.setItem('ACTION_COMPANY',action);
  }

  addCompanyOnClick(): void  {
    this.router.navigate(['add-company']);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogSelectCourse, {
      data: this.levelCourses
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
